import { Reducer } from 'react';

type State = {
  file: File | undefined;
};

type SetFileAction = {
  type: 'SET_FILE';
  file: File;
};

type ResetAction = {
  type: 'RESET';
};

export type FileAction = SetFileAction | ResetAction;

export const fileUploadReducer: Reducer<State, FileAction> = (
  state,
  action,
) => {
  switch (action.type) {
    case 'SET_FILE':
      return {
        ...state,
        file: action.file,
      };
    case 'RESET':
      return {
        ...state,
        file: undefined,
      };
    default:
      return state;
  }
};
