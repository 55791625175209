import countryList from 'country-list';
import { sortBy } from 'lodash';
import React from 'react';

import { overwrittenCountries } from '@/utils/formatter/formatCountryCode';
import { BaseInputFieldProps } from './BaseInputField';
import { SelectField } from './SelectField';

countryList.overwrite(overwrittenCountries);

const options = countryList.getData().map(({ code, name }) => ({
  value: code,
  label: name,
}));

const sortedOptions = sortBy(options, (a) => a.label);

export const CountryField = (
  props: BaseInputFieldProps & { isMulti?: boolean; allowSelectAll?: boolean },
) => {
  return <SelectField options={sortedOptions} {...props} />;
};
