import { graphql } from 'gatsby';
import React, { FC } from 'react';

import {
  useGetGlobalPlayerOverviewPageLink,
  useGetPlayerOverviewPageLink,
} from '@/bits/links/useLink';
import { Card, CardBody, CardOptions, CardOptionsButton } from '@/components';
import { useDataGrid } from '@/components/DataGrid';
import { NextIcon, PreviousIcon, RefreshIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import { PendingKycFlowViewSearchOrder } from '@/globalTypes';
import usePendingKycFlows from './usePendingKycFlows';

export const Fragment = graphql`
  fragment SanityKycPendingFlowsBlock on SanityKycPendingFlowsBlock {
    title {
      ...SanityLocaleString
    }
    playerId {
      ...SanityLocaleString
    }
    playerGlobalId {
      ...SanityLocaleString
    }
    kycCheckStartedAt {
      ...SanityLocaleString
    }
    kycCheckDeadline {
      ...SanityLocaleString
    }
  }
`;

const KycPendingFlowsBlock: FC<{
  block: Queries.SanityKycPendingFlowsBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();
  const getPlayerOverviewLink = useGetPlayerOverviewPageLink();
  const getGlobalPlayerOverviewLink = useGetGlobalPlayerOverviewPageLink();

  const {
    kycPendingFlows,
    fetching,
    nextPage,
    previousPage,
    refresh,
    orderBy,
    setOrderBy,
    desc,
    defaultFilter,
    updateFilter,
  } = usePendingKycFlows();

  const { DataGrid, GlobalCardOptions } = useDataGrid({
    name: 'kycPendingFlows',
    data: kycPendingFlows,
    fetching,
    orderBy,
    setOrderBy,
    desc,
    defaultFilter,
    updateFilter,
    schema: (s) => [
      s.stringValue({
        field: 'playerGlobalUUID',
        title: t(block.playerGlobalId),
        filterField: 'playerGlobalUUID',
        linkTo: ({ row }) =>
          getGlobalPlayerOverviewLink(`${row.playerGlobalId}`) ?? '',
      }),
      s.stringValue({
        field: 'playerUUID',
        title: t(block.playerId),
        filterField: 'playerId',
        linkTo: ({ row }) => getPlayerOverviewLink(`${row.playerId}`) ?? '',
      }),
      s.dateTimeValue({
        field: 'kycCheckStartedAt',
        title: t(block.kycCheckStartedAt),
        orderBy: PendingKycFlowViewSearchOrder.kycCheckStartedAt,
        fromFilterField: 'kycCheckStartedFrom',
        toFilterField: 'kycCheckStartedTo',
      }),
      s.dateTimeValue({
        field: 'kycCheckDeadline',
        title: t(block.kycCheckDeadline),
        orderBy: PendingKycFlowViewSearchOrder.kycCheckDeadline,
        fromFilterField: 'kycCheckDeadlineFrom',
        toFilterField: 'kycCheckDeadlineTo',
      }),
    ],
  });

  return (
    <Card
      size="lg"
      title={t(block.title)}
      showOptionsAtBottom
      options={
        <CardOptions>
          <CardOptionsButton
            disabled={!previousPage}
            onClick={() => previousPage && previousPage()}
          >
            <PreviousIcon />
          </CardOptionsButton>
          <CardOptionsButton
            disabled={!nextPage}
            onClick={() => nextPage && nextPage()}
          >
            <NextIcon />
          </CardOptionsButton>
          <GlobalCardOptions />
          <CardOptionsButton
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <DataGrid />
      </CardBody>
    </Card>
  );
};

export default KycPendingFlowsBlock;
