import React from 'react';

import { Card, CardBody, CardOptions, CardOptionsButton } from '@/components';

const example = () => {
  return (
    <Card
      size="lg"
      title="Test Block"
      options={
        <CardOptions>
          <CardOptionsButton>
            <svg
              className="w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path fill="#fff" d="M12 14l9-5-9-5-9 5 9 5z" />
              <path
                fill="#fff"
                d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
              />
            </svg>
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        helloooo
        {/* <div className="overflow-x-auto">
            <table className="w-full text-left border-collapse table-auto">
              <thead className="bg-gray-50 border-gray-200 border-b p-2">
                <tr className="text-sm font-semibold divide-x">
                  <Header
                    property={PlayerSearchOrder.countryCode}
                    name={t(block.country)}
                  />
                  <Header property={PlayerSearchOrder.brand} name="Brand" />
                  <Header property={PlayerSearchOrder.firstName} name="Name" />
                  <Header
                    property={PlayerSearchOrder.registeredAt}
                    name="Registration Date"
                  />
                  <Header name="Account Status" />
                  <Header property={PlayerSearchOrder.balance} name="Balance" />
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-100">
                {playerSearchItems?.map((item) => {
                  const PlayerLink: FC = ({ children }) => (
                    <Link
                      className="block p-3 w-full"
                      to={insertParams(
                        block.playerLinkPage?.path?.current,
                        item,
                      )}
                    >
                      {children}
                    </Link>
                  );

                  return (
                    <tr key={item?.playerId} className="hover:bg-gray-100">
                      <td className="text-sm text-gray-800 whitespace-nowrap">
                        <PlayerLink>
                          <Country countryCode={item?.countryCode} />
                        </PlayerLink>
                      </td>
                      <td className="text-sm text-gray-800 whitespace-nowrap">
                        <PlayerLink>{item?.brand?.name}</PlayerLink>
                      </td>
                      <td className="text-sm text-gray-800 whitespace-nowrap">
                        <PlayerLink>
                          {item?.firstName} {item?.lastName}
                        </PlayerLink>
                      </td>
                      <td className="text-sm text-gray-800 whitespace-nowrap">
                        <PlayerLink>
                          <Date date={item?.registrationInfoV2?.registeredAt} />
                        </PlayerLink>
                      </td>
                      <td className="text-sm text-gray-800 whitespace-nowrap">
                        <PlayerLink>{item?.accountStatus}</PlayerLink>
                      </td>
                      <td className="text-sm text-gray-800 whitespace-nowrap">
                        <PlayerLink>
                          <Money amount={item?.balance} />
                        </PlayerLink>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div> */}
      </CardBody>
    </Card>
  );
};

export default example;
