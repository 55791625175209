import { graphql } from 'gatsby';
import React, { FC } from 'react';

import {
  useGetPaymentPageLink,
  useGetPlayerOverviewPageLink,
} from '@/bits/links/useLink';
import { Card, CardBody, CardOptions, CardOptionsButton } from '@/components';
import { useDataGrid } from '@/components/DataGrid';
import {
  DocumentDownloadIcon,
  NextIcon,
  PreviousIcon,
  RefreshIcon,
} from '@/components/icons';
import { useTranslate } from '@/contexts';
import { PaymentSearchOrder, PaymentStatus, PaymentType } from '@/globalTypes';
import usePayments from './usePayments';
import usePaymentsReport from './usePaymentsReport';

export const Fragment = graphql`
  fragment SanityPaymentsBlock on SanityPaymentsBlock {
    title {
      ...SanityLocaleString
    }
  }
`;

const PaymentsBlock: FC<{
  block: Queries.SanityPaymentsBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();
  const {
    payments,
    fetching,
    nextPage,
    previousPage,
    refresh,
    orderBy,
    desc,
    setOrderBy,
    defaultFilter,
    updateFilter,
  } = usePayments();
  const report = usePaymentsReport();
  const getPlayerOverviewPageLink = useGetPlayerOverviewPageLink();
  const paymentPageLink = useGetPaymentPageLink();

  const { DataGrid, GlobalCardOptions } = useDataGrid({
    name: 'payments',
    data: payments,
    fetching,
    orderBy,
    setOrderBy,
    desc,
    defaultFilter,
    updateFilter,
    getRowLink: ({ row }) => paymentPageLink(row.rawPaymentId),
    schema: (s) => [
      s.stringValue({
        field: 'playerId',
        title: 'Player',
        linkTo: ({ row }) =>
          (row.playerId && getPlayerOverviewPageLink(row.playerId)) ?? '',
      }),
      s.dateTimeValue({
        field: 'processedAt',
        title: 'Last Updated At',
        fromFilterField: 'from',
        toFilterField: 'to',
        orderBy: PaymentSearchOrder.processedAt,
        includeTime: true,
        includeTimeInFilter: true,
      }),
      s.dateTimeValue({
        field: 'completedAt',
        title: 'Completed At',
        orderBy: 'completedAt',
      }),
      s.dateTimeValue({
        field: 'cancelledAt',
        title: 'Cancelled At',
        orderBy: 'cancelledAt',
      }),
      s.dateTimeValue({
        field: 'rollbackAt',
        title: 'Rollback At',
        orderBy: 'rollbackAt',
      }),
      s.dateTimeValue({
        field: 'initializedAt',
        title: 'Initialized At',
        orderBy: 'initializedAt',
      }),
      s.monetaryValue({
        field: 'initialAmount',
        title: 'Initial Amount',
      }),
      s.stringValue({
        field: 'rawPaymentId',
        title: 'Payment ID',
        filterField: 'rawId',
      }),
      s.monetaryValue({
        field: 'amount',
        title: 'Amount',
      }),
      s.enumValue({
        field: 'paymentType',
        title: 'Payment Type',
        e: PaymentType,
        filterField: 'paymentTypes',
      }),
      s.enumValue({
        field: 'paymentStatus',
        title: 'Payment Status',
        e: PaymentStatus,
        filterField: 'paymentStatus',
        isMulti: false,
      }),
      s.stringValue({
        field: 'paymentMethod',
        title: 'Payment Method',
      }),
      s.monetaryValue({
        field: 'openingBalance',
        title: 'Opening Balance',
      }),
      s.monetaryValue({
        field: 'closingBalance',
        title: 'Closing Balance',
      }),
      s.stringValue({
        field: 'instrument',
        title: 'Instrument',
      }),
      s.stringValue({
        field: 'statusMessage',
        title: 'Message',
      }),
      s.stringValue({
        field: 'extTransactionId',
        title: 'Ext. ID',
      }),
      s.monetaryValue({
        field: 'paymentFee',
        title: 'Payment Fee',
        getValue: ({ row }) => {
          return row?.paymentFee?.money.amount || 0;
        },
      }),
    ],
  });

  return (
    <Card
      size="lg"
      title={t(block.title)}
      showOptionsAtBottom
      options={
        <CardOptions>
          <CardOptionsButton
            disabled={!previousPage}
            onClick={() => previousPage && previousPage()}
          >
            <PreviousIcon />
          </CardOptionsButton>
          <CardOptionsButton
            disabled={!nextPage}
            onClick={() => nextPage && nextPage()}
          >
            <NextIcon />
          </CardOptionsButton>
          <GlobalCardOptions />
          <CardOptionsButton
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
          <CardOptionsButton onClick={() => report.generate()}>
            <DocumentDownloadIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <DataGrid />
      </CardBody>
    </Card>
  );
};

export default PaymentsBlock;
