import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { useQuery } from 'urql';

import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  ControlledModal,
  Copy,
  InlineIconButton,
  Value,
} from '@/components';
import { EditIcon, RefreshIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import { PlayerForfeitActiveBonusForm } from '@/forms';
import { BonusStatus } from '@/globalTypes';
import { usePlayer } from '@/hooks/usePlayer';
import { getInitiator } from '@/utils';
import formatMoney from '@/utils/formatter/formatMoney';
import {
  PlayerActiveBonusBoxQuery,
  PlayerActiveBonusBoxQueryVariables,
} from './__generated__/component';

export const Fragment = graphql`
  fragment SanityPlayerActiveBonusBlock on SanityPlayerActiveBonusBlock {
    title {
      ...SanityLocaleString
    }
    amountLabel {
      ...SanityLocaleString
    }
    maxPayoutLabel {
      ...SanityLocaleString
    }
    createdAtLabel {
      ...SanityLocaleString
    }
    expiresAtLabel {
      ...SanityLocaleString
    }
    processedAtLabel {
      ...SanityLocaleString
    }
    wageringProgressLabel {
      ...SanityLocaleString
    }
    wageringRequirementLabel {
      ...SanityLocaleString
    }
    wageringRequirementFactorLabel {
      ...SanityLocaleString
    }
    createdByLabel {
      ...SanityLocaleString
    }
    currentAmountLabel {
      ...SanityLocaleString
    }
    rewardIdLabel {
      ...SanityLocaleString
    }
    campaignIdLabel {
      ...SanityLocaleString
    }
  }
`;

const QUERY = gql`
  query PlayerActiveBonusBox(
    $playerIds: [String!]!
    $statuses: [BonusStatus!]!
  ) {
    viewer {
      bonuses(playerIds: $playerIds, statuses: $statuses) {
        edges {
          node {
            id
            bonusId
            rewardId
            campaignId
            amount
            closingBalance
            currency
            wageringProgress
            wageringRequirement
            wageringRequirementFactor
            maxPayout
            expiresAt
            createdAt
            processedAt
            createdBy {
              ... on AgentInitiator {
                agent {
                  firstName
                  lastName
                }
              }
            }
          }
        }
      }
    }
  }
`;

const PlayerActiveBonusBlock: FC<{
  block: Queries.SanityPlayerActiveBonusBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();
  const { uuid } = usePlayer();
  const { playerId } = useParams();

  const [{ data, fetching }, refresh] = useQuery<
    PlayerActiveBonusBoxQuery,
    PlayerActiveBonusBoxQueryVariables
  >({
    query: QUERY,
    variables: {
      playerIds: [uuid],
      statuses: [BonusStatus.Active],
    },
    pause: !uuid,
  });

  const activeBonus = data?.viewer?.bonuses?.edges?.[0]?.node;

  const agentName = activeBonus?.createdBy
    ? getInitiator(activeBonus.createdBy)
    : '-';

  const wageringProgressInPercent =
    (activeBonus?.wageringProgress / activeBonus?.wageringRequirement) * 100;

  const wageringProgressAmount = formatMoney(
    activeBonus?.wageringProgress,
    activeBonus?.currency,
  );

  const wageringProgress = wageringProgressInPercent
    ? `${wageringProgressInPercent}% (${wageringProgressAmount})`
    : '-';

  return (
    <Card
      size="lg"
      title={
        <>
          {`${t(block.title)} - `}
          <Copy fetching={fetching} value={activeBonus?.bonusId} />
        </>
      }
      options={
        <CardOptions>
          <CardOptionsButton
            className="flex"
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <div className="p-3 grid grid-rows-4 grid-cols-1 grid-flow-col sm:grid-cols-2 md:grid-cols-3 gap-2">
          <Value
            fetching={fetching}
            title={t(block.amountLabel)}
            value={formatMoney(activeBonus?.amount, activeBonus?.currency)}
            suffix={
              activeBonus ? (
                <ControlledModal
                  content={<PlayerForfeitActiveBonusForm playerId={playerId} />}
                >
                  <InlineIconButton>
                    <EditIcon />
                  </InlineIconButton>
                </ControlledModal>
              ) : null
            }
          />
          <Value
            fetching={fetching}
            title={t(block.currentAmountLabel)}
            value={formatMoney(
              activeBonus?.closingBalance,
              activeBonus?.currency,
            )}
          />
          <Value
            fetching={fetching}
            title={t(block.wageringProgressLabel)}
            value={wageringProgress}
          />
          <Value
            fetching={fetching}
            title={t(block.wageringRequirementLabel)}
            value={formatMoney(
              activeBonus?.wageringRequirement,
              activeBonus?.currency,
            )}
          />
          <Value
            fetching={fetching}
            title={t(block.wageringRequirementFactorLabel)}
            value={activeBonus?.wageringRequirementFactor}
          />
          <Value
            fetching={fetching}
            title={t(block.maxPayoutLabel)}
            value={formatMoney(activeBonus?.maxPayout, activeBonus?.currency)}
          />
          <Value
            fetching={fetching}
            title={t(block.createdAtLabel)}
            value={activeBonus?.createdAt}
          />
          <Value
            fetching={fetching}
            title={t(block.expiresAtLabel)}
            value={activeBonus?.expiresAt}
          />
          <Value
            fetching={fetching}
            title={t(block.processedAtLabel)}
            value={activeBonus?.processedAt}
          />
          <Value
            fetching={fetching}
            title={t(block.createdByLabel)}
            value={agentName}
          />
          <Value
            fetching={fetching}
            title={t(block.rewardIdLabel)}
            value={activeBonus?.rewardId}
          />
          <Value
            fetching={fetching}
            title={t(block.campaignIdLabel)}
            value={activeBonus?.campaignId}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default PlayerActiveBonusBlock;
