import gql from 'graphql-tag';
import { useQuery } from 'urql';
import { withDefault } from 'use-query-params';

import { PaymentSearchOrder } from '@/globalTypes';
import { useQueryParamsWithTime } from '@/hooks';
import { mapVariables } from '@/utils';
import { makeOrderBy, makePagination } from '@/utils/pagination';
import { PaymentSearchOrderParam } from '@/utils/query/params';
import {
  PaymentsQuery,
  PaymentsQueryVariables,
} from './__generated__/usePayments';
import { queryParams } from './queryParams';

const PAYMENTS_QUERY = gql`
  query Payments(
    $id: String
    $rawId: String
    $from: OffsetDateTime
    $to: OffsetDateTime
    $paymentType: PaymentType
    $paymentStatus: PaymentStatus
    $orderBy: PaymentSearchOrder
    $desc: Boolean
    $indexPrefix: String
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    viewer {
      brands {
        code
        name
      }
      payments(
        id: $id
        rawId: $rawId
        from: $from
        to: $to
        paymentType: $paymentType
        paymentStatus: $paymentStatus
        orderBy: $orderBy
        desc: $desc
        indexPrefix: $indexPrefix
        before: $before
        after: $after
        first: $first
        last: $last
      ) {
        edges {
          node {
            rawPaymentId
            playerId
            initialAmount
            amount
            processedAt
            completedAt
            cancelledAt
            rollbackAt
            initializedAt
            paymentType
            brand {
              name
            }
            paymentStatus
            openingBalance
            closingBalance
            instrument
            paymentMethod
            statusMessage
            paymentFee {
              money {
                amount
                currency
              }
            }
            extTransactionId
            currency
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;

export default function usePayments() {
  const [query, setQuery] = useQueryParamsWithTime(
    {
      ...queryParams,
      orderBy: withDefault(
        PaymentSearchOrderParam,
        PaymentSearchOrder.processedAt,
      ),
    },
    {
      fromAndToOptional: true,
    },
  );

  const mappedVariables = mapVariables(query);
  const [{ data, fetching }, refresh] = useQuery<
    PaymentsQuery,
    PaymentsQueryVariables
  >({
    query: PAYMENTS_QUERY,
    variables: {
      ...mappedVariables,
      paymentStatus: mappedVariables.paymentStatus?.[0],
      paymentType: mappedVariables.paymentTypes?.[0],
    },
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter: (values: {}) => setQuery(values, 'replaceIn'),
    ...makeOrderBy(query, setQuery),
    ...makePagination(query, setQuery, data?.viewer.payments.pageInfo),
    brands: data?.viewer.brands,
    payments: data?.viewer.payments?.edges?.map((edge) => edge?.node),
  };
}
