import React from 'react';

import { logger } from '@/utils';
import { PlayerRiskAssessmentHistoryQuery } from './__generated__/usePlayerRiskAssessmentHistory';

const formatOutcome = (outcomeName: string) => {
  return outcomeName.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
};

const simpleOutcomeTypesArray = [
  'RiskOverrideRemoved',
  'RiskLevelUpdated',
  'ReviewNeeded',
  'ReviewApproved',
  'ReviewRejected',
  'ReviewPending',
];

export const renderRiskOutcomes = (
  row: NonNullable<
    NonNullable<
      PlayerRiskAssessmentHistoryQuery['viewer']['riskAssessmentHistory']['edges']
    >[number]
  >['node'],
) => {
  return row.outcomes.map((outcome) => {
    if (outcome.__typename === 'RiskOverrideAdded') {
      return (
        <>
          <li>{formatOutcome(outcome?.name)}</li>
          <li>{outcome.riskLevel}</li>
        </>
      );
    }
    if (simpleOutcomeTypesArray.indexOf(outcome.__typename) >= 0) {
      return <li>{formatOutcome(outcome?.name)}</li>;
    }
    if (outcome.__typename === 'FinalRiskLevelUpdated') {
      return <li>{outcome.riskLevel}</li>;
    }
    logger.warn(`${outcome.__typename} not found`);
    return null;
  });
};
