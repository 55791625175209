import {
  Active,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import React, { Fragment, ReactNode, useMemo, useState } from 'react';

import { SortableOverlay } from './SortableOverlay';

type BaseItem = {
  [key: string]: any;
};

type Props<T extends BaseItem> = {
  items: T[];
  idFieldName: keyof T;
  onChange(items: T[]): void;
  renderItem(item: T): ReactNode;
  className?: string;
};

export const SortableList = <T extends BaseItem>({
  items,
  idFieldName,
  onChange,
  renderItem,
  className,
}: Props<T>) => {
  const [active, setActive] = useState<Active | null>(null);

  const itemsWithId = useMemo(
    () =>
      items.map((item) => ({
        ...item,
        id: item[idFieldName],
      })),
    [items, idFieldName],
  );

  const activeItem = useMemo(
    () => items.find((item) => item[idFieldName] === active?.id),
    [active?.id, idFieldName, items],
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  return (
    <DndContext
      sensors={sensors}
      onDragStart={({ active }) => {
        setActive(active);
      }}
      onDragEnd={({ active, over }) => {
        if (over && active.id !== over?.id) {
          const activeIndex = items.findIndex(
            (item) => item[idFieldName] === active.id,
          );

          const overIndex = itemsWithId.findIndex(
            (item) => item[idFieldName] === over.id,
          );
          onChange(arrayMove(items, activeIndex, overIndex));
        }
        setActive(null);
      }}
      onDragCancel={() => {
        setActive(null);
      }}
    >
      <SortableContext items={itemsWithId}>
        <div
          className={
            className ? className : 'm-0 flex flex-col space-y-2 py-3 pr-3'
          }
          role="application"
        >
          {items.map((item) => (
            <Fragment key={item[idFieldName]}>{renderItem(item)}</Fragment>
          ))}
        </div>
      </SortableContext>
      <SortableOverlay>
        {activeItem ? renderItem(activeItem) : null}
      </SortableOverlay>
    </DndContext>
  );
};
