import gql from 'graphql-tag';
import { useMemo } from 'react';
import { useQuery } from 'urql';

import { SelectOption } from '@/components';
import { Nullable } from '@/types';
import { BrandsQuery } from './__generated__/useBrands';

const query = gql`
  query Brands {
    viewer {
      id
      brands {
        code
        name
      }
    }
  }
`;

export const useBrands = () => {
  const [{ fetching, data }] = useQuery<BrandsQuery>({
    query,
  });

  return { fetching, brands: data?.viewer.brands };
};

type BrandEntry = Omit<BrandsQuery['viewer']['brands'][number], '__typename'>;

export const useBrandValueOptions = (brands: Nullable<BrandEntry[]>) => {
  const options = useMemo<SelectOption[]>(() => {
    if (!brands) {
      return [];
    }

    return brands?.map((brand) => ({
      value: brand.code,
      label: brand.name,
    }));
  }, [brands]);

  return { options };
};
