import React from 'react';
import { useForm } from 'react-hook-form';

import { logger } from '@/utils';
import { NakedForm } from '../Form';
import { SwitchField } from './SwitchField';

const Example = () => {
  const methods = useForm({
    defaultValues: {
      myField: undefined,
    },
  });

  const values = methods.watch();

  return (
    <>
      <NakedForm methods={methods} onSubmit={logger.log}>
        <SwitchField
          name="myField"
          title="Mah switch (standard label placement)"
        />
      </NakedForm>
      {JSON.stringify(values)}
    </>
  );
};

export default Example;
