import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { useQuery } from 'urql';

import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  Copy,
  Date as DateComponent,
  Value,
} from '@/components';
import { RefreshIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import formatCountryCode from '@/utils/formatter/formatCountryCode';
import {
  PlayerRewardsActivityInfoBoxQuery,
  PlayerRewardsActivityInfoBoxQueryVariables,
} from './__generated__/component';

export const Fragment = graphql`
  fragment SanityPlayerRewardsActivityInfoBlock on SanityPlayerRewardsActivityInfoBlock {
    title {
      ...SanityLocaleString
    }
    registered {
      ...SanityLocaleString
    }
    lastDepositAmount {
      ...SanityLocaleString
    }
    lastDeposit {
      ...SanityLocaleString
    }
    numberOfFreeSpinsAssignations {
      ...SanityLocaleString
    }
    lastBonusDate {
      ...SanityLocaleString
    }
    lastMoneyDrop {
      ...SanityLocaleString
    }
  }
`;

const QUERY = gql`
  query PlayerRewardsActivityInfoBox($playerId: ID!) {
    player(playerId: $playerId) {
      id
      uuid
      depositInfoV2 {
        last
      }
      loginInfo {
        registration {
          loggedAt
          countryCode
        }
      }
      lastBonusAdjustment: transactionsV2(
        first: 1
        desc: true
        orderBy: processedAt
        adjustmentCategory: Bonus
      ) {
        edges {
          node {
            processedAt
          }
        }
      }
      lastMoneyDrop: transactionsV2(
        first: 1
        desc: true
        transactionTypes: MoneyDropDeposit
      ) {
        edges {
          node {
            processedAt
          }
        }
      }
    }
  }
`;

const PlayerActivityInfoBlock: FC<{
  block: Queries.SanityPlayerRewardsActivityInfoBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();
  const params = useParams();

  const [{ data, fetching }, refresh] = useQuery<
    PlayerRewardsActivityInfoBoxQuery,
    PlayerRewardsActivityInfoBoxQueryVariables
  >({
    query: QUERY,
    variables: {
      playerId: params.playerId,
    },
  });

  const lastBonusDate =
    data?.player.lastBonusAdjustment.edges?.[0]?.node.processedAt;

  const lastMoneyDropDate =
    data?.player.lastMoneyDrop.edges?.[0]?.node.processedAt;

  return (
    <Card
      size="md"
      title={
        <>
          {t(block.title)}{' '}
          <Copy fetching={fetching} value={data?.player.uuid} />
        </>
      }
      options={
        <CardOptions>
          <CardOptionsButton
            className="flex"
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <div className="p-3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-2">
          <Value fetching={fetching} title={t(block.registered)}>
            {formatCountryCode(
              data?.player?.loginInfo?.registration.countryCode,
            )}{' '}
            <DateComponent
              copyable
              date={data?.player?.loginInfo?.registration?.loggedAt}
            />
          </Value>
          <Value fetching={fetching} title={t(block.lastDeposit)}>
            <DateComponent
              copyable
              date={data?.player?.depositInfoV2?.last}
              presentation="timeAgo"
            />
          </Value>
          <Value fetching={fetching} title={t(block.lastBonusDate)}>
            <DateComponent
              copyable
              date={lastBonusDate}
              presentation="timeAgo"
            />
          </Value>
          <Value fetching={fetching} title={t(block.lastMoneyDrop)}>
            <DateComponent
              copyable
              date={lastMoneyDropDate}
              presentation="timeAgo"
            />
          </Value>
        </div>
      </CardBody>
    </Card>
  );
};

export default PlayerActivityInfoBlock;
