import React from 'react';

import formatDuration from '@/utils/formatter/formatDuration';
import { DataDef, DataDefProps } from '../types';

export function secondsValue<O, T extends Record<string, any>>({
  field,
  getValue,
  ...rest
}: DataDefProps<O, T>): DataDef<O, T> {
  return {
    field,
    ...rest,
    component: ({ row }) => {
      const value = getValue ? getValue({ row }) : row[field];

      return <>{formatDuration(value)}</>;
    },
  };
}
