import React, { BaseSyntheticEvent, ReactNode, useEffect, useRef } from 'react';
import {
  FieldValues,
  FormProvider,
  UseFormProps,
  UseFormReturn,
  useForm,
} from 'react-hook-form';

export const NakedForm = <T extends FieldValues = FieldValues>(props: {
  methods: UseFormReturn<T>;
  onSubmit: (
    data: T,
    event: BaseSyntheticEvent<object, unknown, unknown> | undefined,
    methods: UseFormReturn<T>,
  ) => void;
  children?: ReactNode;
  className?: string;
  autoFocus?: boolean;
}) => {
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (props.autoFocus && formRef.current) {
      const firstInput = formRef.current.querySelector('input');
      if (firstInput) {
        firstInput.focus();
        firstInput.select();
      }
    }
  }, [props.autoFocus]);

  return (
    <FormProvider {...props.methods}>
      <form
        onSubmit={props.methods.handleSubmit((a, event) =>
          props.onSubmit(a, event, props.methods),
        )}
        className={props.className}
        autoComplete="off"
        ref={formRef}
      >
        {props.children}
      </form>
    </FormProvider>
  );
};

export const Form = <T extends FieldValues = FieldValues>(props: {
  defaultValues: UseFormProps<T>['defaultValues'];
  onSubmit: (
    data: T,
    event: React.BaseSyntheticEvent<object, unknown, unknown> | undefined,
    methods: UseFormReturn<T>,
  ) => void;
  children?: React.ReactNode;
  className?: string;
  autoFocus?: boolean;
}) => {
  const methods = useForm<T>({
    defaultValues: props.defaultValues,
  });

  return <NakedForm {...props} methods={methods} />;
};
