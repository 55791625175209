import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useState } from 'react';
import { useMutation, useQuery } from 'urql';

import {
  Card,
  CardCloseButton,
  CardOptions,
  CheckboxField,
  ErrorMessage,
  Form,
  SubmitButton,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import {
  ChangePlayerMarketingEmailMutation,
  ChangePlayerMarketingEmailMutationVariables,
  PlayerMarketingEmailFormQuery,
} from './__generated__/PlayerMarketingEmailForm';

const query = graphql`
  query SanityPlayerMarketingEmailForm {
    sanityPlayerMarketingEmailForm {
      title {
        ...SanityLocaleString
      }
      isEmailMarketingAccepted {
        ...SanityLocaleString
      }
      submit {
        ...SanityLocaleString
      }
    }
  }
`;

const playerQuery = gql`
  query PlayerMarketingEmailForm($playerId: ID!) {
    player(playerId: $playerId) {
      id
      isEmailMarketingAccepted
    }
  }
`;

const playerMutation = gql`
  mutation ChangePlayerMarketingEmail(
    $playerId: ID!
    $isEmailMarketingAccepted: Boolean!
  ) {
    changeEmailMarketingSettings(
      playerId: $playerId
      isEmailMarketingAccepted: $isEmailMarketingAccepted
    ) {
      id
      isEmailMarketingAccepted
    }
  }
`;

const PlayerMarketingEmailForm: FC<{ playerId: string }> = ({ playerId }) => {
  const staticData =
    useStaticQuery<Queries.SanityPlayerMarketingEmailFormQuery>(query);

  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();

  const form = staticData.sanityPlayerMarketingEmailForm;

  const [{ data }] = useQuery<PlayerMarketingEmailFormQuery>({
    query: playerQuery,
    variables: { playerId },
  });

  const [changePlayerMarketingEmailState, changePlayerMarketingEmail] =
    useMutation<
      ChangePlayerMarketingEmailMutation,
      ChangePlayerMarketingEmailMutationVariables
    >(playerMutation);

  const defaultValues = {
    isEmailMarketingAccepted: !!data?.player?.isEmailMarketingAccepted,
  };

  const { close } = useModalContext();

  const { t } = useTranslate();

  const onSubmit = (values: typeof defaultValues) => {
    changePlayerMarketingEmail({
      playerId,
      isEmailMarketingAccepted: values.isEmailMarketingAccepted,
    }).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      } else if (close) {
        close();
      }
    });
  };

  if (!form || !data) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">
        <Form
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          className="grid grid-cols-2 sm:grid-cols-3 gap-6"
        >
          <CheckboxField
            title={t(form.isEmailMarketingAccepted)}
            name="isEmailMarketingAccepted"
            id="PlayerMarketingEmailForm__isEmailMarketingAccepted"
            className="col-span-full"
          />

          <ErrorMessage message={errorMessage} />
          <SubmitButton
            value={t(form.submit)}
            disabled={changePlayerMarketingEmailState.fetching}
          />
        </Form>
      </div>
    </Card>
  );
};

export default PlayerMarketingEmailForm;
