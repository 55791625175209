import { formatISO } from 'date-fns';
import gql from 'graphql-tag';
import { useMemo } from 'react';
import { useQuery } from 'urql';
import { useQueryParams } from 'use-query-params';

import { RangeDate } from '@/globalTypes';
import { mapVariables } from '@/utils';
import { makeOrderBy, makePagination } from '@/utils/pagination';
import {
  PlayerRgRiskAssessmentHistoryQuery,
  PlayerRgRiskAssessmentHistoryQueryVariables,
} from './__generated__/usePlayerRgRiskAssessmentHistory';
import { queryParams } from './queryParams';

const QUERY = gql`
  query PlayerRgRiskAssessmentHistory(
    $rgCraEventId: String
    $playerGlobalId: ID
    $rgCraEventType: RgCraEventType
    $rgCraOutcomes: [RgCraOutcomeEnum!]
    $comment: String
    $initiatorId: String
    $initiatorType: InitiatorTypeEnum
    $rgCraProcessedAt: RangeDate
    $rgCraHistoryOrderBy: RgCraRiskHistoryOrderBy
    $desc: Boolean
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    viewer {
      id
      rgRiskAssessmentHistory: rgCraRiskAssessmentHistory(
        rgCraEventId: $rgCraEventId
        playerGlobalId: $playerGlobalId
        rgCraEventType: $rgCraEventType
        rgCraOutcomes: $rgCraOutcomes
        comment: $comment
        initiatorId: $initiatorId
        initiatorType: $initiatorType
        rgCraProcessedAt: $rgCraProcessedAt
        rgCraHistoryOrderBy: $rgCraHistoryOrderBy
        desc: $desc
        before: $before
        after: $after
        first: $first
        last: $last
      ) {
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
        edges {
          node {
            eventId
            playerGlobalId
            playerId
            eventType
            comment
            initiator {
              ... on PlayerInitiator {
                player {
                  firstName
                }
              }
              ... on AgentInitiator {
                agent {
                  agentId
                  email
                  firstName
                  lastName
                }
              }
            }
            processedAt
          }
        }
      }
    }
  }
`;

export default function usePlayerRiskAssessment(playerGlobalId: string) {
  const [query, setQuery] = useQueryParams(queryParams);

  const rgCraProcessedAt = useMemo((): RangeDate | undefined => {
    if (query.from && query.to) {
      return {
        from: formatISO(query.from),
        to: formatISO(query.to),
      };
    }

    return undefined;
  }, [query.from, query.to]);

  const [{ data, fetching }, refresh] = useQuery<
    PlayerRgRiskAssessmentHistoryQuery,
    PlayerRgRiskAssessmentHistoryQueryVariables
  >({
    query: QUERY,
    variables: {
      playerGlobalId,
      ...mapVariables(query),
      rgCraProcessedAt,
    },
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter: (values: {}) => setQuery(values, 'replaceIn'),
    ...makeOrderBy(query, setQuery),
    ...makePagination(
      query,
      setQuery,
      data?.viewer.rgRiskAssessmentHistory.pageInfo,
    ),
    history: data?.viewer.rgRiskAssessmentHistory?.edges?.map(
      (edge) => edge?.node,
    ),
  };
}
