import { KpiMetricsBoxStatsQuery } from '@/blocks/kpi-metrics-block/__generated__/component';
import { Nullable } from '@/types';

export enum KpiBoxMode {
  GlobalPlayer = 'GlobalPlayer',
  Player = 'Player',
}

type Viewer = Extract<
  KpiMetricsBoxStatsQuery['node'],
  { __typename: 'Viewer' }
>;

export const getKpiValue = (
  metric: Nullable<string>,
  row: Nullable<NonNullable<Viewer['stats']>['rows'][number]>,
): number | undefined => {
  if (row) {
    const value = row[metric as keyof typeof row];
    return typeof value === 'number' ? value : undefined;
  }
  return undefined;
};

export const getKpiBaseNode = (
  data: Nullable<KpiMetricsBoxStatsQuery>,
  mode: KpiBoxMode,
) => {
  if (data?.node?.__typename === 'Viewer' || data?.node?.__typename === mode) {
    return data.node;
  }
  return null;
};

export const getKpiStats = (
  data: Nullable<KpiMetricsBoxStatsQuery>,
  isGlobal: Nullable<boolean>,
) => {
  const mode = isGlobal ? KpiBoxMode.GlobalPlayer : KpiBoxMode.Player;
  const dataByMode = getKpiBaseNode(data, mode);

  const stats = isGlobal
    ? dataByMode?.stats?.rows[0]
    : dataByMode?.stats?.rows.filter((r) => r.currency)[0];

  const allTimeStats = isGlobal
    ? dataByMode?.allTimeStats?.rows[0]
    : dataByMode?.allTimeStats?.rows.filter((r) => r.currency)[0];

  return { stats, allTimeStats, currency: stats?.currency };
};
