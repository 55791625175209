import { OktaAuth } from '@okta/okta-auth-js';
import { devtoolsExchange } from '@urql/devtools';
import { authExchange } from '@urql/exchange-auth';
import { cacheExchange } from '@urql/exchange-graphcache';
import { retryExchange } from '@urql/exchange-retry';
import { feedback } from 'react-feedbacker';
import {
  CombinedError,
  Operation,
  createClient,
  errorExchange,
  fetchExchange,
} from 'urql';

import { logger } from '@/utils';
import schema from './../backoffice-schema.json';
import { urqlMutationUpdates } from './urqlMutationUpdates';

let lastError = { text: '', time: 0 };

const hasInvalidOktaToken = (error: CombinedError) => {
  return error.graphQLErrors.some(
    (gqlError) =>
      // @ts-expect-error errorCode does exist on originalError
      gqlError.originalError?.errorCode === 'INVALID_OKTA_TOKEN',
  );
};

const initializeAuthState = async (oktaAuth: OktaAuth) => {
  const tokens = await oktaAuth.tokenManager.getTokens();
  const token = tokens.accessToken?.accessToken;
  const refreshToken = tokens.refreshToken?.refreshToken;
  const isAuthenticated =
    await oktaAuth.authStateManager.getAuthState()?.isAuthenticated;

  return {
    token,
    refreshToken,
    isAuthenticated,
  };
};

export const makeClient = (url: string, oktaAuth: OktaAuth) => {
  const cacheConfig = {
    schema,
    keys: {
      AccountStatus: () => null,
      AdjustmentTypeData: () => null,
      AffiliateInfo: () => null,
      Agent: ({ agentId }: any) => agentId,
      AgentInitiator: () => null,
      AllLinkedAccounts: () => null,
      AmlRiskAssessmentReport: () => null,
      AuthenticationMethod: () => null,
      BoSourceOfFundType: () => null,
      BoSourceOfFundsType: () => null,
      BonusMoneyDropReward: () => null,
      Brand: ({ code }: any) => code,
      CashbackTransactionItem: () => null,
      ChallengeItemV3: ({ challengeId }: any) => challengeId,
      ChallengeReward: () => null,
      CraRiskLevelChangedItem: () => null,
      CustomerClosure: () => null,
      CustomerRiskAssessment: ({ playerGlobalId }: any) => playerGlobalId,
      CustomerRiskAssessmentHistoryItem: () => null,
      DepositInfo: () => null,
      DocumentResponse: () => null,
      EmailInfo: () => null,
      FinalRiskLevelUpdated: () => null,
      FreeSpinReward: () => null,
      FundingDocumentsRequestedItem: () => null,
      GameDescriptor: (data: any) => data?.json?.gameId,
      GameDescriptorV2: (data: any) => data?.json?.gameId,
      GameRoundItem: () => null,
      GlobalLoginAttemptsInfo: () => null,
      GlobalOrigin: () => null,
      GlobalWallet: ({ playerGlobalId }: any) => playerGlobalId,
      HampiCheckResult: () => null,
      Identity: () => null,
      KycCheckStatus: () => null,
      Limit: () => null,
      LimitSearchItem: () => null,
      LinkedAccounts: () => null,
      LoginInfo: () => null,
      MarketingPreferences: () => null,
      Money: () => null,
      MoneyDropReward: () => null,
      NoteResponse: () => null,
      Payment: () => null,
      PaymentApproval: () => null,
      PendingLimit: () => null,
      PeriodLimit: () => null,
      PlayerAddressInfo: () => null,
      PlayerChallengeItem: () => null,
      PlayerChallengeItemV2: () => null,
      PlayerComplianceDetails: () => null,
      PlayerComplianceGroupedV2: () => null,
      PlayerExportDetails: () => null,
      PlayerInfoHistory: () => null,
      PlayerInitiator: () => null,
      PlayerKYCValidationItem: () => null,
      PlayerKYCVerifiedItem: () => null,
      PlayerLoginInfo: () => null,
      PlayerLoginInfoData: () => null,
      PlayerRewardFlatItem: () => null,
      PlayerSearchItem: ({ playerId }: any) => playerId,
      Region: () => null,
      RegistrationInfo: () => null,
      ReviewStatusInfo: () => null,
      ReviewTrigger: () => null,
      RewardItem: ({ rewardId }: any) => rewardId,
      RgCraAutomationHistoryItem: () => null,
      RgCraCustomerRiskAssessment: () => null,
      RgCraCustomerRiskAssessmentHistoryItem: () => null,
      RgCraRegion: () => null,
      RgCraReviewStatusInfo: () => null,
      RgCraReviewTrigger: () => null,
      RgCraRisk: () => null,
      Risk: ({ riskId }: any) => riskId,
      RiskLevelUpdated: () => null,
      RiskOverrideAdded: () => null,
      RiskOverrideRemoved: () => null,
      SelfExclusionData: () => null,
      SelfExclusionDetails: () => null,
      SessionGroupItem: () => null,
      SessionSearchGroupedItem: () => null,
      SessionSearchItem: () => null,
      SourceOfWealth: () => null,
      SourceOfWealthManuallyRequested: () => null,
      SourceOfWealthRequiredItem: () => null,
      SourceOfWealthSubmittedItem: () => null,
      SourceOfWealthVerifiedItem: () => null,
      SowExportResponse: () => null,
      StatsEntryType: () => null,
      StatsType: () => null,
      TransactionSearchItem: ({ uuid }: any) => uuid,
      TranslatedPersonalInfo: () => null,
      VerificationWithProvider: () => null,
      Viewer: () => null,
      Wallet: () => null,
    },
    updates: {
      Mutation: urqlMutationUpdates,
    },
  };

  const ourAuthExchange = authExchange(async (utils) => {
    const { token } = await initializeAuthState(oktaAuth);

    return {
      addAuthToOperation: (operation) => {
        if (token) {
          return utils.appendHeaders(operation, {
            Authorization: `Bearer ${token}`,
          });
        }
        return operation;
      },
      willAuthError: () => !token,
      didAuthError: (error) => hasInvalidOktaToken(error),
      refreshAuth: async () => {},
    };
  });

  // Maximum number of retries will be 1 with an initial delay of 3 sec between first request and retry
  const retryOptions = {
    initialDelayMs: 3000,
    maxNumberAttempts: 1,
  };

  return createClient({
    url,
    fetchOptions: {
      headers: {
        accept: 'application/json',
        operator: process.env.GATSBY_OPERATOR!,
      },
    },
    exchanges: [
      errorExchange({
        onError: (error: CombinedError, operation: Operation) => {
          let message = error.message;
          if (message === '[Network] Failed to fetch') {
            message += `. Are you connected to the VPN?`;
          }

          if (hasInvalidOktaToken(error)) {
            return;
          }

          // this will make sure to not show same message more than once for 10 seconds
          const now = Date.now();
          if (lastError.text !== message || now - lastError.time > 10000) {
            lastError = { text: message, time: now };
            feedback.error(message);
          }
          logger.log({ error, operation });
        },
      }),
      devtoolsExchange,
      cacheExchange(cacheConfig),
      ourAuthExchange,
      retryExchange(retryOptions),
      fetchExchange,
    ],
  });
};
