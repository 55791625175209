/**
 * Exported alias for console meant for logs that we actually want to keep.
 * This way, we can give eslint errors on console uses outside of it in order
 * for debugging console logs and so on not to reach production.
 *
 * Usage (just alias for console, so for example):
 * logger.count('Hello world');
 * logger.debug('Hello world');
 * logger.error('Hello world');
 * logger.info('Hello world');
 * logger.log('Hello world');
 * logger.warn('Hello world');
 */

export const logger = console;
