import { graphql, useStaticQuery } from 'gatsby';
import React, { ComponentProps } from 'react';

import { Value } from '@/components';
import { useTranslate } from '@/contexts';
import { Nullable } from '@/types';

const query = graphql`
  query SanityBooleanValueBit {
    sanityBooleanValueBit {
      yes {
        ...SanityLocaleString
      }
      no {
        ...SanityLocaleString
      }
    }
  }
`;

const useValueToString = (
  bit: Queries.SanityBooleanValueBitQuery['sanityBooleanValueBit'],
) => {
  const { t } = useTranslate();

  return (value: Nullable<boolean>) => {
    if (typeof value !== 'boolean' || !bit) {
      return undefined;
    }

    return value ? t(bit.yes) : t(bit.no);
  };
};

type Props = Omit<ComponentProps<typeof Value>, 'value'> & {
  value: Nullable<boolean>;
};

export const BooleanValue = ({ value, ...props }: Props) => {
  const bit =
    useStaticQuery<Queries.SanityBooleanValueBitQuery>(
      query,
    ).sanityBooleanValueBit;
  const valueToString = useValueToString(bit);

  if (!bit) {
    return null;
  }

  return <Value {...props} value={valueToString(value)} />;
};
