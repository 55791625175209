import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useState } from 'react';
import { useMutation, useQuery } from 'urql';

import {
  Card,
  CardCloseButton,
  CardOptions,
  EmailField,
  ErrorMessage,
  Form,
  SubmitButton,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import {
  ChangePlayerEmailMutation,
  PlayerEmailFormQuery,
} from './__generated__/PlayerEmailForm';

const query = graphql`
  query SanityPlayerEmailForm {
    sanityPlayerEmailForm {
      title {
        ...SanityLocaleString
      }
      email {
        ...SanityLocaleString
      }
      submit {
        ...SanityLocaleString
      }
    }
  }
`;

const playerQuery = gql`
  query PlayerEmailForm($playerId: ID!) {
    player(playerId: $playerId) {
      id
      uuid
      email
      brand {
        code
      }
    }
  }
`;

const playerMutation = gql`
  mutation ChangePlayerEmail(
    $playerId: ID!
    $email: EmailAddress!
    $brand: BrandEnum!
  ) {
    changeEmail(playerId: $playerId, email: $email, brand: $brand) {
      id
      email
      isEmailVerified
    }
  }
`;

const PlayerEmailForm: FC<{ playerId: string }> = ({ playerId }) => {
  const staticData = useStaticQuery<Queries.SanityPlayerEmailFormQuery>(query);

  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();

  const form = staticData.sanityPlayerEmailForm;

  const [{ data }] = useQuery<PlayerEmailFormQuery>({
    query: playerQuery,
    variables: { playerId },
  });

  const [changePlayerEmailState, changePlayerEmail] =
    useMutation<ChangePlayerEmailMutation>(playerMutation);

  const defaultValues = {
    email: data?.player?.email,
  };

  const { close } = useModalContext();

  const { t } = useTranslate();

  const onSubmit = (values: typeof defaultValues) => {
    changePlayerEmail({
      playerId: data?.player.uuid,
      brand: data?.player.brand.code,
      email: values.email,
    }).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      } else if (close) {
        close();
      }
    });
  };

  if (!form || !data) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">
        <Form
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          className="grid grid-cols-2 sm:grid-cols-3 gap-6"
        >
          <EmailField
            title={t(form.email)}
            name="email"
            id="PlayerEmailForm__email"
            className="col-span-full"
          />

          <ErrorMessage message={errorMessage} />
          <SubmitButton
            value={t(form.submit)}
            disabled={changePlayerEmailState.fetching}
          />
        </Form>
      </div>
    </Card>
  );
};

export default PlayerEmailForm;
