import { BooleanParam, StringParam, withDefault } from 'use-query-params';

import { SearchChallengesOrderBy } from '@/globalTypes';
import { enumParam } from '@/utils/query/params';

const SearchChallengesOrderByParam = enumParam(SearchChallengesOrderBy);

export const queryParams = {
  challengeId: StringParam,
  desc: withDefault(BooleanParam, true),
  orderBy: withDefault(
    SearchChallengesOrderByParam,
    SearchChallengesOrderBy.startsAt,
  ),
  before: StringParam,
  after: StringParam,
};
