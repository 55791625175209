/**
 * DownloadLink
 *
 * The reason for this component is that `file-saver` as we use in other
 * places don't work with files from slow requests.
 *
 * What we do here instead is to forge a click on a download anchor tag as well
 * as show the anchor tag in case the click event would be
 * prevented by the browser.
 */
import { graphql, useStaticQuery } from 'gatsby';
import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';

import { useTranslate } from '@/contexts';
import { Nullable } from '@/types';
import { assert } from '@/utils/error';

const query = graphql`
  query SanityDownloadLinkBit {
    sanityDownloadLinkBit {
      download {
        ...SanityLocaleString
      }
    }
  }
`;

export const useDownloadLinkState = () => {
  return useState<string>();
};

type Props = {
  downloadLink: Nullable<string>;
  filename: string;
  children?: ReactNode;
};

export const DownloadLink: FC<Props> = ({
  downloadLink,
  filename,
  children,
}) => {
  const { t } = useTranslate();
  const anchorRef = useRef<HTMLAnchorElement>(null);
  const bit =
    useStaticQuery<Queries.SanityDownloadLinkBitQuery>(
      query,
    ).sanityDownloadLinkBit;

  assert(bit, 'no bit');

  useEffect(() => {
    if (downloadLink && anchorRef.current) {
      anchorRef.current.click();
    }
  }, [downloadLink]);

  if (downloadLink) {
    return (
      <a href={downloadLink} download={filename} ref={anchorRef}>
        {children || t(bit.download)}
      </a>
    );
  }

  return null;
};
