import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useState } from 'react';
import { useMutation } from 'urql';

import {
  Card,
  CardCloseButton,
  CardOptions,
  ErrorMessage,
  Form,
  SubmitButton,
  useModalContext,
} from '@/components';
import { SelectField, TextareaField } from '@/components/form';
import { useTranslate } from '@/contexts';
import { RgCraRiskLevel } from '@/globalTypes';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import {
  RgOverrideRiskLevelMutation,
  RgOverrideRiskLevelMutationVariables,
} from './__generated__/PlayerRgRiskLevelForm';

const query = graphql`
  query SanityPlayerRgRiskLevelForm {
    sanityPlayerRgRiskLevelForm {
      title {
        ...SanityLocaleString
      }
      adjustment {
        ...SanityLocaleString
      }
      note {
        ...SanityLocaleString
      }
      confirm {
        ...SanityLocaleString
      }
    }
  }
`;

const rgOverrideRiskLevelMutation = gql`
  mutation RgOverrideRiskLevel(
    $playerGlobalId: ID!
    $rgCraRiskLevel: RgCraRiskLevel!
    $comment: String!
  ) {
    rgCraOverrideRiskLevel(
      playerGlobalId: $playerGlobalId
      rgCraRiskLevel: $rgCraRiskLevel
      comment: $comment
    ) {
      playerGlobalId
      riskLevel
      overriddenRiskLevel
    }
  }
`;

type FormValues = {
  riskLevel: RgCraRiskLevel;
  comment: string;
};

const PlayerRgRiskLevelForm: FC<{
  playerGlobalId: string;
  riskLevel: RgCraRiskLevel;
}> = ({ playerGlobalId, riskLevel }) => {
  const staticData =
    useStaticQuery<Queries.SanityPlayerRgRiskLevelFormQuery>(query);

  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();

  const form = staticData.sanityPlayerRgRiskLevelForm;

  const [changePlayerRgRiskLevelState, changePlayerRgRiskLevel] = useMutation<
    RgOverrideRiskLevelMutation,
    RgOverrideRiskLevelMutationVariables
  >(rgOverrideRiskLevelMutation);

  const defaultValues: FormValues = {
    riskLevel,
    comment: '',
  };

  const { close } = useModalContext();

  const { t } = useTranslate();

  const onSubmit = (values: FormValues) => {
    return changePlayerRgRiskLevel({
      playerGlobalId,
      rgCraRiskLevel: values.riskLevel,
      comment: values.comment,
    }).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      } else if (close) {
        close();
      }
    });
  };

  if (!form || !riskLevel) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">
        <Form
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          className="grid grid-cols-1 sm:grid-cols-1s gap-6"
        >
          <SelectField
            name="riskLevel"
            id="playerRgRiskLevelForm__new-risk-level"
            title={t(form.adjustment)}
            options={[
              {
                label: RgCraRiskLevel.VeryHigh,
                value: RgCraRiskLevel.VeryHigh,
              },
              {
                label: RgCraRiskLevel.High,
                value: RgCraRiskLevel.High,
              },
              {
                label: RgCraRiskLevel.Medium,
                value: RgCraRiskLevel.Medium,
              },
              {
                label: RgCraRiskLevel.Low,
                value: RgCraRiskLevel.Low,
              },
            ]}
          />
          <TextareaField
            title={t(form.note)}
            name="comment"
            id="playerRgRiskLevelForm__comment"
            className="grid-cols-1"
            required
          />

          <ErrorMessage message={errorMessage} />
          <SubmitButton
            value={t(form.confirm)}
            disabled={changePlayerRgRiskLevelState.fetching}
          />
        </Form>
      </div>
    </Card>
  );
};

export default PlayerRgRiskLevelForm;
