import gql from 'graphql-tag';
import { useQuery } from 'urql';

import { GameDescriptor } from '@/fragments/GameDescriptor';
import { useQueryParamsWithTime } from '@/hooks';
import { mapVariables } from '@/utils';
import { makeOrderBy, makePagination } from '@/utils/pagination';
import {
  PlayerGameRoundsQuery,
  PlayerGameRoundsQueryVariables,
} from './__generated__/usePlayerGameRounds';
import { queryParams } from './queryParams';

const PLAYER_GAME_ROUNDS_QUERY = gql`
  query PlayerGameRounds(
    $playerId: ID!
    $desc: Boolean
    $gameRoundId: String
    $from: OffsetDateTime
    $to: OffsetDateTime
    $orderBy: GameRoundSearchOrder
    $status: GameRoundStatus
    $gameRoundCompleted: Boolean
    $gameProviderId: GameProvider
    $gameId: String
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    player(playerId: $playerId) {
      id
      gameRounds(
        desc: $desc
        gameRoundId: $gameRoundId
        from: $from
        to: $to
        orderBy: $orderBy
        status: $status
        gameRoundCompleted: $gameRoundCompleted
        gameProviderId: $gameProviderId
        gameId: $gameId
        before: $before
        after: $after
        first: $first
        last: $last
      ) {
        edges {
          node {
            brand {
              id
              name
            }
            currency
            gameId
            gameRoundId
            gameProvider
            startedAt
            completedAt
            totalBets
            totalWins
            totalJackpotContribution
            totalJackpotPayout
            openingBalance
            closingBalance
            status
            gameDescriptor: gameDescriptorV2 {
              ...GameDescriptor
            }
            bonusBetAmount
            bonusWinAmount
            closingBonusBalance
            openingBonusBalance
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
      }
    }
  }
  ${GameDescriptor}
`;

export default function usePlayerGameRounds(playerId: string) {
  const [query, setQuery] = useQueryParamsWithTime(queryParams, {
    fromAndToOptional: true,
  });
  const [{ data, fetching }, refresh] = useQuery<
    PlayerGameRoundsQuery,
    PlayerGameRoundsQueryVariables
  >({
    query: PLAYER_GAME_ROUNDS_QUERY,
    variables: {
      playerId,
      ...mapVariables(query),
    },
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter: (values: {}) => setQuery(values, 'replaceIn'),
    ...makeOrderBy(query, setQuery),
    ...makePagination(query, setQuery, data?.player.gameRounds.pageInfo),
    gameRounds: data?.player.gameRounds?.edges?.map((edge) => edge?.node),
  };
}
