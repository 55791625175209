import _ from 'lodash';
import React, { FC, ReactNode, useState } from 'react';

import {
  Button,
  Card,
  CardBody,
  CardCloseButton,
  CardOptions,
  ControlledModal,
  InlineIconButton,
} from '@/components';
import { CogIcon } from '@/components/icons';
import { CardActions } from '../Card';
import { DragHandle, SortableItem } from '../SortableList';
import { SortableList } from '../SortableList/SortableList';
import { DataDef } from './types';

export const ColumnForm: FC<{
  schema: DataDef<any, any>[];
  visibleColumns: string[];
  updateVisibleColumns: (values: string[] | undefined) => void;
  resetVisibleColumns: () => void;
  children: ReactNode;
}> = ({
  schema,
  visibleColumns,
  updateVisibleColumns,
  resetVisibleColumns,
  children,
}) => {
  const schemaByField = _.keyBy(schema, 'field');

  const [visibleColumnDefs, setColumnDefs] = useState(
    visibleColumns.map((a) => schemaByField[a]).filter(Boolean),
  );

  if (schema.length === 0) {
    return null;
  }

  const reset = () => {
    setColumnDefs(schema.filter((a) => !a.hidden));
    resetVisibleColumns();
  };

  return (
    <ControlledModal
      content={
        <Card
          size="lg"
          title="Filter"
          options={
            <CardOptions>
              <CardCloseButton />
            </CardOptions>
          }
        >
          <CardBody className="p-3">
            <div className="grid overflow-auto max-h-[calc(90vh-110px)] grid-cols-2 divide-x dark:divide-gray-700">
              <SortableList
                items={visibleColumnDefs}
                idFieldName="field"
                onChange={setColumnDefs}
                renderItem={(item) => (
                  <SortableItem id={item.field}>
                    {item.title}
                    <DragHandle />
                  </SortableItem>
                )}
              />
              <div className="p-3 space-y-2">
                {_.sortBy(schema, ['title']).map((def) => {
                  const isChecked = visibleColumnDefs.includes(def);
                  return (
                    <div key={def.field}>
                      <label className="flex items-center space-x-2 dark:text-gray-200">
                        <input
                          type="checkbox"
                          checked={visibleColumnDefs.includes(def)}
                          onChange={() =>
                            setColumnDefs(
                              isChecked
                                ? _.without(visibleColumnDefs, def)
                                : [...visibleColumnDefs, def],
                            )
                          }
                        />
                        <span>{def.title}</span>
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </CardBody>
          <CardActions>
            <Button variant="secondary" onClick={() => reset()}>
              Reset Columns
            </Button>
            <Button
              variant="primary"
              onClick={() =>
                updateVisibleColumns(visibleColumnDefs.map((a) => a.field))
              }
            >
              Save
            </Button>
          </CardActions>
        </Card>
      }
    >
      {children ? (
        children
      ) : (
        <InlineIconButton>
          <CogIcon />
        </InlineIconButton>
      )}
    </ControlledModal>
  );
};
