import {
  BooleanParam,
  DateTimeParam,
  NumberParam,
  StringParam,
  withDefault,
} from 'use-query-params';

import { PaymentSearchOrder } from '@/globalTypes';
import {
  PaymentSearchOrderParam,
  PaymentStatusParam,
  PaymentTypeParam,
} from '@/utils/query/params';

export const queryParams = {
  rawId: StringParam,
  paymentType: PaymentTypeParam,
  paymentStatus: PaymentStatusParam,
  from: DateTimeParam,
  to: DateTimeParam,
  desc: withDefault(BooleanParam, true),
  orderBy: withDefault(
    PaymentSearchOrderParam,
    PaymentSearchOrder.initializedAt,
  ),
  before: StringParam,
  after: StringParam,
  first: withDefault(NumberParam, 20),
};
