import { useParams } from '@reach/router';
import { Link as GatsbyLink, GatsbyLinkProps, graphql } from 'gatsby';
import React, { forwardRef } from 'react';
import { generatePath } from 'react-router-dom';

import { useTranslate } from '@/contexts';

export const link_fragment = graphql`
  fragment SanityLink on SanityLink {
    title {
      ...SanityLocaleString
    }
    pageLink {
      path {
        current
      }
    }
    route
    link
  }
`;

type LinkProps = { link: Queries.SanityLinkFragment };
type Props<T> = Omit<GatsbyLinkProps<T>, 'to' | 'ref'> & LinkProps;

export const Link = forwardRef<HTMLAnchorElement, Props<{}>>(
  ({ link, ...rest }, ref) => {
    const params = useParams();
    const { t } = useTranslate();

    const internalLink = link.route || link.pageLink?.path?.current;
    if (internalLink) {
      const path = generatePath(internalLink, params || {});
      return (
        <GatsbyLink to={path} {...rest}>
          {t(link.title)}
        </GatsbyLink>
      );
    }
    if (link.link) {
      return (
        <a href={link.link} {...rest} ref={ref}>
          {t(link.title)}
        </a>
      );
    }
    return null;
  },
);
