import gql from 'graphql-tag';
import { useQuery } from 'urql';

import { useQueryParamsWithTime } from '@/hooks';
import { mapVariables } from '@/utils';
import { makeOrderBy, makePagination } from '@/utils/pagination';
import {
  GetPlayerPhoneLoginHistoryQuery,
  GetPlayerPhoneLoginHistoryQueryVariables,
} from './__generated__/usePlayerPhoneLogins';
import { queryParams } from './queryParams';

const QUERY = gql`
  query GetPlayerPhoneLoginHistory(
    $playerId: ID!
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    player(playerId: $playerId) {
      id
      phoneLoginHistory(
        before: $before
        after: $after
        first: $first
        last: $last
      ) {
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
        edges {
          node {
            id
            playerId
            authenticationMethodId
            eventType
            eventId
            processedAt
            initiator {
              ... on PlayerInitiator {
                player {
                  id
                }
              }
              ... on AgentInitiator {
                agent {
                  email
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default function usePlayerPhoneLogins(playerId: string) {
  const [query, setQuery] = useQueryParamsWithTime(queryParams);

  const [{ data, fetching }, refresh] = useQuery<
    GetPlayerPhoneLoginHistoryQuery,
    GetPlayerPhoneLoginHistoryQueryVariables
  >({
    query: QUERY,
    variables: {
      ...mapVariables(query),
      playerId,
    },
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter: (values: {}) => setQuery(values, 'replaceIn'),
    ...makeOrderBy(query, setQuery),
    ...makePagination(query, setQuery, data?.player.phoneLoginHistory.pageInfo),
    phoneLogins: data?.player.phoneLoginHistory?.edges?.map((e) => e?.node),
  };
}
