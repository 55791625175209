import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useState } from 'react';
import { OptionProps, components } from 'react-select';
import { useMutation, useQuery } from 'urql';

import {
  Card,
  CardBody,
  CardCloseButton,
  CardOptions,
  ErrorMessage,
  Form,
  SelectField,
  SelectOption,
  SubmitButton,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import { assert } from '@/utils/error';
import { makeBrandThumbnail } from '@/utils/makeBrandThumbnail';
import {
  ChangeKycMasterPlayerMutation,
  ChangeKycMasterPlayerMutationVariables,
  KycMasterPlayerFormQuery,
} from './__generated__/KycMasterPlayerForm';

const query = graphql`
  query SanityKycMasterPlayerForm {
    sanityKycMasterPlayerForm {
      title {
        ...SanityLocaleString
      }
      selectPlayerIdLabel {
        ...SanityLocaleString
      }
      kycMasterPlayerIdSubmit {
        ...SanityLocaleString
      }
    }
  }
`;

const kycMasterPlayer = gql`
  query KycMasterPlayerForm($playerGlobalId: ID!) {
    playerGlobal(playerGlobalId: $playerGlobalId) {
      id
      kycMasterPlayer {
        id
      }
      players {
        id
        brand {
          code
        }
      }
    }
  }
`;

const changeKycMasterPlayerMutation = gql`
  mutation ChangeKycMasterPlayer($playerGlobalId: ID!, $playerId: ID!) {
    changeKycMasterPlayer(
      playerGlobalId: $playerGlobalId
      playerId: $playerId
    ) {
      kycMasterPlayer {
        id
      }
    }
  }
`;

type FormValues = {
  kycMasterPlayerId: {
    playerId: string;
  };
};

const OptionComponent: FC<
  OptionProps<
    SelectOption<KycMasterPlayerFormQuery['playerGlobal']['players'][number]>
  >
> = (props) => {
  const { children, data } = props;

  const fluid = data.value.brand ? makeBrandThumbnail(data.value.brand) : null;

  return (
    <components.Option {...props}>
      <div className="flex flex-col flex-o w-full pl-2 p-1 cursor-pointer hover:bg-gray-200">
        <div className="flex flex-grow overflow-hidden overflow-ellipsis whitespace-nowrap">
          {fluid ? (
            <picture className="flex-none">
              <source sizes={fluid.sizes} srcSet={fluid.srcSet} />
              <img
                src={fluid.src}
                alt={data.label}
                loading="lazy"
                className="w-6 h-6 rounded-sm mr-2"
              />
            </picture>
          ) : (
            <div className="w-6 h-6 rounded-sm mr-2 bg-gray-300" />
          )}
          {children}
        </div>
      </div>
    </components.Option>
  );
};

const KycMasterPlayerForm: FC<{ playerGlobalId: string }> = ({
  playerGlobalId,
}) => {
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const { t } = useTranslate();
  const staticData =
    useStaticQuery<Queries.SanityKycMasterPlayerFormQuery>(query);
  const form = staticData.sanityKycMasterPlayerForm;
  const isMounted = useIsMounted();

  assert(form, 'missing form data');

  const [{ data }] = useQuery<KycMasterPlayerFormQuery>({
    query: kycMasterPlayer,
    variables: { playerGlobalId },
  });

  const [changeKycMasterPlayerState, changeKycMasterPlayer] = useMutation<
    ChangeKycMasterPlayerMutation,
    ChangeKycMasterPlayerMutationVariables
  >(changeKycMasterPlayerMutation);

  const { close } = useModalContext();

  const defaultValues: FormValues = {
    kycMasterPlayerId: {
      playerId: '',
    },
  };

  const onSubmit = ({ kycMasterPlayerId }: typeof defaultValues) => {
    if (kycMasterPlayerId.playerId) {
      changeKycMasterPlayer({
        playerGlobalId,
        playerId: kycMasterPlayerId.playerId,
      }).then((res) => {
        if (res.error?.message && isMounted) {
          setErrorMessage(res.error.message);
        } else if (close) {
          close();
        }
      });
    }
  };

  if (!data) {
    return null;
  }

  const selectPlayerIdOptions = data.playerGlobal.players.map((player) => ({
    label: player.id,
    value: {
      brand: player.brand,
      playerId: player.id,
    },
  }));

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <CardBody>
        <div className="p-3">
          <Form
            defaultValues={defaultValues}
            onSubmit={onSubmit}
            className="grid grid-cols-2 sm:grid-cols-3 gap-6"
          >
            <SelectField
              options={selectPlayerIdOptions}
              name="kycMasterPlayerId"
              id="KycMasterPlayerForm__kycMasterPlayerId"
              title={t(form.selectPlayerIdLabel)}
              components={{ Option: OptionComponent }}
            />
            <ErrorMessage message={errorMessage} />
            <SubmitButton
              value={t(form.kycMasterPlayerIdSubmit)}
              disabled={changeKycMasterPlayerState.fetching}
            />
          </Form>
        </div>
      </CardBody>
    </Card>
  );
};

export default KycMasterPlayerForm;
