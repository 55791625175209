import gql from 'graphql-tag';
import { useQuery } from 'urql';
import { useQueryParams } from 'use-query-params';

import { useElasticIndexPrefix } from '@/contexts';
import { makeOrderBy, makePagination } from '@/utils/pagination';
import {
  PlayerSelfExclusionHistoryQuery,
  PlayerSelfExclusionHistoryQueryVariables,
} from './__generated__/usePlayerSelfExclusionHistory';
import { queryParams } from './queryParams';

const PLAYER_SELF_EXCLUSION_QUERY = gql`
  query PlayerSelfExclusionHistory(
    $after: String
    $before: String
    $desc: Boolean
    $first: Int
    $indexPrefix: String
    $last: Int
    $playerId: ID!
  ) {
    player(playerId: $playerId) {
      id
      selfExclusionHistory(
        after: $after
        before: $before
        desc: $desc
        first: $first
        indexPrefix: $indexPrefix
        last: $last
      ) {
        edges {
          node {
            id
            playerId
            eventType
            processedAt
            requestedAt
            requestedBy {
              ... on PlayerInitiator {
                player {
                  firstName
                  lastName
                  email
                }
              }
              ... on AgentInitiator {
                agent {
                  email
                  firstName
                  lastName
                }
              }
            }
            activeFrom
            endsAt
            cancelledAt
            previousEndDate
            comment
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;

export default function usePlayerSelfExclusionHistory(playerId: string) {
  const [query, setQuery] = useQueryParams(queryParams);
  const { indexPrefix } = useElasticIndexPrefix();

  const [{ data, fetching }, refresh] = useQuery<
    PlayerSelfExclusionHistoryQuery,
    PlayerSelfExclusionHistoryQueryVariables
  >({
    query: PLAYER_SELF_EXCLUSION_QUERY,
    variables: {
      playerId,
      first: 20,
      indexPrefix,
      ...query,
    },
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter: (values: {}) => setQuery(values, 'replaceIn'),
    ...makeOrderBy(query, setQuery),
    ...makePagination(
      query,
      setQuery,
      data?.player.selfExclusionHistory.pageInfo,
    ),
    history: data?.player.selfExclusionHistory?.edges?.map(
      (edge) => edge?.node,
    ),
  };
}
