import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useState } from 'react';
import { useMutation } from 'urql';

import {
  Card,
  CardBody,
  CardCloseButton,
  CardOptions,
  CheckboxField,
  ErrorMessage,
  Form,
  SubmitButton,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { useIsMounted } from '@/hooks';
import { Nullable, PlayerId } from '@/types';
import {
  PlayerUpdateRgNetDepositLimitBlockMutation,
  PlayerUpdateRgNetDepositLimitBlockMutationVariables,
} from './__generated__/PlayerUpdateRgNetDepositLimitBlockForm';

const query = graphql`
  query SanityPlayerUpdateRgNetDepositLimitBlockForm {
    sanityPlayerUpdateRgNetDepositLimitBlockForm {
      title {
        ...SanityLocaleString
      }
      description {
        ...SanityLocaleString
      }
      isRgNetDepositLimitBlocked {
        ...SanityLocaleString
      }
      submit {
        ...SanityLocaleString
      }
    }
  }
`;

const updateCustomerRgNetDepositLimitBlockMutation = gql`
  mutation PlayerUpdateRgNetDepositLimitBlock(
    $playerGlobalId: ID!
    $enable: Boolean!
  ) {
    updateCustomerRgNetDepositLimitBlock(
      playerGlobalId: $playerGlobalId
      enable: $enable
    ) {
      ... on Customer {
        isRgNetDepositLimitEnabled
      }
    }
  }
`;

const PlayerUpdateRgNetDepositLimitBlockForm: FC<{
  playerGlobalId: PlayerId;
  isBlocked: boolean;
}> = ({ playerGlobalId, isBlocked }) => {
  const staticData =
    useStaticQuery<Queries.SanityPlayerUpdateRgNetDepositLimitBlockFormQuery>(
      query,
    );

  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();
  const form = staticData.sanityPlayerUpdateRgNetDepositLimitBlockForm;
  const { close } = useModalContext();
  const { t } = useTranslate();

  const [updateRgNetDepositLimitBlockState, updateRgNetDepositLimitBlock] =
    useMutation<
      PlayerUpdateRgNetDepositLimitBlockMutation,
      PlayerUpdateRgNetDepositLimitBlockMutationVariables
    >(updateCustomerRgNetDepositLimitBlockMutation);

  const defaultValues = {
    isBlocked,
  };

  const onSubmit = ({ isBlocked }: typeof defaultValues) => {
    updateRgNetDepositLimitBlock({
      playerGlobalId,
      enable: !isBlocked,
    }).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      } else if (close) {
        close();
      }
    });
  };

  if (!form) {
    return null;
  }

  return (
    <Card
      size="md"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <CardBody>
        <div className="p-3">
          <Form
            defaultValues={defaultValues}
            onSubmit={onSubmit}
            className="grid gap-6"
          >
            <div className="space-y-1">
              <div className="block text-gray-600 text-sm dark:text-gray-200">
                {t(form.description)}
              </div>
            </div>
            <CheckboxField
              title={t(form.isRgNetDepositLimitBlocked)}
              name="isRgNetDepositLimitBlocked"
              id="PlayerUpdateRgNetDepositLimitBlockForm_isRgNetDepositLimitBlocked"
              className="col-span-full"
              defaultChecked={isBlocked}
            />
            <ErrorMessage message={errorMessage} />
            <SubmitButton
              value={t(form.submit)}
              disabled={updateRgNetDepositLimitBlockState.fetching}
            />
          </Form>
        </div>
      </CardBody>
    </Card>
  );
};

export default PlayerUpdateRgNetDepositLimitBlockForm;
