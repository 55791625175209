import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { useQuery } from 'urql';

import { limitPeriodDetailList_periodLimit } from '@/bits/limit-period-detail-list/component';
import { Card, CardBody, CardOptions, CardOptionsButton } from '@/components';
import { RefreshIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import { limitFragment } from '@/fragments/Limits';
import { LimitTypeEnum } from '@/globalTypes';
import {
  PlayerWagerLimitsBoxQuery,
  PlayerWagerLimitsBoxQueryVariables,
} from './__generated__/component';
import { WagerLimitBar } from './WagerLimitBar';

export const Fragment = graphql`
  fragment SanityPlayerWagerLimitsBlock on SanityPlayerWagerLimitsBlock {
    title {
      ...SanityLocaleString
    }
    wagerLimitsLabel {
      ...SanityLocaleString
    }
    dailyLabel {
      ...SanityLocaleString
    }
    weeklyLabel {
      ...SanityLocaleString
    }
    monthlyLabel {
      ...SanityLocaleString
    }
  }
`;

const QUERY = gql`
  query PlayerWagerLimitsBox($playerId: ID!) {
    player(playerId: $playerId) {
      id
      wallet {
        id
        currency
        wagerLimit {
          dayLimit {
            ...PeriodLimit
            ...LimitPeriodDetailList_periodLimit
          }
          weekLimit {
            ...PeriodLimit
            ...LimitPeriodDetailList_periodLimit
          }
          monthLimit {
            ...PeriodLimit
            ...LimitPeriodDetailList_periodLimit
          }
        }
      }
    }
  }
  ${limitFragment}
  ${limitPeriodDetailList_periodLimit}
`;

const PlayerWagerLimitsBlock: FC<{
  block: Queries.SanityPlayerWagerLimitsBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();
  const params = useParams();

  const [{ data, fetching }, refresh] = useQuery<
    PlayerWagerLimitsBoxQuery,
    PlayerWagerLimitsBoxQueryVariables
  >({
    query: QUERY,
    variables: {
      playerId: params.playerId,
    },
  });

  const wagerLimit = data?.player.wallet?.wagerLimit;

  return (
    <Card
      size="lg"
      title={t(block.title)}
      options={
        <CardOptions>
          <CardOptionsButton
            className="flex"
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <div className="p-3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
          <div className="col-span-full">
            <label className="text-sm text-gray-500 font-semibold">
              {t(block.wagerLimitsLabel)}
            </label>
            <div className="grid gap-2 pt-1">
              <WagerLimitBar
                currency={data?.player.wallet?.currency}
                fetching={fetching}
                limit={wagerLimit?.dayLimit}
                label={block.dailyLabel}
                playerId={data?.player.id}
                limitPeriodType={LimitTypeEnum.Day}
              />
              <WagerLimitBar
                currency={data?.player.wallet?.currency}
                fetching={fetching}
                limit={wagerLimit?.weekLimit}
                label={block.weeklyLabel}
                playerId={data?.player.id}
                limitPeriodType={LimitTypeEnum.Week}
              />
              <WagerLimitBar
                currency={data?.player.wallet?.currency}
                fetching={fetching}
                limit={wagerLimit?.monthLimit}
                label={block.monthlyLabel}
                playerId={data?.player.id}
                limitPeriodType={LimitTypeEnum.Month}
              />
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default PlayerWagerLimitsBlock;
