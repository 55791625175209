import {
  BooleanParam,
  DateTimeParam,
  NumberParam,
  StringParam,
  withDefault,
} from 'use-query-params';

import { PendingKycFlowViewSearchOrder } from '@/globalTypes';
import { enumParam } from '@/utils/query/params';

const SearchPendingKycFlowOrderByParam = enumParam(
  PendingKycFlowViewSearchOrder,
);

export const queryParams = {
  playerId: StringParam,
  playerGlobalUUID: StringParam,
  kycCheckStartedFrom: DateTimeParam,
  kycCheckStartedTo: DateTimeParam,
  kycCheckDeadlineFrom: DateTimeParam,
  kycCheckDeadlineTo: DateTimeParam,
  after: StringParam,
  before: StringParam,
  first: withDefault(NumberParam, 20),
  last: NumberParam,
  desc: withDefault(BooleanParam, true),
  orderBy: withDefault(
    SearchPendingKycFlowOrderByParam,
    PendingKycFlowViewSearchOrder.kycCheckDeadline,
  ),
};
