import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { feedback } from 'react-feedbacker';
import { SubmitHandler, useForm } from 'react-hook-form';

import { useFeedbackMessages } from '@/bits';
import { Button, NakedForm, SelectField } from '@/components';
import { useDataSource, useDispatchDataSource, useTranslate } from '@/contexts';
import { DataSourceVersionEnum } from '@/globalTypes';

const query = graphql`
  query SanityAnalyticsDataSourceForm {
    sanityAnalyticsDataSourceForm {
      title {
        ...SanityLocaleString
      }
      dataSourceTitle {
        ...SanityLocaleString
      }
      dataSourcePlaceholder {
        ...SanityLocaleString
      }
      new {
        ...SanityLocaleString
      }
      old {
        ...SanityLocaleString
      }
      submit {
        ...SanityLocaleString
      }
      remove {
        ...SanityLocaleString
      }
    }
  }
`;

type FormValues = {
  dataSource: DataSourceVersionEnum | '';
};

const AnalyticsDataSourceForm = () => {
  const { t } = useTranslate();
  const form =
    useStaticQuery<Queries.SanityAnalyticsDataSourceFormQuery>(
      query,
    ).sanityAnalyticsDataSourceForm;

  const feedbackMessages = useFeedbackMessages();
  const dataSourceState = useDataSource();
  const dispatch = useDispatchDataSource();

  const methods = useForm<FormValues>({
    defaultValues: {
      dataSource: dataSourceState.dataSource ?? '',
    },
  });

  const removeDataSource = () => {
    dispatch({
      type: 'REMOVE_DATA_SOURCE',
    });
    methods.setValue('dataSource', '');
    feedback.success(t(feedbackMessages.success));
  };

  const handleSubmit: SubmitHandler<FormValues> = ({ dataSource }) => {
    if (dataSource) {
      dispatch({
        type: 'SET_DATA_SOURCE',
        dataSource,
      });
      feedback.success(t(feedbackMessages.success));
      return;
    }
    removeDataSource();
  };

  if (!form) {
    return null;
  }

  return (
    <div>
      <h3 className="text-black dark:text-white mb-2">{t(form.title)}</h3>
      <NakedForm methods={methods} onSubmit={handleSubmit}>
        <div className="grid gap-2">
          <SelectField
            title={t(form.dataSourceTitle)}
            name="dataSource"
            options={[
              {
                label: t(form.dataSourcePlaceholder),
                value: '',
              },
              {
                label: t(form.new),
                value: DataSourceVersionEnum.NEW,
              },
              {
                label: t(form.old),
                value: DataSourceVersionEnum.OLD,
              },
            ]}
          />

          <div className="grid gap-2 grid-cols-2">
            <Button type="submit" variant="primary">
              {t(form.submit)}
            </Button>
            <Button
              type="button"
              variant="secondary"
              onClick={removeDataSource}
            >
              {t(form.remove)}
            </Button>
          </div>
        </div>
      </NakedForm>
    </div>
  );
};

export default AnalyticsDataSourceForm;
