import { ChangeEvent, useMemo, useState } from 'react';

export const useMultiSelect = (length: number) => {
  const [selected, setSelected] = useState<number[]>([]);
  const allSelected = useMemo(() => selected.length === length, [
    selected,
    length,
  ]);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const index = selected.indexOf(Number(value));
    if (index > -1) {
      setSelected([...selected.slice(0, index), ...selected.slice(index + 1)]);
    } else {
      setSelected([...selected, Number(value)]);
    }
  };

  const isSelected = (value: number) => {
    return selected.includes(value);
  };

  const onChangeAll = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    if (checked) {
      setSelected([...Array(length).keys()]);
    } else {
      setSelected([]);
    }
  };

  return {
    selected,
    allSelected,
    isSelected,
    onChange,
    onChangeAll,
  };
};
