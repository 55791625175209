import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useState } from 'react';
import { useMutation } from 'urql';

import {
  Card,
  CardCloseButton,
  CardOptions,
  ErrorMessage,
  Form,
  SubmitButton,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { limitFragment } from '@/fragments/Limits';
import { LimitTypeEnum } from '@/globalTypes';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import {
  CancelPlayerLossLimitMutation,
  CancelPlayerLossLimitMutationVariables,
} from './__generated__/PlayerCancelLossLimitForm';

const query = graphql`
  query SanityPlayerCancelLossLimitForm {
    sanityPlayerCancelLossLimitsForm {
      title {
        ...SanityLocaleString
      }
      submit {
        ...SanityLocaleString
      }
    }
  }
`;

const cancelPendingLossLimitMutation = gql`
  mutation CancelPlayerLossLimit(
    $playerId: ID!
    $limitPeriodType: LimitTypeEnum!
  ) {
    cancelPendingLossLimit(
      playerId: $playerId
      limitPeriodType: $limitPeriodType
    ) {
      id
      lossLimit {
        dayLimit {
          ...PeriodLimit
        }
        weekLimit {
          ...PeriodLimit
        }
        monthLimit {
          ...PeriodLimit
        }
      }
    }
  }
  ${limitFragment}
`;

const PlayerCancelLossLimitForm: FC<{
  playerId: string;
  limitPeriodType: LimitTypeEnum;
}> = ({ playerId, limitPeriodType }) => {
  const staticData =
    useStaticQuery<Queries.SanityPlayerCancelLossLimitFormQuery>(query);

  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();

  const form = staticData.sanityPlayerCancelLossLimitsForm;

  const [playerCancelLossLimitState, cancelPendingLossLimit] = useMutation<
    CancelPlayerLossLimitMutation,
    CancelPlayerLossLimitMutationVariables
  >(cancelPendingLossLimitMutation);

  const { close } = useModalContext();

  const { t } = useTranslate();

  const onSubmit = (_values: {}) => {
    if (playerId) {
      cancelPendingLossLimit({
        playerId,
        limitPeriodType,
      }).then((res) => {
        if (res.error?.message && isMounted) {
          setErrorMessage(res.error?.message);
        } else if (close) {
          close();
        }
      });
    }
  };

  if (!form) {
    return null;
  }
  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">
        <Form
          defaultValues={{}}
          onSubmit={onSubmit}
          className="grid grid-cols-2 sm:grid-cols-3 gap-6"
        >
          <ErrorMessage message={errorMessage} />
          <SubmitButton
            value={t(form.submit)}
            disabled={playerCancelLossLimitState.fetching}
          />
        </Form>
      </div>
    </Card>
  );
};

export default PlayerCancelLossLimitForm;
