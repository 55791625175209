import React, { ComponentType } from 'react';

export default function browserOnly<Props extends object>(
  C: ComponentType<Props>,
) {
  const ComponentWithBrowserOnly = (props: Props) => {
    const isSSR = typeof window === 'undefined';

    if (isSSR) {
      return null;
    }

    return <C {...props} />;
  };

  return ComponentWithBrowserOnly;
}
