import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useState } from 'react';
import { useMutation } from 'urql';

import { PlayerKycOverviewBoxQuery } from '@/blocks/player-kyc-overview-block/__generated__/component';
import {
  Card,
  CardBody,
  CardCloseButton,
  CardOptions,
  ErrorMessage,
  Form,
  SelectField,
  SubmitButton,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { DocumentVerificationType } from '@/globalTypes';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import {
  ManualPlayerKycCheckMutation,
  ManualPlayerKycCheckMutationVariables,
} from './__generated__/PlayerKycStatusForm';

const query = graphql`
  query SanityPlayerKYCStatusForm {
    sanityPlayerKycStatusForm {
      title {
        ...SanityLocaleString
      }
      globalTitle {
        ...SanityLocaleString
      }
      requiredDocumentTypesLabel {
        ...SanityLocaleString
      }
      description {
        ...SanityLocaleString
      }
      noNeedDescription {
        ...SanityLocaleString
      }
      requestedLabel {
        ...SanityLocaleString
      }
      verifiedLabel {
        ...SanityLocaleString
      }
      requestAll {
        ...SanityLocaleString
      }
      submit {
        ...SanityLocaleString
      }
    }
  }
`;

const playerMutation = gql`
  mutation ManualPlayerKycCheck(
    $requestedAt: OffsetDateTime
    $playerId: ID!
    $documentVerificationTypes: [DocumentVerificationType!]!
  ) {
    manualKycCheck(
      playerId: $playerId
      requestedAt: $requestedAt
      documentVerificationTypes: $documentVerificationTypes
    ) {
      kycCheckStatus {
        status
        deadline
        verificationsDone
        verificationsMissing
      }
    }
  }
`;

type PickEnum<T, K extends T> = {
  [P in keyof K]: P extends K ? P : never;
};

type KycDocumentVerificationType = PickEnum<
  DocumentVerificationType,
  DocumentVerificationType.Identity | DocumentVerificationType.Address
>;

const kycVerificationTypes: KycDocumentVerificationType[] = [
  DocumentVerificationType.Identity,
  DocumentVerificationType.Address,
];

const kycVerificationOptions = Object.values(kycVerificationTypes).map(
  (value) => ({
    label: value,
    value,
  }),
);

const PlayerNameForm: FC<{
  kycCheckStatus: PlayerKycOverviewBoxQuery['player']['kycCheckStatus'];
  playerId: string;
}> = ({ kycCheckStatus, playerId }) => {
  const staticData =
    useStaticQuery<Queries.SanityPlayerKYCStatusFormQuery>(query);

  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();
  const form = staticData.sanityPlayerKycStatusForm;
  const { close } = useModalContext();
  const { t } = useTranslate();

  const [requestPlayerKycDocumentsState, requestPlayerKycDocuments] =
    useMutation<
      ManualPlayerKycCheckMutation,
      ManualPlayerKycCheckMutationVariables
    >(playerMutation);

  const defaultValues = {
    documentVerificationTypes: [],
  };

  const allValues = {
    documentVerificationTypes: kycVerificationTypes,
  };

  const onSubmit = ({
    documentVerificationTypes,
  }: {
    documentVerificationTypes: DocumentVerificationType[];
  }) => {
    requestPlayerKycDocuments({
      playerId,
      documentVerificationTypes,
    }).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      } else if (close) {
        close();
      }
    });
  };

  if (!form) {
    return null;
  }

  const kycStatusNotNeed = kycCheckStatus?.status === 'NotNeed';

  const showMissingVerifications =
    kycCheckStatus &&
    kycCheckStatus?.verificationsMissing.length > 0 &&
    !kycStatusNotNeed;

  const showVerifiedVerifications =
    kycCheckStatus &&
    kycCheckStatus?.verificationsDone.length > 0 &&
    !kycStatusNotNeed;

  return (
    <Card
      size="md"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <CardBody>
        <div className="p-3">
          <Form
            defaultValues={kycStatusNotNeed ? allValues : defaultValues}
            onSubmit={onSubmit}
            className="grid gap-6"
          >
            <div className="space-y-1">
              <div className="block text-gray-600 text-sm dark:text-gray-500">
                {t(form?.description)}
              </div>
              {showMissingVerifications && (
                <div className="block text-gray-600 text-sm dark:text-gray-500">
                  {t(form.requestedLabel)}:{' '}
                  {kycCheckStatus?.verificationsMissing.join(', ')}
                </div>
              )}
              {showVerifiedVerifications && (
                <div className="block text-gray-600 text-sm dark:text-gray-500">
                  {t(form.verifiedLabel)}:{' '}
                  {kycCheckStatus?.verificationsDone.join(', ')}
                </div>
              )}
              {!kycStatusNotNeed && (
                <div className="space-y-3">
                  <SelectField
                    options={kycVerificationOptions}
                    name="documentVerificationTypes"
                    id="PlayerKycStatusForm__documentVerificationTypes"
                    title={t(form.requiredDocumentTypesLabel)}
                    isMulti
                  />
                </div>
              )}
            </div>
            <ErrorMessage message={errorMessage} />
            <SubmitButton
              value={kycStatusNotNeed ? t(form.requestAll) : t(form.submit)}
              disabled={requestPlayerKycDocumentsState.fetching}
            />
          </Form>
        </div>
      </CardBody>
    </Card>
  );
};

export default PlayerNameForm;
