import { differenceInHours } from 'date-fns';
import React from 'react';
import { feedback } from 'react-feedbacker';
import { gql, useMutation } from 'urql';

import { useFeedbackMessages } from '@/bits';
import { Button } from '@/components';
import { useTranslate } from '@/contexts';
import { BrandEnum } from '@/globalTypes';
import { useCan } from '@/utils/access';
import { TransactionInspectionQuery } from './__generated__/useTransaction';
import {
  ApprovePendingZimplerWithdrawalMutation,
  ApprovePendingZimplerWithdrawalMutationVariables,
} from './__generated__/ZimplerWithdrawalManualApproveButton';

const APPROVE_PENDING_ZIMPLER_WITHDRAWAL = gql`
  mutation ApprovePendingZimplerWithdrawal(
    $externalTransactionId: String!
    $playerSessionId: String!
    $brand: BrandEnum!
  ) {
    approvePendingZimplerWithdrawal(
      externalTransactionId: $externalTransactionId
      playerSessionId: $playerSessionId
      brand: $brand
    )
  }
`;

const useApprovePendingZimplerWithdrawal = () => {
  const canApprove = useCan('APPROVE_PENDING_ZIMPLER_WITHDRAWAL');

  const [{ fetching: loading }, approve] = useMutation<
    ApprovePendingZimplerWithdrawalMutation,
    ApprovePendingZimplerWithdrawalMutationVariables
  >(APPROVE_PENDING_ZIMPLER_WITHDRAWAL);

  return {
    canApprove,
    approve,
    loading,
  };
};

type Transactions = NonNullable<
  NonNullable<
    TransactionInspectionQuery['viewer']['transactionsV2']['edges']
  >[number]
>['node'];

const getTransactionIsInitializedZimplerWithdrawal = (
  transaction: Transactions,
): boolean =>
  transaction?.paymentMethod === 'ZimplerWithdrawal' &&
  transaction.paymentStatus === 'Initialized';

const getIsWithinTimeThreshold = (transaction: Transactions): boolean => {
  const processedAt = transaction.processedAt
    ? new Date(transaction.processedAt)
    : null;

  // if we have processedAt (which we should), only allow manual action
  // the first 72 hours after being processed
  if (processedAt && differenceInHours(Date.now(), processedAt) > 72) {
    return false;
  }
  return true;
};

type Props = {
  transaction: Transactions;
};

const ZimplerWithdrawalManualApproveButton = ({ transaction }: Props) => {
  const { t } = useTranslate();
  const { approve, loading, canApprove } = useApprovePendingZimplerWithdrawal();
  const feedbackMessages = useFeedbackMessages();

  const isInitializedZimplerWithdrawal =
    getTransactionIsInitializedZimplerWithdrawal(transaction);

  if (!transaction || !isInitializedZimplerWithdrawal || !canApprove) {
    return null;
  }

  const { brand, extTransactionId, playerSessionUUID } = transaction;

  if (!brand || !extTransactionId || !playerSessionUUID) {
    return null;
  }

  if (!getIsWithinTimeThreshold(transaction)) {
    return null;
  }

  return (
    <Button
      type="button"
      variant="primary"
      disabled={loading}
      className="text-xs"
      onClick={() => {
        approve({
          brand: brand.code as BrandEnum,
          externalTransactionId: extTransactionId,
          playerSessionId: playerSessionUUID,
        }).then((res) => {
          if (res?.data) {
            feedback.success(t(feedbackMessages.success));
          }
        });
      }}
    >
      Approve
    </Button>
  );
};

export default ZimplerWithdrawalManualApproveButton;
