import { GlobalPlayerActivityInfoBoxQuery } from '@/blocks/global-player-activity-info-block/__generated__/component';
import { GlobalPlayerInfoBoxQuery } from '@/blocks/global-player-info-block/__generated__/component';
import { GlobalPlayerKycOverviewBoxQuery } from '@/blocks/global-player-kyc-overview-block/__generated__/component';
import { GlobalPlayerQuickOverviewBoxQuery } from '@/blocks/global-player-quick-overview-block/__generated__/component';
import { GlobalPlayerWalletBoxQuery } from '@/blocks/global-player-wallet-block/__generated__/component';
import { PlayerSearchAsYouTypeQuery } from '@/hooks/__generated__/usePlayerSearch';
import { Nullable } from '@/types';
import formatMoney from './formatter/formatMoney';

export interface PlayerListValueType extends PlayerSearchAsYouTypeNode {
  isGlobal?: boolean;
}
export type PlayerListType = {
  value: PlayerListValueType | undefined;
  label: string;
};

type PlayerSearchAsYouTypeNode = NonNullable<
  NonNullable<
    PlayerSearchAsYouTypeQuery['viewer']['playersAsYouTypeV2']['edges']
  >[number]
>['node'];

type PlayerFormattedForSearchType = {
  value: PlayerSearchAsYouTypeNode | undefined;
  label: string;
};

type PlayersFormattedForSearchType = PlayerFormattedForSearchType[];

export type RenderBrandValueType = {
  brand: string;
  brandValue: any;
  status: string;
  playerId: string;
  rawPlayerId: string;
  registeredLoggedAt: Nullable<string>;
  lastLoginLoggedAt: Nullable<string>;
};

export type RenderSelfExclusionBrandValueType = {
  brand: string;
  configuredAt: Nullable<string>;
  exclusionEndsAt: Nullable<string>;
  status: string;
  playerId: string;
  rawPlayerId: string;
};

export function someItemIsDifferent(arr: Nullable<any[]>) {
  if (!arr || !arr.length) return null;
  return arr.every((v) => v === arr[0]);
}

export function getGenericBrandsValues<
  T extends
    | GlobalPlayerInfoBoxQuery
    | GlobalPlayerKycOverviewBoxQuery
    | GlobalPlayerQuickOverviewBoxQuery
    | GlobalPlayerActivityInfoBoxQuery,
>(
  data: T,
  iteratee: (value: T['playerGlobal']['players'][number]) => any,
): Nullable<RenderBrandValueType[]> {
  return data?.playerGlobal.players.map((p) => ({
    brand: p.brand.name,
    brandValue: iteratee(p),
    status: p.accountStatus.status,
    playerId: p.id,
    rawPlayerId: p.rawPlayerId,
    registeredLoggedAt: p.loginInfo?.registration.loggedAt,
    lastLoginLoggedAt: p.loginInfo?.lastLogin.loggedAt,
  }));
}

export function getSelfExclusionBrandsValues<
  T extends GlobalPlayerInfoBoxQuery | GlobalPlayerQuickOverviewBoxQuery,
>(data: T): Nullable<RenderSelfExclusionBrandValueType[]> {
  return data?.playerGlobal.players.map((p) => ({
    brand: p.brand.name,
    configuredAt: p.selfExclusionDetails?.configuredAt,
    exclusionEndsAt: p.selfExclusionDetails?.exclusionEndsAt,
    status: p.accountStatus.status,
    playerId: p.id,
    rawPlayerId: p.rawPlayerId,
  }));
}

export function getMoneyBrandsValues<T extends GlobalPlayerWalletBoxQuery>(
  data: T,
  currency: string,
  iteratee: (value: T['playerGlobal']['players'][number]) => any,
): Nullable<RenderBrandValueType[]> {
  return data?.playerGlobal.players.map((p) => ({
    brand: p.brand.name,
    brandValue: formatMoney(iteratee(p), currency),
    status: p.accountStatus.status,
    playerId: p.id,
    rawPlayerId: p.rawPlayerId,
    registeredLoggedAt: p.loginInfo?.registration.loggedAt,
    lastLoginLoggedAt: p.loginInfo?.lastLogin.loggedAt,
  }));
}
export function sortByGlobalPlayerID(a: PlayerListType, b: PlayerListType) {
  if (a.value?.playerGlobalId && b.value?.playerGlobalId) {
    if (a.value.playerGlobalId < b.value.playerGlobalId) {
      return -1;
    }
    if (a.value.playerGlobalId > b.value.playerGlobalId) {
      return 1;
    }
  }
  return 0;
}

export function getGlobalPlayerIds(players: PlayersFormattedForSearchType) {
  return (
    players?.reduce<string[]>((acc, p) => {
      if (p?.value?.playerGlobalId && !acc.includes(p?.value?.playerGlobalId)) {
        return [...acc, p?.value?.playerGlobalId];
      }
      return acc;
    }, []) || []
  );
}

export function generateGlobalPlayersForSearch(
  players: PlayersFormattedForSearchType,
): PlayerListType[] {
  const finalPlayers: PlayerListType[] = [];
  const alreadyRenderGlobalPlayerIds = new Set<string>();
  players?.forEach((p, i, players) => {
    if (
      p.value?.playerGlobalId &&
      !alreadyRenderGlobalPlayerIds.has(p.value?.playerGlobalId)
    ) {
      finalPlayers.push({
        value: { ...p.value, isGlobal: true },
        label: `${p.value?.firstName} ${players[i].value?.lastName}`,
      });
      if (players[i].value && players[i].value?.playerGlobalId) {
        alreadyRenderGlobalPlayerIds.add(p.value.playerGlobalId);
      }
    }
    finalPlayers.push(p);
  });
  return finalPlayers;
}
