import React, { FC } from 'react';

export const ResetHardIcon: FC<{ className?: string }> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 21 21"
    stroke="currentColor"
    style={{ height: '1em', width: '1em' }}
    className={className}
    strokeWidth="1.8"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      transform="matrix(0 1 1 0 2.5 2.5)"
    >
      <path
        d="m13 11 3 3v-6c0-3.36502327-2.0776-6.24479706-5.0200433-7.42656457-.9209869-.36989409-1.92670197-.57343543-2.9799567-.57343543-4.418278 0-8 3.581722-8 8s3.581722 8 8 8c1.48966767 0 3.4724708-.3698516 5.0913668-1.5380762"
        transform="matrix(-1 0 0 -1 16 16)"
      />
      <path d="m5 5 6 6" /> <path d="m11 5-6 6" />{' '}
    </g>
  </svg>
);
