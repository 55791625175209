import React, { useEffect } from 'react';
import { usePrevious } from 'react-use';

import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  InlineSpinner,
} from '@/components';
import {
  ChatIcon,
  NextIcon,
  PreviousIcon,
  RefreshIcon,
} from '@/components/icons';
import { useTranslate } from '@/contexts';
import { PlayerNoteSearchOrder } from '@/globalTypes';
import { useOktaInfo } from '@/hooks';
import { getBrandCodeImage } from '@/utils/get-brand-code-image';
import {
  NotesOrderForm,
  useNotesOrderByForm,
} from '../../components/NotesOrder';
import { usePlayerNotes } from '../../hooks/usePlayerNotes';
import AddNote from './AddNote';
import Note from './Note';

export type NotesBlock = NonNullable<
  | Queries.StaticGlobalPlayerNotesBlockQuery['sanityGlobalPlayerNotesBlock']
  | Queries.StaticPlayerNotesBlockQuery['sanityPlayerNotesBlock']
>;

const getOrderBy = (singleOrderBy: PlayerNoteSearchOrder) => {
  // when ordering by priority value, always order by date within the order
  if (singleOrderBy === PlayerNoteSearchOrder.priorityValue) {
    return [
      PlayerNoteSearchOrder.priorityValue,
      PlayerNoteSearchOrder.createdAt,
    ];
  }
  return [singleOrderBy];
};

const Notes = ({
  block,
  isGlobal = false,
}: {
  block: NotesBlock;
  isGlobal?: boolean;
}) => {
  const { t } = useTranslate();
  const user = useOktaInfo();
  const notesOrderForm = useNotesOrderByForm();
  const previousOrderBy = usePrevious(notesOrderForm.orderBy);
  const orderBy = getOrderBy(notesOrderForm.orderBy);

  const { notes, nextPage, previousPage, firstPage, loading, fetch } =
    usePlayerNotes({
      orderBy,
    });

  // When the order changes, reset pagination (go to first page)
  useEffect(() => {
    if (previousOrderBy !== notesOrderForm.orderBy) {
      firstPage?.();
    }
  }, [previousOrderBy, notesOrderForm.orderBy, firstPage]);

  return (
    <Card
      title={t(block.title)}
      size="md"
      showOptionsAtBottom
      options={
        <CardOptions>
          <CardOptionsButton
            className="flex"
            onClick={() => fetch({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
          <CardOptionsButton
            disabled={!previousPage}
            onClick={() => previousPage && previousPage()}
          >
            <PreviousIcon />
          </CardOptionsButton>
          <CardOptionsButton
            disabled={!nextPage}
            onClick={() => nextPage && nextPage()}
          >
            <NextIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody className="divide-y">
        <AddNote block={block} isGlobal={isGlobal} />
        <div className="space-y-3 p-3">
          <NotesOrderForm methods={notesOrderForm.methods} block={block} />

          {loading ? (
            <div className="p-3 text-gray-500 text-center flex items-center flex-col space-y-1">
              <InlineSpinner />
            </div>
          ) : (
            <>
              {notes.length === 0 && (
                <div className="p-3 text-gray-500 text-center flex items-center flex-col space-y-1">
                  <div>
                    <ChatIcon />
                  </div>
                  <div>{t(block.noNotesPlaceholder)}</div>
                </div>
              )}
              <ul>
                {notes.map((node, index, arr) => (
                  <li
                    className="flex justify-start [overflow-wrap:anywhere]"
                    key={node.note.id}
                  >
                    <Note
                      note={node.note}
                      isLast={index === arr.length - 1}
                      email={user.email || ''}
                      icon={getBrandCodeImage(node.origin)}
                    />
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default Notes;
