import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { feedback } from 'react-feedbacker';
import { useMutation } from 'urql';

import { WithdrawalApprovalsQuery } from '@/blocks/withdrawal-approvals-block/__generated__/useWithdrawalApprovals';
import {
  Card,
  CardCloseButton,
  CardOptions,
  ErrorMessage,
  Form,
  SelectField,
  SelectOption,
  SubmitButton,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { DecisionType } from '@/globalTypes';
import { Nullable } from '@/types';
import { assert } from '@/utils/error';
import {
  ManualWithdrawalApprovalMutation,
  ManualWithdrawalApprovalMutationVariables,
} from './__generated__/WithdrawalReviewsForm';

const query = graphql`
  query SanityWithdrawalReviewsForm {
    sanityWithdrawalReviewsForm {
      title {
        ...SanityLocaleString
      }
      description {
        ...SanityLocaleString
      }
      decisionType {
        ...SanityLocaleString
      }
      deny {
        ...SanityLocaleString
      }
      approve {
        ...SanityLocaleString
      }
      success {
        ...SanityLocaleString
      }
    }
  }
`;

const manualWithdrawalApprovalMutation = gql`
  mutation ManualWithdrawalApproval(
    $paymentId: String!
    $decision: DecisionType!
  ) {
    manualWithdrawalApproval(paymentId: $paymentId, decision: $decision) {
      paymentId
      playerId
    }
  }
`;

type ListPaymentApprovalsNode = NonNullable<
  NonNullable<
    WithdrawalApprovalsQuery['viewer']['listPaymentApprovals']['edges']
  >[number]
>['node'];

type Props = {
  selectedWithdrawals: (ListPaymentApprovalsNode | undefined)[];
};

const decisionTypesOptions: SelectOption[] = [
  {
    label: 'Approve',
    value: DecisionType.Approve,
  },
  {
    label: 'Deny',
    value: DecisionType.Deny,
  },
];

const WithdrawalReviewsForm = ({ selectedWithdrawals }: Props) => {
  const staticData =
    useStaticQuery<Queries.SanityWithdrawalReviewsFormQuery>(query);

  const [errorMessage, setErrorMessage] = useState<Nullable<string>[]>([]);
  const form = staticData.sanityWithdrawalReviewsForm;

  assert(form, 'missing form data');
  const { t } = useTranslate();

  const [manualWithdrawalApprovalState, manualWithdrawalApproval] = useMutation<
    ManualWithdrawalApprovalMutation,
    ManualWithdrawalApprovalMutationVariables
  >(manualWithdrawalApprovalMutation);

  const defaultValues: { decisionType: '' | DecisionType } = {
    decisionType: '',
  };

  const { close } = useModalContext();

  const onSubmit = async (values: typeof defaultValues) => {
    setErrorMessage([]);

    const promises = await Promise.allSettled(
      selectedWithdrawals.map((selectedWithdrawal) => {
        return new Promise((resolve, reject) => {
          if (!values.decisionType) {
            return null;
          }
          return manualWithdrawalApproval({
            paymentId: selectedWithdrawal?.paymentId || '',
            decision: values.decisionType,
          }).then((res) => {
            if (res.error?.message) {
              reject('Rejected');
              setErrorMessage((current) => [
                ...current,
                `paymentId: ${selectedWithdrawal?.paymentId} ${res.error?.message}`,
              ]);
            }
            resolve('Resolved');
          });
        });
      }),
    );

    const findRejected = promises.find(
      (element) => element.status === 'rejected',
    );

    if (!findRejected && close) {
      feedback.success(t(form.success));
      close();
    }
  };

  return (
    <Card
      size="sm"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">
        <Form
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          className="grid grid-cols-2 sm:grid-cols-3 gap-6"
        >
          <SelectField
            name="decisionType"
            id="WithdrawalReviewForm__decisionType"
            title={t(form.decisionType)}
            required
            options={decisionTypesOptions}
            className="col-span-full"
          />
          {errorMessage.map((value, index) => (
            <ErrorMessage message={errorMessage[index]} key={value} />
          ))}
          <SubmitButton
            value="Submit"
            disabled={manualWithdrawalApprovalState.fetching}
          />
        </Form>
      </div>
    </Card>
  );
};

export default WithdrawalReviewsForm;
