import { addDays, addHours, addMonths } from 'date-fns';
import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useMemo, useState } from 'react';
import { feedback } from 'react-feedbacker';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'urql';

import { useFeedbackMessages } from '@/bits';
import {
  Card,
  CardCloseButton,
  CardOptions,
  DateField,
  ErrorMessage,
  NakedForm,
  SelectField,
  SelectOption,
  SubmitButton,
  TextareaField,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { playerSelfExclusionDetailsFragment } from '@/fragments/PlayerSelfExclusionDetails';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import { mapVariables } from '@/utils';
import {
  PlayerSelfExclusionFormQuery,
  PlayerSelfExclusionFormQueryVariables,
  PlayerSelfExclusionMutation,
  PlayerSelfExclusionMutationVariables,
} from './__generated__/PlayerSelfExclusionForm';

const query = graphql`
  query SanityPlayerSelfExclusionForm {
    sanityPlayerSelfExclusionForm {
      title {
        ...SanityLocaleString
      }
      durationLabel {
        ...SanityLocaleString
      }
      selectDuration {
        ...SanityLocaleString
      }
      duration24Hours {
        ...SanityLocaleString
      }
      duration7Days {
        ...SanityLocaleString
      }
      duration1Month {
        ...SanityLocaleString
      }
      duration3Months {
        ...SanityLocaleString
      }
      duration6Months {
        ...SanityLocaleString
      }
      duration12Months {
        ...SanityLocaleString
      }
      durationIndefinite {
        ...SanityLocaleString
      }
      durationCustom {
        ...SanityLocaleString
      }
      dateLabel {
        ...SanityLocaleString
      }
      commentLabel {
        ...SanityLocaleString
      }
      submit {
        ...SanityLocaleString
      }
    }
  }
`;

const playerQuery = gql`
  query PlayerSelfExclusionForm($playerId: ID!) {
    player(playerId: $playerId) {
      id
    }
  }
`;

const playerMutation = gql`
  mutation PlayerSelfExclusion(
    $playerId: ID!
    $exclusionEndsAt: OffsetDateTime
    $comment: String!
  ) {
    selfExcludeV3(
      playerId: $playerId
      comment: $comment
      exclusionEndsAt: $exclusionEndsAt
    ) {
      id
      ...PlayerSelfExclusionDetails
    }
  }
  ${playerSelfExclusionDetailsFragment}
`;

enum SpecialDurations {
  Indefinite = 'Indefinite',
  Custom = 'Custom',
}

const isAnjouan = process.env.GATSBY_LICENSE === 'ANJ';
const SIX_MONTHS = 6;

const useDurationOptions = (
  form: Queries.SanityPlayerSelfExclusionFormQuery['sanityPlayerSelfExclusionForm'],
) => {
  const { t } = useTranslate();

  return useMemo(() => {
    if (!form) {
      return [];
    }

    const now = Date.now();

    if (isAnjouan) {
      const anjOptions: SelectOption[] = [
        {
          label: t(form.selectDuration),
          value: '',
        },
        {
          label: t(form.duration7Days),
          value: addDays(now, 7).toJSON(),
        },
        {
          label: t(form.durationIndefinite),
          value: SpecialDurations.Indefinite,
        },
        {
          label: t(form.durationCustom),
          value: SpecialDurations.Custom,
        },
      ];

      return anjOptions;
    }

    const options: SelectOption[] = [
      {
        label: t(form.selectDuration),
        value: '',
      },
      {
        label: t(form.duration24Hours),
        value: addHours(now, 24).toJSON(),
      },
      {
        label: t(form.duration7Days),
        value: addDays(now, 7).toJSON(),
      },
      {
        label: t(form.duration1Month),
        value: addMonths(now, 1).toJSON(),
      },
      {
        label: t(form.duration3Months),
        value: addMonths(now, 3).toJSON(),
      },
      {
        label: t(form.duration6Months),
        value: addMonths(now, 6).toJSON(),
      },
      {
        label: t(form.duration12Months),
        value: addMonths(now, 12).toJSON(),
      },
      {
        label: t(form.durationIndefinite),
        value: SpecialDurations.Indefinite,
      },
      {
        label: t(form.durationCustom),
        value: SpecialDurations.Custom,
      },
    ];

    return options;
  }, [form, t]);
};

type FormValues = {
  comment: string;
  duration: string;
  customDate: string | Date;
};

const getExclusionEndsAt = (values: FormValues) => {
  if (values.duration === SpecialDurations.Custom) {
    return new Date(values.customDate);
  }
  if (values.duration === SpecialDurations.Indefinite) {
    return null;
  }
  return new Date(values.duration);
};

const PlayerSelfExclusionForm: FC<{ playerId: string }> = ({ playerId }) => {
  const staticData =
    useStaticQuery<Queries.SanityPlayerSelfExclusionFormQuery>(query);
  const feedbackMessages = useFeedbackMessages();

  const defaultValues: FormValues = {
    comment: '',
    duration: '',
    customDate: '',
  };

  const formMethods = useForm({
    defaultValues,
  });

  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();

  const form = staticData.sanityPlayerSelfExclusionForm;

  const [{ data }] = useQuery<
    PlayerSelfExclusionFormQuery,
    PlayerSelfExclusionFormQueryVariables
  >({
    query: playerQuery,
    variables: { playerId },
  });

  const [selfExcludeState, selfExclude] = useMutation<
    PlayerSelfExclusionMutation,
    PlayerSelfExclusionMutationVariables
  >(playerMutation);

  const { close } = useModalContext();
  const { t } = useTranslate();

  const durationOptions = useDurationOptions(form);

  const onSubmit = (values: FormValues) => {
    if (
      !data?.player?.id ||
      !values.comment.trim() ||
      !values.duration ||
      (values.duration === SpecialDurations.Custom && !values.customDate)
    ) {
      return;
    }

    selfExclude(
      mapVariables({
        playerId: data?.player.id,
        comment: values.comment,
        exclusionEndsAt: getExclusionEndsAt(values),
      }),
    ).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      } else if (close) {
        close();
        feedback.success(t(feedbackMessages.success));
      }
    });
  };

  if (!form || !data) {
    return null;
  }

  const { duration } = formMethods.watch();

  const todayDate = new Date();
  const minSelfExclusionTime = todayDate.setMonth(
    todayDate.getMonth() + SIX_MONTHS,
  );
  const minDate = new Date(minSelfExclusionTime);

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">
        <NakedForm
          methods={formMethods}
          onSubmit={onSubmit}
          className="grid grid-cols-2 sm:grid-cols-3 gap-6"
        >
          <SelectField
            title={t(form.durationLabel)}
            name="duration"
            id="PlayerSelfExclusion__duration"
            options={durationOptions}
            required
          />

          {duration === SpecialDurations.Custom && (
            <DateField
              name="customDate"
              id="PlayerSelfExclusion__customDate"
              title={t(form.dateLabel)}
              minDate={isAnjouan ? minDate : undefined}
            />
          )}

          <TextareaField
            title={t(form.commentLabel)}
            name="comment"
            id="PlayerSelfExclusion__comment"
            className="col-span-full"
            required
          />

          <ErrorMessage message={errorMessage} />
          <SubmitButton
            value={t(form.submit)}
            disabled={selfExcludeState.fetching}
          />
        </NakedForm>
      </div>
    </Card>
  );
};

export default PlayerSelfExclusionForm;
