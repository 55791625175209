import gql from 'graphql-tag';
import { useQuery } from 'urql';

import { useQueryParamsWithTime } from '@/hooks';
import { mapVariables } from '@/utils';
import { makeOrderBy, makePagination } from '@/utils/pagination';
import {
  PendingKycFlowsQuery,
  PendingKycFlowsQueryVariables,
} from './__generated__/usePendingKycFlows';
import { queryParams } from './queryParams';

const QUERY = gql`
  query PendingKycFlows(
    $playerId: String
    $playerGlobalUUID: String
    $kycFlowId: ID
    $kycCheckStartedFrom: OffsetDateTime
    $kycCheckStartedTo: OffsetDateTime
    $kycCheckDeadlineFrom: OffsetDateTime
    $kycCheckDeadlineTo: OffsetDateTime
    $after: String
    $before: String
    $first: Int
    $last: Int
    $desc: Boolean
    $orderBy: PendingKycFlowViewSearchOrder
  ) {
    viewer {
      id
      pendingKycFlowSearch(
        playerId: $playerId
        playerGlobalUUID: $playerGlobalUUID
        kycFlowId: $kycFlowId
        kycCheckStartedFrom: $kycCheckStartedFrom
        kycCheckStartedTo: $kycCheckStartedTo
        kycCheckDeadlineFrom: $kycCheckDeadlineFrom
        kycCheckDeadlineTo: $kycCheckDeadlineTo
        after: $after
        before: $before
        first: $first
        last: $last
        desc: $desc
        orderBy: $orderBy
      ) {
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
        edges {
          node {
            playerId
            playerUUID
            playerGlobalId
            playerGlobalUUID
            kycCheckStartedAt
            kycCheckDeadline
          }
        }
      }
    }
  }
`;

export default function usePendingKycFlows() {
  const [query, setQuery] = useQueryParamsWithTime(queryParams);

  const [{ data, fetching }, refresh] = useQuery<
    PendingKycFlowsQuery,
    PendingKycFlowsQueryVariables
  >({
    query: QUERY,
    variables: mapVariables(query),
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter: (values: {}) => {
      setQuery(values, 'replaceIn');
    },
    ...makeOrderBy(query, setQuery),
    ...makePagination(
      query,
      setQuery,
      data?.viewer.pendingKycFlowSearch.pageInfo,
    ),
    kycPendingFlows: data?.viewer.pendingKycFlowSearch?.edges?.map(
      (edge) => edge?.node,
    ),
  };
}
