import React, { useCallback, useMemo } from 'react';

import { ControlledModal, InlineIconButton, Value } from '@/components';
import { EditIcon, ExternalLinkIcon } from '@/components/icons';
import { TranslateFn, useTranslate } from '@/contexts';
import { PlayerKycStatusForm } from '@/forms';
import { Nullable } from '@/types';
import { logger } from '@/utils';
import { PlayerKycOverviewBoxQuery } from './__generated__/component';

const createStatusTranslationMap = (
  t: TranslateFn,
  block: Queries.SanityPlayerKycOverviewBlockFragment,
): Record<string, string> => ({
  Done: t(block.kycStatusDone),
  Failed: t(block.kycStatusFailed),
  NotNeed: t(block.kycStatusNotNeeded),
  Pending: t(block.kycStatusPending),
  Rejected: t(block.kycStatusRejected),
  Required: t(block.kycStatusRequired),
  WillBeRequired: t(block.kycStatusWillBeRequired),
});

const useGetKycStatus = (
  block: Queries.SanityPlayerKycOverviewBlockFragment,
) => {
  const { t } = useTranslate();

  const statusTranslationMap = useMemo(
    () => createStatusTranslationMap(t, block),
    [block, t],
  );

  const getKycStatus = useCallback<
    (
      kycCheckStatus: PlayerKycOverviewBoxQuery['player']['kycCheckStatus'],
    ) => string | undefined
  >(
    (kycCheckStatus) => {
      const kycStatus = kycCheckStatus?.status;

      if (kycStatus) {
        return statusTranslationMap[kycStatus];
      }

      logger.warn(`No translation for kyc status ${kycStatus}`);

      return undefined;
    },
    [statusTranslationMap],
  );

  return getKycStatus;
};

export const KycStatusValue = ({
  fetching,
  block,
  data,
}: {
  fetching: boolean;
  block: Queries.SanityPlayerKycOverviewBlockFragment;
  data: Nullable<PlayerKycOverviewBoxQuery>;
}) => {
  const { t } = useTranslate();
  const getKycStatus = useGetKycStatus(block);

  return (
    <Value
      fetching={fetching}
      title={t(block.kycStatusLabel)}
      value={
        data?.player.kycCheckStatus && getKycStatus(data.player.kycCheckStatus)
      }
      suffix={
        <>
          {data?.player.kycCheckStatus?.sumSubEndUserId && (
            <InlineIconButton>
              <a
                href={`${process.env.SUMSUB_APPLICANT_PROFILE}externalUserId=${data.player.kycCheckStatus.sumSubEndUserId}`}
                target="_blank"
                rel="noreferrer"
              >
                <ExternalLinkIcon />
              </a>
            </InlineIconButton>
          )}
          <ControlledModal
            content={
              data?.player.id ? (
                <PlayerKycStatusForm
                  kycCheckStatus={data.player.kycCheckStatus}
                  playerId={data?.player.id}
                />
              ) : null
            }
          >
            <InlineIconButton>
              <EditIcon />
            </InlineIconButton>
          </ControlledModal>
        </>
      }
    />
  );
};
