import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { feedback } from 'react-feedbacker';
import { SubmitHandler, useForm } from 'react-hook-form';

import { useFeedbackMessages } from '@/bits';
import { Button, NakedForm, TextField } from '@/components';
import {
  useDispatchElasticIndexPrefix,
  useElasticIndexPrefix,
  useTranslate,
} from '@/contexts';

const query = graphql`
  query SanityElasticIndexPrefixForm {
    sanityElasticIndexPrefixForm {
      title {
        ...SanityLocaleString
      }
      indexPrefix {
        ...SanityLocaleString
      }
      submit {
        ...SanityLocaleString
      }
      remove {
        ...SanityLocaleString
      }
    }
  }
`;

type FormValues = {
  indexPrefix: string;
};

const ElasticIndexPrefixForm = () => {
  const { t } = useTranslate();
  const form =
    useStaticQuery<Queries.SanityElasticIndexPrefixFormQuery>(
      query,
    ).sanityElasticIndexPrefixForm;

  const feedbackMessages = useFeedbackMessages();
  const indexState = useElasticIndexPrefix();
  const dispatch = useDispatchElasticIndexPrefix();

  const methods = useForm<FormValues>({
    defaultValues: {
      indexPrefix: indexState.indexPrefix ?? '',
    },
  });

  const removeIndexPrefix = () => {
    dispatch({
      type: 'REMOVE_INDEX_PREFIX',
    });
    methods.setValue('indexPrefix', '');
    feedback.success(t(feedbackMessages.success));
  };

  const handleSubmit: SubmitHandler<FormValues> = ({ indexPrefix }) => {
    if (indexPrefix) {
      dispatch({
        type: 'SET_INDEX_PREFIX',
        indexPrefix,
      });
      feedback.success(t(feedbackMessages.success));
      return;
    }
    removeIndexPrefix();
  };

  if (!form) {
    return null;
  }

  return (
    <div>
      <h3 className="text-black dark:text-white mb-2">{t(form.title)}</h3>
      <NakedForm methods={methods} onSubmit={handleSubmit}>
        <div className="grid gap-2">
          <TextField name="indexPrefix" title={t(form.indexPrefix)} />

          <div className="grid gap-2 grid-cols-2">
            <Button type="submit" variant="primary">
              {t(form.submit)}
            </Button>
            <Button
              type="button"
              variant="secondary"
              onClick={removeIndexPrefix}
            >
              {t(form.remove)}
            </Button>
          </div>
        </div>
      </NakedForm>
    </div>
  );
};

export default ElasticIndexPrefixForm;
