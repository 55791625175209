import { useParams } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect } from 'react';
import { useQueryParams } from 'use-query-params';

import { Card, CardBody, Pill, Value } from '@/components';
import { useTranslate } from '@/contexts';
import { useGames } from '@/hooks/useGames';
import { Nullable } from '@/types';
import { assert } from '@/utils/error';
import { queryParams } from './queryParams';

const BLOCK_STATIC_QUERY = graphql`
  query SanityGameInfoBlock {
    sanityGameInfoBlock {
      title {
        ...SanityLocaleString
      }
    }
  }
`;

const renderPills = (pills: Nullable<string[]>) => {
  if (pills && pills.length > 0) {
    return pills.map((pill, i) => <Pill key={i}>{pill}</Pill>);
  }

  return '-';
};

const GameInfoBlock = () => {
  const { t } = useTranslate();
  const [query] = useQueryParams(queryParams);
  const params = { ...query, ...useParams() };

  assert(params.gameId, 'gameId missing');

  const block =
    useStaticQuery<Queries.SanityGameInfoBlockQuery>(
      BLOCK_STATIC_QUERY,
    ).sanityGameInfoBlock;

  const { getGame, data, loading } = useGames();

  useEffect(() => {
    getGame(params.gameId);
  }, [params.gameId, getGame]);

  if (loading || !block || !data) {
    return null;
  }

  const game = data[0];

  return (
    <Card size="lg" title={t(block.title)}>
      <CardBody>
        <div className="p-3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
          <Value fetching={loading} title={'Game Name'} value={game?.name} />
          <Value
            fetching={loading}
            title={'Game Provider'}
            value={game?.provider}
          />
          <Value
            fetching={loading}
            title={'Game Studio'}
            value={game?.studio}
          />
          <Value
            fetching={loading}
            title={'Release Date'}
            value={game?.releaseDate}
          />
          <Value fetching={loading} title={'RTP'} value={game?.rtp} />
          <Value
            fetching={loading}
            title={'Category'}
            value={game?.gameCategory}
          />

          <Value fetching={loading} title={'Lines'} value={game?.lines} />
          <Value fetching={loading} title={'Layout'} value={game?.layout} />
          <Value fetching={loading} title={'Features'}>
            <div className="space-x-1">{renderPills(game?.features)}</div>
          </Value>
          <Value fetching={loading} title={'Theme'}>
            <div className="space-x-1">{renderPills(game?.themes)}</div>
          </Value>
        </div>
      </CardBody>
    </Card>
  );
};

export default GameInfoBlock;
