import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import { gql, useMutation } from 'urql';

import {
  Card,
  CardCloseButton,
  CardOptions,
  ErrorMessage,
  Form,
  SubmitButton,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import {
  PlayerRequestSowMutation,
  PlayerRequestSowMutationVariables,
} from './__generated__/PlayerRequestSowForm';

const sanityPlayerRequestSowForm = graphql`
  query SanityPlayerRequestSowForm {
    sanityPlayerRequestSowForm {
      title {
        ...SanityLocaleString
      }
      description {
        ...SanityLocaleString
      }
      submit {
        ...SanityLocaleString
      }
    }
  }
`;

const requestSowMutation = gql`
  mutation PlayerRequestSow($playerId: ID!) {
    requestSow(playerId: $playerId)
  }
`;

const PlayerRequestSowForm = ({
  playerUuid,
  callback,
}: {
  playerUuid: string;
  callback?: () => void;
}) => {
  const { t } = useTranslate();
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();
  const { close } = useModalContext();

  const form = useStaticQuery<Queries.SanityPlayerRequestSowFormQuery>(
    sanityPlayerRequestSowForm,
  ).sanityPlayerRequestSowForm;

  const [requestSowState, requestSow] = useMutation<
    PlayerRequestSowMutation,
    PlayerRequestSowMutationVariables
  >(requestSowMutation);

  const defaultValues = {};

  const onSubmit = (_values: typeof defaultValues) => {
    if (!playerUuid) {
      return;
    }

    setErrorMessage(null);

    requestSow({ playerId: playerUuid })
      .then((res) => {
        if (res.error?.message && isMounted) {
          setErrorMessage(res.error.message);
        } else if (callback) {
          callback();
        }
      })
      .then(() => {
        if (close) {
          close();
        }
      });
  };

  if (!form) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">
        <Form
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          className="grid gap-6"
        >
          <div className="text-black dark:text-white">
            {t(form.description)}
          </div>
          <ErrorMessage message={errorMessage} />
          <SubmitButton
            value={t(form.submit)}
            disabled={requestSowState.fetching}
          />
        </Form>
      </div>
    </Card>
  );
};

export default PlayerRequestSowForm;
