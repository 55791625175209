import classNames from 'classnames';
import { saveAs } from 'file-saver';
import React, {
  ComponentProps,
  FC,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useState,
} from 'react';
import { TransformWrapper } from 'react-zoom-pan-pinch';

import {
  DocumentSearchIcon,
  DownloadIcon,
  ExternalLinkIcon,
  NextIcon,
  PreviousIcon,
  RefreshIcon,
  ZoomInIcon,
  ZoomOutIcon,
} from '@/components/icons';

export type TransformWrapperChildren = {
  zoomIn: () => void;
  zoomOut: () => void;
  resetTransform: () => void;
};

type Props = {
  options?: ReactNode;
  filePath: string;
  isLoading: boolean;
  pagination?: {
    currentPage: number;
    totalPages: number;
    onNextPage: () => void;
    onPrevPage: () => void;
  };
};

const OptionButton = (props: ComponentProps<'button'>) => (
  <button
    {...props}
    className="ml-1 align-top disabled:opacity-50 disabled:pointer-events-none dark:disabled:opacity-20 focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-800 focus-visible:ring-white inline-flex justify-center items-center rounded-md p-1 focus:outline-none"
  />
);

export const FileLayout: FC<PropsWithChildren<Props>> = ({
  children,
  filePath,
  isLoading,
  pagination,
}) => {
  const [rotation, setRotation] = useState(0);

  const rotate = () => setRotation((current) => (current + 90) % 360);

  const onNextPage = useCallback(
    () => pagination?.onNextPage(),
    [pagination?.onNextPage],
  );

  const onPrevPage = useCallback(
    () => pagination?.onPrevPage(),
    [pagination?.onPrevPage],
  );

  return (
    <TransformWrapper
      wheel={{
        disabled: true,
      }}
    >
      {(wrapperProps: TransformWrapperChildren) => (
        <div className="relative overflow-hidden">
          {!isLoading && (
            <div className="absolute top-0 right-0 z-10 p-2 flex items-center shadow dark:shadow bg-white text-black dark:bg-gray-800 dark:text-gray-200">
              <a href={filePath} target="_blank" rel="noreferrer">
                <ExternalLinkIcon />
              </a>
              <OptionButton type="button" onClick={() => saveAs(filePath)}>
                <DownloadIcon />
              </OptionButton>
              <OptionButton type="button" onClick={wrapperProps.zoomIn}>
                <ZoomInIcon />
              </OptionButton>
              <OptionButton type="button" onClick={wrapperProps.zoomOut}>
                <ZoomOutIcon />
              </OptionButton>
              <OptionButton type="button" onClick={wrapperProps.resetTransform}>
                <DocumentSearchIcon />
              </OptionButton>
              <OptionButton type="button" onClick={rotate}>
                <RefreshIcon />
              </OptionButton>
              {pagination && (
                <>
                  <OptionButton
                    type="button"
                    disabled={pagination.currentPage === 1}
                    onClick={onPrevPage}
                  >
                    <PreviousIcon />
                  </OptionButton>
                  <div className="text-xs">
                    {pagination.currentPage} of {pagination.totalPages}
                  </div>
                  <OptionButton
                    type="button"
                    disabled={pagination.currentPage === pagination.totalPages}
                    onClick={onNextPage}
                  >
                    <NextIcon />
                  </OptionButton>
                </>
              )}
            </div>
          )}
          <div
            className={classNames('transform overflow-auto', {
              'rotate-90': rotation === 90,
              'rotate-180': rotation === 180,
              '-rotate-90': rotation === 270,
            })}
          >
            {children}
          </div>
        </div>
      )}
    </TransformWrapper>
  );
};
