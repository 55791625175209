import React, { FC, ReactNode } from 'react';

import { Layout, Secure } from '@/components';
import { ThemeProvider, ViewerProvider } from '@/contexts';
import './layout.css';

export const BaseWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Secure>
      <ViewerProvider>
        <ThemeProvider>
          <Layout>{children}</Layout>
        </ThemeProvider>
      </ViewerProvider>
    </Secure>
  );
};
