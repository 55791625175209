import { useParams } from '@reach/router';
import { Link, graphql } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { useQuery } from 'urql';

import { useGetGlobalPlayerOverviewPageLink } from '@/bits/links/useLink';
import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  ControlledModal,
  Copy,
  InlineIconButton,
  InlineMoreInfoIcon,
  Value,
} from '@/components';
import { EditIcon, ExternalLinkIcon, RefreshIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import {
  PlayerAlternativeAddressForm,
  PlayerBirthDateForm,
  PlayerCityForm,
  PlayerInstantBankingForm,
  PlayerNameForm,
  PlayerSsnForm,
  PlayerStreetForm,
  PlayerZipCodeForm,
} from '@/forms';
import { Nullable } from '@/types';
import { useCan } from '@/utils/access';
import formatCountryCode from '@/utils/formatter/formatCountryCode';
import formatDate from '@/utils/formatter/formatDate';
import {
  PlayerKycOverviewBoxQuery,
  PlayerKycOverviewBoxQueryVariables,
} from './__generated__/component';
import { KycStatusValue } from './kyc-status-value';
import { PlayersLinkedBySourceOfWealthValue } from './players-linked-by-source-of-wealth-value';

export const Fragment = graphql`
  fragment SanityPlayerKycOverviewBlock on SanityPlayerKycOverviewBlock {
    title {
      ...SanityLocaleString
    }
    firstNameLabel {
      ...SanityLocaleString
    }
    lastNameLabel {
      ...SanityLocaleString
    }
    titleLabel {
      ...SanityLocaleString
    }
    ssnLabel {
      ...SanityLocaleString
    }
    birthDateLabel {
      ...SanityLocaleString
    }
    countryLabel {
      ...SanityLocaleString
    }
    zipCodeLabel {
      ...SanityLocaleString
    }
    streetLabel {
      ...SanityLocaleString
    }
    cityLabel {
      ...SanityLocaleString
    }
    genderLabel {
      ...SanityLocaleString
    }
    upToDateVerificationsLabel {
      ...SanityLocaleString
    }
    kycStatusLabel {
      ...SanityLocaleString
    }
    kycStatusWillBeRequired {
      ...SanityLocaleString
    }
    kycStatusRequired {
      ...SanityLocaleString
    }
    kycStatusPending {
      ...SanityLocaleString
    }
    kycStatusFailed {
      ...SanityLocaleString
    }
    kycStatusNotNeeded {
      ...SanityLocaleString
    }
    kycStatusRejected {
      ...SanityLocaleString
    }
    kycStatusDone {
      ...SanityLocaleString
    }
    outdatedVerificationsLabel {
      ...SanityLocaleString
    }
    sharedSowAccountsLabel {
      ...SanityLocaleString
    }
    expiredVerificationsLabel {
      ...SanityLocaleString
    }
    playerGlobalUuid {
      ...SanityLocaleString
    }
    alternativeFirstNameLabel {
      ...SanityLocaleString
    }
    alternativeLastNameLabel {
      ...SanityLocaleString
    }
    alternativeCityLabel {
      ...SanityLocaleString
    }
    alternativeStreetLabel {
      ...SanityLocaleString
    }
    alternativeDistrictLabel {
      ...SanityLocaleString
    }
    alternativeStateLabel {
      ...SanityLocaleString
    }
    instantBankingStatusLabel {
      ...SanityLocaleString
    }
    blocked {
      ...SanityLocaleString
    }
    active {
      ...SanityLocaleString
    }
    activeUntil {
      ...SanityLocaleString
    }
    blockedFrom {
      ...SanityLocaleString
    }
  }
`;

const QUERY = gql`
  query PlayerKycOverviewBox($playerId: ID!) {
    player(playerId: $playerId) {
      id
      playerGlobalId
      playerGlobalUUID
      firstName
      lastName
      title
      isInstantBankingBlocked
      instantBankingBlock {
        activeFrom
      }
      ssn
      birthDate
      countryCode
      zipCode
      street
      city
      gender
      verificationTypes {
        upToDateVerifications {
          verificationType
        }
        expiredVerifications
      }
      kycCheckStatus {
        status
        kycFlow {
          status
        }
        verificationsDone
        verificationsMissing
        sumSubEndUserId
      }
      playersLinkedBySourceOfWealth {
        id
        player {
          id
          uuid
          brand {
            name
          }
        }
      }
      riskAssessment {
        playerGlobalId
      }
      translatedPersonalInfo {
        firstName
        lastName
        city
        street
        district
        state
      }
    }
  }
`;

const getUpToDateVerificationsString = (
  data: Nullable<PlayerKycOverviewBoxQuery>,
) => {
  return data?.player.verificationTypes?.upToDateVerifications
    .map((a) => a.verificationType)
    .join(', ');
};

enum InstantBankingStatus {
  Blocked = 'BLOCKED',
  TempActive = 'TEMP_ACTIVE',
  Active = 'ACTIVE',
}

const getInstantBankingStatus = (
  player: PlayerKycOverviewBoxQuery['player'],
): InstantBankingStatus => {
  const { isInstantBankingBlocked, instantBankingBlock } = player;

  if (isInstantBankingBlocked) {
    return InstantBankingStatus.Blocked;
  }

  if (!isInstantBankingBlocked && instantBankingBlock?.activeFrom) {
    return InstantBankingStatus.TempActive;
  }

  return InstantBankingStatus.Active;
};

const PlayerKycOverviewBlock: FC<{
  block: Queries.SanityPlayerKycOverviewBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();
  const params = useParams();
  const getGlobalPlayerOverviewLink = useGetGlobalPlayerOverviewPageLink();
  const isSsnChangeAllowed = useCan('MANAGE_PLAYER_SSN');

  const [{ data, fetching }, refresh] = useQuery<
    PlayerKycOverviewBoxQuery,
    PlayerKycOverviewBoxQueryVariables
  >({
    query: QUERY,
    variables: {
      playerId: params.playerId,
    },
  });

  const instantBankingStatus = data && getInstantBankingStatus(data?.player);
  const isTempActive = instantBankingStatus === InstantBankingStatus.TempActive;
  const isBlocked = instantBankingStatus === InstantBankingStatus.Blocked;
  const activeFrom = data?.player.instantBankingBlock?.activeFrom;

  const formattedActiveFrom = formatDate(
    data?.player.instantBankingBlock?.activeFrom,
  );

  return (
    <Card
      size="lg"
      title={t(block.title)}
      options={
        <CardOptions>
          <CardOptionsButton
            className="flex"
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <div className="p-3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
          <Value
            fetching={fetching}
            title={t(block.firstNameLabel)}
            value={data?.player.firstName}
            suffix={
              <ControlledModal
                content={
                  data?.player.id ? (
                    <PlayerNameForm playerId={data.player.id} />
                  ) : null
                }
              >
                <InlineIconButton>
                  <EditIcon />
                </InlineIconButton>
              </ControlledModal>
            }
          />
          <Value
            fetching={fetching}
            title={t(block.lastNameLabel)}
            value={data?.player.lastName}
            suffix={
              <ControlledModal
                content={
                  data?.player.id ? (
                    <PlayerNameForm playerId={data.player.id} />
                  ) : null
                }
              >
                <InlineIconButton>
                  <EditIcon />
                </InlineIconButton>
              </ControlledModal>
            }
          />
          <Value
            fetching={fetching}
            title={t(block.birthDateLabel)}
            value={data?.player.birthDate}
            suffix={
              <ControlledModal
                content={
                  data?.player.id ? (
                    <PlayerBirthDateForm playerId={data.player.id} />
                  ) : null
                }
              >
                <InlineIconButton>
                  <EditIcon />
                </InlineIconButton>
              </ControlledModal>
            }
          />
          <Value
            fetching={fetching}
            title={t(block.genderLabel)}
            value={data?.player.gender}
          />

          <Value
            fetching={fetching}
            title={t(block.ssnLabel)}
            value={data?.player.ssn}
            suffix={
              isSsnChangeAllowed && (
                <ControlledModal
                  content={
                    data?.player.id ? (
                      <PlayerSsnForm playerId={data.player.id} />
                    ) : null
                  }
                >
                  <InlineIconButton>
                    <EditIcon />
                  </InlineIconButton>
                </ControlledModal>
              )
            }
          />
          <Value
            fetching={fetching}
            title={t(block.countryLabel)}
            value={formatCountryCode(data?.player.countryCode)}
          />
          <Value
            fetching={fetching}
            title={t(block.streetLabel)}
            value={data?.player.street}
            suffix={
              <ControlledModal
                content={
                  data?.player.id ? (
                    <PlayerStreetForm playerId={data.player.id} />
                  ) : null
                }
              >
                <InlineIconButton>
                  <EditIcon />
                </InlineIconButton>
              </ControlledModal>
            }
          />
          <Value
            fetching={fetching}
            title={t(block.zipCodeLabel)}
            value={data?.player.zipCode}
            suffix={
              <ControlledModal
                content={
                  data?.player.id ? (
                    <PlayerZipCodeForm playerId={data.player.id} />
                  ) : null
                }
              >
                <InlineIconButton>
                  <EditIcon />
                </InlineIconButton>
              </ControlledModal>
            }
          />
          <Value
            fetching={fetching}
            title={t(block.cityLabel)}
            value={data?.player.city}
            suffix={
              <ControlledModal
                content={
                  data?.player.id ? (
                    <PlayerCityForm playerId={data.player.id} />
                  ) : null
                }
              >
                <InlineIconButton>
                  <EditIcon />
                </InlineIconButton>
              </ControlledModal>
            }
          />
          <Value
            fetching={fetching}
            title={t(block.upToDateVerificationsLabel)}
            value={getUpToDateVerificationsString(data)}
          />
          <KycStatusValue fetching={fetching} data={data} block={block} />
          <Value
            fetching={fetching}
            title={t(block.outdatedVerificationsLabel)}
            value={data?.player.verificationTypes?.expiredVerifications.join(
              ', ',
            )}
          />
          <PlayersLinkedBySourceOfWealthValue
            fetching={fetching}
            block={block}
            data={data}
          />
          <Value fetching={fetching} title={t(block.playerGlobalUuid)}>
            {data?.player.playerGlobalId && (
              <div className="inline-block align-middle">
                <Copy
                  fetching={fetching}
                  value={data?.player.playerGlobalUUID}
                />
                <Link
                  className="inline-block mt-1"
                  to={
                    getGlobalPlayerOverviewLink(data?.player.playerGlobalId) ??
                    ''
                  }
                >
                  <ExternalLinkIcon />
                </Link>
              </div>
            )}
          </Value>
          <Value
            fetching={fetching}
            title={t(block.instantBankingStatusLabel)}
            value={
              instantBankingStatus === InstantBankingStatus.Blocked
                ? t(block.blocked)
                : t(block.active)
            }
            prefix={
              isBlocked && activeFrom ? (
                <InlineMoreInfoIcon
                  tippyContent={`${t(block.blockedFrom)} ${formattedActiveFrom}`}
                />
              ) : isTempActive ? (
                <InlineMoreInfoIcon
                  tippyContent={`${t(block.activeUntil)} ${formattedActiveFrom}`}
                />
              ) : null
            }
            suffix={
              <ControlledModal
                content={
                  data?.player.id ? (
                    <PlayerInstantBankingForm playerId={data.player.id} />
                  ) : null
                }
              >
                <InlineIconButton>
                  <EditIcon />
                </InlineIconButton>
              </ControlledModal>
            }
          />
          <hr className="col-span-3" />
          <Value
            fetching={fetching}
            title={t(block.alternativeFirstNameLabel)}
            value={data?.player?.translatedPersonalInfo?.firstName}
            suffix={
              <ControlledModal
                content={
                  data?.player.id ? (
                    <PlayerAlternativeAddressForm playerId={data.player.id} />
                  ) : null
                }
              >
                <InlineIconButton>
                  <EditIcon />
                </InlineIconButton>
              </ControlledModal>
            }
          />
          <Value
            fetching={fetching}
            title={t(block.alternativeLastNameLabel)}
            value={data?.player?.translatedPersonalInfo?.lastName}
            suffix={
              <ControlledModal
                content={
                  data?.player.id ? (
                    <PlayerAlternativeAddressForm playerId={data.player.id} />
                  ) : null
                }
              >
                <InlineIconButton>
                  <EditIcon />
                </InlineIconButton>
              </ControlledModal>
            }
          />
          <Value
            fetching={fetching}
            title={t(block.alternativeCityLabel)}
            value={data?.player?.translatedPersonalInfo?.city}
            suffix={
              <ControlledModal
                content={
                  data?.player.id ? (
                    <PlayerAlternativeAddressForm playerId={data.player.id} />
                  ) : null
                }
              >
                <InlineIconButton>
                  <EditIcon />
                </InlineIconButton>
              </ControlledModal>
            }
          />
          <Value
            fetching={fetching}
            title={t(block.alternativeStreetLabel)}
            value={data?.player?.translatedPersonalInfo?.street}
            suffix={
              <ControlledModal
                content={
                  data?.player.id ? (
                    <PlayerAlternativeAddressForm playerId={data.player.id} />
                  ) : null
                }
              >
                <InlineIconButton>
                  <EditIcon />
                </InlineIconButton>
              </ControlledModal>
            }
          />
          <Value
            fetching={fetching}
            title={t(block.alternativeDistrictLabel)}
            value={data?.player?.translatedPersonalInfo?.district}
            suffix={
              <ControlledModal
                content={
                  data?.player.id ? (
                    <PlayerAlternativeAddressForm playerId={data.player.id} />
                  ) : null
                }
              >
                <InlineIconButton>
                  <EditIcon />
                </InlineIconButton>
              </ControlledModal>
            }
          />
          <Value
            fetching={fetching}
            title={t(block.alternativeStateLabel)}
            value={data?.player?.translatedPersonalInfo?.state}
            suffix={
              <ControlledModal
                content={
                  data?.player.id ? (
                    <PlayerAlternativeAddressForm playerId={data.player.id} />
                  ) : null
                }
              >
                <InlineIconButton>
                  <EditIcon />
                </InlineIconButton>
              </ControlledModal>
            }
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default PlayerKycOverviewBlock;
