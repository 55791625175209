import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useState } from 'react';
import { useMutation, useQuery } from 'urql';

import {
  Card,
  CardCloseButton,
  CardOptions,
  CheckboxField,
  ErrorMessage,
  Form,
  SubmitButton,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import {
  ChangePlayerMarketingSmsMutation,
  ChangePlayerMarketingSmsMutationVariables,
  PlayerMarketingSmsFormQuery,
} from './__generated__/PlayerMarketingSmsForm';

const query = graphql`
  query SanityPlayerMarketingSmsForm {
    sanityPlayerMarketingSmsForm {
      title {
        ...SanityLocaleString
      }
      isSmsMarketingAccepted {
        ...SanityLocaleString
      }
      submit {
        ...SanityLocaleString
      }
    }
  }
`;

const playerQuery = gql`
  query PlayerMarketingSmsForm($playerId: ID!) {
    player(playerId: $playerId) {
      id
      isSmsMarketingAccepted
    }
  }
`;

const playerMutation = gql`
  mutation ChangePlayerMarketingSms(
    $playerId: ID!
    $isSmsMarketingAccepted: Boolean!
  ) {
    changeSmsMarketingSettings(
      playerId: $playerId
      isSmsMarketingAccepted: $isSmsMarketingAccepted
    ) {
      id
      isSmsMarketingAccepted
    }
  }
`;

const PlayerMarketingSmsForm: FC<{ playerId: string }> = ({ playerId }) => {
  const staticData =
    useStaticQuery<Queries.SanityPlayerMarketingSmsFormQuery>(query);

  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();

  const form = staticData.sanityPlayerMarketingSmsForm;

  const [{ data }] = useQuery<PlayerMarketingSmsFormQuery>({
    query: playerQuery,
    variables: { playerId },
  });

  const [changePlayerMarketingSmsState, changePlayerMarketingSms] = useMutation<
    ChangePlayerMarketingSmsMutation,
    ChangePlayerMarketingSmsMutationVariables
  >(playerMutation);

  const defaultValues = {
    isSmsMarketingAccepted: !!data?.player?.isSmsMarketingAccepted,
  };

  const { close } = useModalContext();

  const { t } = useTranslate();

  const onSubmit = (values: typeof defaultValues) => {
    changePlayerMarketingSms({
      playerId,
      isSmsMarketingAccepted: values.isSmsMarketingAccepted,
    }).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      } else if (close) {
        close();
      }
    });
  };

  if (!form || !data) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">
        <Form
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          className="grid grid-cols-2 sm:grid-cols-3 gap-6"
        >
          <CheckboxField
            title={t(form.isSmsMarketingAccepted)}
            name="isSmsMarketingAccepted"
            id="PlayerMarketingSmsForm__isSmsMarketingAccepted"
            className="col-span-full"
          />

          <ErrorMessage message={errorMessage} />
          <SubmitButton
            value={t(form.submit)}
            disabled={changePlayerMarketingSmsState.fetching}
          />
        </Form>
      </div>
    </Card>
  );
};

export default PlayerMarketingSmsForm;
