import gql from 'graphql-tag';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useTimeoutFn } from 'react-use';
import { useQuery } from 'urql';

import { ViewerQueryQuery } from './__generated__/ViewerContext';
import { ContextBuilder } from './ContextBuilder';

type ViewerContextValue = {
  viewer: ViewerQueryQuery['viewer'] | undefined;
  stale: boolean;
};

const ViewerContext = new ContextBuilder<ViewerContextValue>('ViewerContext');

export const useViewer = ViewerContext.use;

export const viewerQuery = gql`
  query ViewerQuery {
    viewer {
      id
      identity {
        orgs
        brands
        roles
      }
    }
  }
`;

export const ViewerProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [{ data, fetching }] = useQuery<ViewerQueryQuery>({
    query: viewerQuery,
  });

  const [stale, setIsStale] = useState(false);

  const [, cancel] = useTimeoutFn(() => {
    if (fetching) setIsStale(true);
  }, 5000);

  useEffect(() => {
    if (!fetching) {
      cancel();
      setIsStale(false);
    }
  }, [fetching, cancel]);

  return (
    <ViewerContext.Context.Provider value={{ viewer: data?.viewer, stale }}>
      {children}
    </ViewerContext.Context.Provider>
  );
};
