export const allNumericFields = [
  'amountAdjustments',
  'amountAllBonus',
  'amountBonusAdjustments',
  'amountCashbacks',
  'amountDeposits',
  'amountFailedDeposits',
  'amountFailedWithdrawals',
  'amountFtd',
  'amountFreeSpinWins',
  'amountMoneyDrops',
  'amountWin',
  'amountWithdrawableWinnings',
  'amountWithdrawals',
  'AvgBetSize',
  'AvgDepositAmount',
  'AvgDepositPerUser',
  'AvgRevenuePerUser',
  'calculatedBalance',
  'ftd',
  'ftdOfNrc',
  'gameWin',
  'ggr',
  'jpc',
  'margin',
  'NetDeposits',
  'ngr',
  'ngrInRewards',
  'nrc',
  'nrcByNsc',
  'nsc',
  'numAdjustments',
  'numBlockAccountRequests',
  'numCancelSelfExclusionRequests',
  'numCashbacks',
  'numCloseAccountRequests',
  'numDeposits',
  'numFailedDeposits',
  'numFailedWithdrawals',
  'numReopenAccountRequests',
  'numSelfExclusionRequests',
  'numTotalCloseAccountRequests',
  'numUniqueActivePlayers',
  'numUniqueDepositors',
  'numUniquePlayers',
  'numUniqueSessions',
  'numWithdrawals',
  'pendingWithdrawals',
  'turnover',
  'wagers',
];
