import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useState } from 'react';
import { useMutation, useQuery } from 'urql';

import {
  Card,
  CardCloseButton,
  CardOptions,
  ErrorMessage,
  Form,
  SubmitButton,
  TextField,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import {
  PlayerAffiliateInfoFormQuery,
  PlayerAffiliateInfoFormQueryVariables,
  UpdateAffiliateParametersMutation,
  UpdateAffiliateParametersMutationVariables,
} from './__generated__/PlayerUpdateAffiliateInfoForm';

const query = graphql`
  query SanityPlayerAffiliateInfoForm {
    sanityPlayerAffiliateInfoForm {
      title {
        ...SanityLocaleString
      }
      affiliateId {
        ...SanityLocaleString
      }
      affiliateToken {
        ...SanityLocaleString
      }
      submit {
        ...SanityLocaleString
      }
    }
  }
`;

const playerQuery = gql`
  query PlayerAffiliateInfoForm($playerId: ID!) {
    player(playerId: $playerId) {
      id
      affiliateInfo {
        affiliateId
        affiliateToken
      }
    }
  }
`;

const updateAffiliateMutation = gql`
  mutation UpdateAffiliateParameters(
    $playerId: ID!
    $affiliateParameters: AffiliateParameters!
  ) {
    updateAffiliateParameters(
      playerId: $playerId
      affiliateParameters: $affiliateParameters
    ) {
      id
      affiliateInfo {
        affiliateId
        affiliateToken
      }
    }
  }
`;

const PlayerUpdateAffiliateInfoForm: FC<{ playerId: string }> = ({
  playerId,
}) => {
  const staticData =
    useStaticQuery<Queries.SanityPlayerAffiliateInfoFormQuery>(query);

  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();

  const form = staticData.sanityPlayerAffiliateInfoForm;

  const [{ data }] = useQuery<
    PlayerAffiliateInfoFormQuery,
    PlayerAffiliateInfoFormQueryVariables
  >({
    query: playerQuery,
    variables: { playerId },
  });

  const [updateAffiliateParametersState, updateAffiliateParameters] =
    useMutation<
      UpdateAffiliateParametersMutation,
      UpdateAffiliateParametersMutationVariables
    >(updateAffiliateMutation);

  const defaultValues = {
    affiliateId: data?.player?.affiliateInfo?.affiliateId,
    affiliateToken: data?.player?.affiliateInfo?.affiliateToken,
  };

  const { close } = useModalContext();

  const { t } = useTranslate();

  const onSubmit = (values: typeof defaultValues) => {
    if (!data?.player || !values.affiliateId || !values.affiliateToken) {
      return;
    }

    setErrorMessage(null);

    updateAffiliateParameters({
      playerId: data?.player.id,
      affiliateParameters: {
        id: values.affiliateId,
        token: values.affiliateToken,
      },
    }).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      } else if (close) {
        close();
      }
    });
  };

  if (!form || !data) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">
        <Form
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          className="grid grid-cols-2 sm:grid-cols-3 gap-6"
        >
          <TextField
            title={t(form.affiliateId)}
            name="affiliateId"
            id="PlayerUpdateAffiliateInfoForm__affiliateId"
            className="col-span-full"
          />
          <TextField
            title={t(form.affiliateToken)}
            name="affiliateToken"
            id="PlayerUpdateAffiliateInfoForm__affiliateToken"
            className="col-span-full"
          />

          <ErrorMessage message={errorMessage} />
          <SubmitButton
            value={t(form.submit)}
            disabled={updateAffiliateParametersState.fetching}
          />
        </Form>
      </div>
    </Card>
  );
};

export default PlayerUpdateAffiliateInfoForm;
