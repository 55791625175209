import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { feedback } from 'react-feedbacker';
import { useMutation } from 'urql';

import {
  Card,
  CardCloseButton,
  CardOptions,
  ErrorMessage,
  Form,
  SubmitButton,
  TextareaField,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import { assert } from '@/utils/error';
import {
  RemoveRewardPlayersMutation,
  RemoveRewardPlayersMutationVariables,
} from './__generated__/RewadRemovePlayersForm';

const query = graphql`
  query SanityRewardRemovePlayersForm {
    sanityRewardRemovePlayersForm {
      title {
        ...SanityLocaleString
      }
      reason {
        ...SanityLocaleString
      }
      removePlayersFromReward {
        ...SanityLocaleString
      }
      removePlayers {
        ...SanityLocaleString
      }
      playersRemoved {
        ...SanityLocaleString
      }
    }
  }
`;

const removeRewardPlayersMutation = gql`
  mutation RemoveRewardPlayers($id: String!, $reason: NonEmptyString!) {
    removeReward(id: $id, reason: $reason)
  }
`;

type Props = {
  rewardId: string;
};

type FormValues = {
  reason: string;
};

const RewardRemovePlayersForm = ({ rewardId }: Props) => {
  const staticData =
    useStaticQuery<Queries.SanityRewardRemovePlayersFormQuery>(query);

  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const form = staticData.sanityRewardRemovePlayersForm;

  assert(form, 'missing form data');
  const { t } = useTranslate();
  const isMounted = useIsMounted();
  const { close } = useModalContext();
  const [removeRewardPlayersState, removeRewardPlayers] = useMutation<
    RemoveRewardPlayersMutation,
    RemoveRewardPlayersMutationVariables
  >(removeRewardPlayersMutation);

  const defaultValues: FormValues = {
    reason: '',
  };

  const onSubmit = (values: FormValues) => {
    const variables = {
      id: rewardId,
      reason: values.reason,
    };
    return removeRewardPlayers(variables).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      } else if (close) {
        feedback.success(t(form.playersRemoved));
        close();
      }
    });
  };

  if (!form) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <Form defaultValues={defaultValues} onSubmit={onSubmit} className="grid">
        <div className="p-3">
          <TextareaField
            name="reason"
            placeholder={t(form.reason)}
            title={t(form.removePlayersFromReward)}
            id="RewardRemovePlayersForm__reason"
            required
          />
          <ErrorMessage message={errorMessage} />
          <div className="flex justify-end mt-3">
            <SubmitButton
              value={t(form.removePlayers)}
              disabled={removeRewardPlayersState.fetching}
            />
          </div>
        </div>
      </Form>
    </Card>
  );
};

export default RewardRemovePlayersForm;
