import { graphql } from 'gatsby';
import React, { FC } from 'react';

import {
  useGetPlayerOverviewPageLink,
  useGetTransactionPageLink,
} from '@/bits/links/useLink';
import { Card, CardBody, CardOptions, CardOptionsButton } from '@/components';
import { useDataGrid } from '@/components/DataGrid';
import {
  DocumentDownloadIcon,
  NextIcon,
  PreviousIcon,
  RefreshIcon,
} from '@/components/icons';
import { useTranslate } from '@/contexts';
import {
  PaymentStatus,
  TransactionSearchOrder,
  TransactionType,
} from '@/globalTypes';
import { useBrandValueOptions } from '@/hooks/useBrands';
import useTransactions from './useTransactions';
import useTransactionsReport from './useTransactionsReport';

export const Fragment = graphql`
  fragment SanityTransactionsBlock on SanityTransactionsBlock {
    title {
      ...SanityLocaleString
    }
  }
`;

const TransactionsBlock: FC<{
  block: Queries.SanityTransactionsBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();
  const {
    transactions,
    fetching,
    nextPage,
    previousPage,
    refresh,
    orderBy,
    desc,
    setOrderBy,
    defaultFilter,
    brands,
    updateFilter,
  } = useTransactions();
  const report = useTransactionsReport();
  const getPlayerOverviewPageLink = useGetPlayerOverviewPageLink();
  const getTransactionPageLink = useGetTransactionPageLink();

  const brandOptions = useBrandValueOptions(brands);

  const { DataGrid, GlobalCardOptions } = useDataGrid({
    name: 'transactions',
    data: transactions,
    fetching,
    orderBy,
    setOrderBy,
    desc,
    defaultFilter,
    updateFilter,
    getRowLink: ({ row }) => getTransactionPageLink(row.rawTransactionId),
    schema: (s) => [
      s.stringValue({
        field: 'playerId',
        title: 'Player',
        linkTo: ({ row }) =>
          (row.playerId && getPlayerOverviewPageLink(row.playerId)) ?? '',
      }),
      s.initiatorValue({
        field: 'initiator',
        title: 'Initiator',
      }),
      s.selectValue({
        field: 'brand',
        title: 'Brand',
        filterField: 'brand',
        options: brandOptions.options,
        orderBy: TransactionSearchOrder.brand,
        getValue: ({ row }) => row.brand?.name,
      }),
      s.stringValue({
        field: 'rawTransactionId',
        title: 'Transaction ID',
        filterField: 'rawId',
      }),
      s.dateTimeValue({
        field: 'processedAt',
        title: 'Time',
        fromFilterField: 'from',
        toFilterField: 'to',
        orderBy: TransactionSearchOrder.processedAt,
        includeTime: true,
        includeTimeInFilter: true,
      }),
      s.enumValue({
        field: 'transactionType',
        title: 'Type',
        e: TransactionType,
        filterField: 'transactionTypes',
        isMulti: true,
      }),
      s.enumValue({
        field: 'paymentStatus',
        title: 'Payment Status',
        e: PaymentStatus,
        filterField: 'paymentStatus',
        isMulti: true,
      }),
      s.monetaryValue({
        field: 'amount',
        title: 'Amount',
        fromFilterField: 'minAmount',
        toFilterField: 'maxAmount',
        orderBy: TransactionSearchOrder.amount,
      }),
      s.monetaryValue({
        field: 'openingBalance',
        title: 'Opening Balance',
      }),
      s.monetaryValue({
        field: 'closingBalance',
        title: 'Closing Balance',
      }),
      s.monetaryValue({
        field: 'bonusAmount',
        title: 'Bonus Win Amount',
        getValue: ({ row }) => row.bonusTransactionData?.amount || '-',
      }),
      s.monetaryValue({
        field: 'bonusOpeningBalance',
        title: 'Bonus Opening Amount',
        getValue: ({ row }) => row.bonusTransactionData?.openingBalance || '-',
      }),
      s.monetaryValue({
        field: 'bonusClosingBalance',
        title: 'Bonus Closing Amount',
        getValue: ({ row }) => row.bonusTransactionData?.closingBalance || '-',
      }),
      s.stringValue({
        field: 'bonusId',
        title: 'Bonus ID',
        getValue: ({ row }) => row.bonusTransactionData?.bonusId || '-',
      }),
      s.monetaryValue({
        field: 'paymentFee',
        title: 'Payment Fee',
        getValue: ({ row }) => {
          return row?.paymentFee?.money.amount || 0;
        },
      }),
    ],
  });

  return (
    <Card
      size="lg"
      title={t(block.title)}
      showOptionsAtBottom
      options={
        <CardOptions>
          <CardOptionsButton
            disabled={!previousPage}
            onClick={() => previousPage && previousPage()}
          >
            <PreviousIcon />
          </CardOptionsButton>
          <CardOptionsButton
            disabled={!nextPage}
            onClick={() => nextPage && nextPage()}
          >
            <NextIcon />
          </CardOptionsButton>
          <GlobalCardOptions />
          <CardOptionsButton
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
          <CardOptionsButton onClick={() => report.generate()}>
            <DocumentDownloadIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <DataGrid />
      </CardBody>
    </Card>
  );
};

export default TransactionsBlock;
