import { useParams } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { useQuery } from 'urql';

import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  ControlledModal,
  InlineIconButton,
  Value,
} from '@/components';
import { PlusIcon, RefreshIcon, TrashIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import {
  PlayerCancelSelfExclusionForm,
  PlayerSelfExclusionForm,
} from '@/forms';
import PlayerCancelPendingSelfExclusionForm from '@/forms/player-cancel-pending-self-exclusion-form/PlayerCancelPendingSelfExclusionForm';
import formatDate from '@/utils/formatter/formatDate';
import {
  PlayerSelfExclusionBoxQuery,
  PlayerSelfExclusionBoxQueryVariables,
} from './__generated__/component';

const BLOCK_STATIC_QUERY = graphql`
  query SanityPlayerSelfExclusionBlockStaticQuery {
    sanityPlayerSelfExclusionBlock {
      title {
        ...SanityLocaleString
      }
      currentConfiguredAt {
        ...SanityLocaleString
      }
      currentEndsAt {
        ...SanityLocaleString
      }
      currentCancelledAt {
        ...SanityLocaleString
      }
      pendingConfiguredAt {
        ...SanityLocaleString
      }
      pendingEndsAt {
        ...SanityLocaleString
      }
      pendingActiveFrom {
        ...SanityLocaleString
      }
    }
  }
`;

const QUERY = gql`
  query PlayerSelfExclusionBox($playerId: ID!) {
    player(playerId: $playerId) {
      id
      selfExclusionDetails {
        id
        configuredAt
        exclusionEndsAt
        willBeCancelledAt
        pending {
          exclusionEndsAt
          configuredAt
          activeFrom
        }
      }
    }
  }
`;

const isEstonia = process.env.GATSBY_LICENSE === 'EST';

const PlayerSelfExclusionBlock: FC = () => {
  const { t } = useTranslate();
  const params = useParams();

  const block =
    useStaticQuery<Queries.SanityPlayerSelfExclusionBlockStaticQueryQuery>(
      BLOCK_STATIC_QUERY,
    ).sanityPlayerSelfExclusionBlock;

  const [{ data, fetching }, refresh] = useQuery<
    PlayerSelfExclusionBoxQuery,
    PlayerSelfExclusionBoxQueryVariables
  >({
    query: QUERY,
    variables: {
      playerId: params.playerId,
    },
  });

  if (!block) {
    return null;
  }

  const details = data?.player.selfExclusionDetails;
  const pending = details?.pending;

  const removeSelfExclusionButton = data?.player.selfExclusionDetails
    ?.configuredAt &&
    !data.player.selfExclusionDetails.pending && (
      <ControlledModal
        content={
          data.player.id ? (
            <PlayerCancelSelfExclusionForm playerId={data.player.id} />
          ) : null
        }
      >
        <InlineIconButton>
          <TrashIcon />
        </InlineIconButton>
      </ControlledModal>
    );

  const removePendingSelfExclusionButton = data?.player.selfExclusionDetails
    ?.pending && (
    <ControlledModal
      content={
        data.player.id ? (
          <PlayerCancelPendingSelfExclusionForm playerId={data.player.id} />
        ) : null
      }
    >
      <InlineIconButton>
        <TrashIcon />
      </InlineIconButton>
    </ControlledModal>
  );

  return (
    <Card
      size="lg"
      title={t(block.title)}
      options={
        <CardOptions>
          <CardOptionsButton
            className="flex"
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
          <ControlledModal
            content={<PlayerSelfExclusionForm playerId={params.playerId} />}
          >
            <CardOptionsButton disabled={isEstonia}>
              <PlusIcon />
            </CardOptionsButton>
          </ControlledModal>
        </CardOptions>
      }
    >
      <CardBody>
        <div className="p-3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
          <Value
            title={t(block.currentConfiguredAt)}
            fetching={fetching}
            value={formatDate(details?.configuredAt)}
            suffix={removeSelfExclusionButton}
          />
          <Value
            title={t(block.currentEndsAt)}
            fetching={fetching}
            value={formatDate(details?.exclusionEndsAt)}
            suffix={removeSelfExclusionButton}
          />
          <Value
            title={t(block.currentCancelledAt)}
            fetching={fetching}
            value={formatDate(details?.willBeCancelledAt)}
            suffix={removeSelfExclusionButton}
          />
          <Value
            title={t(block.pendingConfiguredAt)}
            fetching={fetching}
            value={formatDate(pending?.configuredAt)}
            suffix={removePendingSelfExclusionButton}
          />
          <Value
            title={t(block.pendingEndsAt)}
            fetching={fetching}
            value={formatDate(pending?.exclusionEndsAt)}
            suffix={removePendingSelfExclusionButton}
          />
          <Value
            title={t(block.pendingActiveFrom)}
            fetching={fetching}
            value={formatDate(pending?.activeFrom)}
            suffix={removePendingSelfExclusionButton}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default PlayerSelfExclusionBlock;
