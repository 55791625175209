import React from 'react';

import { Breadcrumb, Breadcrumbs, InlineSpinner } from '@/components';

const example = () => {
  return (
    <Breadcrumbs>
      <Breadcrumb>First</Breadcrumb>
      <Breadcrumb>Second</Breadcrumb>
      <Breadcrumb>
        <a href="#some-link">Link</a>
      </Breadcrumb>
      <Breadcrumb>
        <InlineSpinner />
      </Breadcrumb>
      <Breadcrumb last>Last</Breadcrumb>
    </Breadcrumbs>
  );
};

export default example;
