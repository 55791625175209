import Tippy from '@tippyjs/react';
import gql from 'graphql-tag';
import { startCase } from 'lodash';
import React from 'react';

import { ControlledModal, InlineIconButton } from '@/components';
import { EditIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import { PlayerAccountStatusForm } from '@/forms';
import { AccountStatusEnum, CloseAccountCauseV4 } from '@/globalTypes';
import { useCan } from '@/utils/access';

export const accountStatusFragment = gql`
  fragment AccountStatusFragment on Player {
    id
    shouldReopenAt
    accountStatus {
      status
      cause
    }
  }
`;

const isNotSevereCause = (cause: CloseAccountCauseV4) => {
  switch (cause) {
    case CloseAccountCauseV4.TemporaryCustomerRequest:
    case CloseAccountCauseV4.BlockCustomerRequest:
    case CloseAccountCauseV4.MultipleAccounts:
      return true;
    default:
      return false;
  }
};

export const AccountStatus = ({
  block,
  playerId,
  status,
  cause,
}: {
  block:
    | Queries.SanityPlayerInfoBlockFragment
    | Queries.SanityGlobalPlayerInfoBlockFragment;
  playerId: string;
  status: AccountStatusEnum;
  cause: CloseAccountCauseV4;
}) => {
  const { t } = useTranslate();
  const accountIsOpen = status === AccountStatusEnum.Open;
  const agentCanReopen = useCan('REOPEN_PLAYER_ACCOUNT');
  const agentIsAllowed = isNotSevereCause(cause) ? true : agentCanReopen;
  const closedCause = startCase(cause);
  const showEdit = accountIsOpen || agentIsAllowed;

  return (
    <>
      {accountIsOpen ? (
        t(block.accountStatusOpen)
      ) : (
        <Tippy content={closedCause}>
          <span>{t(block.accountStatusClosed)}</span>
        </Tippy>
      )}
      {showEdit && (
        <ControlledModal
          content={
            <PlayerAccountStatusForm
              playerId={playerId}
              canOpenWithoutCooldown={agentCanReopen}
              isGlobal
            />
          }
        >
          <InlineIconButton>
            <Tippy content={t(block.changeAccountStatus)}>
              <div>
                <EditIcon />
              </div>
            </Tippy>
          </InlineIconButton>
        </ControlledModal>
      )}
    </>
  );
};
