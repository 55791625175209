import React, { ReactNode } from 'react';

type Row = {
  data: ReactNode[];
  key?: string;
};

export function Table({
  headings,
  rows,
  columnWidths,
}: {
  headings: ReactNode[];
  rows: Row[];
  columnWidths?: string[];
}) {
  return (
    <div className="overflow-x-auto">
      <table className="w-full text-left border-collapse">
        <thead className="bg-gray-50 dark:bg-gray-900 border-gray-200 dark:border-gray-700 border-b p-2 divide-y divide-gray-200 dark:divide-gray-800">
          <tr className="text-sm font-semibold divide-x dark:divide-gray-800">
            {headings.map((heading, index) => (
              <th
                key={index}
                style={{ width: columnWidths?.[index] }}
                className="p-3 whitespace-nowrap text-black dark:text-gray-200"
              >
                {heading}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-100 dark:divide-gray-800">
          {rows.map((row, i1) => (
            <tr key={row.key ?? i1}>
              {row.data.map((cell, i2) => (
                <td
                  key={i2}
                  style={{ width: columnWidths?.[i2] }}
                  className="text-sm text-gray-800 dark:text-gray-200 truncate p-3"
                >
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
