import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import { feedback } from 'react-feedbacker';
import { useForm } from 'react-hook-form';
import { gql, useMutation, useQuery } from 'urql';

import { useFeedbackMessages } from '@/bits';
import {
  Card,
  CardCloseButton,
  CardOptions,
  ErrorMessage,
  NakedForm,
  SelectField,
  SubmitButton,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { SourceOfFundType } from '@/globalTypes';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import {
  ChangeProvidedSourceOfFundsMutation,
  ChangeProvidedSourceOfFundsMutationVariables,
  PlayerSourceOfFundsFormQuery,
  PlayerSourceOfFundsFormQueryVariables,
} from './__generated__/PlayerSourceOfFundsForm';
import { useSourceOfFundsOptions } from './useSourceOfFundsOptions';

const STATIC_QUERY = graphql`
  query SanityPlayerSourceOfFundsForm {
    sanityPlayerSourceOfFundsForm {
      title {
        ...SanityLocaleString
      }
      sourceOfFundsLabel {
        ...SanityLocaleString
      }
      selectSourceOfFunds {
        ...SanityLocaleString
      }
      submit {
        ...SanityLocaleString
      }
    }
  }
`;

const query = gql`
  query PlayerSourceOfFundsForm($playerId: ID!) {
    player(playerId: $playerId) {
      id
      rawPlayerId
      providedSourceOfFunds {
        sourceOfFunds {
          sourceOfFundType
          wasProvided
        }
      }
    }
  }
`;

const SourceOfFundsMutation = gql`
  mutation ChangeProvidedSourceOfFunds(
    $playerId: ID!
    $providedSourceOfFunds: BoSourceOfFunds!
  ) {
    changeProvidedSourceOfFunds(
      playerId: $playerId
      providedSourceOfFunds: $providedSourceOfFunds
    ) {
      sourceOfFunds {
        sourceOfFundType
        wasProvided
      }
    }
  }
`;

type FormValues = {
  providedSourceOfFunds: SourceOfFundType[] | null;
};

const PlayerSourceOfFundsForm = ({ playerId }: { playerId: string }) => {
  const { t } = useTranslate();
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();
  const { close } = useModalContext();
  const feedbackMessages = useFeedbackMessages();

  const [{ data }] = useQuery<
    PlayerSourceOfFundsFormQuery,
    PlayerSourceOfFundsFormQueryVariables
  >({
    query,
    variables: { playerId },
  });

  const form =
    useStaticQuery<Queries.SanityPlayerSourceOfFundsFormQuery>(
      STATIC_QUERY,
    ).sanityPlayerSourceOfFundsForm;

  const [sourceOfFundsState, changeSourceOfFunds] = useMutation<
    ChangeProvidedSourceOfFundsMutation,
    ChangeProvidedSourceOfFundsMutationVariables
  >(SourceOfFundsMutation);

  const sourceOfFundsOptions = useSourceOfFundsOptions();

  const methods = useForm<FormValues>({
    defaultValues: {
      providedSourceOfFunds: data?.player.providedSourceOfFunds?.sourceOfFunds
        .filter((sof) => sof.wasProvided)
        .map((sof) => sof.sourceOfFundType),
    },
  });

  const onSubmit = (values: FormValues) => {
    setErrorMessage(null);
    const providedSourceOfFunds = sourceOfFundsOptions.map((sof) => {
      return {
        sourceOfFundType: sof.value,
        wasProvided: !!values.providedSourceOfFunds?.includes(sof.value),
      };
    });

    changeSourceOfFunds({
      playerId,
      providedSourceOfFunds: { sourceOfFunds: providedSourceOfFunds },
    }).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      } else if (close) {
        close();
      }

      if (!res.error) {
        feedback.success(t(feedbackMessages.success));
      }
    });
  };

  if (!form || !data) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">
        <NakedForm methods={methods} onSubmit={onSubmit} className="grid gap-6">
          <div className="space-y-3">
            <SelectField
              options={sourceOfFundsOptions}
              name="providedSourceOfFunds"
              id="PlayerSourceOfFundsForm__providedSourceOfFunds"
              title={t(form.sourceOfFundsLabel)}
              isMulti
            />
          </div>

          <ErrorMessage message={errorMessage} />
          <SubmitButton
            value={t(form.submit)}
            disabled={sourceOfFundsState.fetching}
          />
        </NakedForm>
      </div>
    </Card>
  );
};

export default PlayerSourceOfFundsForm;
