import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import Notes from '@/components/Notes/Notes';

const staticQuery = graphql`
  query StaticPlayerNotesBlock {
    sanityPlayerNotesBlock {
      title {
        ...SanityLocaleString
      }
      content {
        ...SanityLocaleString
      }
      priority {
        ...SanityLocaleString
      }
      date {
        ...SanityLocaleString
      }
      orderBy {
        ...SanityLocaleString
      }
      brands {
        ...SanityLocaleString
      }
      createGlobalNote {
        ...SanityLocaleString
      }
      submit {
        ...SanityLocaleString
      }
      noNotesPlaceholder {
        ...SanityLocaleString
      }
    }
  }
`;

const PlayerNotesBlock = () => {
  const { sanityPlayerNotesBlock: block } =
    useStaticQuery<Queries.StaticPlayerNotesBlockQuery>(staticQuery);

  if (!block) {
    return null;
  }

  return <Notes block={block} />;
};

export default PlayerNotesBlock;
