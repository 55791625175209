import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { useQuery } from 'urql';

import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  ControlledModal,
  GlobalPlayerBrandValues,
  InlineIconButton,
  Value,
} from '@/components';
import { EditIcon, RefreshIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import { KycMasterPlayerForm } from '@/forms';
import { Nullable } from '@/types';
import { RenderBrandValueType, getGenericBrandsValues } from '@/utils';
import formatCountryCode from '@/utils/formatter/formatCountryCode';
import {
  GlobalPlayerKycOverviewBoxQuery,
  GlobalPlayerKycOverviewBoxQueryVariables,
} from './__generated__/component';

export const Fragment = graphql`
  fragment SanityGlobalPlayerKycOverviewBlock on SanityGlobalPlayerKycOverviewBlock {
    title {
      ...SanityLocaleString
    }
    firstNameLabel {
      ...SanityLocaleString
    }
    lastNameLabel {
      ...SanityLocaleString
    }
    titleLabel {
      ...SanityLocaleString
    }
    ssnLabel {
      ...SanityLocaleString
    }
    birthDateLabel {
      ...SanityLocaleString
    }
    countryLabel {
      ...SanityLocaleString
    }
    zipCodeLabel {
      ...SanityLocaleString
    }
    streetLabel {
      ...SanityLocaleString
    }
    cityLabel {
      ...SanityLocaleString
    }
    genderLabel {
      ...SanityLocaleString
    }
    upToDateVerificationsLabel {
      ...SanityLocaleString
    }
    kycStatusLabel {
      ...SanityLocaleString
    }
    kycStatusWillBeRequired {
      ...SanityLocaleString
    }
    kycStatusRequired {
      ...SanityLocaleString
    }
    kycStatusPending {
      ...SanityLocaleString
    }
    kycStatusFailed {
      ...SanityLocaleString
    }
    kycStatusNotNeeded {
      ...SanityLocaleString
    }
    kycStatusRejected {
      ...SanityLocaleString
    }
    kycStatusDone {
      ...SanityLocaleString
    }
    outdatedVerificationsLabel {
      ...SanityLocaleString
    }
    sharedSowAccountsLabel {
      ...SanityLocaleString
    }
    expiredVerificationsLabel {
      ...SanityLocaleString
    }
    masterPlayerIdLabel {
      ...SanityLocaleString
    }
  }
`;

const QUERY = gql`
  query GlobalPlayerKycOverviewBox($playerGlobalId: ID!) {
    playerGlobal(playerGlobalId: $playerGlobalId) {
      __typename
      id
      playerGlobalUUID
      kycMasterPlayer {
        __typename
        id
      }
      players {
        __typename
        id
        rawPlayerId
        accountStatus {
          __typename
          status
        }
        brand {
          __typename
          id
          name
        }
        firstName
        lastName
        title
        ssn
        birthDate
        countryCode
        zipCode
        street
        city
        gender
        verificationTypes {
          __typename
          upToDateVerifications {
            __typename
            verificationType
          }
          expiredVerifications
        }
        kycCheckStatus {
          __typename
          status
          kycFlow {
            status
          }
          sumSubEndUserId
        }
        playersLinkedBySourceOfWealth {
          id
          player {
            __typename
            id
            uuid
            brand {
              name
            }
          }
        }
        riskAssessment {
          __typename
          playerGlobalId
        }
        translatedPersonalInfo {
          __typename
          firstName
          lastName
          city
          street
          district
          state
        }
        loginInfo {
          __typename
          lastLogin {
            __typename
            loggedAt
          }
          registration {
            __typename
            loggedAt
          }
        }
      }
    }
  }
`;

const getUpToDateVerificationsString = (
  player: Nullable<
    GlobalPlayerKycOverviewBoxQuery['playerGlobal']['players'][0]
  >,
) => {
  return player?.verificationTypes?.upToDateVerifications
    .map((a) => a.verificationType)
    .join(', ');
};

function getDateVerificationsBrandsValues(
  data: Nullable<GlobalPlayerKycOverviewBoxQuery>,
): Nullable<RenderBrandValueType[]> {
  return data?.playerGlobal.players.map((p) => ({
    brand: p.brand.name,
    brandValue: getUpToDateVerificationsString(p),
    status: p.accountStatus.status,
    playerId: p.id,
    rawPlayerId: p.rawPlayerId,
    registeredLoggedAt: p.loginInfo?.registration.loggedAt,
    lastLoginLoggedAt: p.loginInfo?.lastLogin.loggedAt,
  }));
}

const GlobalPlayerKycOverviewBlock: FC<{
  block: Queries.SanityGlobalPlayerKycOverviewBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();
  const { playerGlobalId } = useParams();

  const [{ data, fetching }, refresh] = useQuery<
    GlobalPlayerKycOverviewBoxQuery,
    GlobalPlayerKycOverviewBoxQueryVariables
  >({
    query: QUERY,
    variables: {
      playerGlobalId,
    },
  });

  const firstPlayer = data?.playerGlobal.players[0];

  return (
    <Card
      size="lg"
      title={t(block.title)}
      options={
        <CardOptions>
          <CardOptionsButton
            className="flex"
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        {data && (
          <div className="p-3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
            <Value
              fetching={fetching}
              title={t(block.firstNameLabel)}
              value={firstPlayer?.firstName}
              suffix={
                <GlobalPlayerBrandValues
                  title={t(block.firstNameLabel)}
                  values={getGenericBrandsValues(
                    data,
                    (entry) => entry.firstName,
                  )}
                />
              }
            />
            <Value
              fetching={fetching}
              title={t(block.lastNameLabel)}
              value={firstPlayer?.lastName}
              suffix={
                <GlobalPlayerBrandValues
                  title={t(block.lastNameLabel)}
                  values={getGenericBrandsValues(
                    data,
                    (entry) => entry.lastName,
                  )}
                />
              }
            />
            <Value
              fetching={fetching}
              title={t(block.birthDateLabel)}
              value={firstPlayer?.birthDate}
              suffix={
                <GlobalPlayerBrandValues
                  title={t(block.birthDateLabel)}
                  values={getGenericBrandsValues(
                    data,
                    (entry) => entry.birthDate,
                  )}
                />
              }
            />
            <Value
              fetching={fetching}
              title={t(block.genderLabel)}
              value={firstPlayer?.gender}
              suffix={
                <GlobalPlayerBrandValues
                  title={t(block.genderLabel)}
                  values={getGenericBrandsValues(data, (entry) => entry.gender)}
                />
              }
            />
            <Value
              fetching={fetching}
              title={t(block.ssnLabel)}
              value={firstPlayer?.ssn}
              suffix={
                <GlobalPlayerBrandValues
                  title={t(block.ssnLabel)}
                  values={getGenericBrandsValues(data, (entry) => entry.ssn)}
                />
              }
            />
            <Value
              fetching={fetching}
              title={t(block.countryLabel)}
              value={formatCountryCode(firstPlayer?.countryCode)}
              suffix={
                <GlobalPlayerBrandValues
                  title={t(block.countryLabel)}
                  values={getGenericBrandsValues(
                    data,
                    (entry) => entry.countryCode,
                  )}
                />
              }
            />
            <Value
              fetching={fetching}
              title={t(block.streetLabel)}
              value={firstPlayer?.street}
              suffix={
                <GlobalPlayerBrandValues
                  title={t(block.streetLabel)}
                  values={getGenericBrandsValues(data, (entry) => entry.street)}
                />
              }
            />
            <Value
              fetching={fetching}
              title={t(block.zipCodeLabel)}
              value={firstPlayer?.zipCode}
              suffix={
                <GlobalPlayerBrandValues
                  title={t(block.zipCodeLabel)}
                  values={getGenericBrandsValues(
                    data,
                    (entry) => entry.zipCode,
                  )}
                />
              }
            />
            <Value
              fetching={fetching}
              title={t(block.cityLabel)}
              value={firstPlayer?.city}
              suffix={
                <GlobalPlayerBrandValues
                  title={t(block.cityLabel)}
                  values={getGenericBrandsValues(data, (entry) => entry.city)}
                />
              }
            />
            <Value
              fetching={fetching}
              title={t(block.upToDateVerificationsLabel)}
              value={getUpToDateVerificationsString(firstPlayer)}
              suffix={
                <GlobalPlayerBrandValues
                  title={t(block.upToDateVerificationsLabel)}
                  values={getDateVerificationsBrandsValues(data)}
                />
              }
            />
            <Value
              fetching={fetching}
              title={t(block.kycStatusLabel)}
              value={firstPlayer?.kycCheckStatus?.status}
              suffix={
                <GlobalPlayerBrandValues
                  title={t(block.kycStatusLabel)}
                  values={getGenericBrandsValues(
                    data,
                    (entry) => entry.kycCheckStatus?.status,
                  )}
                />
              }
            />
            <Value
              fetching={fetching}
              title={t(block.outdatedVerificationsLabel)}
              value={firstPlayer?.verificationTypes?.expiredVerifications.join(
                ', ',
              )}
            />
            <Value
              fetching={fetching}
              title={t(block.masterPlayerIdLabel)}
              value={data.playerGlobal.kycMasterPlayer?.id}
              suffix={
                <ControlledModal
                  content={
                    <KycMasterPlayerForm playerGlobalId={playerGlobalId} />
                  }
                >
                  <InlineIconButton>
                    <EditIcon />
                  </InlineIconButton>
                </ControlledModal>
              }
            />
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default GlobalPlayerKycOverviewBlock;
