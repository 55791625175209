import gql from 'graphql-tag';
import { useQuery } from 'urql';
import { useQueryParams } from 'use-query-params';

import { mapVariables } from '@/utils';
import { makeOrderBy, makePagination } from '@/utils/pagination';
import {
  PlayerBonusesQuery,
  PlayerBonusesQueryVariables,
} from './__generated__/usePlayerBonuses';
import { queryParams } from './queryParams';

const PLAYER_BONUSES_QUERY = gql`
  query PlayerBonuses(
    $playerIds: [String!]
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    viewer {
      id
      bonuses(
        playerIds: $playerIds
        first: $first
        last: $last
        after: $after
        before: $before
      ) {
        edges {
          node {
            bonusId
            rewardId
            campaignId
            amount
            closingBalance
            currency
            wageringProgress
            wageringRequirement
            wageringRequirementFactor
            maxPayout
            expiresAt
            createdAt
            processedAt
            status
            forfeitCause
            forfeitedAt
            amountForfeited
            convertedAt
            amountConverted
            amountDiscarded
            createdBy {
              ... on AgentInitiator {
                agent {
                  firstName
                  lastName
                }
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;

export default function usePlayerBonuses(playerId: string) {
  const [query, setQuery] = useQueryParams(queryParams);
  const [{ data, fetching }, refresh] = useQuery<
    PlayerBonusesQuery,
    PlayerBonusesQueryVariables
  >({
    query: PLAYER_BONUSES_QUERY,
    variables: {
      first: 10,
      ...mapVariables(query),
      playerIds: [playerId],
    },
    pause: !playerId,
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter: (values: {}) => setQuery(values, 'replaceIn'),
    ...makeOrderBy(query, setQuery),
    ...makePagination(query, setQuery, data?.viewer.bonuses.pageInfo),
    playerBonuses: data?.viewer.bonuses.edges?.map((edge) => edge?.node),
  };
}
