import { addDays, formatDuration, formatISO } from 'date-fns';
import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useMemo, useState } from 'react';
import { feedback } from 'react-feedbacker';
import { useForm } from 'react-hook-form';
import { useMutation } from 'urql';

import { useCurrencies } from '@/bits';
import {
  Card,
  CardCloseButton,
  CardOptions,
  DateTimeField,
  ErrorMessage,
  NakedForm,
  NumberField,
  SelectField,
  SelectOption,
  TextField,
  TextareaField,
  useModalContext,
} from '@/components';
import { SubmitButton } from '@/components/form/SubmitButton';
import { useTranslate } from '@/contexts';
import {
  ClaimType,
  GameProvider,
  RewardTypeEnum,
  Scalars,
} from '@/globalTypes';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import { convertValuesToNumbers, rewardGameProviders } from '@/utils';
import { assert } from '@/utils/error';
import { GameSearch } from '../../components/GameSearch';
import { PlayersSearch } from '../../components/PlayersSearch';
import {
  CreateBonusMoneyDropMutation,
  CreateBonusMoneyDropMutationVariables,
  CreateFreeSpinsMutation,
  CreateFreeSpinsMutationVariables,
  CreateMoneyDropMutation,
  CreateMoneyDropMutationVariables,
} from './__generated__/RewardCreateForm';

const query = graphql`
  query SanityRewardCreateForm {
    sanityRewardCreateForm {
      title {
        ...SanityLocaleString
      }
      rewardCreated {
        ...SanityLocaleString
      }
      rewardType {
        ...SanityLocaleString
      }
      rewardType {
        ...SanityLocaleString
      }
      value {
        ...SanityLocaleString
      }
      currency {
        ...SanityLocaleString
      }
      numberOfSpins {
        ...SanityLocaleString
      }
      paylines {
        ...SanityLocaleString
      }
      game {
        ...SanityLocaleString
      }
      gameProviders {
        ...SanityLocaleString
      }
      nameOfReward {
        ...SanityLocaleString
      }
      startDate {
        ...SanityLocaleString
      }
      endDate {
        ...SanityLocaleString
      }
      claimableInstant {
        ...SanityLocaleString
      }
      players {
        ...SanityLocaleString
      }
      notes {
        ...SanityLocaleString
      }
      campaignId {
        ...SanityLocaleString
      }
      expiresIn {
        ...SanityLocaleString
      }
      maxPayout {
        ...SanityLocaleString
      }
      wageringRequirementFactor {
        ...SanityLocaleString
      }
    }
  }
`;

const createFreeSpinsMutation = gql`
  mutation CreateFreeSpins(
    $rewardName: String!
    $claimType: ClaimType!
    $startsAt: OffsetDateTime!
    $endsAt: OffsetDateTime
    $expiresIn: Duration
    $comment: String!
    $playerIds: [String!]!
    $spinsNumber: Int!
    $spinsValue: Long!
    $betLevel: Int!
    $currencyCode: ISOCurrencyCode!
    $gameProviderId: GameProvider!
    $gameMobileId: String
    $gameDesktopId: String
    $campaignId: CampaignId!
  ) {
    createFreeSpins(
      campaignId: $campaignId
      rewardName: $rewardName
      claimType: $claimType
      startsAt: $startsAt
      endsAt: $endsAt
      expiresIn: $expiresIn
      comment: $comment
      playerIds: $playerIds
      spinsNumber: $spinsNumber
      spinsValue: $spinsValue
      betLevel: $betLevel
      currencyCode: $currencyCode
      gameProviderId: $gameProviderId
      gameMobileId: $gameMobileId
      gameDesktopId: $gameDesktopId
    )
  }
`;

const createMoneyDropMutation = gql`
  mutation CreateMoneyDrop(
    $rewardName: String!
    $claimType: ClaimType!
    $startsAt: OffsetDateTime!
    $endsAt: OffsetDateTime
    $expiresIn: Duration
    $comment: String!
    $playerIds: [String!]!
    $amount: PositiveBigDecimal!
    $currencyCode: ISOCurrencyCode!
    $campaignId: CampaignId!
  ) {
    createMoneyDrop(
      campaignId: $campaignId
      rewardName: $rewardName
      claimType: $claimType
      startsAt: $startsAt
      endsAt: $endsAt
      expiresIn: $expiresIn
      comment: $comment
      playerIds: $playerIds
      amount: $amount
      currencyCode: $currencyCode
    )
  }
`;

const createBonusMoneyDropMutation = gql`
  mutation CreateBonusMoneyDrop(
    $rewardName: String!
    $claimType: ClaimType!
    $startsAt: OffsetDateTime!
    $endsAt: OffsetDateTime
    $expiresIn: Duration
    $comment: String!
    $playerIds: [String!]!
    $amount: PositiveBigDecimal!
    $currencyCode: ISOCurrencyCode!
    $wageringRequirementFactor: Int!
    $maxPayout: PositiveBigDecimal!
    $campaignId: CampaignId!
  ) {
    createBonusMoneyDrop(
      campaignId: $campaignId
      rewardName: $rewardName
      claimType: $claimType
      startsAt: $startsAt
      endsAt: $endsAt
      expiresIn: $expiresIn
      comment: $comment
      playerIds: $playerIds
      amount: $amount
      currencyCode: $currencyCode
      wageringRequirementFactor: $wageringRequirementFactor
      maxPayout: $maxPayout
    )
  }
`;

type Rewards = 'FreeSpinsType' | 'MoneyDropType' | 'BonusMoneyDropType';
type RewardOption = SelectOption & { value: Rewards };
type FormValues = {
  rewardName: string;
  rewardType: RewardTypeEnum;
  claimType: ClaimType;
  startsAt: Date;
  endsAt?: Date | null;
  expiresIn?: Duration | null;
  comment: string;
  playerIds: string[];
  spinsNumber: number | null;
  value: number | null;
  betLevel: number;
  gameProvider: GameProvider;
  currencyCode: Scalars['ISOCurrencyCode']['input'];
  campaignId: string;
  gameDetails: {
    mobileId?: string | null;
    desktopId?: string | null;
  };
  maxPayout: number | null;
  wageringRequirementFactor: number | null;
};

const useOptions = () => {
  return useMemo(() => {
    const rewardTypesOptions: RewardOption[] = [
      {
        label: 'FreeSpins',
        value: RewardTypeEnum.FreeSpinsType,
      },
      {
        label: 'MoneyDrop',
        value: RewardTypeEnum.MoneyDropType,
      },
      {
        label: 'BonusMoneyDrop',
        value: RewardTypeEnum.BonusMoneyDropType,
      },
    ];

    const claimOptions: SelectOption[] = [
      {
        label: 'Claimable',
        value: 'Manual',
      },
      {
        label: 'Instant',
        value: 'Instant',
      },
    ];

    return {
      rewardTypesOptions,
      claimOptions,
    };
  }, []);
};

const RewardCreateForm: FC = () => {
  const { t } = useTranslate();
  const staticData = useStaticQuery<Queries.SanityRewardCreateFormQuery>(query);
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();
  const { close } = useModalContext();

  const form = staticData.sanityRewardCreateForm;
  assert(form, 'missing form data');

  const { rewardTypesOptions, claimOptions } = useOptions();
  const { currencyOptions } = useCurrencies();

  const [createFreeSpinsState, createFreeSpins] = useMutation<
    CreateFreeSpinsMutation,
    CreateFreeSpinsMutationVariables
  >(createFreeSpinsMutation);

  const [createMoneyDropState, createMoneyDrop] = useMutation<
    CreateMoneyDropMutation,
    CreateMoneyDropMutationVariables
  >(createMoneyDropMutation);

  const [createBonusMoneyDropState, createBonusMoneyDrop] = useMutation<
    CreateBonusMoneyDropMutation,
    CreateBonusMoneyDropMutationVariables
  >(createBonusMoneyDropMutation);

  const defaultValues: FormValues = {
    rewardType: RewardTypeEnum.FreeSpinsType,
    claimType: ClaimType.Manual,
    rewardName: '',
    startsAt: new Date(),
    endsAt: addDays(new Date(), 14),
    expiresIn: { days: 3 },
    comment: '',
    playerIds: [],
    spinsNumber: null,
    value: null,
    betLevel: 1,
    campaignId: 'CompensationCS',
    gameProvider: GameProvider.Relax,
    gameDetails: {
      mobileId: '',
      desktopId: '',
    },
    currencyCode: 'EUR' as Scalars['ISOCurrencyCode']['input'],
    maxPayout: 1000,
    wageringRequirementFactor: 30,
  };

  const methods = useForm<FormValues>({
    defaultValues,
  });

  const rewardType = methods.watch('rewardType');
  const selectedGameProvider = methods.watch('gameProvider');

  const onSubmit = (values: FormValues) => {
    if (values.rewardType === 'FreeSpinsType') {
      const variables: CreateFreeSpinsMutationVariables = {
        rewardName: values.rewardName,
        campaignId: values.campaignId,
        claimType: values.claimType,
        startsAt: formatISO(values.startsAt),
        endsAt: values.endsAt ? formatISO(values.endsAt) : null,
        comment: values.comment,
        playerIds: values.playerIds,
        spinsNumber: Number(values.spinsNumber),
        spinsValue: Number(values.value),
        betLevel: Number(values.betLevel),
        currencyCode: values.currencyCode,
        gameProviderId: selectedGameProvider,
        gameMobileId: values.gameDetails.mobileId,
        gameDesktopId: values.gameDetails.desktopId,
      };

      return createFreeSpins(variables).then((res) => {
        if (res.error?.message && isMounted) {
          setErrorMessage(res.error.message);
        } else if (close) {
          feedback.success(t(form.rewardCreated));
          close();
        }
      });
    }

    if (values.rewardType === 'BonusMoneyDropType') {
      const variables: CreateBonusMoneyDropMutationVariables = {
        rewardName: values.rewardName,
        claimType: values.claimType,
        startsAt: formatISO(values.startsAt),
        campaignId: values.campaignId,
        endsAt: values.endsAt ? formatISO(values.endsAt) : null,
        expiresIn: values.expiresIn
          ? formatDuration(convertValuesToNumbers(values.expiresIn))
          : null,
        comment: values.comment,
        playerIds: values.playerIds,
        amount: Number(values.value),
        currencyCode: values.currencyCode,
        maxPayout: Number(values.maxPayout),
        wageringRequirementFactor: Number(values.wageringRequirementFactor),
      };

      return createBonusMoneyDrop(variables).then((res) => {
        if (res.error?.message && isMounted) {
          setErrorMessage(res.error.message);
        } else if (close) {
          feedback.success(t(form.rewardCreated));
          close();
        }
      });
    }

    const variables: CreateMoneyDropMutationVariables = {
      rewardName: values.rewardName,
      claimType: values.claimType,
      startsAt: formatISO(values.startsAt),
      campaignId: values.campaignId,
      endsAt: values.endsAt ? formatISO(values.endsAt) : null,
      comment: values.comment,
      playerIds: values.playerIds,
      amount: Number(values.value),
      currencyCode: values.currencyCode,
    };

    return createMoneyDrop(variables).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      } else if (close) {
        feedback.success(t(form.rewardCreated));
        close();
      }
    });
  };

  if (!form) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="flex p-6">
        <NakedForm className="w-full" onSubmit={onSubmit} methods={methods}>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            <div className="grid gap-2 content-start">
              <SelectField
                name="rewardType"
                id="RewardCreateForm__rewardType"
                title={t(form.rewardType)}
                required
                options={rewardTypesOptions}
              />
              <div className="grid sm:grid-cols-2 gap-2">
                <TextField
                  name="value"
                  required
                  id="RewardCreateForm__value"
                  title={t(form.value)}
                />
                <SelectField
                  name="currencyCode"
                  required
                  id="RewardCreateForm__currency"
                  title={t(form.currency)}
                  options={currencyOptions}
                />
              </div>
              {rewardType === 'FreeSpinsType' ? (
                <>
                  <div className="grid sm:grid-cols-2 gap-2">
                    <NumberField
                      name="spinsNumber"
                      required
                      id="RewardCreateForm__spinsNumber"
                      title={t(form.numberOfSpins)}
                      step="1"
                    />
                    <NumberField
                      name="betLevel"
                      required
                      id="RewardCreateForm__betLevel"
                      title={t(form.paylines)}
                      step="1"
                    />
                  </div>
                  <SelectField
                    name="gameProvider"
                    id="RewardCreateForm__gameProviders"
                    title={t(form.gameProviders)}
                    options={rewardGameProviders}
                  />
                  <GameSearch
                    name="gameDetails"
                    id="RewardCreateForm__gameDetails"
                    title={t(form.game)}
                    providerName={selectedGameProvider}
                  />
                </>
              ) : null}
              {rewardType === 'BonusMoneyDropType' ? (
                <>
                  <div className="grid sm:grid-cols-2 gap-2">
                    <TextField
                      name="maxPayout"
                      required
                      id="RewardCreateForm__maxPayout"
                      title={t(form.maxPayout)}
                    />
                    <NumberField
                      name="expiresIn.days"
                      required
                      id="RewardCreateForm__expiresIn"
                      title={t(form.expiresIn)}
                      step="1"
                    />
                  </div>
                  <div className="grid sm:grid-cols-2 gap-2">
                    <NumberField
                      name="wageringRequirementFactor"
                      required
                      id="RewardCreateForm__wageringRequirementFactor"
                      title={t(form.wageringRequirementFactor)}
                      step="1"
                    />
                  </div>
                </>
              ) : null}
            </div>
            <div className="grid gap-2 content-start">
              <TextField
                name="rewardName"
                id="RewardCreateForm__rewardName"
                title={t(form.nameOfReward)}
                required
              />
              <div className="grid sm:grid-cols-2 gap-2">
                <DateTimeField
                  title={t(form.startDate)}
                  name="startsAt"
                  id="analytics-block-settings__startDate"
                  required
                />
                <DateTimeField
                  title={t(form.endDate)}
                  name="endsAt"
                  id="analytics-block-settings__endDate"
                  required
                />
              </div>
              <TextField
                name="campaignId"
                required
                id="RewardCreateForm__campaignId"
                title={t(form.campaignId)}
              />
              <SelectField
                name="claimType"
                id="RewardCreateForm__claimType"
                title={t(form.claimableInstant)}
                options={claimOptions}
                required
              />
              <PlayersSearch
                name="playerIds"
                id="RewardCreateForm__playerIds"
                title={t(form.players)}
              />
            </div>
            <div className="grid gap-2 content-start">
              <TextareaField
                name="comment"
                title={t(form.notes)}
                id="RewardCreateForm__comment"
              />
            </div>
          </div>

          <ErrorMessage message={errorMessage} />
          <div className="flex justify-end">
            <SubmitButton
              value="Create Reward"
              disabled={
                createFreeSpinsState.fetching ||
                createMoneyDropState.fetching ||
                createBonusMoneyDropState.fetching
              }
            />
          </div>
        </NakedForm>
      </div>
    </Card>
  );
};

export default RewardCreateForm;
