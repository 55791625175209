import classNames from 'classnames';
import _ from 'lodash';
import React, { FC } from 'react';

import { Tippy } from '@/components';
import {
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from '@/components/icons';
import { PriorityEnum } from '@/globalTypes';
import { PlayerNotes_noteFragment } from '@/hooks/__generated__/usePlayerNotes';
import { getInitiator } from '@/utils';
import formatDate from '@/utils/formatter/formatDate';

const PriorityIcon: FC<{ priority: PriorityEnum }> = ({ priority }) => {
  switch (priority) {
    case PriorityEnum.HIGH:
      return <ChevronUpIcon />;

    case PriorityEnum.MEDIUM:
      return <ChevronRightIcon />;

    case PriorityEnum.LOW:
      return <ChevronDownIcon />;

    default:
      return null;
  }
};

const Note = ({
  note,
  isLast,
  email,
  icon,
}: {
  note: PlayerNotes_noteFragment;
  isLast: boolean;
  email: string;
  icon: string;
}) => {
  if (!note) {
    return null;
  }

  const date = new Date(note.createdAt);
  const agentFullName = getInitiator(note.addedBy);

  const agentEmail =
    note.addedBy.__typename === 'AgentInitiator'
      ? note.addedBy.agent?.email
      : '';

  const isCurrentAgent = agentEmail === email;

  return (
    <>
      <div className="flex flex-col justify-start flex-grow-0">
        <Tippy content={_.startCase(note?.priority.toLowerCase())}>
          <div
            className={classNames(
              'w-5 h-5 rounded-full p-1 flex items-center justify-center',
              {
                'bg-red-300 text-red-800': note?.priority === PriorityEnum.HIGH,
                'bg-yellow-200 text-yellow-700':
                  note?.priority === PriorityEnum.MEDIUM,
                'bg-green-200 text-green-900':
                  note?.priority === PriorityEnum.LOW,
              },
            )}
          >
            <PriorityIcon priority={note?.priority || PriorityEnum.LOW} />
          </div>
        </Tippy>
        <div className="flex-grow-flex-grow flex-grow flex justify-center items-stretch">
          {!isLast && (
            <div className="line w-0.5 h-full rounded-md mt-1.5 mb-0 bg-gray-300"></div>
          )}
        </div>
      </div>
      <div className="flex-grow ml-3 mb-3">
        <div className="flex justify-between items-center flex-wrap">
          <div className="font-medium text-sm text-gray-700 dark:text-gray-300">
            {agentFullName}
          </div>
          <div className="text-sm font-medium text-gray-500">
            {formatDate(date)}
          </div>
        </div>
        <div className="flex justify-between">
          <div
            className={classNames(
              'flex-1 pr-4 rounded-md rounded-tl-none px-3 py-1',
              isCurrentAgent
                ? 'bg-blue-600 dark:bg-blue-800 text-white'
                : 'bg-gray-200 dark:bg-gray-700 dark:text-gray-200',
              {
                'animate-pulse': '__isNew' in note,
                'mb-1': !isLast,
              },
            )}
          >
            {note?.content.split('\n').map((a, i) => (
              <div key={i}>{a}</div>
            ))}
          </div>
          <div className="ml-2">
            <picture>
              <source sizes="100vw" srcSet={icon} />
              <img
                src={icon}
                alt=""
                loading="lazy"
                className="w-8 h-8 rounded-md"
              />
            </picture>
          </div>
        </div>
      </div>
    </>
  );
};

export default Note;
