import React from 'react';

import { NumberField, Pill } from '@/components';
import { Nullable } from '@/types';
import { DataDef, DataDefProps } from '../types';

export function numberValue<O, T extends Record<string, any>>({
  fromFilterField,
  toFilterField,
  maxToFilter,
  title,
  field,
  getValue,
  ...rest
}: DataDefProps<O, T> & {
  currencyField?: string;
  getCurrency?: ({ row }: { row: T }) => Nullable<string>;
  fromFilterField?: string;
  toFilterField?: string;
  maxToFilter?: number;
}): DataDef<O, T> {
  return {
    ...rest,
    title,
    field,
    component: ({ row }) => {
      const value = getValue ? getValue({ row }) : row[field];
      return <div>{value}</div>;
    },
    filter:
      fromFilterField && toFilterField
        ? {
            field,
            transformValues: (values) => {
              const [from, to] = [
                values[fromFilterField],
                values[toFilterField],
              ];

              return {
                [fromFilterField]: from || undefined,
                [toFilterField]: to || undefined,
              };
            },
            component: () => {
              return (
                <>
                  <NumberField
                    title={`${title} From`}
                    name={fromFilterField}
                    id={fromFilterField}
                  />
                  <NumberField
                    title={`${title} To`}
                    name={toFilterField}
                    id={toFilterField}
                  />
                </>
              );
            },
            pillView: (values, updateFilter) => {
              const [from, to] = [
                values[fromFilterField],
                values[toFilterField],
              ];

              if (from || to) {
                return (
                  <Pill
                    onClick={() =>
                      updateFilter({
                        [fromFilterField]: undefined,
                        [toFilterField]: undefined,
                      })
                    }
                  >{`${from || 0} - ${to || maxToFilter || '∞'}`}</Pill>
                );
              }

              return null;
            },
          }
        : undefined,
  };
}
