import classNames from 'classnames';
import React, { ComponentProps, FC, ReactNode } from 'react';

import { useModalContext } from '@/components';
import { CloseIcon } from '@/components/icons';
import { Nullable } from '@/types';

export const CardsWrapper: FC<ComponentProps<'div'>> = ({
  className,
  ...rest
}) => {
  return (
    <div
      className={classNames(
        'px-3 pb-6 container mx-auto grid grid-cols-6 gap-3 self-start',
        className,
      )}
      {...rest}
    />
  );
};

export const Card: FC<{
  title: Nullable<ReactNode>;
  options?: ReactNode;
  showOptionsAtBottom?: boolean;
  className?: string;
  size: 'sm' | 'md' | 'lg';
  children: ReactNode;
}> = ({ children, title, options, className, size, showOptionsAtBottom }) => {
  return (
    <div
      className={classNames(
        'bg-white dark:bg-gray-800 shadow dark:shadow divide-y divide-gray-200 dark:divide-gray-700 rounded-md',
        className,
        {
          'col-span-full': size === 'lg',
          'col-span-full md:col-span-3': size === 'md',
          'col-span-3 lg:col-span-1': size === 'sm',
        },
      )}
    >
      {title && (
        <div className="drag_handle place-content-between flex items-center p-3 bg-gray-50 dark:bg-gray-900 rounded-t-md">
          <h2 className="mt-0 text-black dark:text-white font-semibold text-lg">
            {title}
          </h2>
          {options}
        </div>
      )}
      {children}
      {showOptionsAtBottom && (
        <div className="place-content-between flex items-center p-3 bg-gray-50 dark:bg-gray-900 rounded-b-md">
          <span></span>
          {options}
        </div>
      )}
    </div>
  );
};

export const CardActions: FC<ComponentProps<'div'>> = (props) => (
  <div
    className="justify-end space-x-2 flex items-center p-3 bg-gray-50 dark:bg-gray-900 rounded-b-md"
    {...props}
  />
);

export const CardBody: FC<ComponentProps<'div'>> = ({ children, ...rest }) => (
  <div {...rest}>{children}</div>
);

export const CardOptions: FC<ComponentProps<'div'>> = ({
  children,
  className,
  ...rest
}) => (
  <div
    className={classNames('flex justify-end items-center', className)}
    {...rest}
  >
    {children}
  </div>
);

export const CardOptionsButton: FC<
  ComponentProps<'button'> & { active?: boolean }
> = ({ children, className, active, ...rest }) => {
  return (
    <button
      className={classNames(
        'disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-800 focus-visible:ring-white inline-flex justify-center items-center rounded-md p-3 text-gray-600 hover:text-gray-700 hover:bg-gray-300 active:bg-gray-400 hover:bg-opacity-30 dark:text-gray-400 dark:hover:text-gray-300 dark:hover:bg-gray-800 dark:active:bg-gray-900 dark:hover-bg-opacity-20',
        {
          'bg-gray-300 bg-opacity-60 hover:bg-opacity-90 dark:bg-gray-500 dark:bg-opacity-30 dark:text-gray-400':
            active,
        },
        className,
      )}
      {...rest}
    >
      {children}
    </button>
  );
};

export const CardCloseButton: FC<
  ComponentProps<typeof CardOptionsButton>
> = () => {
  const { close } = useModalContext();

  if (!close) {
    return null;
  }
  return (
    <CardOptionsButton onClick={close}>
      <CloseIcon />
    </CardOptionsButton>
  );
};

export const CardOptionsInfo: FC<ComponentProps<'div'>> = (props) => (
  <div
    {...props}
    className={classNames(
      'text-sm text-gray-600 dark:text-gray-400',
      props.className,
    )}
  />
);
