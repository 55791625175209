import flag from 'country-code-emoji';
import { getName, overwrite } from 'country-list';

// This is a request from compliance team to show a different name for Cabo Verde
export const overwrittenCountries = [
  {
    code: 'CV',
    name: 'Cape Verde',
  },
];
overwrite(overwrittenCountries);

const CC_REGEX = /^[a-z]{2}$/i;

export function validateCountryCode(code?: string | null) {
  return !!code && CC_REGEX.test(code);
}

export default function formatCountryCode(code?: string | null): string {
  if (!code) {
    return '-';
  }

  return `${CC_REGEX.test(code) ? flag(code) : ''} ${getName(code) || code}`;
}
