import Tippy from '@tippyjs/react';
import gql from 'graphql-tag';
import React from 'react';

import { ControlledModal, InlineIconButton } from '@/components';
import { EditIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import { PlayerWithdrawalsStatusForm } from '@/forms';
import { useCan } from '@/utils/access';

export const withdrawalsStatusFragment = gql`
  fragment WithdrawalsStatusFragment on Player {
    id
    shouldReopenAt
    areWithdrawalsBlocked
  }
`;

export const WithdrawalsStatus = ({
  block,
  playerId,
  isBlocked,
}: {
  block: Queries.SanityPlayerInfoBlockFragment;
  playerId: string;
  isBlocked: boolean;
}) => {
  const { t } = useTranslate();
  const agentCanReopen = useCan('BLOCK_WITHDRAWALS');

  return (
    <>
      {isBlocked ? (
        t(block.withdrawalsStatusBlocked)
      ) : (
        <Tippy>
          <span>{t(block.withdrawalsStatusNotBlocked)}</span>
        </Tippy>
      )}
      {agentCanReopen && (
        <ControlledModal
          content={<PlayerWithdrawalsStatusForm playerId={playerId} />}
        >
          <InlineIconButton>
            <Tippy content={'Edit'}>
              <div>
                <EditIcon />
              </div>
            </Tippy>
          </InlineIconButton>
        </ControlledModal>
      )}
    </>
  );
};
