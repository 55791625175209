import React from 'react';

import formatMoney from '@/utils/formatter/formatMoney';
import { DetailList } from './DetailList';

const example = () => {
  return (
    <DetailList
      items={[
        {
          label: 'My first',
          value: 'the value',
        },
        {
          label: 'My second',
          value: formatMoney(10_000),
        },
      ]}
    />
  );
};

export default example;
