import gql from 'graphql-tag';
import { useClient } from 'urql';

import { useElasticIndexPrefix } from '@/contexts';
import {
  PlayerSearchAsYouTypeQuery,
  PlayerSearchAsYouTypeQueryVariables,
} from './__generated__/usePlayerSearch';

const PLAYERS_AS_YOU_TYPE_QUERY = gql`
  query PlayerSearchAsYouType(
    $query: String!
    $email: EmailAddress!
    $indexPrefix: String
  ) {
    viewer {
      id
      playersAsYouTypeV2(
        firstName: $query
        lastName: $query
        playerId: $query
        playerGlobalId: $query
        email: $email
        indexPrefix: $indexPrefix
      ) {
        edges {
          node {
            playerId
            playerGlobalId
            firstName
            lastName
            brand {
              id
              code
            }
          }
        }
      }
    }
  }
`;

export const usePlayerSearch = () => {
  const client = useClient();
  const { indexPrefix } = useElasticIndexPrefix();

  const searchPlayers = (query: string) => {
    const players = client
      .query<PlayerSearchAsYouTypeQuery, PlayerSearchAsYouTypeQueryVariables>(
        PLAYERS_AS_YOU_TYPE_QUERY,
        {
          query,
          indexPrefix,
          email: query,
        },
      )
      .toPromise();

    return players;
  };

  return { searchPlayers };
};
