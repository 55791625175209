import gql from 'graphql-tag';
import { useQuery } from 'urql';

import {
  PaymentInspectionQuery,
  PaymentInspectionQueryVariables,
} from './__generated__/usePayment';

const PAYMENT_QUERY = gql`
  query PaymentInspection($rawId: String!) {
    viewer {
      id
      payments(rawId: $rawId) {
        edges {
          node {
            rawPaymentId
            playerId
            initialAmount
            amount
            processedAt
            completedAt
            cancelledAt
            rollbackAt
            initializedAt
            paymentType
            brand {
              name
            }
            paymentStatus
            openingBalance
            closingBalance
            instrument
            paymentMethod
            statusMessage
            playerSessionId
            paymentFee {
              money {
                amount
                currency
              }
            }
            extTransactionId
            currency
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;

export default function usePayment(paymentId: string) {
  const [{ data, fetching }, refresh] = useQuery<
    PaymentInspectionQuery,
    PaymentInspectionQueryVariables
  >({
    query: PAYMENT_QUERY,
    variables: {
      rawId: paymentId,
    },
  });

  return {
    refresh,
    fetching,
    payment: data?.viewer.payments?.edges?.[0]?.node,
  };
}
