import { RgCraRiskLevel, RiskLevel } from '@/globalTypes';
import { Nullable } from '@/types';

const riskLevelMap: Record<RiskLevel, string> = {
  High: 'text-red-600',
  Medium: 'text-yellow-600',
  Low: 'text-green-600',
};

const rgCraRiskLevelMap: Record<RgCraRiskLevel, string> = {
  VeryHigh: 'text-red-600',
  High: 'text-orange-600',
  Medium: 'text-yellow-600',
  Low: 'text-green-600',
};

export const getRiskLevelColor = (level: Nullable<RiskLevel>) =>
  riskLevelMap[level ?? RiskLevel.Low];

export const getRgRiskLevelColor = (level: Nullable<RgCraRiskLevel>) =>
  rgCraRiskLevelMap[level ?? RgCraRiskLevel.Low];
