import React from 'react';

import { Pill, SelectField } from '@/components';
import { SelectOption } from '@/components/form';
import { DataDef, DataDefProps } from '../types';

export function selectValue<O, T extends Record<string, any>>({
  field,
  title,
  filterField,
  isMulti,
  getValue,
  options,
  ...rest
}: DataDefProps<O, T> & {
  filterField?: string;
  isMulti?: boolean;
  options: SelectOption[];
}): DataDef<O, T> {
  return {
    title,
    field,
    component: ({ row }) => {
      const value = getValue ? getValue({ row }) : row[field];
      return <>{value ?? '-'}</>;
    },
    ...rest,
    filter: filterField
      ? {
          field,
          transformDefaultValues: (values) => {
            const val = values[filterField];
            if (isMulti) {
              return { ...values, [filterField]: val || [] };
            }
            return { ...values, [filterField]: val || '' };
          },
          transformValues: (values) => {
            const val = values[filterField];

            return {
              ...values,
              [filterField]: val && val.length > 0 ? val : undefined,
            };
          },
          component: () => {
            return (
              <SelectField
                title={title}
                name={filterField}
                id={filterField}
                isMulti={isMulti}
                options={options}
              />
            );
          },
          pillView: (values, updateFilter) => {
            const value = values[filterField];
            if (value) {
              if (value instanceof Array) {
                return (
                  <>
                    {value.map((val) => (
                      <Pill
                        key={val}
                        onClick={() =>
                          updateFilter({
                            [filterField]: value.filter((v) => v !== val),
                          })
                        }
                      >
                        {val}
                      </Pill>
                    ))}
                  </>
                );
              }

              return (
                <Pill
                  onClick={() =>
                    updateFilter({
                      [filterField]: undefined,
                    })
                  }
                >
                  {value}
                </Pill>
              );
            }
            return null;
          },
        }
      : undefined,
  };
}
