import React, {
  Dispatch,
  FC,
  ReactNode,
  Reducer,
  useEffect,
  useReducer,
} from 'react';

import { createStorage } from '@/utils/storage';
import { ContextBuilder } from './ContextBuilder';

type ElasticIndexPrefixContextState = {
  indexPrefix: string | null;
};

type RemoveElasticIndexAction = {
  type: 'REMOVE_INDEX_PREFIX';
};

type SetElasticIndexAction = {
  type: 'SET_INDEX_PREFIX';
  indexPrefix: string;
};

type ElasticIndexPrefixAction =
  | SetElasticIndexAction
  | RemoveElasticIndexAction;

const ElasticIndexPrefixContext =
  new ContextBuilder<ElasticIndexPrefixContextState>('IndexPrefixContext');
const DispatchElasticIndexPrefixContext = new ContextBuilder<
  Dispatch<ElasticIndexPrefixAction>
>('DispatchIndexPrefixContext');

export const useElasticIndexPrefix = ElasticIndexPrefixContext.use;
export const useDispatchElasticIndexPrefix =
  DispatchElasticIndexPrefixContext.use;

type IndexPrefixReducer = Reducer<
  ElasticIndexPrefixContextState,
  ElasticIndexPrefixAction
>;

const reducer: IndexPrefixReducer = (state, action) => {
  switch (action.type) {
    case 'SET_INDEX_PREFIX': {
      return {
        ...state,
        indexPrefix: action.indexPrefix,
      };
    }
    case 'REMOVE_INDEX_PREFIX':
      return {
        ...state,
        indexPrefix: null,
      };
    default:
      return state;
  }
};

const storage = createStorage('sessionStorage', 'elasticIndexPrefix');

export const ElasticIndexPrefixProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer<
    IndexPrefixReducer,
    { indexPrefix: null }
  >(reducer, { indexPrefix: null }, () => ({
    indexPrefix: storage.get() ?? null,
  }));

  useEffect(() => {
    if (state.indexPrefix) {
      storage.set(state.indexPrefix);
      return;
    }

    storage.remove();
  }, [state.indexPrefix]);

  return (
    <DispatchElasticIndexPrefixContext.Context.Provider value={dispatch}>
      <ElasticIndexPrefixContext.Context.Provider value={state}>
        {children}
      </ElasticIndexPrefixContext.Context.Provider>
    </DispatchElasticIndexPrefixContext.Context.Provider>
  );
};
