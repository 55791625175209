import { Link } from 'gatsby';
import React from 'react';

import { useGetPlayerOverviewPageLink } from '@/bits/links/useLink';
import {
  Card,
  CardCloseButton,
  CardOptions,
  ConditionalWrap,
  ControlledModal,
  InlineIconButton,
  Table,
  Value,
} from '@/components';
import { EyeIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import { Nullable } from '@/types';
import { PlayerKycOverviewBoxQuery } from './__generated__/component';

export const PlayersLinkedBySourceOfWealthValue = ({
  fetching,
  block,
  data,
}: {
  fetching: boolean;
  block: Queries.SanityPlayerKycOverviewBlockFragment;
  data: Nullable<PlayerKycOverviewBoxQuery>;
}) => {
  const { t } = useTranslate();
  const getPlayerOverviewPageLink = useGetPlayerOverviewPageLink();

  return (
    <Value
      fetching={fetching}
      title={t(block.sharedSowAccountsLabel)}
      value={`${data?.player.playersLinkedBySourceOfWealth?.length ?? 0}`}
      suffix={
        data?.player.playersLinkedBySourceOfWealth?.length != null && (
          <ControlledModal
            content={
              <Card
                size="lg"
                title={t(block.sharedSowAccountsLabel)}
                options={
                  <CardOptions>
                    <CardCloseButton />
                  </CardOptions>
                }
              >
                <Table
                  headings={['Player', 'Brand']}
                  rows={data.player.playersLinkedBySourceOfWealth.map(
                    (link) => {
                      const pageLink = getPlayerOverviewPageLink(
                        link.player.id,
                      );
                      return {
                        key: link.id,
                        data: [
                          <ConditionalWrap
                            condition={!!pageLink}
                            wrap={(a) =>
                              pageLink ? <Link to={pageLink}>{a}</Link> : null
                            }
                          >
                            {link.player.uuid}
                          </ConditionalWrap>,
                          link.player.brand.name,
                        ],
                      };
                    },
                  )}
                />
              </Card>
            }
          >
            <InlineIconButton>
              <EyeIcon />
            </InlineIconButton>
          </ControlledModal>
        )
      }
    />
  );
};
