import { sanityClient } from '@/utils/sanityClient';

export const searchGames = (query: string, providerName?: string) => {
  return sanityClient.fetch<Game[]>(
    `*[_type == 'game' && enabled == true && visible == true && name match "${query}*" ${
      providerName
        ? `&& gameProvider->.internalIntegrationId == "${providerName}"`
        : ''
    }] {
        name,
        "features": gameFeatures[]->meta.en.name,
        "themes": gameThemes[]->meta.en.name,
        "gameCategory": gameCategory->.meta.en.name,
        "studio": gameStudio->.name,
        "slug": slug.current,
        "desktopId": desktopLaunchId,
        "mobileId": mobileLaunchId,
        "thumbnail": thumbnail.thumbnail,
        "thumbnailUpdatedAt": thumbnail.updatedAt,
        "provider": gameProvider->.name
      }[0..50]
    `,
  );
};
