import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useState } from 'react';
import { useMutation, useQuery } from 'urql';

import {
  Card,
  CardCloseButton,
  CardOptions,
  CheckboxField,
  ErrorMessage,
  Form,
  SubmitButton,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import {
  BlockInstantBankingMutation,
  BlockInstantBankingMutationVariables,
  PlayerIsInstantBankingFormQuery,
  UnblockInstantBankingMutation,
  UnblockInstantBankingMutationVariables,
} from './__generated__/PlayerInstantBankingForm';

const query = graphql`
  query SanityPlayerInstantBankingForm {
    sanityPlayerInstantBankingForm {
      title {
        ...SanityLocaleString
      }
      isInstantBankingBlocked {
        ...SanityLocaleString
      }
      submit {
        ...SanityLocaleString
      }
    }
  }
`;

const blockInstantBankingQuery = gql`
  mutation BlockInstantBanking($playerId: ID!) {
    blockInstantBanking: blockInstantBankingV2(playerId: $playerId) {
      id
      isInstantBankingBlocked
      instantBankingBlock {
        activeFrom
      }
    }
  }
`;

const unblockInstantBankingQuery = gql`
  mutation UnblockInstantBanking($playerId: ID!) {
    unblockInstantBanking(playerId: $playerId) {
      id
      isInstantBankingBlocked
      instantBankingBlock {
        activeFrom
      }
    }
  }
`;

const playerQuery = gql`
  query PlayerIsInstantBankingForm($playerId: ID!) {
    player(playerId: $playerId) {
      id
      isInstantBankingBlocked
    }
  }
`;

const PlayerInstantBankingForm: FC<{ playerId: string }> = ({ playerId }) => {
  const staticData =
    useStaticQuery<Queries.SanityPlayerInstantBankingFormQuery>(query);

  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();

  const form = staticData.sanityPlayerInstantBankingForm;

  const [{ data }] = useQuery<PlayerIsInstantBankingFormQuery>({
    query: playerQuery,
    variables: { playerId },
  });

  const [{ fetching: blockFetching }, blockInstantBanking] = useMutation<
    BlockInstantBankingMutation,
    BlockInstantBankingMutationVariables
  >(blockInstantBankingQuery);

  const [{ fetching: unblockFetching }, unblockInstantBanking] = useMutation<
    UnblockInstantBankingMutation,
    UnblockInstantBankingMutationVariables
  >(unblockInstantBankingQuery);

  const defaultValues = {
    isInstantBankingBlocked: data?.player?.isInstantBankingBlocked,
  };

  const { close } = useModalContext();

  const { t } = useTranslate();

  const onSubmit = (values: typeof defaultValues) => {
    if (!data?.player?.id) {
      return;
    }

    const blockOrUnBlock = values.isInstantBankingBlocked
      ? blockInstantBanking
      : unblockInstantBanking;

    blockOrUnBlock({ playerId: data?.player.id }).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      } else if (close) {
        close();
      }
    });
  };
  if (!form || !data) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">
        <Form
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          className="grid grid-cols-2 sm:grid-cols-3 gap-6"
        >
          <CheckboxField
            title={t(form.isInstantBankingBlocked)}
            name="isInstantBankingBlocked"
            id="PlayerInstantBankingForm_isInstantBankingBlocked"
            className="col-span-full"
          />
          <ErrorMessage message={errorMessage} />
          <SubmitButton
            value={t(form.submit)}
            disabled={blockFetching || unblockFetching}
          />
        </Form>
      </div>
    </Card>
  );
};

export default PlayerInstantBankingForm;
