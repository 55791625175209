import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { NakedForm, SwitchField } from '@/components';
import { useTheme, useTranslate } from '@/contexts';
import { useFunctionRef } from '@/hooks';

const noop = () => {};

const query = graphql`
  query SanityThemeForm {
    sanityThemeForm {
      title {
        ...SanityLocaleString
      }
      darkMode {
        ...SanityLocaleString
      }
    }
  }
`;

const ThemeForm = () => {
  const form =
    useStaticQuery<Queries.SanityThemeFormQuery>(query).sanityThemeForm;
  const { t } = useTranslate();
  const theme = useTheme();

  const setThemeRef = useFunctionRef(theme.setTheme);

  const methods = useForm({
    defaultValues: {
      dark: theme.name === 'dark',
    },
  });

  const dark = methods.watch('dark');

  useEffect(() => {
    setThemeRef.current(dark ? 'dark' : 'light');
  }, [dark, setThemeRef]);

  if (!form) {
    return null;
  }

  return (
    <div>
      <h3 className="text-black dark:text-white mb-2">{t(form.title)}</h3>
      <NakedForm methods={methods} onSubmit={noop}>
        <SwitchField
          name="dark"
          title={t(form.darkMode)}
          allowUndefinedState={false}
          className="text-sm text-gray-500 dark:text-gray-400 font-semibold"
        />
      </NakedForm>
    </div>
  );
};

export default ThemeForm;
