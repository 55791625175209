import { gql, useMutation } from 'urql';

import { limitBlockingFragment } from '@/fragments/Limits';
import {
  BlockDepositLimitMutation,
  BlockDepositLimitMutationVariables,
  UnblockDepositLimitMutation,
  UnblockDepositLimitMutationVariables,
} from './__generated__/blocking';

const BLOCK_SESSION_LIMIT = gql`
  mutation BlockDepositLimit($playerId: ID!) {
    blockDepositLimit(playerId: $playerId) {
      id
      depositLimit {
        ...LimitBlockingStatus
      }
    }
  }
  ${limitBlockingFragment}
`;

const UNBLOCK_SESSION_LIMIT = gql`
  mutation UnblockDepositLimit($playerId: ID!) {
    unblockDepositLimit(playerId: $playerId) {
      id
      depositLimit {
        ...LimitBlockingStatus
      }
    }
  }
  ${limitBlockingFragment}
`;

export const useBlockDepositLimit = () => {
  return useMutation<
    BlockDepositLimitMutation,
    BlockDepositLimitMutationVariables
  >(BLOCK_SESSION_LIMIT);
};

export const useUnblockDepositLimit = () => {
  return useMutation<
    UnblockDepositLimitMutation,
    UnblockDepositLimitMutationVariables
  >(UNBLOCK_SESSION_LIMIT);
};
