import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import React, { FC } from 'react';

import { Card, CardBody, CardOptions, CardOptionsButton } from '@/components';
import { useDataGrid } from '@/components/DataGrid';
import {
  DocumentDownloadIcon,
  NextIcon,
  PreviousIcon,
  RefreshIcon,
} from '@/components/icons';
import { useTranslate } from '@/contexts';
import { PaymentStatus, PaymentType } from '@/globalTypes';
import { useBrandValueOptions } from '@/hooks/useBrands';
import { assert } from '@/utils/error';
import useGlobalPlayerPayments from './useGlobalPlayerPayments';
import useGlobalPlayerPaymentsReport from './useGlobalPlayerPaymentsReport';

export const Fragment = graphql`
  fragment SanityGlobalPlayerPaymentsBlock on SanityGlobalPlayerPaymentsBlock {
    title {
      ...SanityLocaleString
    }
    processedAt {
      ...SanityLocaleString
    }
    initializedAt {
      ...SanityLocaleString
    }
    completedAt {
      ...SanityLocaleString
    }
    cancelledAt {
      ...SanityLocaleString
    }
    rollbackAt {
      ...SanityLocaleString
    }
    brand {
      ...SanityLocaleString
    }
    initialAmount {
      ...SanityLocaleString
    }
    paymentId {
      ...SanityLocaleString
    }
    amount {
      ...SanityLocaleString
    }
    paymentType {
      ...SanityLocaleString
    }
    paymentStatus {
      ...SanityLocaleString
    }
    paymentMethod {
      ...SanityLocaleString
    }
    openingBalance {
      ...SanityLocaleString
    }
    closingBalance {
      ...SanityLocaleString
    }
    instrument {
      ...SanityLocaleString
    }
    statusMessage {
      ...SanityLocaleString
    }
    extTransactionId {
      ...SanityLocaleString
    }
  }
`;

const GlobalPlayerPaymentsBlock: FC<{
  block: Queries.SanityGlobalPlayerPaymentsBlockFragment;
}> = ({ block }) => {
  const params = useParams();

  assert(params.playerGlobalId, 'missing playerId');

  const { t } = useTranslate();

  const {
    brands,
    payments,
    fetching,
    nextPage,
    previousPage,
    refresh,
    orderBy,
    desc,
    setOrderBy,
    defaultFilter,
    updateFilter,
  } = useGlobalPlayerPayments(params.playerGlobalId);
  const report = useGlobalPlayerPaymentsReport(params.playerGlobalId);

  const brandValueOptions = useBrandValueOptions(brands);

  const { DataGrid, GlobalCardOptions } = useDataGrid({
    name: 'globalPlayerPayments',
    data: payments,
    fetching,
    orderBy,
    setOrderBy,
    desc,
    defaultFilter,
    updateFilter,
    schema: (s) => [
      s.dateTimeValue({
        field: 'processedAt',
        title: t(block.processedAt),
        fromFilterField: 'from',
        toFilterField: 'to',
        orderBy: 'processedAt',
      }),
      s.dateTimeValue({
        field: 'completedAt',
        title: t(block.completedAt),
        orderBy: 'completedAt',
      }),
      s.dateTimeValue({
        field: 'cancelledAt',
        title: t(block.cancelledAt),
        orderBy: 'cancelledAt',
      }),
      s.dateTimeValue({
        field: 'rollbackAt',
        title: t(block.rollbackAt),
        orderBy: 'rollbackAt',
      }),
      s.dateTimeValue({
        field: 'initializedAt',
        title: 'Initialized At',
        orderBy: 'initializedAt',
      }),
      s.monetaryValue({
        field: 'initialAmount',
        title: t(block.initialAmount),
      }),
      s.stringValue({
        field: 'rawPaymentId',
        title: t(block.paymentId),
        filterField: 'rawId',
      }),
      s.selectValue({
        field: 'brand',
        title: t(block.brand),
        filterField: 'brand',
        options: brandValueOptions.options,
        getValue: ({ row }) => row.brand?.name,
      }),
      s.monetaryValue({
        field: 'amount',
        title: t(block.amount),
      }),
      s.enumValue({
        field: 'paymentType',
        title: t(block.paymentType),
        filterField: 'paymentType',
        e: PaymentType,
      }),
      s.enumValue({
        field: 'paymentStatus',
        title: t(block.paymentStatus),
        filterField: 'paymentStatus',
        e: PaymentStatus,
      }),
      s.stringValue({
        field: 'paymentMethod',
        title: t(block.paymentMethod),
      }),
      s.monetaryValue({
        field: 'openingBalance',
        title: t(block.openingBalance),
      }),
      s.monetaryValue({
        field: 'closingBalance',
        title: t(block.closingBalance),
      }),
      s.stringValue({
        field: 'instrument',
        title: t(block.instrument),
      }),
      s.stringValue({
        field: 'statusMessage',
        title: t(block.statusMessage),
      }),
      s.stringValue({
        field: 'extTransactionId',
        title: t(block.extTransactionId),
      }),
      s.monetaryValue({
        field: 'paymentFee',
        title: 'Payment Fee',
        getValue: ({ row }) => {
          return row?.paymentFee?.money.amount || 0;
        },
      }),
    ],
  });
  return (
    <Card
      size="lg"
      title={t(block.title)}
      showOptionsAtBottom
      options={
        <CardOptions>
          <CardOptionsButton
            disabled={!previousPage}
            onClick={() => previousPage && previousPage()}
          >
            <PreviousIcon />
          </CardOptionsButton>
          <CardOptionsButton
            disabled={!nextPage}
            onClick={() => nextPage && nextPage()}
          >
            <NextIcon />
          </CardOptionsButton>
          <GlobalCardOptions />

          <CardOptionsButton
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
          <CardOptionsButton onClick={() => report.generate()}>
            <DocumentDownloadIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <DataGrid />
      </CardBody>
    </Card>
  );
};

export default GlobalPlayerPaymentsBlock;
