import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import { gql, useMutation, useQuery } from 'urql';

import {
  Card,
  CardCloseButton,
  CardOptions,
  ErrorMessage,
  Form,
  SelectField,
  SubmitButton,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { VerificationDecision } from '@/globalTypes';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import {
  PlayerSourceOfWealthFormQuery,
  PlayerSourceOfWealthFormQueryVariables,
  VerifyPlayerSourceOfWealthMutation,
  VerifyPlayerSourceOfWealthMutationVariables,
} from './__generated__/PlayerVerifySourceOfWealthForm';

const query = graphql`
  query SanityPlayerVerifySourceOfWealthForm {
    sanityPlayerVerifySourceOfWealthForm {
      title {
        ...SanityLocaleString
      }
      verificationDecisionLabel {
        ...SanityLocaleString
      }
      submit {
        ...SanityLocaleString
      }
    }
  }
`;

const verifySourceOfWealthMutation = gql`
  mutation VerifyPlayerSourceOfWealth(
    $playerId: ID!
    $verificationDecision: VerificationDecision!
  ) {
    verifyPlayerSourceOfWealth(
      playerId: $playerId
      verificationDecision: $verificationDecision
    ) {
      id
      status
      needsManualReview
    }
  }
`;

const QUERY = gql`
  query PlayerSourceOfWealthForm($playerId: ID!) {
    player(playerId: $playerId) {
      id
      rawPlayerId
    }
  }
`;

const PlayerVerifySourceOfWealthForm = ({ playerId }: { playerId: string }) => {
  const { t } = useTranslate();
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();
  const { close } = useModalContext();

  const form =
    useStaticQuery<Queries.SanityPlayerVerifySourceOfWealthFormQuery>(
      query,
    ).sanityPlayerVerifySourceOfWealthForm;

  const [{ data }] = useQuery<
    PlayerSourceOfWealthFormQuery,
    PlayerSourceOfWealthFormQueryVariables
  >({
    query: QUERY,
    variables: {
      playerId,
    },
  });

  const [verifySourceOfWealthState, verifySourceOfWealth] = useMutation<
    VerifyPlayerSourceOfWealthMutation,
    VerifyPlayerSourceOfWealthMutationVariables
  >(verifySourceOfWealthMutation);

  const defaultValues = {
    verificationDecision: VerificationDecision.Approved,
  };

  const onSubmit = (values: typeof defaultValues) => {
    if (!data?.player.rawPlayerId) {
      return;
    }

    setErrorMessage(null);

    verifySourceOfWealth({
      playerId: data.player.rawPlayerId,
      verificationDecision: values.verificationDecision,
    }).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      } else if (close) {
        close();
      }
    });
  };

  if (!form || !data) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">
        <Form
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          className="grid grid-cols-2 sm:grid-cols-3 gap-6"
        >
          <SelectField
            name="verificationDecision"
            id="PlayerVerifySourceOfWealthForm__verificationDecision"
            title={t(form.verificationDecisionLabel)}
            options={[
              {
                label: VerificationDecision.Approved,
                value: VerificationDecision.Approved,
              },
              {
                label: VerificationDecision.Rejected,
                value: VerificationDecision.Rejected,
              },
            ]}
          />
          <ErrorMessage message={errorMessage} />
          <SubmitButton
            value={t(form.submit)}
            disabled={verifySourceOfWealthState.fetching}
          />
        </Form>
      </div>
    </Card>
  );
};

export default PlayerVerifySourceOfWealthForm;
