import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useState } from 'react';
import { useMutation } from 'urql';

import {
  Card,
  CardBody,
  CardCloseButton,
  CardOptions,
  CheckboxField,
  ErrorMessage,
  Form,
  SubmitButton,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { useIsMounted } from '@/hooks';
import { Nullable, PlayerId } from '@/types';
import {
  PlayerUpdateDepositBlockMutation,
  PlayerUpdateDepositBlockMutationVariables,
} from './__generated__/PlayerUpdateDepositsBlockForm';

const query = graphql`
  query SanityPlayerUpdateDepositsBlockForm {
    sanityPlayerUpdateDepositsBlockForm {
      title {
        ...SanityLocaleString
      }
      description {
        ...SanityLocaleString
      }
      isDepositsBlocked {
        ...SanityLocaleString
      }
      submit {
        ...SanityLocaleString
      }
    }
  }
`;

const updateCustomerDepositBlockMutation = gql`
  mutation PlayerUpdateDepositBlock($playerGlobalId: ID!, $enable: Boolean!) {
    updateCustomerDepositBlock(
      playerGlobalId: $playerGlobalId
      enable: $enable
    ) {
      ... on Customer {
        isDepositsBlocked
      }
    }
  }
`;

const PlayerUpdateDepositsBlockForm: FC<{
  playerGlobalId: PlayerId;
  isBlocked: boolean;
}> = ({ playerGlobalId, isBlocked }) => {
  const staticData =
    useStaticQuery<Queries.SanityPlayerUpdateDepositsBlockFormQuery>(query);

  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();
  const form = staticData.sanityPlayerUpdateDepositsBlockForm;
  const { close } = useModalContext();
  const { t } = useTranslate();

  const [updateDepositsBlockState, updateDepositsBlock] = useMutation<
    PlayerUpdateDepositBlockMutation,
    PlayerUpdateDepositBlockMutationVariables
  >(updateCustomerDepositBlockMutation);

  const defaultValues = {
    isBlocked,
  };

  const onSubmit = ({ isBlocked }: typeof defaultValues) => {
    updateDepositsBlock({
      playerGlobalId,
      enable: !isBlocked,
    }).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      } else if (close) {
        close();
      }
    });
  };

  if (!form) {
    return null;
  }

  return (
    <Card
      size="md"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <CardBody>
        <div className="p-3">
          <Form
            defaultValues={defaultValues}
            onSubmit={onSubmit}
            className="grid gap-6"
          >
            <div className="space-y-1">
              <div className="block text-gray-600 text-sm dark:text-gray-200">
                {t(form.description)}
              </div>
            </div>
            <CheckboxField
              title={t(form.isDepositsBlocked)}
              name="isDepositsBlocked"
              id="PlayerUpdateDepositsBlockForm_isDepositsBlocked"
              className="col-span-full"
              defaultChecked={isBlocked}
            />
            <ErrorMessage message={errorMessage} />
            <SubmitButton
              value={t(form.submit)}
              disabled={updateDepositsBlockState.fetching}
            />
          </Form>
        </div>
      </CardBody>
    </Card>
  );
};

export default PlayerUpdateDepositsBlockForm;
