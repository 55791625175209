import classNames from 'classnames';
import React, { FC } from 'react';

import { useTheme } from '@/contexts/ThemeContext';

export const Loading: FC = () => {
  const { name: themeName } = useTheme();

  return (
    <div
      className={classNames(
        'flex items-center justify-center',
        'h-6 w-6 border-4 border-t-4 border-gray-200 rounded-full animate-spin',
        {
          'border-t-gray-800': themeName === 'dark',
          'border-t-gray-500': themeName === 'light',
        },
      )}
    />
  );
};
