import gql from 'graphql-tag';
import { useMutation } from 'urql';

import {
  ResendVerificationEmailMutation,
  ResendVerificationEmailMutationVariables,
} from './__generated__/useResendVerificationEmail';

const RESEND_VERIFICATION_EMAIL_MUTATION = gql`
  mutation ResendVerificationEmail($playerId: ID!) {
    resendVerificationEmail(playerId: $playerId) {
      playerId
    }
  }
`;

export default function useResendVerificationEmail() {
  return useMutation<
    ResendVerificationEmailMutation,
    ResendVerificationEmailMutationVariables
  >(RESEND_VERIFICATION_EMAIL_MUTATION);
}
