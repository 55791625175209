import React from 'react';
import DatePicker from 'react-datepicker';
import { Controller, useFormContext } from 'react-hook-form';

import { dateFormats } from '@/utils/formatter/formatDate';
import 'react-datepicker/dist/react-datepicker.css';
import { BaseInputFieldProps } from '../BaseInputField';
import { BaseWrapper } from '../BaseWrapper';

export function DateField({
  maxDate,
  minDate,
  name,
  showMonthDropdown,
  showTimeInput,
  showYearDropdown,
  title,
  id,
  ...props
}: Omit<BaseInputFieldProps, 'step'> & {
  maxDate?: Date;
  minDate?: Date;
  showMonthDropdown?: boolean;
  showTimeInput?: boolean;
  showYearDropdown?: boolean;
}) {
  const { control } = useFormContext();
  return (
    <BaseWrapper title={title} name={name} id={id} {...props}>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        rules={{
          required: props.required,
        }}
        render={({ field }) => (
          <DatePicker
            id={id || name}
            onChange={(e) => {
              field.onChange(e);
            }}
            wrapperClassName="block w-full"
            showTimeInput={showTimeInput}
            showYearDropdown={showYearDropdown}
            showMonthDropdown={showMonthDropdown}
            selected={field.value}
            popperPlacement="top"
            placeholderText={title}
            dateFormat={showTimeInput ? dateFormats.dateTime : dateFormats.date}
            className="mt-1 focus:ring block w-full sm:text-sm border-gray-300 rounded-md"
            maxDate={maxDate}
            minDate={minDate}
          />
        )}
      />
    </BaseWrapper>
  );
}
