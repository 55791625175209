import { navigate } from 'gatsby';
import React, { useEffect } from 'react';

import { BaseWrapper } from '@/components';

const Component = () => {
  // TODO: This component / page shouldn't even be needed anymore
  useEffect(() => {
    navigate('/players');
  }, []);

  return null;
};

const Wrapper = () => (
  <BaseWrapper>
    <Component />
  </BaseWrapper>
);

export default Wrapper;
