import { useParams } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { feedback } from 'react-feedbacker';
import { useMutation } from 'urql';

import {
  Card,
  CardCloseButton,
  CardOptions,
  ErrorMessage,
  Form,
  SubmitButton,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import { assert } from '@/utils/error';
import {
  RetryRewardMutation,
  RetryRewardMutationVariables,
} from './__generated__/RewardRetryForm';

const query = graphql`
  query SanityRewardRetryForm {
    sanityRewardRetryForm {
      title {
        ...SanityLocaleString
      }
      message {
        ...SanityLocaleString
      }
      submit {
        ...SanityLocaleString
      }
      retrySuccess {
        ...SanityLocaleString
      }
    }
  }
`;

const retryRewardMutation = gql`
  mutation RetryReward($id: String!) {
    claimFailedRewards(id: $id)
  }
`;

type FormValues = {
  id: string;
};

const RewardRetryForm = () => {
  const staticData = useStaticQuery<Queries.SanityRewardRetryFormQuery>(query);

  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const form = staticData.sanityRewardRetryForm;

  assert(form, 'missing form data');
  const { t } = useTranslate();
  const isMounted = useIsMounted();
  const { close } = useModalContext();
  const params = useParams();

  const [retryRewardState, retryReward] = useMutation<
    RetryRewardMutation,
    RetryRewardMutationVariables
  >(retryRewardMutation);

  const defaultValues: FormValues = {
    id: '',
  };

  const onSubmit = () => {
    const variable = {
      id: params.rewardId,
    };
    return retryReward(variable).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      } else if (close) {
        feedback.success(t(form.retrySuccess));
        close();
      }
    });
  };

  if (!form) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <Form defaultValues={defaultValues} onSubmit={onSubmit} className="grid">
        <div className="p-3">
          <h4>{t(form.message)}</h4>
          <div className="flex justify-end mt-3">
            <SubmitButton
              value={t(form.submit)}
              disabled={retryRewardState.fetching}
            />
            <ErrorMessage message={errorMessage} />
          </div>
        </div>
      </Form>
    </Card>
  );
};

export default RewardRetryForm;
