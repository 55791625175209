import React from 'react';

import { OptionsMenu, OptionsMenuItem } from '@/components';
import { TrashIcon } from '@/components/icons';
import { logger } from '@/utils';

const items: OptionsMenuItem[] = [
  {
    content: 'Delete some stuff',
    icon: <TrashIcon />,
    onClick: () => logger.log('delete!'),
    shouldConfirm: true,
  },
  {
    content: 'Some stuff',
    onClick: () => logger.log('Some stuff'),
  },
];

const example = () => {
  return (
    <div>
      <div>
        <div>Standard</div>
        <OptionsMenu items={items} />
      </div>

      <div>
        <div>Filtered by content</div>
        <OptionsMenu
          items={items}
          filterItem={(filter, item) =>
            item.content.toLowerCase().includes(filter.toLowerCase())
          }
        />
      </div>
    </div>
  );
};

export default example;
