import gql from 'graphql-tag';
import { useQuery } from 'urql';
import { useQueryParams } from 'use-query-params';

import { mapVariables } from '@/utils';
import { makeOrderBy, makePagination } from '@/utils/pagination';
import {
  PlayerRewardQuery,
  PlayerRewardQueryVariables,
} from './__generated__/usePlayerRewards';
import { queryParams } from './queryParams';

const PLAYER_REWARDS_QUERY = gql`
  query PlayerReward(
    $playerId: ID!
    $rewardStatus: PlayerRewardStatus
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    player(playerId: $playerId) {
      id
      uuid
      playerRewards(
        rewardStatus: $rewardStatus
        first: $first
        last: $last
        after: $after
        before: $before
      ) {
        edges {
          node {
            status
            reason
            updatedAt
            reward {
              id
              rewardId
              name
              createdAt
              createdBy {
                firstName
                lastName
              }
              startsAt
              endsAt
              status
              rewardType {
                name
                __typename
                ... on FreeSpinReward {
                  freeSpinsAmount: amount
                }
                ... on MoneyDropReward {
                  moneyDropAmount: amount
                  currency
                }
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;

export default function usePlayerRewards(playerId: string) {
  const [query, setQuery] = useQueryParams(queryParams);
  const [{ data, fetching }, refresh] = useQuery<
    PlayerRewardQuery,
    PlayerRewardQueryVariables
  >({
    query: PLAYER_REWARDS_QUERY,
    variables: {
      first: 10,
      ...mapVariables(query),
      playerId,
    },
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter: (values: {}) => setQuery(values, 'replaceIn'),
    ...makeOrderBy(query, setQuery),
    ...makePagination(query, setQuery, data?.player.playerRewards.pageInfo),
    player: data?.player,
    playerRewards: data?.player.playerRewards.edges?.map((edge) => edge?.node),
  };
}
