import { useParams } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import { gql, useMutation, useQuery } from 'urql';

import { Locker, MarketingSettings } from '@/bits';
import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  ErrorMessage,
} from '@/components';
import { useTranslate } from '@/contexts';
import { Nullable } from '@/types';
import {
  ChangeMarketingBlockMutation,
  ChangeMarketingBlockMutationVariables,
  PlayerMarketingBlockQuery,
  PlayerMarketingBlockQueryVariables,
} from './__generated__/component';

const BLOCK_STATIC_QUERY = graphql`
  query SanityPlayerMarketingBlock {
    sanityPlayerMarketingBlock {
      title {
        ...SanityLocaleString
      }
    }
  }
`;

const query = gql`
  query PlayerMarketingBlock($playerId: ID!) {
    player(playerId: $playerId) {
      id
      marketingPreferences {
        marketingBlocked
      }
      isEmailMarketingAccepted
      isSmsMarketingAccepted
      isPhoneMarketingAccepted
    }
  }
`;

const changeMarketingBlockMutation = gql`
  mutation ChangeMarketingBlock($playerId: ID!, $isMarketingBlocked: Boolean!) {
    changeMarketingBlockSettings(
      playerId: $playerId
      isMarketingBlocked: $isMarketingBlocked
    ) {
      id
      marketingPreferences {
        marketingBlocked
      }
    }
  }
`;

const PlayerMarketingBlock = () => {
  const { playerId } = useParams();
  const { t } = useTranslate();
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);

  const block =
    useStaticQuery<Queries.SanityPlayerMarketingBlockQuery>(
      BLOCK_STATIC_QUERY,
    ).sanityPlayerMarketingBlock;

  const [{ data, fetching }, refresh] = useQuery<
    PlayerMarketingBlockQuery,
    PlayerMarketingBlockQueryVariables
  >({
    query,
    variables: {
      playerId,
    },
  });

  const isMarketingBlocked = data?.player.marketingPreferences.marketingBlocked;

  const [changePlayerMarketingState, changePlayerMarketingBlock] = useMutation<
    ChangeMarketingBlockMutation,
    ChangeMarketingBlockMutationVariables
  >(changeMarketingBlockMutation);

  if (!block) {
    return null;
  }

  const submitMarketingBlock = () => {
    changePlayerMarketingBlock({
      playerId,
      isMarketingBlocked: !isMarketingBlocked,
    }).then((res) => {
      if (res.error?.message) {
        setErrorMessage(res.error.message);
      } else {
        refresh({ requestPolicy: 'network-only' });
      }
    });
  };

  const isEmailMarketingAccepted = isMarketingBlocked
    ? !!data?.player.isEmailMarketingAccepted
    : data?.player.isEmailMarketingAccepted;

  const isSmsMarketingAccepted = isMarketingBlocked
    ? !!data?.player.isSmsMarketingAccepted
    : data?.player.isSmsMarketingAccepted;

  const isPhoneMarketingAccepted = isMarketingBlocked
    ? !!data?.player.isPhoneMarketingAccepted
    : data?.player.isPhoneMarketingAccepted;

  const marketingSettings = {
    email: isEmailMarketingAccepted,
    sms: isSmsMarketingAccepted,
    phone: isPhoneMarketingAccepted,
  };

  return (
    <Card
      size="md"
      title={t(block.title)}
      options={
        <CardOptions>
          <CardOptionsButton
            disabled={changePlayerMarketingState.fetching}
            className="flex"
            onClick={submitMarketingBlock}
          >
            <Locker isLocked={isMarketingBlocked || false} />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <div
          className={`${
            isMarketingBlocked && 'pointer-events-none opacity-50'
          }`}
        >
          <MarketingSettings
            playerId={playerId}
            fetching={fetching}
            marketingSettings={marketingSettings}
          />
          <ErrorMessage message={errorMessage} />
        </div>
      </CardBody>
    </Card>
  );
};

export default PlayerMarketingBlock;
