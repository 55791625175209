import { gql, useMutation } from 'urql';

import { limitBlockingFragment } from '@/fragments/Limits';
import {
  BlockSessionLimitMutation,
  BlockSessionLimitMutationVariables,
  UnblockSessionLimitMutation,
  UnblockSessionLimitMutationVariables,
} from './__generated__/blocking';

const BLOCK_SESSION_LIMIT = gql`
  mutation BlockSessionLimit($playerId: ID!) {
    blockSessionLimit(playerId: $playerId) {
      id
      sessionLimit {
        ...LimitBlockingStatus
      }
    }
  }
  ${limitBlockingFragment}
`;

const UNBLOCK_SESSION_LIMIT = gql`
  mutation UnblockSessionLimit($playerId: ID!) {
    unblockSessionLimit(playerId: $playerId) {
      id
      sessionLimit {
        ...LimitBlockingStatus
      }
    }
  }
  ${limitBlockingFragment}
`;

export const useBlockSessionLimit = () => {
  return useMutation<
    BlockSessionLimitMutation,
    BlockSessionLimitMutationVariables
  >(BLOCK_SESSION_LIMIT);
};

export const useUnblockSessionLimit = () => {
  return useMutation<
    UnblockSessionLimitMutation,
    UnblockSessionLimitMutationVariables
  >(UNBLOCK_SESSION_LIMIT);
};
