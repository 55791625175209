import { PlayerQuery } from '@/hooks/__generated__/usePlayer';

export const getBrandCodeImage = (
  origin: PlayerQuery['player']['brand'] | { __typename: 'GlobalOrigin' },
) => {
  if (origin.__typename === 'Brand' && origin.code) {
    return `/${origin.code}-maskable.jpg`;
  }

  return '/Globe.png';
};
