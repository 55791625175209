import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { useMemo, useState } from 'react';
import { feedback } from 'react-feedbacker';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'urql';

import { useFeedbackMessages } from '@/bits';
import {
  Card,
  CardBody,
  CountryField,
  DateField,
  EmailField,
  ErrorMessage,
  NakedForm,
  PhoneNumberField,
  SelectField,
  SelectOption,
  SubmitButton,
  TextField,
} from '@/components';
import { useTranslate } from '@/contexts';
import { BrandEnum, CloseAccountCauseV4 } from '@/globalTypes';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import formatDate from '@/utils/formatter/formatDate';
import {
  CreateExcludedPlayerFormBlockBoxQuery,
  CreateExcludedPlayerMutation,
  CreateExcludedPlayerMutationVariables,
} from './__generated__/component';
import { closeAccountCauseMap } from './closeAccountCauseMap';

const BLOCK_STATIC_QUERY = graphql`
  query SanityCreateExcludedPlayerFormBlock {
    sanityCreateExcludedPlayerFormBlock {
      title {
        ...SanityLocaleString
      }
      emailLabel {
        ...SanityLocaleString
      }
      phoneNumberLabel {
        ...SanityLocaleString
      }
      firstNameLabel {
        ...SanityLocaleString
      }
      lastNameLabel {
        ...SanityLocaleString
      }
      dateOfBirthLabel {
        ...SanityLocaleString
      }
      cityLabel {
        ...SanityLocaleString
      }
      streetLabel {
        ...SanityLocaleString
      }
      countryLabel {
        ...SanityLocaleString
      }
      zipCodeLabel {
        ...SanityLocaleString
      }
      brandLabel {
        ...SanityLocaleString
      }
      closeCauseLabel {
        ...SanityLocaleString
      }
      selectBrand {
        ...SanityLocaleString
      }
      submit {
        ...SanityLocaleString
      }
    }
  }
`;

const QUERY = gql`
  query CreateExcludedPlayerFormBlockBox {
    viewer {
      id
      brands {
        code
        name
      }
    }
  }
`;

const CREATE_EXCLUDED_PLAYER = gql`
  mutation CreateExcludedPlayer(
    $phoneNumber: PhoneNumber!
    $birthDate: LocalDate!
    $email: EmailAddress!
    $firstName: String!
    $lastName: String!
    $middleName: String
    $street: String!
    $zipCode: String!
    $city: String!
    $district: String
    $state: String
    $countryCode: String!
    $brand: BrandEnum!
    $closeCause: CloseAccountCauseV4
  ) {
    createPlayerV4(
      phoneNumber: $phoneNumber
      birthDate: $birthDate
      email: $email
      firstName: $firstName
      lastName: $lastName
      middleName: $middleName
      street: $street
      zipCode: $zipCode
      city: $city
      district: $district
      state: $state
      countryCode: $countryCode
      brand: $brand
      closeCause: $closeCause
    ) {
      id
    }
  }
`;

type FormValues = {
  phoneNumber: string;
  birthDate: string;
  email: string;
  firstName: string;
  lastName: string;
  street: string;
  zipCode: string;
  city: string;
  countryCode: string;
  brand: BrandEnum | '';
  closeCause: CloseAccountCauseV4 | '';
};

const CreateExcludedPlayerFormBlock = () => {
  const { t } = useTranslate();
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const feedbackMessages = useFeedbackMessages();
  const isMounted = useIsMounted();

  const [{ data }] = useQuery<CreateExcludedPlayerFormBlockBoxQuery>({
    query: QUERY,
  });

  const [, createExcludedPlayer] = useMutation<
    CreateExcludedPlayerMutation,
    CreateExcludedPlayerMutationVariables
  >(CREATE_EXCLUDED_PLAYER);

  const block =
    useStaticQuery<Queries.SanityCreateExcludedPlayerFormBlockQuery>(
      BLOCK_STATIC_QUERY,
    ).sanityCreateExcludedPlayerFormBlock;

  const brandOptions = useMemo(() => {
    const brandOptions: SelectOption[] =
      data?.viewer.brands.map((brand) => ({
        label: brand.name,
        value: brand.code,
      })) ?? [];

    const options: SelectOption[] = [
      {
        label: t(block?.selectBrand),
        value: '',
      },
      ...brandOptions,
    ];

    return options;
  }, [data, t, block?.selectBrand]);

  const closeCauseOptions = useMemo(
    () => [
      {
        label: 'Not specified',
        value: '',
      },
      ...Object.entries(closeAccountCauseMap).map(([value, label]) => ({
        label,
        value,
      })),
    ],
    [],
  );

  const defaultValues: FormValues = {
    birthDate: '',
    brand: '',
    city: '',
    closeCause: '',
    countryCode: '',
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    street: '',
    zipCode: '',
  };

  const methods = useForm({
    defaultValues,
  });

  const onSubmit = ({ brand, ...values }: FormValues) => {
    if (!brand) {
      return;
    }

    setErrorMessage(null);

    createExcludedPlayer({
      ...values,
      brand,
      birthDate: formatDate(values.birthDate, true),
      closeCause: values.closeCause || null,
    }).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      }
      if (res.data?.createPlayerV4.id && isMounted) {
        feedback.success(t(feedbackMessages.success));
        methods.reset();
      }
    });
  };

  if (!block) {
    return null;
  }

  return (
    <Card size="lg" title={t(block.title)}>
      <CardBody>
        <div className="p-3">
          <NakedForm
            methods={methods}
            onSubmit={onSubmit}
            className="grid gap-3 grid-cols-1 sm:grid-cols-2 md:grid-cols-3"
          >
            <EmailField
              name="email"
              id="create-excluded-player-form-block__email"
              title={t(block.emailLabel)}
              required
            />
            <PhoneNumberField
              name="phoneNumber"
              id="create-excluded-player-form-block__phoneNumber"
              title={t(block.phoneNumberLabel)}
              required
            />
            <TextField
              name="firstName"
              id="create-excluded-player-form-block__firstName"
              title={t(block.firstNameLabel)}
              required
            />
            <TextField
              name="lastName"
              id="create-excluded-player-form-block__lastName"
              title={t(block.lastNameLabel)}
              required
            />
            <DateField
              name="birthDate"
              id="create-excluded-player-form-block__birthDate"
              title={t(block.dateOfBirthLabel)}
              showYearDropdown
              showMonthDropdown
              required
            />
            <TextField
              name="city"
              id="create-excluded-player-form-block__city"
              title={t(block.cityLabel)}
              required
            />
            <TextField
              name="street"
              id="create-excluded-player-form-block__street"
              title={t(block.streetLabel)}
              required
            />
            <CountryField
              name="countryCode"
              id="create-excluded-player-form-block__countryCode"
              title={t(block.countryLabel)}
              required
            />
            <TextField
              name="zipCode"
              id="create-excluded-player-form-block__zipCode"
              title={t(block.zipCodeLabel)}
              required
            />
            <SelectField
              name="brand"
              id="create-excluded-player-form-block__brand"
              title={t(block.brandLabel)}
              options={brandOptions}
              required
            />
            <SelectField
              name="closeCause"
              id="create-excluded-player-form-block__closeCause"
              title={t(block.closeCauseLabel)}
              options={closeCauseOptions}
            />
            <ErrorMessage message={errorMessage} />
            <SubmitButton value={t(block.submit)} />
          </NakedForm>
        </div>
      </CardBody>
    </Card>
  );
};

export default CreateExcludedPlayerFormBlock;
