import React, { FC } from 'react';

import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  Value,
} from '@/components';
import { RefreshIcon } from '@/components/icons';
import formatDate from '@/utils/formatter/formatDate';
import usePayment from './usePayment';

const PaymentsView: FC<{
  rawId: string;
}> = ({ rawId }) => {
  const { fetching, refresh, payment } = usePayment(rawId);

  if (!payment) return null;

  return (
    <Card
      size="lg"
      title="Payment"
      options={
        <CardOptions>
          <CardOptionsButton
            className="flex"
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <div className="p-3">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
            <Value
              fetching={fetching}
              title="Player"
              value={payment.playerId}
            />
            <Value
              fetching={fetching}
              title="Brand"
              value={payment.brand?.name}
            />
            <Value fetching={fetching} title="Amount" value={payment.amount} />
            <Value
              fetching={fetching}
              title="Initial Amount"
              value={payment.initialAmount}
            />
            <Value
              fetching={fetching}
              title="Opening balance"
              value={payment.openingBalance}
            />
            <Value
              fetching={fetching}
              title="Closing balance"
              value={payment.closingBalance}
            />
            <Value
              fetching={fetching}
              title="Payment Method"
              value={payment.paymentMethod}
            />
            <Value
              fetching={fetching}
              title="Payment Status"
              value={payment.paymentStatus}
            />
            <Value
              fetching={fetching}
              title="Payment Status Message"
              value={payment.statusMessage}
            />
            <Value
              fetching={fetching}
              title="Currency"
              value={payment.currency}
            />
            <Value
              fetching={fetching}
              title="Instrument"
              value={payment.instrument}
            />
            <Value
              fetching={fetching}
              title={`Payment Fee (${payment.paymentFee?.money.currency ?? '-'})`}
              value={`${payment.paymentFee?.money.amount}` ?? ''}
            />
            <Value
              fetching={fetching}
              title="Player Session ID"
              value={formatDate(payment.playerSessionId ?? undefined)}
            />
            <Value
              fetching={fetching}
              title="External transition ID"
              value={formatDate(payment.extTransactionId ?? undefined)}
            />
            <Value
              fetching={fetching}
              title="Processed At"
              value={formatDate(payment.processedAt ?? undefined)}
            />
            <Value
              fetching={fetching}
              title="Cancelled At"
              value={formatDate(payment.cancelledAt ?? undefined)}
            />
            <Value
              fetching={fetching}
              title="Rollback At"
              value={formatDate(payment.rollbackAt ?? undefined)}
            />
            <Value
              fetching={fetching}
              title="Initialized At"
              value={formatDate(payment.initializedAt ?? undefined)}
            />
            <Value
              fetching={fetching}
              title="Completed At"
              value={formatDate(payment.completedAt ?? undefined)}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default PaymentsView;
