import { Nullable } from '@/types';

export const isKeyOf = <T extends object>(
  obj: T,
  key: unknown,
): key is keyof T => typeof key === 'string' && key in obj;

export const getProperty = <T extends object>(
  obj: T,
  key: Nullable<string>,
) => {
  return isKeyOf(obj, key) ? obj[key] : undefined;
};
