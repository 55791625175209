import { useParams } from '@reach/router';
import gql from 'graphql-tag';
import { useMemo } from 'react';
import { useQuery } from 'urql';

import { PlayerQuery } from './__generated__/usePlayer';
import {
  PlayerGlobalQuery,
  PlayerGlobalQueryVariables,
} from './__generated__/usePlayerGlobal';

const query = gql`
  query PlayerGlobal($playerGlobalId: ID!) {
    playerGlobal(playerGlobalId: $playerGlobalId) {
      __typename
      id
      players {
        id
        __typename
        uuid
      }
    }
  }
`;

export const usePlayerGlobal = () => {
  const { playerGlobalId } = useParams();

  const [{ fetching, data }] = useQuery<
    PlayerGlobalQuery,
    PlayerGlobalQueryVariables
  >({
    query,
    variables: {
      playerGlobalId,
    },
    pause: !playerGlobalId,
  });

  const results = useMemo(() => {
    return {
      playerGlobalId,
      uuid: data?.playerGlobal.players[0].uuid || '',
      brand: { code: '' } as PlayerQuery['player']['brand'],
    };
  }, [playerGlobalId, fetching]);

  return results;
};
