import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { useController } from 'react-hook-form';

import { BaseInputFieldProps } from './BaseInputField';
import { ErrorMessage } from './ErrorMessage';

export const BaseWrapper: React.FC<
  BaseInputFieldProps & { children: ReactNode }
> = ({ title, required, children, className, name, id }) => {
  const {
    fieldState: { error },
  } = useController({ name });

  return (
    <div className={classNames('w-full', className)}>
      <label
        className={classNames(
          'block tracking-wide text-gray-700 dark:text-white text-sm font-semibold truncate',
          {
            'text-red-600': !!error,
          },
        )}
        htmlFor={id ?? name}
      >
        {title}
        {required && '*'}
      </label>
      {children}
      {error && <ErrorMessage message={error} variant="inline" />}
    </div>
  );
};
