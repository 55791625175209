import React, { FC, ReactNode, createContext, useContext } from 'react';

type DrawerContextValue = {
  isOpen: boolean;
};

const DrawerContext = createContext<DrawerContextValue | undefined>(undefined);

export const useDrawer = () => useContext(DrawerContext);

export const DrawerProvider: FC<{ isOpen: boolean; children: ReactNode }> = ({
  children,
  isOpen,
}) => {
  return (
    <DrawerContext.Provider value={{ isOpen }}>
      {children}
    </DrawerContext.Provider>
  );
};
