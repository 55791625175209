import gql from 'graphql-tag';
import { useQuery } from 'urql';

import { LimitType } from '@/globalTypes';
import { useQueryParamsWithTime } from '@/hooks';
import { mapVariables } from '@/utils';
import { makeOrderBy, makePagination } from '@/utils/pagination';
import {
  PlayerGlobalLimitHistoryQuery,
  PlayerGlobalLimitHistoryQueryVariables,
} from './__generated__/usePlayerGlobalLimitHistory';
import { queryParams } from './queryParams';

const PLAYER_GLOBAL_LIMIT_HISTORY_QUERY = gql`
  query PlayerGlobalLimitHistory(
    $after: String
    $before: String
    $desc: Boolean
    $first: Int
    $last: Int
    $limitPeriodType: LimitTypeEnum
    $limitStatus: LimitStatusEnum
    $limitType: LimitType
    $orderBy: LimitSearchOrder
    $playerId: ID!
  ) {
    player(playerId: $playerId) {
      id
      wallet {
        id
        currency
      }
      globalLimits(
        after: $after
        before: $before
        desc: $desc
        first: $first
        last: $last
        limitPeriodType: $limitPeriodType
        limitStatus: $limitStatus
        limitType: $limitType
        orderBy: $orderBy
      ) {
        edges {
          node {
            limitType
            requestedAt
            availableAt
            confirmedAt
            prevValue
            newValue
            period
            limitStatus
            requestedBy {
              ... on PlayerInitiator {
                player {
                  id
                  firstName
                  lastName
                  email
                }
              }
              ... on AgentInitiator {
                agent {
                  email
                  firstName
                  lastName
                }
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;

export default function usePlayerGlobalLimitHistory(
  playerId: string,
  limitType: LimitType,
) {
  const [query, setQuery] = useQueryParamsWithTime(queryParams);

  const [{ data, fetching }, refresh] = useQuery<
    PlayerGlobalLimitHistoryQuery,
    PlayerGlobalLimitHistoryQueryVariables
  >({
    query: PLAYER_GLOBAL_LIMIT_HISTORY_QUERY,
    variables: {
      playerId,
      limitType,
      ...mapVariables(query),
    },
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter: (values: {}) => setQuery(values, 'replaceIn'),
    ...makeOrderBy(query, setQuery),
    ...makePagination(query, setQuery, data?.player.globalLimits.pageInfo),
    history: data?.player.globalLimits?.edges?.map((edge) => ({
      currency: data?.player.wallet?.currency,
      ...edge?.node,
    })),
  };
}
