import { graphql } from 'gatsby';
import React, { FC } from 'react';

import { Card, CardBody, CardOptions, CardOptionsButton } from '@/components';
import { useDataGrid } from '@/components/DataGrid';
import { booleanFilter, stringFilter } from '@/components/DataGrid/values';
import { NextIcon, PreviousIcon, RefreshIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import useTransactions from './useExpiredKycValidations';

export const Fragment = graphql`
  fragment SanityKycValidationsBlock on SanityKycValidationsBlock {
    title {
      ...SanityLocaleString
    }
  }
`;

const TransactionsBlock: FC<{
  block: Queries.SanityKycValidationsBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();
  const {
    validations,
    fetching,
    nextPage,
    previousPage,
    refresh,
    defaultFilter,
    updateFilter,
  } = useTransactions();

  const { DataGrid, GlobalCardOptions } = useDataGrid({
    name: 'validations',
    data: validations,
    fetching,
    setOrderBy: () => {},
    desc: true,
    defaultFilter,
    updateFilter,
    externalFilters: [
      stringFilter({
        field: '__filterByAmlPercentageAbove',
        filterField: 'filterByAmlPercentageAbove',
        title: 'AML Percentage',
      }),
      stringFilter({
        field: '__aboutToExpireThresholdInDays',
        filterField: 'aboutToExpireThresholdInDays',
        title: 'Days before expire (Default 30)',
      }),
      booleanFilter({
        field: '__ignorePendingValidations',
        filterField: 'ignorePendingValidations',
        title: 'Ignore Pending Validations',
      }),
    ],
    schema: (s) => [
      s.stringValue({
        field: 'email',
        title: 'Email',
      }),
      s.countryValue({
        field: 'countryCode',
        title: 'Country',
        filterField: 'countryCode',
      }),
      s.stringValue({
        field: 'upToDateValidations',
        title: 'Up to Date',
        getValue: ({ row }) => row.upToDateValidations.join(', '),
      }),
      s.stringValue({
        field: 'aboutToExpireValidations',
        title: 'About to Expire',
        getValue: ({ row }) => row.aboutToExpireValidations.join(', '),
      }),
      s.stringValue({
        field: 'expiredValidations',
        title: 'Expired',
        getValue: ({ row }) => row.expiredValidations.join(', '),
      }),
      s.stringValue({
        field: 'pendingValidations',
        title: 'Pending Validations',
        getValue: ({ row }) => row.pendingValidations.join(', '),
      }),
      s.stringValue({
        field: 'pendingDocumentIds',
        title: 'Pending Document IDs',
        getValue: ({ row }) => row.pendingDocumentIds.join(', '),
      }),
      s.stringValue({
        field: 'rejectedDocumentIds',
        title: 'Rejected Document IDs',
        getValue: ({ row }) => row.rejectedDocumentIds.join(', '),
      }),
    ],
  });

  return (
    <Card
      size="lg"
      title={t(block.title)}
      showOptionsAtBottom
      options={
        <CardOptions>
          <CardOptionsButton
            disabled={!previousPage}
            onClick={() => previousPage && previousPage()}
          >
            <PreviousIcon />
          </CardOptionsButton>
          <CardOptionsButton
            disabled={!nextPage}
            onClick={() => nextPage && nextPage()}
          >
            <NextIcon />
          </CardOptionsButton>
          <GlobalCardOptions />
          <CardOptionsButton
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <DataGrid />
      </CardBody>
    </Card>
  );
};

export default TransactionsBlock;
