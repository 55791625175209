import { graphql } from 'gatsby';
import React, { FC, ReactNode, useMemo } from 'react';

import { useLanguage } from '@/hooks';
import { Nullable } from '@/types';
import { ReplaceMacrosParams, replaceMacros } from '@/utils';
import { ContextBuilder } from './ContextBuilder';

export const Fragment = graphql`
  fragment SanityLocaleString on SanityLocaleString {
    en
    sv
  }
`;

export type TranslateFn = (
  localeString: Nullable<Queries.SanityLocaleStringFragment>,
  params?: ReplaceMacrosParams,
) => string;

type TranslateContextValue = {
  t: TranslateFn;
};

const TranslateContext = new ContextBuilder<TranslateContextValue>(
  'TranslateContext',
);

export const useTranslate = TranslateContext.use;

export const TranslateProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [language] = useLanguage();
  const ctx = useMemo(() => {
    const t = (
      localeString: Nullable<Queries.SanityLocaleStringFragment>,
      params?: ReplaceMacrosParams,
    ) => {
      const str = localeString?.[language] ?? localeString?.en;
      if (!str) {
        return 'No translation';
      }
      return params ? replaceMacros(str, params) : str;
    };

    return {
      t,
    };
  }, [language]);

  return (
    <TranslateContext.Context.Provider value={ctx}>
      {children}
    </TranslateContext.Context.Provider>
  );
};
