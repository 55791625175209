import { formatISO } from 'date-fns';
import { useMemo } from 'react';
import { gql, useQuery } from 'urql';

import { RangeDate } from '@/globalTypes';
import { useQueryParamsWithTime } from '@/hooks';
import { mapVariables } from '@/utils';
import { makeOrderBy, makePagination } from '@/utils/pagination';
import {
  SourceOfWealthQuery,
  SourceOfWealthQueryVariables,
} from './__generated__/useSourceOfWealth';
import { queryParams } from './queryParams';

const QUERY = gql`
  query SourceOfWealth(
    $amlLevel: AmlRiskLevel
    $complianceStatus: PlayerComplianceStatus
    $statusChangedAt: RangeDate
    $limitReachedAt: RangeDate
    $submittedAt: RangeDate
    $orderBy: SoWSearchOrder
    $desc: Boolean
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    viewer {
      id
      playerCompliancePlayersV3(
        after: $after
        before: $before
        statusChangedAt: $statusChangedAt
        limitReachedAt: $limitReachedAt
        submittedAt: $submittedAt
        orderBy: $orderBy
        desc: $desc
        amlLevel: $amlLevel
        complianceStatus: $complianceStatus
        first: $first
        last: $last
      ) {
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
        edges {
          node {
            playerGlobalId
            playerGlobalUUID
            risk
            status
            needsManualReview
            startedAt
            statusChangedAt
            limitReachedAt
            submittedAt
            players {
              playerId
              playerUUID
              brand {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export default function useSourceOfWealth() {
  const [query, setQuery] = useQueryParamsWithTime(queryParams, {
    fromAndToOptional: true,
  });

  const statusChangedAt = useMemo((): RangeDate | undefined => {
    if (query.statusChangedAtFrom && query.statusChangedAtTo) {
      return {
        from: formatISO(query.statusChangedAtFrom),
        to: formatISO(query.statusChangedAtTo),
      };
    }

    return undefined;
  }, [query.statusChangedAtFrom, query.statusChangedAtTo]);

  const limitReachedAt = useMemo((): RangeDate | undefined => {
    if (query.limitReachedAtFrom && query.limitReachedAtTo) {
      return {
        from: formatISO(query.limitReachedAtFrom),
        to: formatISO(query.limitReachedAtTo),
      };
    }

    return undefined;
  }, [query.limitReachedAtFrom, query.limitReachedAtTo]);

  const submittedAt = useMemo((): RangeDate | undefined => {
    if (query.from && query.to) {
      return {
        from: formatISO(query.from),
        to: formatISO(query.to),
      };
    }

    return undefined;
  }, [query.from, query.to]);

  const variables = {
    ...mapVariables(query),
    statusChangedAt,
    limitReachedAt,
    submittedAt,
  };

  const [{ data, fetching }, refresh] = useQuery<
    SourceOfWealthQuery,
    SourceOfWealthQueryVariables
  >({
    query: QUERY,
    variables,
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter: (values: {}) => setQuery(values, 'replaceIn'),
    ...makeOrderBy(query, setQuery),
    ...makePagination(
      query,
      setQuery,
      data?.viewer.playerCompliancePlayersV3.pageInfo,
    ),
    entries: data?.viewer.playerCompliancePlayersV3.edges?.map(
      (edge) => edge?.node,
    ),
  };
}
