import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { useMemo, useState } from 'react';
import { useClient, useQuery } from 'urql';

import { DownloadLink, useDownloadLinkState } from '@/bits/download-link';
import {
  Card,
  CardBody,
  Form,
  SelectField,
  SelectOption,
  SubmitButton,
  SwitchField,
} from '@/components';
import { useTranslate } from '@/contexts';
import { BrandEnum } from '@/globalTypes';
import {
  MarketingListReportBoxQuery,
  MarketingListReportQuery,
  MarketingListReportQueryVariables,
} from './__generated__/component';

const BLOCK_STATIC_QUERY = graphql`
  query SanityMarketingListReportBlockStaticQuery {
    sanityMarketingListReportBlock {
      title {
        ...SanityLocaleString
      }
      selectBrand {
        ...SanityLocaleString
      }
      brandLabel {
        ...SanityLocaleString
      }
      isSmsMarketingAccepted {
        ...SanityLocaleString
      }
      submit {
        ...SanityLocaleString
      }
    }
  }
`;

const QUERY = gql`
  query MarketingListReportBox {
    viewer {
      id
      brands {
        code
        name
      }
    }
  }
`;

const REPORT_QUERY = gql`
  query MarketingListReport(
    $brand: BrandEnum
    $isSmsMarketingAccepted: Boolean
  ) {
    viewer {
      id
      exportedMarketingPlayers(
        brand: $brand
        isSmsMarketingAccepted: $isSmsMarketingAccepted
      ) {
        url
      }
    }
  }
`;

type FormValues = {
  brand: BrandEnum | '';
  isSmsMarketingAccepted: boolean | null;
};

const MarketingListReportBlock = () => {
  const [downloadLink, setDownloadLink] = useDownloadLinkState();
  const [isGenerating, setIsGenerating] = useState(false);
  const { t } = useTranslate();
  const client = useClient();
  const [{ data }] = useQuery<MarketingListReportBoxQuery>({
    query: QUERY,
  });

  const block =
    useStaticQuery<Queries.SanityMarketingListReportBlockStaticQueryQuery>(
      BLOCK_STATIC_QUERY,
    ).sanityMarketingListReportBlock;

  const brandOptions = useMemo(() => {
    const brandOptions: SelectOption[] =
      data?.viewer.brands.map((brand) => ({
        label: brand.name,
        value: brand.code,
      })) ?? [];

    const options: SelectOption[] = [
      {
        label: t(block?.selectBrand),
        value: '',
      },
      ...brandOptions,
    ];

    return options;
  }, [data, block?.selectBrand, t]);

  const defaultValues: FormValues = {
    brand: '',
    isSmsMarketingAccepted: null,
  };

  const onSubmit = ({ brand, isSmsMarketingAccepted }: FormValues) => {
    setIsGenerating(true);
    setDownloadLink(undefined);

    client
      .query<MarketingListReportQuery, MarketingListReportQueryVariables>(
        REPORT_QUERY,
        {
          brand: brand || null,
          isSmsMarketingAccepted: isSmsMarketingAccepted ?? null,
        },
        {
          requestPolicy: 'network-only',
        },
      )
      .toPromise()
      .then((res) => {
        const url = res.data?.viewer.exportedMarketingPlayers.url;

        if (url) {
          setDownloadLink(url);
        }
      })
      .finally(() => {
        setIsGenerating(false);
      });
  };

  if (!block) {
    return null;
  }

  return (
    <Card size="lg" title={t(block.title)}>
      <CardBody>
        <div className="p-3">
          <Form
            defaultValues={defaultValues}
            onSubmit={onSubmit}
            className="grid gap-3 mb-2"
          >
            <SelectField
              name="brand"
              id="marketing-list-report-block__brand"
              title={t(block.brandLabel)}
              options={brandOptions}
            />
            <SwitchField
              name="isSmsMarketingAccepted"
              id="marketing-list-report-block__isSmsMarketingAccepted"
              title={t(block.isSmsMarketingAccepted)}
              labelPlacement="above"
            />
            <SubmitButton value={t(block.submit)} disabled={isGenerating} />
          </Form>
          <DownloadLink
            downloadLink={downloadLink}
            filename="marketing-list-report.csv"
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default MarketingListReportBlock;
