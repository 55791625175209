import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { feedback } from 'react-feedbacker';
import { UseFormReturn, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'urql';

import { useFeedbackMessages } from '@/bits';
import {
  Button,
  Card,
  CardCloseButton,
  CardOptions,
  ErrorMessage,
  NakedForm,
  SelectField,
  SubmitButton,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { FundingOption } from '@/globalTypes';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import {
  PlayerRequestDocumentsFormQuery,
  PlayerRequestDocumentsFormQueryVariables,
  RequestDocumentsMutation,
  RequestDocumentsMutationVariables,
} from './__generated__/PlayerRequestDocumentsForm';
import { useFundingOptions } from './useFundingOptions';

const STATIC_QUERY = graphql`
  query SanityPlayerRequestDocumentsForm {
    sanityPlayerRequestDocumentsForm {
      title {
        ...SanityLocaleString
      }
      requestedSowDocumentsLabel {
        ...SanityLocaleString
      }
      quickSelectSalary {
        ...SanityLocaleString
      }
      quickSelectOther {
        ...SanityLocaleString
      }
      submit {
        ...SanityLocaleString
      }
    }
  }
`;

const query = gql`
  query PlayerRequestDocumentsForm($playerId: ID!) {
    player(playerId: $playerId) {
      id
      rawPlayerId
      playerComplianceDetails {
        id
        requiredDocumentTypes
      }
    }
  }
`;

const requestDocumentsMutation = gql`
  mutation RequestDocuments(
    $playerId: ID!
    $requestedSowDocuments: [FundingOption!]!
  ) {
    requestDocuments(
      playerId: $playerId
      requestedSowDocuments: $requestedSowDocuments
    ) {
      id
      playerId
      requiredDocumentTypes
    }
  }
`;

const QuickFundingOptionButton = ({
  methods,
  requestedSowDocuments,
  fundingOption,
  title,
}: {
  methods: UseFormReturn<FormValues>;
  requestedSowDocuments: FundingOption[];
  fundingOption: FundingOption;
  title: string;
}) => (
  <Button
    type="button"
    variant="secondary"
    className="text-sm"
    disabled={requestedSowDocuments.includes(fundingOption)}
    onClick={() =>
      methods.setValue('requestedSowDocuments', [
        ...requestedSowDocuments,
        fundingOption,
      ])
    }
  >
    {title}
  </Button>
);

type FormValues = {
  requestedSowDocuments: FundingOption[];
};

const PlayerRequestDocumentsForm = ({ playerId }: { playerId: string }) => {
  const { t } = useTranslate();
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();
  const { close } = useModalContext();
  const feedbackMessages = useFeedbackMessages();

  const [{ data }] = useQuery<
    PlayerRequestDocumentsFormQuery,
    PlayerRequestDocumentsFormQueryVariables
  >({
    query,
    variables: { playerId },
  });

  const form =
    useStaticQuery<Queries.SanityPlayerRequestDocumentsFormQuery>(
      STATIC_QUERY,
    ).sanityPlayerRequestDocumentsForm;

  const [requestDocumentsState, requestDocuments] = useMutation<
    RequestDocumentsMutation,
    RequestDocumentsMutationVariables
  >(requestDocumentsMutation);

  const fundingOptions = useFundingOptions();

  const methods = useForm<FormValues>({
    defaultValues: {
      requestedSowDocuments:
        data?.player.playerComplianceDetails?.requiredDocumentTypes ?? [],
    },
  });

  const onSubmit = (values: FormValues) => {
    setErrorMessage(null);

    requestDocuments({
      playerId,
      requestedSowDocuments: values.requestedSowDocuments,
    }).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      } else if (close) {
        close();
      }

      if (!res.error) {
        feedback.success(t(feedbackMessages.success));
      }
    });
  };

  if (!form || !data) {
    return null;
  }

  const requestedSowDocuments = methods.watch('requestedSowDocuments') || [];

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">
        <NakedForm methods={methods} onSubmit={onSubmit} className="grid gap-6">
          <div className="space-y-3">
            <SelectField
              options={fundingOptions}
              name="requestedSowDocuments"
              id="PlayerRequestDocumentsForm__requestedSowDocuments"
              title={t(form.requestedSowDocumentsLabel)}
              isMulti
            />
            <div className="grid grid-cols-2 gap-6">
              <QuickFundingOptionButton
                title={t(form.quickSelectSalary)}
                fundingOption={FundingOption.Salary}
                methods={methods}
                requestedSowDocuments={requestedSowDocuments}
              />
              <QuickFundingOptionButton
                title={t(form.quickSelectOther)}
                fundingOption={FundingOption.Other}
                methods={methods}
                requestedSowDocuments={requestedSowDocuments}
              />
            </div>
          </div>

          <ErrorMessage message={errorMessage} />
          <SubmitButton
            value={t(form.submit)}
            disabled={requestDocumentsState.fetching}
          />
        </NakedForm>
      </div>
    </Card>
  );
};

export default PlayerRequestDocumentsForm;
