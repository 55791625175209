import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { feedback } from 'react-feedbacker';
import { useMutation } from 'urql';

import {
  ErrorMessage,
  Form,
  SubmitButton,
  TextareaField,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import { assert } from '@/utils/error';
import {
  RemoveRewardMutation,
  RemoveRewardMutationVariables,
} from './__generated__/RewardRemoveForm';

const query = graphql`
  query SanityRewardRemoveForm {
    sanityRewardRemoveForm {
      comment {
        ...SanityLocaleString
      }
      removePlayer {
        ...SanityLocaleString
      }
      playerRemoved {
        ...SanityLocaleString
      }
    }
  }
`;

const removeRewardMutation = gql`
  mutation RemoveReward(
    $id: String!
    $playerIds: [String!]!
    $comment: String!
  ) {
    removePlayers(id: $id, playerIds: $playerIds, comment: $comment)
  }
`;

type Props = {
  playerId: string;
  rewardId: string;
};

type FormValues = {
  comment: string;
};

const RewardRemoveForm = ({ playerId, rewardId }: Props) => {
  const staticData = useStaticQuery<Queries.SanityRewardRemoveFormQuery>(query);

  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const form = staticData.sanityRewardRemoveForm;

  assert(form, 'missing form data');
  const { t } = useTranslate();
  const isMounted = useIsMounted();
  const { close } = useModalContext();
  const [removeRewardState, removeReward] = useMutation<
    RemoveRewardMutation,
    RemoveRewardMutationVariables
  >(removeRewardMutation);

  const defaultValues: FormValues = {
    comment: '',
  };

  const onSubmit = (values: FormValues) => {
    const variables = {
      id: rewardId,
      comment: values.comment,
      playerIds: [playerId],
    };
    return removeReward(variables).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      } else if (close) {
        feedback.success(t(form.playerRemoved));
        close();
      }
    });
  };

  if (!form) {
    return null;
  }

  return (
    <Form defaultValues={defaultValues} onSubmit={onSubmit} className="grid">
      <div className="p-3">
        <TextareaField
          name="comment"
          title={t(form.comment)}
          id="RewardRemoveForm__comment"
        />
        <ErrorMessage message={errorMessage} />
        <div className="flex justify-end mt-3">
          <SubmitButton
            value={t(form.removePlayer)}
            disabled={removeRewardState.fetching}
          />
        </div>
      </div>
    </Form>
  );
};

export default RewardRemoveForm;
