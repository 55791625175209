import {
  ArrayParam,
  BooleanParam,
  DateTimeParam,
  StringParam,
  withDefault,
} from 'use-query-params';

import { GranularityEnum } from '@/globalTypes';
import { GranularityParam } from '@/utils/query/params';

export enum TimeRangeEnum {
  LastMonth = 'last-month',
  ThisMonth = 'this-month',
  LastWeek = 'last-week',
  ThisWeek = 'this-week',
  Yesterday = 'yesterday',
  Today = 'today',
  Custom = 'custom',
}

const sharedQueryParams = {
  affiliateId: withDefault(BooleanParam, false),
  allWins: withDefault(BooleanParam, false),
  amountAdjustments: withDefault(BooleanParam, false),
  amountAllBonus: withDefault(BooleanParam, false),
  amountBonusAdjustments: withDefault(BooleanParam, false),
  amountCashbacks: withDefault(BooleanParam, false),
  amountDeposits: withDefault(BooleanParam, false),
  amountFailedDeposits: withDefault(BooleanParam, false),
  amountFailedWithdrawals: withDefault(BooleanParam, false),
  amountFtd: withDefault(BooleanParam, false),
  amountFreeSpinWins: withDefault(BooleanParam, false),
  amountMoneyDrops: withDefault(BooleanParam, false),
  amountReversals: withDefault(BooleanParam, false),
  amountWithdrawableWinnings: withDefault(BooleanParam, false),
  amountWithdrawals: withDefault(BooleanParam, false),
  AvgBetSize: withDefault(BooleanParam, false),
  AvgDepositAmount: withDefault(BooleanParam, false),
  AvgDepositPerUser: withDefault(BooleanParam, false),
  AvgRevenuePerUser: withDefault(BooleanParam, false),
  brand: withDefault(BooleanParam, false),
  calculatedBalance: withDefault(BooleanParam, false),
  campaignId: withDefault(BooleanParam, false),
  cashBackEnableLookup: withDefault(BooleanParam, false),
  columns: withDefault(ArrayParam, []),
  currency: withDefault(BooleanParam, false),
  device: withDefault(BooleanParam, false),
  endDate: DateTimeParam,
  exchangeRateBaseCurrency: StringParam,
  exchangeRateDate: DateTimeParam,
  ftd: withDefault(BooleanParam, false),
  ftdOfNrc: withDefault(BooleanParam, false),
  gameDescriptor: withDefault(BooleanParam, false),
  gameId: withDefault(BooleanParam, false),
  gameCategoryLookup: withDefault(BooleanParam, false),
  gameNameLookup: withDefault(BooleanParam, false),
  gameProvider: withDefault(BooleanParam, false),
  ggr: withDefault(BooleanParam, false),
  granularity: withDefault(GranularityParam, GranularityEnum.All),
  isIncognito: withDefault(BooleanParam, false),
  isLiveCasinoGameLookup: withDefault(BooleanParam, false),
  jpc: withDefault(BooleanParam, false),
  margin: withDefault(BooleanParam, false),
  moneyDropType: withDefault(BooleanParam, false),
  NetDeposits: withDefault(BooleanParam, false),
  ngr: withDefault(BooleanParam, false),
  ngrInRewards: withDefault(BooleanParam, false),
  nrc: withDefault(BooleanParam, false),
  nrcByNsc: withDefault(BooleanParam, false),
  nsc: withDefault(BooleanParam, false),
  numAdjustments: withDefault(BooleanParam, false),
  numBlockAccountRequests: withDefault(BooleanParam, false),
  numCancelSelfExclusionRequests: withDefault(BooleanParam, false),
  numCashbacks: withDefault(BooleanParam, false),
  numCloseAccountRequests: withDefault(BooleanParam, false),
  numDeposits: withDefault(BooleanParam, false),
  numFailedDeposits: withDefault(BooleanParam, false),
  numFailedWithdrawals: withDefault(BooleanParam, false),
  numReopenAccountRequests: withDefault(BooleanParam, false),
  numReversals: withDefault(BooleanParam, false),
  numSelfExclusionRequests: withDefault(BooleanParam, false),
  numTotalCloseAccountRequests: withDefault(BooleanParam, false),
  numUniqueActivePlayers: withDefault(BooleanParam, false),
  numUniqueActiveGlobalPlayers: withDefault(BooleanParam, false),
  numUniqueDepositors: withDefault(BooleanParam, false),
  numUniqueActiveDepositors: withDefault(BooleanParam, false),
  numUniquePlayers: withDefault(BooleanParam, false),
  numUniqueSessions: withDefault(BooleanParam, false),
  numWithdrawals: withDefault(BooleanParam, false),
  os: withDefault(BooleanParam, false),
  paymentProvider: withDefault(BooleanParam, false),
  pendingWithdrawals: withDefault(BooleanParam, false),
  playerAccountStatusLookup: withDefault(BooleanParam, false),
  playerCurrentSegment: withDefault(BooleanParam, false),
  residenceCountryCode: withDefault(BooleanParam, false),
  rows: withDefault(ArrayParam, []),
  startDate: DateTimeParam,
  timePeriod: withDefault(BooleanParam, false),
  timeRange: withDefault(StringParam, TimeRangeEnum.ThisMonth),
  timeZone: StringParam,
  turnover: withDefault(BooleanParam, false),
  values: withDefault(ArrayParam, []),
  volatilityLookup: withDefault(BooleanParam, false),
  wagers: withDefault(BooleanParam, false),
};

export const viewerQueryParams = {
  ...sharedQueryParams,
  player: withDefault(BooleanParam, false),
  playerId: withDefault(BooleanParam, false),
};

export const playerQueryParams = sharedQueryParams;
