import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';

import {
  Card,
  CardBody,
  CardCloseButton,
  CardOptions,
  ControlledModal,
  Form,
  InlineIconButton,
  SubmitButton,
} from '@/components';
import { FilterIcon } from '@/components/icons';
import { FilterType } from './types';

export const FilterForm: FC<{
  filters: FilterType[];
  defaultValues: Record<string, any>;
  updateFilters: (values: Record<string, any>) => void;
  children?: ReactNode;
}> = ({ filters, defaultValues, updateFilters, children }) => {
  if (filters.length === 0) {
    return null;
  }

  return (
    <ControlledModal
      content={({ close }) => (
        <Card
          size="lg"
          title="Filter"
          options={
            <CardOptions>
              <CardCloseButton />
            </CardOptions>
          }
        >
          <CardBody className="p-3">
            <Form
              autoFocus
              className={classNames('grid grid-cols-1 gap-3', {
                'md:grid-cols-2': filters.length > 1,
              })}
              defaultValues={filters.reduce(
                (acc, filter) => {
                  if (filter.transformDefaultValues) {
                    return filter.transformDefaultValues(acc);
                  }
                  return acc;
                },
                { ...defaultValues },
              )}
              onSubmit={(values) => {
                const transformedValues = filters.reduce((acc, filter) => {
                  if (filter.transformValues) {
                    return { ...acc, ...filter.transformValues(acc) };
                  }
                  return acc;
                }, values);
                updateFilters(transformedValues);
                close();
              }}
            >
              {filters.map((filter) => {
                return <filter.component key={filter.field} />;
              })}
              <SubmitButton />
            </Form>
          </CardBody>
        </Card>
      )}
    >
      {children ? (
        children
      ) : (
        <InlineIconButton>
          <FilterIcon />
        </InlineIconButton>
      )}
    </ControlledModal>
  );
};
