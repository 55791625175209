import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

import { BaseInputFieldProps } from './BaseInputField';
import { BaseWrapper } from './BaseWrapper';

export const NumberFormatField = ({
  title,
  name,
  suffix,
  ...props
}: BaseInputFieldProps & { showTimeInput?: boolean; suffix?: string }) => {
  const { control } = useFormContext();
  return (
    <BaseWrapper title={title} name={name} {...props}>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field }) => (
          <NumericFormat
            onValueChange={({ floatValue }) => {
              field.onChange(floatValue);
            }}
            value={field.value}
            onBlur={field.onBlur}
            thousandSeparator
            allowLeadingZeros={false}
            suffix={suffix}
          />
        )}
      />
    </BaseWrapper>
  );
};
