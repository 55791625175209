import { DataDef, DataDefProps } from '../types';

export function actionValue<O, T extends Record<string, any>>({
  field,
  getValue,
  ...rest
}: DataDefProps<O, T>): DataDef<O, T> {
  return {
    field,
    ...rest,
    component: ({ row }) => (getValue ? getValue({ row }) : row[field]),
  };
}
