import { gql } from '@urql/core';
import { Link } from 'gatsby';
import React from 'react';
import { useQuery } from 'urql';

import { useGetPlayerOverviewPageLink } from '@/bits/links/useLink';
import { Card, Table } from '@/components';
import {
  PlayerLinkedAccountsQuery,
  PlayerLinkedAccountsQueryVariables,
} from './__generated__/PlayerLinkedAccounts';

const QUERY = gql`
  query PlayerLinkedAccounts($playerId: ID!) {
    player(playerId: $playerId) {
      id
      linkedAccounts {
        playerId
        rawPlayerId
        numberOfLinks
        triggerTypes
        firstName
        lastName
        status
        brand {
          name
        }
        birthDate
        addressLine
        city
        zipCode
      }
    }
  }
`;

const PlayerLinkedAccounts = ({ playerId }: { playerId: string }) => {
  const [{ data }] = useQuery<
    PlayerLinkedAccountsQuery,
    PlayerLinkedAccountsQueryVariables
  >({ query: QUERY, variables: { playerId } });
  const getPlayerOverviewLink = useGetPlayerOverviewPageLink();

  if (!data?.player) {
    return null;
  }

  return (
    <Card size="lg" title="Player Linked Accounts">
      <div className="p-3">
        <Table
          headings={[
            'Player ID',
            'Number of Links',
            'First Name',
            'Last Name',
            'Status',
            'Brand',
            'Birth Date',
            'Address',
            'City',
            'Zip Code',
          ]}
          rows={data.player.linkedAccounts.map((account) => ({
            key: account.playerId,
            data: [
              <Link
                className="w-full h-full p-3 block"
                to={getPlayerOverviewLink(account.playerId) ?? ''}
              >
                {account.rawPlayerId}
              </Link>,
              account.numberOfLinks,
              account.firstName,
              account.lastName,
              account.status,
              account.brand?.name,
              account.birthDate,
              account.addressLine,
              account.city,
              account.zipCode,
            ],
          }))}
        />
      </div>
    </Card>
  );
};

export default PlayerLinkedAccounts;
