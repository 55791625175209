import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useState } from 'react';
import { useMutation } from 'urql';

import {
  Card,
  CardCloseButton,
  CardOptions,
  ErrorMessage,
  Form,
  SubmitButton,
  TextareaField,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { playerSelfExclusionDetailsFragment } from '@/fragments/PlayerSelfExclusionDetails';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import {
  CancelPlayerPendingSelfExclusionMutation,
  CancelPlayerPendingSelfExclusionMutationVariables,
} from './__generated__/PlayerCancelPendingSelfExclusionForm';

const query = graphql`
  query SanityPlayerCancelPendingSelfExclusionForm {
    sanityPlayerCancelPendingSelfExclusionForm {
      title {
        ...SanityLocaleString
      }
      commentLabel {
        ...SanityLocaleString
      }
      submit {
        ...SanityLocaleString
      }
    }
  }
`;

const cancelPendingSelfExclusionMutation = gql`
  mutation CancelPlayerPendingSelfExclusion($playerId: ID!, $comment: String!) {
    cancelPendingSelfExclusionV2(playerId: $playerId, comment: $comment) {
      id
      ...PlayerSelfExclusionDetails
    }
  }
  ${playerSelfExclusionDetailsFragment}
`;

const PlayerCancelPendingSelfExclusionForm: FC<{ playerId: string }> = ({
  playerId,
}) => {
  const staticData =
    useStaticQuery<Queries.SanityPlayerCancelPendingSelfExclusionFormQuery>(
      query,
    );

  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();

  const form = staticData.sanityPlayerCancelPendingSelfExclusionForm;

  const [cancelPendingSelfExclusionState, cancelPendingSelfExclusion] =
    useMutation<
      CancelPlayerPendingSelfExclusionMutation,
      CancelPlayerPendingSelfExclusionMutationVariables
    >(cancelPendingSelfExclusionMutation);

  const defaultValues = {
    comment: '',
  };

  const { close } = useModalContext();

  const { t } = useTranslate();

  const onSubmit = (values: typeof defaultValues) => {
    if (playerId) {
      cancelPendingSelfExclusion({
        playerId,
        comment: values.comment,
      }).then((res) => {
        if (res.error?.message && isMounted) {
          setErrorMessage(res.error?.message);
        } else if (close) {
          close();
        }
      });
    }
  };

  if (!form || !defaultValues) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">
        <Form
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          className="grid grid-cols-1 gap-6"
        >
          <TextareaField
            title={t(form.commentLabel)}
            name="comment"
            id="PlayerCancelPendingSelfExclusionForm__comment"
            rows={3}
          />
          <ErrorMessage message={errorMessage} />
          <SubmitButton
            value={t(form.submit)}
            disabled={cancelPendingSelfExclusionState.fetching}
          />
        </Form>
      </div>
    </Card>
  );
};

export default PlayerCancelPendingSelfExclusionForm;
