import { useParams } from '@reach/router';
import { saveAs } from 'file-saver';
import { graphql } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useMemo, useState } from 'react';
import { useClient, useQuery } from 'urql';

import {
  Card,
  CardBody,
  DateTimeField,
  Form,
  SubmitButton,
} from '@/components';
import { useTranslate } from '@/contexts';
import { BrandEnum } from '@/globalTypes';
import {
  PlayerCashbackQuery,
  PlayerCashbackQueryVariables,
  PlayerCashbackReportQuery,
  PlayerCashbackReportQueryVariables,
} from './__generated__/component';

export const Fragment = graphql`
  fragment SanityPlayerCashbackReportBlock on SanityPlayerCashbackReportBlock {
    title {
      ...SanityLocaleString
    }
    fromLabel {
      ...SanityLocaleString
    }
    toLabel {
      ...SanityLocaleString
    }
    exportPlayerCashback {
      ...SanityLocaleString
    }
  }
`;

const PLAYER_QUERY = gql`
  query PlayerCashback($playerId: ID!) {
    player(playerId: $playerId) {
      id
      uuid
      brand {
        code
      }
    }
  }
`;

const PLAYER_CASHBACK_REPORT_QUERY = gql`
  query PlayerCashbackReport(
    $playerUUID: String!
    $from: OffsetDateTime!
    $to: OffsetDateTime!
    $brand: BrandEnum!
  ) {
    viewer {
      id
      detailedCashbackReport(
        playerUUID: $playerUUID
        from: $from
        to: $to
        brand: $brand
      ) {
        url
      }
    }
  }
`;

type FormValues = {
  from: string;
  to: string;
};

const PlayerCashbackReportBlock: FC<{
  block: Queries.SanityPlayerCashbackReportBlockFragment;
}> = ({ block }) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const { t } = useTranslate();
  const params = useParams();
  const client = useClient();

  const today = useMemo(() => new Date(), []);

  const [{ data }] = useQuery<
    PlayerCashbackQuery,
    PlayerCashbackQueryVariables
  >({
    query: PLAYER_QUERY,
    variables: {
      playerId: params.playerId,
    },
  });

  const defaultValues: FormValues = {
    from: '',
    to: '',
  };

  const onSubmit = ({ from, to }: FormValues) => {
    if (!data?.player.uuid || !from || !to) {
      return;
    }

    setIsGenerating(true);

    client
      .query<PlayerCashbackReportQuery, PlayerCashbackReportQueryVariables>(
        PLAYER_CASHBACK_REPORT_QUERY,
        {
          playerUUID: data?.player.uuid,
          from,
          to,
          brand: data?.player.brand.code as BrandEnum,
        },
        {
          requestPolicy: 'network-only',
        },
      )
      .toPromise()
      .then((res) => {
        const url = res.data?.viewer.detailedCashbackReport.url;

        if (url) {
          saveAs(url, `player-${data?.player.uuid}-cashback-report.csv`);
        }
      })
      .finally(() => {
        setIsGenerating(false);
      });
  };

  if (!block) {
    return null;
  }

  return (
    <Card size="lg" title={t(block.title)}>
      <CardBody>
        <div className="p-3">
          <Form
            defaultValues={defaultValues}
            onSubmit={onSubmit}
            className="grid gap-3"
          >
            <DateTimeField
              name="from"
              id="cashback-report-block__from"
              title={t(block.fromLabel)}
              maxDate={today}
              required
            />
            <DateTimeField
              name="to"
              id="cashback-report-block__to"
              title={t(block.toLabel)}
              maxDate={today}
              required
            />
            <SubmitButton
              value={t(block.exportPlayerCashback)}
              disabled={isGenerating}
            />
          </Form>
        </div>
      </CardBody>
    </Card>
  );
};

export default PlayerCashbackReportBlock;
