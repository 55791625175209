import { graphql, useStaticQuery } from 'gatsby';
import { Maybe } from 'graphiql/dist/types';
import React, { FC } from 'react';

import { ControlledModal, InlineIconButton } from '@/components';
import { EditIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import {
  PlayerMarketingEmailForm,
  PlayerMarketingPhoneForm,
  PlayerMarketingSmsForm,
} from '@/forms';
import { BooleanValue } from '../boolean-value';

const query = graphql`
  query SanityMarketingSettingsBit {
    sanityMarketingSettingsBit {
      acceptsEmailMarketing {
        ...SanityLocaleString
      }
      acceptsSmsMarketing {
        ...SanityLocaleString
      }
      acceptsPhoneMarketing {
        ...SanityLocaleString
      }
    }
  }
`;

type MarketingSettingsType = {
  email: Maybe<boolean>;
  sms: Maybe<boolean>;
  phone: Maybe<boolean>;
};

export const MarketingSettings: FC<{
  playerId: string;
  fetching: boolean;
  marketingSettings: MarketingSettingsType;
}> = ({ playerId, fetching, marketingSettings }) => {
  const { t } = useTranslate();
  const { email, sms, phone } = marketingSettings;

  const bit =
    useStaticQuery<Queries.SanityMarketingSettingsBitQuery>(
      query,
    ).sanityMarketingSettingsBit;

  if (!bit) {
    return null;
  }

  return (
    <div className="p-3 grid grid-cols-1 md:grid-cols-2 gap-2">
      <BooleanValue
        title={t(bit.acceptsEmailMarketing)}
        fetching={fetching}
        value={email}
        suffix={
          <ControlledModal
            content={<PlayerMarketingEmailForm playerId={playerId} />}
          >
            <InlineIconButton>
              <EditIcon />
            </InlineIconButton>
          </ControlledModal>
        }
      />
      <BooleanValue
        title={t(bit.acceptsSmsMarketing)}
        fetching={fetching}
        value={sms}
        suffix={
          <ControlledModal
            content={<PlayerMarketingSmsForm playerId={playerId} />}
          >
            <InlineIconButton>
              <EditIcon />
            </InlineIconButton>
          </ControlledModal>
        }
      />
      <BooleanValue
        title={t(bit.acceptsPhoneMarketing)}
        fetching={fetching}
        value={phone}
        suffix={
          <ControlledModal
            content={<PlayerMarketingPhoneForm playerId={playerId} />}
          >
            <InlineIconButton>
              <EditIcon />
            </InlineIconButton>
          </ControlledModal>
        }
      />
    </div>
  );
};
