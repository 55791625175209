import React from 'react';

import { TimeProvider } from '@/contexts';
import { TimePicker } from './TimePicker';

const Example = () => {
  return (
    <TimeProvider>
      <TimePicker />
    </TimeProvider>
  );
};

export default Example;
