import { useParams } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { gql, useQuery } from 'urql';

import { BooleanValue } from '@/bits';
import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  ControlledModal,
  InlineIconButton,
  Value,
} from '@/components';
import { EditIcon, RefreshIcon, ResetHardIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import {
  PlayerAmlRiskLevelForm,
  PlayerRequestDocumentsForm,
  PlayerVerifySourceOfWealthForm,
} from '@/forms';
import { PlayerRequestSowForm } from '@/forms/player-request-sow-form';
import PlayerSourceOfFundsForm from '@/forms/player-source-of-funds-form/PlayerSourceOfFundsForm';
import { sourceOfFundsOptionLabels } from '@/forms/player-source-of-funds-form/useSourceOfFundsOptions';
import { PlayerComplianceStatus } from '@/globalTypes';
import { usePlayer } from '@/hooks/usePlayer';
import { Nullable } from '@/types';
import formatMoney from '@/utils/formatter/formatMoney';
import { fundingOptionLabels } from '@/utils/funding-options';
import {
  PlayerSowBlockQuery,
  PlayerSowBlockQueryVariables,
} from './__generated__/component';

const BLOCK_STATIC_QUERY = graphql`
  query SanityPlayerSowBlock {
    sanityPlayerSowBlock {
      title {
        ...SanityLocaleString
      }
      risk {
        ...SanityLocaleString
      }
      status {
        ...SanityLocaleString
      }
      needsManualApproval {
        ...SanityLocaleString
      }
      nationality {
        ...SanityLocaleString
      }
      placeOfBirth {
        ...SanityLocaleString
      }
      fundingOptions {
        ...SanityLocaleString
      }
      grossIncomeUpper {
        ...SanityLocaleString
      }
      grossIncomeLower {
        ...SanityLocaleString
      }
      expendingLowerBound {
        ...SanityLocaleString
      }
      expendingUpperBound {
        ...SanityLocaleString
      }
      otherFundingDescription {
        ...SanityLocaleString
      }
      requiredDocumentTypes {
        ...SanityLocaleString
      }
      sourceOfFunds {
        ...SanityLocaleString
      }
      requestSow {
        ...SanityLocaleString
      }
    }
  }
`;

const QUERY = gql`
  query PlayerSowBlock($playerId: ID!) {
    player(playerId: $playerId) {
      id
      rawPlayerId
      wallet {
        currency
      }
      playerComplianceDetails: playerComplianceDetailsV2 {
        id
        needsManualReview
        requiredDocumentTypes
        risk
        status
      }
      providedSourceOfFunds {
        sourceOfFunds {
          sourceOfFundType
          wasProvided
        }
      }
      sourceOfWealth {
        expendingLowerBound
        expendingUpperBound
        fundingOptions
        nationality
        placeOfBirth
        otherFundingDescription
        salaryLowerBound
        salaryUpperBound
        grossIncomeLowerBound
        grossIncomeUpperBound
      }
    }
  }
`;

const getStatusLabel = (status: Nullable<PlayerComplianceStatus>) => {
  if (status === PlayerComplianceStatus.Verified) {
    return 'Approved';
  }

  return status;
};

const PlayerSowBlock = () => {
  const params = useParams();
  const { uuid } = usePlayer();
  const { t } = useTranslate();

  const block =
    useStaticQuery<Queries.SanityPlayerSowBlockQuery>(
      BLOCK_STATIC_QUERY,
    ).sanityPlayerSowBlock;

  const [{ data, fetching }, refresh] = useQuery<
    PlayerSowBlockQuery,
    PlayerSowBlockQueryVariables
  >({
    query: QUERY,
    variables: {
      playerId: params.playerId,
    },
  });

  if (!block) {
    return null;
  }

  const details = data?.player.playerComplianceDetails;
  const currency = data?.player.wallet?.currency;
  const sow = data?.player.sourceOfWealth;
  const providedSourceOfFunds = data?.player.providedSourceOfFunds;

  const getActiveSourcesOfFunds = () => {
    const providedSoF = providedSourceOfFunds?.sourceOfFunds.filter(
      (sof) => sof.wasProvided,
    );

    const renderSof =
      providedSoF &&
      providedSoF
        .slice(0, 2)
        .map((sof) => sourceOfFundsOptionLabels[sof.sourceOfFundType])
        .join(', ');
    return providedSoF && providedSoF.length > 2
      ? `${renderSof} + ${providedSoF.length - 2}`
      : renderSof;
  };

  return (
    <Card
      size="lg"
      title={t(block.title)}
      options={
        <CardOptions>
          <CardOptionsButton
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <div className="p-3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
          <Value
            title={t(block.risk)}
            fetching={fetching}
            value={details?.risk}
            suffix={
              details && (
                <ControlledModal
                  content={
                    params.playerId && (
                      <PlayerAmlRiskLevelForm playerId={params.playerId} />
                    )
                  }
                >
                  <InlineIconButton>
                    <EditIcon />
                  </InlineIconButton>
                </ControlledModal>
              )
            }
          />
          <Value
            title={t(block.status)}
            fetching={fetching}
            value={getStatusLabel(details?.status)}
            suffix={
              <>
                {details && (
                  <ControlledModal
                    content={
                      params.playerId && (
                        <PlayerVerifySourceOfWealthForm
                          playerId={params.playerId}
                        />
                      )
                    }
                  >
                    <InlineIconButton>
                      <EditIcon />
                    </InlineIconButton>
                  </ControlledModal>
                )}
                <ControlledModal
                  content={
                    uuid && (
                      <PlayerRequestSowForm
                        playerUuid={uuid}
                        callback={() =>
                          refresh({ requestPolicy: 'network-only' })
                        }
                      />
                    )
                  }
                >
                  <InlineIconButton tippyContent={t(block.requestSow)}>
                    <ResetHardIcon />
                  </InlineIconButton>
                </ControlledModal>
              </>
            }
          />
          <BooleanValue
            title={t(block.needsManualApproval)}
            fetching={fetching}
            value={details?.needsManualReview}
          />
          <Value
            title={t(block.nationality)}
            fetching={fetching}
            value={sow?.nationality}
          />
          <Value
            title={t(block.placeOfBirth)}
            fetching={fetching}
            value={sow?.placeOfBirth}
          />
          <Value
            title={t(block.fundingOptions)}
            fetching={fetching}
            value={sow?.fundingOptions.join(', ')}
          />
          <Value
            title={t(block.grossIncomeLower)}
            fetching={fetching}
            value={formatMoney(sow?.grossIncomeLowerBound, currency)}
          />
          <Value
            title={t(block.grossIncomeUpper)}
            fetching={fetching}
            value={formatMoney(sow?.grossIncomeUpperBound, currency)}
          />
          <Value
            title={t(block.expendingLowerBound)}
            fetching={fetching}
            value={formatMoney(sow?.expendingLowerBound, currency)}
          />
          <Value
            title={t(block.expendingUpperBound)}
            fetching={fetching}
            value={formatMoney(sow?.expendingUpperBound, currency)}
          />
          <Value
            title={t(block.otherFundingDescription)}
            fetching={fetching}
            value={sow?.otherFundingDescription}
          />
          <Value
            title={t(block.requiredDocumentTypes)}
            fetching={fetching}
            value={details?.requiredDocumentTypes
              .map((value) => fundingOptionLabels[value])
              .join(', ')}
            suffix={
              <ControlledModal
                content={
                  data?.player.id ? (
                    <PlayerRequestDocumentsForm playerId={data.player.id} />
                  ) : null
                }
              >
                <InlineIconButton>
                  <EditIcon />
                </InlineIconButton>
              </ControlledModal>
            }
          />
          <Value
            title={t(block.sourceOfFunds)}
            fetching={fetching}
            value={getActiveSourcesOfFunds()}
            suffix={
              <ControlledModal
                content={
                  data?.player.id ? (
                    <PlayerSourceOfFundsForm playerId={data.player.id} />
                  ) : null
                }
              >
                <InlineIconButton>
                  <EditIcon />
                </InlineIconButton>
              </ControlledModal>
            }
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default PlayerSowBlock;
