import classNames from 'classnames';
import React, { useMemo } from 'react';
import { CSVLink } from 'react-csv';

import { DocumentDownloadIcon } from '@/components/icons';
import formatDate from '@/utils/formatter/formatDate';
import { mapPivot } from '../pivot';
import { VisualizationProps } from '.';

type Pivot = ReturnType<typeof mapPivot>;

export const useCsvData = ({
  mappedData,
  tableHeadings,
}: {
  mappedData: Pivot['mappedData'];
  tableHeadings: Pivot['tableHeadings'];
}) => {
  return useMemo(() => {
    return [
      ...tableHeadings.map((row) =>
        row.flatMap((cell) => [
          (cell.valueConfig
            ? cell.valueConfig.toFormattedValue(cell.value)
            : cell.value) ?? '',
          ...new Array((cell.span ?? 1) - 1).fill(''),
        ]),
      ),
      ...mappedData.map((row) => {
        return row.flatMap((value) => {
          return [
            value?.valueConfig?.toFormattedValue(value.value) ?? '',
            ...new Array((value?.span ?? 1) - 1).fill(''),
          ];
        });
      }),
    ];
  }, [mappedData, tableHeadings]);
};

const DownloadLink = (props: {
  mappedData: Pivot['mappedData'];
  tableHeadings: Pivot['tableHeadings'];
  exportedUrl?: string | null;
}) => {
  const { exportedUrl } = props;
  const now = useMemo(() => new Date(), []);
  const hasData = useMemo(
    () => props.mappedData.length > 0,
    [props.mappedData],
  );
  const csvData = useCsvData(props);

  if (exportedUrl) {
    return (
      <a
        href={exportedUrl}
        className="align-top disabled:opacity-50 disabled:pointer-events-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-800 focus-visible:ring-white inline-flex justify-center items-center rounded-md p-2 text-gray-600 hover:text-gray-700 hover:bg-gray-300 active:bg-gray-400 hover:bg-opacity-30 focus:outline-none dark:text-gray-200 dark:hover:bg-gray-700 dark:active:bg-gray-600 dark:hover:bg-opacity-30"
      >
        <span className="mr-1">Download</span>
        <DocumentDownloadIcon />
      </a>
    );
  }

  if (hasData) {
    return (
      <CSVLink
        filename={`report_${formatDate(now, true)}.csv`}
        className="align-top disabled:opacity-50 disabled:pointer-events-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-800 focus-visible:ring-white inline-flex justify-center items-center rounded-md p-2 text-gray-600 hover:text-gray-700 hover:bg-gray-300 active:bg-gray-400 hover:bg-opacity-30 focus:outline-none dark:text-gray-200 dark:hover:bg-gray-700 dark:active:bg-gray-600 dark:hover:bg-opacity-30"
        data={csvData}
      >
        <span className="mr-1">Download</span>
        <DocumentDownloadIcon />
      </CSVLink>
    );
  }

  return null;
};

const sharedCellClassNames =
  'text-sm p-2 whitespace-nowrap text-black dark:text-gray-200';

const TableVisualization = ({
  columns,
  rows,
  stats,
  values,
  exportedUrl,
}: VisualizationProps) => {
  const { mappedData, tableHeadings } = mapPivot({
    columns,
    rows,
    stats,
    values,
  });

  return (
    <div className="space-y-2">
      <DownloadLink
        exportedUrl={exportedUrl}
        mappedData={mappedData}
        tableHeadings={tableHeadings}
      />

      <div className="overflow-x-auto">
        <table className="w-full text-left border-collapse">
          <thead className="bg-gray-50 dark:bg-gray-900 border-gray-200 dark:border-gray-700 border-b p-2 divide-y divide-gray-200 dark:divide-gray-800">
            {tableHeadings.map((row, i) => (
              <tr
                key={i}
                className="text-sm font-semibold divide-x dark:divide-gray-800"
              >
                {row.map((cell, i2) => {
                  const Component = cell.valueConfig?.Component;

                  return (
                    <th
                      key={i2}
                      className={classNames(
                        sharedCellClassNames,
                        'font-semibold divide-x',
                      )}
                      colSpan={cell.span}
                    >
                      {Component ? (
                        <Component
                          value={cell.value}
                          {...(cell.valueConfig?.staticProps ?? {})}
                        />
                      ) : (
                        <>{cell.value}</>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className="divide-y divide-gray-100 dark:divide-gray-800">
            {mappedData.map((entry, i) => (
              <tr key={i} className="hover:bg-gray-100 dark:hover:bg-gray-700">
                {entry.map((value, i2) => {
                  const Component = value?.valueConfig?.Component;

                  const isHeader = value?.type === 'header';
                  const CellTag = value?.type === 'header' ? 'th' : 'td';

                  return (
                    <CellTag
                      key={i2}
                      className={classNames(
                        sharedCellClassNames,
                        'border border-gray-100 dark:border-gray-700',
                        {
                          'font-semibold': isHeader,
                          'text-gray-800 dark:text-gray-200': !isHeader,
                        },
                      )}
                      colSpan={value?.span}
                    >
                      {value && Component && (
                        <Component
                          value={value.value}
                          {...(value.valueConfig?.staticProps ?? {})}
                        />
                      )}
                    </CellTag>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableVisualization;
