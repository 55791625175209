import gql from 'graphql-tag';
import { useQuery } from 'urql';
import { useQueryParams } from 'use-query-params';

import { mapVariables } from '@/utils';
import { makeOrderBy, makePagination } from '@/utils/pagination';
import {
  ChallengesQuery,
  ChallengesQueryVariables,
} from './__generated__/useChallenges';
import { queryParams } from './queryParams';

const CHALLENGES_QUERY = gql`
  query Challenges(
    $challengeId: String
    $after: String
    $before: String
    $desc: Boolean
    $orderBy: SearchChallengesOrderBy
    $first: Int
  ) {
    viewer {
      id
      challengesV2(
        challengeId: $challengeId
        after: $after
        before: $before
        desc: $desc
        orderBy: $orderBy
        first: $first
      ) {
        edges {
          node {
            challengeUUID
            challengeId
            name
            numberOfPlayers
            status
            startDate
            endDate
            countries
            brands
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;

export default function useChallenges() {
  const [query, setQuery] = useQueryParams(queryParams);
  const [{ data, fetching }, refresh] = useQuery<
    ChallengesQuery,
    ChallengesQueryVariables
  >({
    query: CHALLENGES_QUERY,
    variables: {
      first: 20,
      ...mapVariables(query),
    },
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter: (values: {}) => setQuery(values, 'replaceIn'),
    ...makeOrderBy(query, setQuery),
    ...makePagination(query, setQuery, data?.viewer.challengesV2.pageInfo),
    challenges: data?.viewer.challengesV2?.edges?.map((edge) => edge?.node),
  };
}
