import { useCallback, useState } from 'react';

import { Nullable } from '@/types';
import { sanityClient } from '@/utils/sanityClient';

export const useGames = () => {
  const [data, setData] = useState<Nullable<Game[]>>(null);
  const [loading, setLoading] = useState(false);

  const getGame = useCallback((slug: string) => {
    setLoading(true);

    sanityClient
      .fetch<Game[]>(
        `*[_type == 'game' && enabled == true && visible == true && slug.current == "${slug}"] {
        name,
        gameProvider->,
        gameStudio->,
        "features": gameFeatures[]->meta.en.name,
        "themes": gameThemes[]->meta.en.name,
        "gameCategory": gameCategory->.meta.en.name,
        "studio": gameStudio->.name,
        "slug": slug.current,
        "desktopId": desktopLaunchId,
        "mobileId": mobileLaunchId,
        "thumbnail": thumbnail.thumbnail,
        "thumbnailUpdatedAt": thumbnail.updatedAt,
        "provider": gameProvider->.name
      }
    `,
      )
      .then((resp) => setData(resp))
      .finally(() => setLoading(false));
  }, []);

  return { data, loading, getGame };
};
