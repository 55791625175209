import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useState } from 'react';
import { useMutation, useQuery } from 'urql';

import {
  Card,
  CardCloseButton,
  CardOptions,
  ErrorMessage,
  Form,
  SubmitButton,
  TextField,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import {
  ChangePlayerAlternativeAddressMutation,
  ChangePlayerAlternativeAddressMutationVariables,
  PlayerAlternativeAddressFormQuery,
  PlayerAlternativeAddressFormQueryVariables,
} from './__generated__/PlayerAlternativeAddressForm';

const query = graphql`
  query SanityPlayerAlternativeAddressForm {
    sanityPlayerAlternativeAddressForm {
      title {
        ...SanityLocaleString
      }
      alternativeFirstName {
        ...SanityLocaleString
      }
      alternativeLastName {
        ...SanityLocaleString
      }
      alternativeCity {
        ...SanityLocaleString
      }
      alternativeStreet {
        ...SanityLocaleString
      }
      alternativeState {
        ...SanityLocaleString
      }
      alternativeDistrict {
        ...SanityLocaleString
      }
      submit {
        ...SanityLocaleString
      }
    }
  }
`;

const playerAlternativeAddressQuery = gql`
  query PlayerAlternativeAddressForm($playerId: ID!) {
    player(playerId: $playerId) {
      id
      translatedPersonalInfo {
        firstName
        lastName
        city
        street
        state
        district
      }
    }
  }
`;

const playerAlternativeAddressMutation = gql`
  mutation ChangePlayerAlternativeAddress(
    $playerId: ID!
    $firstName: String!
    $lastName: String!
    $city: String!
    $state: String
    $street: String!
    $district: String
  ) {
    changeTranslatedPersonalInfo(
      playerId: $playerId
      firstName: $firstName
      lastName: $lastName
      city: $city
      street: $street
      state: $state
      district: $district
    ) {
      firstName
      lastName
      city
      street
      state
      district
    }
  }
`;

const PlayerAlternateAddressForm: FC<{ playerId: string }> = ({ playerId }) => {
  const staticData =
    useStaticQuery<Queries.SanityPlayerAlternativeAddressFormQuery>(query);

  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();

  const form = staticData.sanityPlayerAlternativeAddressForm;

  const [{ data }] = useQuery<
    PlayerAlternativeAddressFormQuery,
    PlayerAlternativeAddressFormQueryVariables
  >({
    query: playerAlternativeAddressQuery,
    variables: { playerId },
  });

  const [updatePlayerAlternativeAddressState, updatePlayerAlternativeAddress] =
    useMutation<
      ChangePlayerAlternativeAddressMutation,
      ChangePlayerAlternativeAddressMutationVariables
    >(playerAlternativeAddressMutation);

  const defaultValues = {
    alternativeFirstName: data?.player?.translatedPersonalInfo?.firstName || '',
    alternativeLastName: data?.player?.translatedPersonalInfo?.lastName || '',
    alternativeCity: data?.player?.translatedPersonalInfo?.city || '',
    alternativeStreet: data?.player.translatedPersonalInfo?.street || '',
    alternativeState: data?.player.translatedPersonalInfo?.state,
    alternativeDistrict: data?.player.translatedPersonalInfo?.district,
  };

  const { close } = useModalContext();

  const { t } = useTranslate();

  const onSubmit = (values: typeof defaultValues) => {
    if (!data?.player?.id) {
      return;
    }

    updatePlayerAlternativeAddress({
      playerId: data.player.id,
      firstName: values.alternativeFirstName,
      lastName: values.alternativeLastName,
      city: values.alternativeCity,
      street: values.alternativeStreet,
      state: values.alternativeState,
      district: values.alternativeDistrict,
    }).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      } else if (close) {
        close();
      }
    });
  };

  if (!form || !data) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">
        <Form
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          className="grid gap-6"
        >
          <div className="grid grid-cols-2 gap-6">
            <div>
              <TextField
                title={t(form.alternativeFirstName)}
                name="alternativeFirstName"
                id="PlayerAlternativeAddressForm__alternativeFirstName"
                className="col-span-full"
                required
              />
            </div>
            <div>
              <TextField
                title={t(form.alternativeLastName)}
                name="alternativeLastName"
                id="PlayerAlternativeAddressForm__alternativeLastName"
                className="col-span-full"
                required
              />
            </div>
            <div>
              <TextField
                title={t(form.alternativeCity)}
                name="alternativeCity"
                id="PlayerAlternativeAddressForm__alternativeCity"
                className="col-span-full"
                required
              />
            </div>
            <div>
              <TextField
                title={t(form.alternativeStreet)}
                name="alternativeStreet"
                id="PlayerAlternativeAddressForm__alternativeStreet"
                className="col-span-full"
                required
              />
            </div>
            <div>
              <TextField
                title={t(form.alternativeState)}
                name="alternativeState"
                id="PlayerAlternativeAddressForm__alternativeState"
                className="col-span-full"
              />
            </div>
            <div>
              <TextField
                title={t(form.alternativeDistrict)}
                name="alternativeDistrict"
                id="PlayerAlternativeAddressForm__alternativeDistrict"
                className="col-span-full"
              />
            </div>
          </div>

          <ErrorMessage message={errorMessage} />
          <SubmitButton
            value={t(form.submit)}
            disabled={updatePlayerAlternativeAddressState.fetching}
          />
        </Form>
      </div>
    </Card>
  );
};

export default PlayerAlternateAddressForm;
