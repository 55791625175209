import { saveAs } from 'file-saver';
import { gql, useClient } from 'urql';
import { useQueryParams } from 'use-query-params';

import { mapVariables } from '@/utils';
import {
  TransactionsReportQuery,
  TransactionsReportQueryVariables,
} from './__generated__/useTransactionsReport';
import { queryParams } from './queryParams';

const TRANSACTIONS_REPORT_QUERY = gql`
  query TransactionsReport(
    $adjustmentCategory: AdjustmentCategory
    $adjustmentType: AdjustmentType
    $after: String
    $before: String
    $brand: BrandEnum
    $currency: String
    $desc: Boolean
    $first: Int
    $from: OffsetDateTime
    $gameRoundId: String
    $id: String
    $last: Int
    $maxAmount: PositiveBigDecimal
    $minAmount: PositiveBigDecimal
    $orderBy: TransactionSearchOrder
    $paymentMethod: String
    $paymentStatus: [PaymentStatus!]
    $playerId: ID
    $rawId: String
    $to: OffsetDateTime
    $transactionTypes: [TransactionType!]
  ) {
    viewer {
      id
      exportedTransactionsV2(
        adjustmentCategory: $adjustmentCategory
        adjustmentType: $adjustmentType
        after: $after
        before: $before
        brand: $brand
        currency: $currency
        desc: $desc
        first: $first
        from: $from
        gameRoundId: $gameRoundId
        id: $id
        last: $last
        maxAmount: $maxAmount
        minAmount: $minAmount
        orderBy: $orderBy
        paymentMethod: $paymentMethod
        paymentStatus: $paymentStatus
        playerId: $playerId
        rawId: $rawId
        to: $to
        transactionTypes: $transactionTypes
      ) {
        url
      }
    }
  }
`;

export default function useTransactionsReport() {
  const [query] = useQueryParams(queryParams);
  const client = useClient();

  const generate = async () => {
    return client
      .query<TransactionsReportQuery, TransactionsReportQueryVariables>(
        TRANSACTIONS_REPORT_QUERY,
        mapVariables(query),
      )
      .toPromise()
      .then((res) => {
        const url = res.data?.viewer.exportedTransactionsV2.url;

        if (url) {
          saveAs(url, 'transactions-report.csv');
        }
      });
  };

  return {
    generate,
  };
}
