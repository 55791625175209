import { RouteComponentProps, Router } from '@reach/router';
import { PageProps, graphql, navigate } from 'gatsby';
import React, { useEffect } from 'react';

import { withErrorBoundary } from '@/bits';
import { BaseWrapper, Page } from '@/components';
import { assert } from '@/utils/error';

const NotFound: React.FC<RouteComponentProps> = () => (
  <p>Sorry, nothing here</p>
);

type SanityPageProps = PageProps<Queries.SanityPageQuery['sanityPage']>;

const Template = ({
  data: { sanityPage },
  pageContext: { routerPath },
}: {
  data: { sanityPage: SanityPageProps['data'] };
  pageContext: { routerPath: string };
}) => {
  assert(sanityPage, 'missing sanityPage');

  const redirectPath = sanityPage.redirectToOperatorPage?.path?.current;

  useEffect(() => {
    if (redirectPath) {
      navigate(redirectPath, { replace: true });
    }
  }, [redirectPath]);

  if (sanityPage.redirectToOperatorPage?.path) {
    return null;
  }

  return (
    <BaseWrapper>
      <Router basepath="/" primary={false}>
        <Page page={sanityPage} path={routerPath} />
        <NotFound default />
      </Router>
    </BaseWrapper>
  );
};

export default withErrorBoundary(Template);

export const query = graphql`
  query SanityPage($id: String!) {
    sanityPage(id: { eq: $id }) {
      title
      template {
        ...SanityPageTemplate
      }
      content {
        ...SanityBlockContainer
      }
      redirectToOperatorPage {
        path {
          current
        }
      }
    }
  }
`;
