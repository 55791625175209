import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import React, { FC } from 'react';

import { Card, CardBody, ControlledModal } from '@/components';
import { ActionButton } from '@/components/ActionButton/ActionButton';
import { useTranslate } from '@/contexts';
import {
  PlayerReportBonusRequestForm,
  PlayerReportComplaintsRequestForm,
  PlayerReportRgIndicatorForm,
} from '@/forms';

export const Fragment = graphql`
  fragment SanityPlayerActionsBlock on SanityPlayerActionsBlock {
    title {
      ...SanityLocaleString
    }
    reportBonusRequestLabel {
      ...SanityLocaleString
    }
    reportBonusRequestDescription {
      ...SanityLocaleString
    }
    reportComplaintsLabel {
      ...SanityLocaleString
    }
    reportComplaintsDescription {
      ...SanityLocaleString
    }
    reportRgIndicatorLabel {
      ...SanityLocaleString
    }
    reportRgIndicatorDescription {
      ...SanityLocaleString
    }
  }
`;

const PlayerActionsBlock: FC<{
  block: Queries.SanityPlayerActionsBlockFragment;
}> = ({ block }) => {
  const { playerId } = useParams();
  const { t } = useTranslate();

  const shouldBeDisplayed = process.env.GATSBY_LICENSE === 'MGA';

  if (!shouldBeDisplayed) {
    return null;
  }

  return (
    <Card size="lg" title={t(block.title)}>
      <CardBody className="inline-flex w-full flex-wrap gap-3 p-3">
        <ControlledModal
          content={<PlayerReportBonusRequestForm playerId={playerId} />}
        >
          <ActionButton
            label={t(block.reportBonusRequestLabel)}
            description={t(block.reportBonusRequestDescription)}
            actionType="report"
          />
        </ControlledModal>
        <ControlledModal
          content={<PlayerReportComplaintsRequestForm playerId={playerId} />}
        >
          <ActionButton
            label={t(block.reportComplaintsLabel)}
            description={t(block.reportComplaintsDescription)}
            actionType="report"
          />
        </ControlledModal>
        <ControlledModal
          content={<PlayerReportRgIndicatorForm playerId={playerId} />}
        >
          <ActionButton
            label={t(block.reportRgIndicatorLabel)}
            description={t(block.reportRgIndicatorDescription)}
            actionType="report"
          />
        </ControlledModal>
      </CardBody>
    </Card>
  );
};

export default PlayerActionsBlock;
