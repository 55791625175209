import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import Notes from '@/components/Notes/Notes';

const staticQuery = graphql`
  query StaticGlobalPlayerNotesBlock {
    sanityGlobalPlayerNotesBlock {
      title {
        ...SanityLocaleString
      }
      content {
        ...SanityLocaleString
      }
      priority {
        ...SanityLocaleString
      }
      date {
        ...SanityLocaleString
      }
      orderBy {
        ...SanityLocaleString
      }
      brands {
        ...SanityLocaleString
      }
      createGlobalNote {
        ...SanityLocaleString
      }
      submit {
        ...SanityLocaleString
      }
      noNotesPlaceholder {
        ...SanityLocaleString
      }
    }
  }
`;

const GlobalPlayerNotesBlock = () => {
  const { sanityGlobalPlayerNotesBlock: block } =
    useStaticQuery<Queries.StaticGlobalPlayerNotesBlockQuery>(staticQuery);

  if (!block) {
    return null;
  }

  return <Notes isGlobal block={block} />;
};

export default GlobalPlayerNotesBlock;
