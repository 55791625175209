import { formatISO } from 'date-fns';
import gql from 'graphql-tag';
import { useMemo } from 'react';
import { useQuery } from 'urql';
import { useQueryParams } from 'use-query-params';

import { RangeDate } from '@/globalTypes';
import { mapVariables } from '@/utils';
import { makeOrderBy, makePagination } from '@/utils/pagination';
import {
  WithdrawalApprovalsQuery,
  WithdrawalApprovalsQueryVariables,
} from './__generated__/useWithdrawalApprovals';
import { queryParams } from './queryParams';

const WITHDRAWAL_APPROVALS_QUERY = gql`
  query WithdrawalApprovals(
    $processedAt: RangeDate
    $paymentId: String
    $playerId: String
    $paymentType: PaymentType
    $PaymentIntegration: PaymentIntegration
    $address: String
    $autoDecision: DecisionType
    $manualDecision: DecisionSearchType
    $first: Int
    $last: Int
    $after: String
    $before: String
    $desc: Boolean
    $orderBy: [PaymentApprovalSearchOrder!]
  ) {
    viewer {
      id
      listPaymentApprovals(
        processedAt: $processedAt
        paymentId: $paymentId
        playerId: $playerId
        paymentType: $paymentType
        PaymentIntegration: $PaymentIntegration
        address: $address
        autoDecision: $autoDecision
        manualDecision: $manualDecision
        first: $first
        last: $last
        after: $after
        before: $before
        orderBy: $orderBy
        desc: $desc
      ) {
        edges {
          node {
            paymentId
            playerId
            paymentType
            amount {
              amount
              currency
            }
            autoDecision
            manualDecision
            address
            processedAt
            integration
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;

export const useWithdrawalApprovals = () => {
  const [query, setQuery] = useQueryParams(queryParams);

  const processedAt = useMemo((): RangeDate | undefined => {
    if (query.processedAtfrom && query.processedAtTo) {
      return {
        from: formatISO(query.processedAtfrom),
        to: formatISO(query.processedAtTo),
      };
    }

    return undefined;
  }, [query.processedAtfrom, query.processedAtTo]);

  const variables = {
    ...mapVariables(query),
    first: 10,
    processedAt,
  };

  const [{ data, fetching }, refresh] = useQuery<
    WithdrawalApprovalsQuery,
    WithdrawalApprovalsQueryVariables
  >({
    query: WITHDRAWAL_APPROVALS_QUERY,
    variables,
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter: (values: {}) => setQuery(values, 'replaceIn'),
    ...makeOrderBy(query, setQuery),
    ...makePagination(
      query,
      setQuery,
      data?.viewer.listPaymentApprovals.pageInfo,
    ),
    withdrawalApprovals: data?.viewer.listPaymentApprovals?.edges?.map(
      (edge) => edge?.node,
    ),
  };
};
