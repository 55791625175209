import gql from 'graphql-tag';
import _ from 'lodash';
import { useQuery } from 'urql';

import { useQueryParamsWithTime } from '@/hooks';
import { mapVariables } from '@/utils';
import { makeOrderBy, makePagination } from '@/utils/pagination';
import {
  PlayerSessionsQuery,
  PlayerSessionsQueryVariables,
} from './__generated__/usePlayerSessions';
import { queryParams } from './queryParams';

const PLAYER_GAME_ROUNDS_QUERY = gql`
  query PlayerSessions(
    $sessionId: String
    $countryCode: String
    $playerId: ID
    $initiatorId: String
    $initiatorType: InitiatorTypeEnum
    $brand: BrandEnum
    $userAgent: String
    $status: SessionStatus
    $cause: TerminationCause
    $from: OffsetDateTime
    $to: OffsetDateTime
    $orderBy: SessionSearchOrder
    $desc: Boolean
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    viewer {
      id
      sessionsV2(
        sessionId: $sessionId
        countryCode: $countryCode
        playerId: $playerId
        initiatorId: $initiatorId
        initiatorType: $initiatorType
        brand: $brand
        userAgent: $userAgent
        status: $status
        cause: $cause
        from: $from
        to: $to
        orderBy: $orderBy
        desc: $desc
        before: $before
        after: $after
        first: $first
        last: $last
      ) {
        edges {
          node {
            ip
            uuid
            status
            countryCode
            userAgent
            cause
            createdAt
            processedAt
            initiator {
              ... on PlayerInitiator {
                player {
                  id
                }
              }
              ... on AgentInitiator {
                agent {
                  firstName
                  lastName
                }
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;

export default function usePlayerSessions(playerId: string) {
  const [query, setQuery] = useQueryParamsWithTime(queryParams, {
    fromAndToOptional: true,
  });

  const [{ data, fetching }, refresh] = useQuery<
    PlayerSessionsQuery,
    PlayerSessionsQueryVariables
  >({
    query: PLAYER_GAME_ROUNDS_QUERY,
    variables: {
      ...mapVariables(query),
      ..._.omitBy(
        {
          playerId,
          first: 20,
        },
        _.isUndefined,
      ),
    },
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter: (values: {}) => setQuery(values, 'replaceIn'),
    ...makeOrderBy(query, setQuery),
    ...makePagination(query, setQuery, data?.viewer.sessionsV2.pageInfo),
    sessions: data?.viewer.sessionsV2?.edges?.map((edge) => edge?.node),
  };
}
