import React from 'react';

import { getInitiator } from '@/utils';
import { DataDef, DataDefProps } from '../types';

export function initiatorValue<O, T extends Record<string, any>>({
  field,
  getValue,
  ...rest
}: DataDefProps<O, T>): DataDef<O, T> {
  return {
    field,
    ...rest,
    component: ({ row }) => {
      const value = getValue ? getValue({ row }) : row[field];
      const initiator = getInitiator(value);
      return <>{initiator}</>;
    },
  };
}
