import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useState } from 'react';
import { useMutation } from 'urql';

import {
  Card,
  CardCloseButton,
  CardOptions,
  ErrorMessage,
  Form,
  SubmitButton,
  useModalContext,
} from '@/components';
import { SelectField, TextareaField } from '@/components/form';
import { useTranslate } from '@/contexts';
import {
  RgCraReviewStatus,
  RgCraReviewTriggerArg,
  RgCraSubmitReviewStatus,
  SubmitReviewStatus,
} from '@/globalTypes';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import {
  SubmitRgReviewStatusFormMutation,
  SubmitRgReviewStatusFormMutationVariables,
} from './__generated__/SubmitRgReviewStatusForm';

const query = graphql`
  query SanitySubmitRgReviewStatusForm {
    sanitySubmitRgReviewStatusForm {
      title {
        ...SanityLocaleString
      }
      adjustment {
        ...SanityLocaleString
      }
      note {
        ...SanityLocaleString
      }
      confirm {
        ...SanityLocaleString
      }
    }
  }
`;

const submitReviewStatusMutation = gql`
  mutation SubmitRgReviewStatusForm(
    $playerGlobalId: ID!
    $rgCraReviewStatus: RgCraSubmitReviewStatus!
    $rgCraReviewTrigger: RgCraReviewTriggerArg!
    $comment: String!
  ) {
    rgCraSubmitReview(
      playerGlobalId: $playerGlobalId
      rgCraReviewStatus: $rgCraReviewStatus
      rgCraReviewTrigger: $rgCraReviewTrigger
      comment: $comment
    ) {
      playerGlobalId
      reviewStatusInfo {
        status
      }
      reviewTrigger {
        byRisks {
          regionId
          riskId
        }
        byOverallRiskLevel
      }
    }
  }
`;

type FormValues = {
  submitReviewStatus: RgCraSubmitReviewStatus | '';
  comment: string;
};

const SubmitRgReviewStatusForm: FC<{
  playerGlobalId: string;
  currentReviewStatus: RgCraReviewStatus;
  reviewTrigger: RgCraReviewTriggerArg;
}> = ({ playerGlobalId, currentReviewStatus, reviewTrigger }) => {
  const staticData =
    useStaticQuery<Queries.SanitySubmitRgReviewStatusFormQuery>(query);

  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();
  const form = staticData.sanitySubmitRgReviewStatusForm;

  const [changeRiskReviewStatusState, changeRiskReviewStatus] = useMutation<
    SubmitRgReviewStatusFormMutation,
    SubmitRgReviewStatusFormMutationVariables
  >(submitReviewStatusMutation);

  const defaultValues: FormValues = {
    submitReviewStatus: '',
    comment: '',
  };

  const { close } = useModalContext();
  const { t } = useTranslate();

  const onSubmit = (values: FormValues) => {
    if (!values.submitReviewStatus) {
      return;
    }
    return changeRiskReviewStatus({
      playerGlobalId,
      rgCraReviewStatus: values.submitReviewStatus,
      comment: values.comment,
      rgCraReviewTrigger: reviewTrigger,
    }).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      } else if (close) {
        close();
      }
    });
  };

  return form ? (
    <Card
      size="lg"
      title={`${t(form.title)}: ${currentReviewStatus}`}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">
        <Form
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          className="grid grid-cols-1 sm:grid-cols-1s gap-6"
        >
          <SelectField
            name="submitReviewStatus"
            id="submitRgReviewStatusForm__submitReviewStatus"
            title={t(form.adjustment)}
            options={[
              {
                label: SubmitReviewStatus.ReviewPending,
                value: SubmitReviewStatus.ReviewPending,
              },
              {
                label: SubmitReviewStatus.ReviewRejected,
                value: SubmitReviewStatus.ReviewRejected,
              },
              {
                label: SubmitReviewStatus.ReviewApproved,
                value: SubmitReviewStatus.ReviewApproved,
              },
            ]}
            required
          />
          <TextareaField
            title={t(form.note)}
            name="comment"
            id="submitRgReviewStatusForm__comment"
            className="grid-cols-1"
            required
          />

          <ErrorMessage message={errorMessage} />
          <SubmitButton
            value={t(form.confirm)}
            disabled={changeRiskReviewStatusState.fetching}
          />
        </Form>
      </div>
    </Card>
  ) : null;
};

export default SubmitRgReviewStatusForm;
