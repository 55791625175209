import { navigate } from 'gatsby';
import { debounce } from 'lodash';
import React, { FC, useEffect, useRef } from 'react';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';

import {
  useGetGamePageLink,
  useGetGlobalPlayerOverviewPageLink,
  useGetPlayerOverviewPageLink,
} from '@/bits/links/useLink';
import { usePlayerSearch } from '@/hooks/usePlayerSearch';
import {
  PlayerListValueType,
  generateGlobalPlayersForSearch,
  sortByGlobalPlayerID,
} from '@/utils';
import { makeBrandThumbnail } from '@/utils/makeBrandThumbnail';
import { searchGames } from '../hooks/useGamesSearch';

//TODO: This should be typed as OptionProps<OptionType> once the components are refactored
// Ticket: DEV-261
type TypeMe = any;

type FluidType = {
  aspectRatio?: number;
  src: string;
  sizes?: string;
  srcSet?: string;
} | null;

const getCategory = (value: PlayerListValueType) => {
  if (value.isGlobal) {
    return 'Global Player';
  }
  if (value.playerId && value.playerGlobalId) {
    return 'Player';
  }
  if (value.playerId) {
    return 'Orphan Player';
  }
  return 'Game';
};

const Option: FC<TypeMe> = ({ children, ...props }) => {
  const fluid: FluidType = props.value.isGlobal
    ? { src: '/Globe.png' }
    : props.value.brand
    ? makeBrandThumbnail(props.value.brand)
    : null;

  const gameIcon = props.value.thumbnail;

  return (
    <components.Option {...props}>
      <div className="flex flex-row w-full items-center">
        {gameIcon ? (
          <div>
            <img
              src={`${gameIcon}?u=${props.value.thumbnailUpdatedAt}`}
              alt=""
              loading="lazy"
              className="w-6 h-6 rounded-sm mr-2"
            />
          </div>
        ) : fluid ? (
          <picture>
            <source sizes={fluid.sizes} srcSet={fluid.srcSet} />
            <img
              src={fluid.src}
              alt=""
              loading="lazy"
              className="w-6 h-6 rounded-sm mr-2"
            />
          </picture>
        ) : (
          <div className="w-6 h-6 rounded-sm mr-2 bg-gray-300" />
        )}
        <div className="flex-grow overflow-hidden overflow-ellipsis whitespace-nowrap">
          {children}
        </div>
        <div className="ml-3 text-xs text-gray-500 uppercase">
          {getCategory(props.value)}
        </div>
      </div>
    </components.Option>
  );
};

export const GlobalSearch = () => {
  const getPlayerOverviewPageLink = useGetPlayerOverviewPageLink();
  const getGlobalPlayerOverviewPageLink = useGetGlobalPlayerOverviewPageLink();
  const getGamePageLink = useGetGamePageLink();
  const { searchPlayers } = usePlayerSearch();

  const handleLoadOptions = (
    value: string,
    callback: (options: readonly object[]) => void,
  ) => {
    const trimmedQuery = value.trim();
    const playersPromise = searchPlayers(trimmedQuery);
    const gamesPromise = searchGames(trimmedQuery);

    Promise.all([playersPromise, gamesPromise]).then(
      ([playerRes, gamesRes]) => {
        const playersForList =
          playerRes?.data?.viewer.playersAsYouTypeV2?.edges
            ?.map((edge) => ({
              value: edge?.node,
              label: `${edge?.node.firstName} ${edge?.node.lastName}`,
            }))
            .sort(sortByGlobalPlayerID) || [];

        const finalGames =
          gamesRes?.map((item) => ({
            value: item,
            label: item.name,
          })) || [];

        const finalPlayers = generateGlobalPlayersForSearch(
          playersForList ?? [],
        );

        callback([...finalPlayers, ...finalGames]);
      },
    );
  };

  const handleInputChangeRef = useRef(debounce(handleLoadOptions, 100)).current;

  useEffect(() => {
    return () => {
      handleInputChangeRef.cancel();
    };
  }, [handleInputChangeRef]);

  const onChange = ({ value }: any) => {
    if (value.isGlobal) {
      const link = getGlobalPlayerOverviewPageLink(value.playerGlobalId);
      if (link) {
        return navigate(link);
      }
    }
    if (value.playerId) {
      const link = getPlayerOverviewPageLink(value.playerId);
      if (link) {
        return navigate(link);
      }
    }

    if (value.slug) {
      const link = getGamePageLink(value.slug);

      if (link) {
        return navigate(link);
      }
    }
    return;
  };

  return (
    <AsyncSelect
      className="w-full md:w-96"
      components={{ Option }}
      placeholder="Search..."
      cacheOptions
      loadOptions={handleInputChangeRef}
      onChange={onChange}
    />
  );
};
