import type {
  DraggableSyntheticListeners,
  UniqueIdentifier,
} from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, {
  CSSProperties,
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
} from 'react';

import { DragDotsIcon } from '../icons';

type Props = {
  id: UniqueIdentifier;
  className?: string;
};

type DragHandleProps = {
  className?: string;
};

type Context = {
  attributes: Record<string, any> | null;
  listeners: DraggableSyntheticListeners;
  ref(node: HTMLElement | null): void;
};

export const SortableItemContext = createContext<Context>({
  attributes: null,
  listeners: undefined,
  ref() {},
});

export const SortableItem = ({
  children,
  id,
  className,
}: PropsWithChildren<Props>) => {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({ id });
  const context = useMemo(
    () => ({
      attributes,
      listeners,
      ref: setActivatorNodeRef,
    }),
    [attributes, listeners, setActivatorNodeRef],
  );
  const style: CSSProperties = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <SortableItemContext.Provider value={context}>
      <div
        className={
          className
            ? className
            : 'flex justify-between grow p-3 border-2 rounded list-none bg-white space-x-2'
        }
        ref={setNodeRef}
        style={style}
      >
        {children}
      </div>
    </SortableItemContext.Provider>
  );
};

export const DragHandle = ({
  className,
}: PropsWithChildren<DragHandleProps>) => {
  const { attributes, listeners, ref } = useContext(SortableItemContext);

  return (
    <button
      className={
        className ? className : 'flex justify-center items-center touch-none'
      }
      {...attributes}
      {...listeners}
      ref={ref}
    >
      <DragDotsIcon />
    </button>
  );
};
