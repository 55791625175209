import flag from 'country-code-emoji';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export default function formatValue(
  v?: string | null,
  showCountryFlag = true,
): string {
  const phoneNumber = v ? parsePhoneNumberFromString(v) : null;

  const countryFlag =
    showCountryFlag && phoneNumber?.country
      ? flag(phoneNumber.country)
      : undefined;

  return phoneNumber
    ? [countryFlag, phoneNumber.formatInternational()].filter(Boolean).join(' ')
    : '-';
}
