import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import { feedback } from 'react-feedbacker';
import { gql, useMutation } from 'urql';

import {
  Card,
  CardCloseButton,
  CardOptions,
  ErrorMessage,
  Form,
  SubmitButton,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import { assert } from '@/utils/error';
import {
  RemoveChallengeMutation,
  RemoveChallengeMutationVariables,
} from './__generated__/ChallengeRemoveForm';

const query = graphql`
  query SanityChallengeRemoveForm {
    sanityChallengeRemoveForm {
      title {
        ...SanityLocaleString
      }
      description {
        ...SanityLocaleString
      }
      submit {
        ...SanityLocaleString
      }
    }
  }
`;

const removeChallengeMutation = gql`
  mutation RemoveChallenge($challengeId: String!) {
    removeChallenge(challengeId: $challengeId)
  }
`;

type Props = {
  challengeId: string;
};

const ChallengeRemoveForm = ({ challengeId }: Props) => {
  const staticData =
    useStaticQuery<Queries.SanityChallengeRemoveFormQuery>(query);
  const form = staticData.sanityChallengeRemoveForm;
  assert(form, 'missing form data');
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const { t } = useTranslate();
  const isMounted = useIsMounted();
  const { close } = useModalContext();

  const [removeChallengeState, removeChallenge] = useMutation<
    RemoveChallengeMutation,
    RemoveChallengeMutationVariables
  >(removeChallengeMutation);

  const defaultValues = {
    challengeId,
  };

  const onSubmit = () => {
    const variables = {
      challengeId,
    };

    return removeChallenge(variables).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      } else if (close) {
        feedback.success('Challenge removed');
        close();
      }
    });
  };

  if (!form) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="flex p-6">
        <Form
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          className="grid gap-3"
        >
          <div className="text-gray-400 dark:text-white">
            {t(form.description)}
          </div>
          <ErrorMessage message={errorMessage} />
          <SubmitButton
            value={t(form.submit)}
            disabled={removeChallengeState.fetching}
          />
        </Form>
      </div>
    </Card>
  );
};

export default ChallengeRemoveForm;
