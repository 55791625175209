import { formatISO } from 'date-fns';
import { saveAs } from 'file-saver';
import { useMemo, useState } from 'react';
import { gql, useClient } from 'urql';

import { RangeDate } from '@/globalTypes';
import { useQueryParamsWithTime } from '@/hooks';
import { mapVariables } from '@/utils';
import {
  RgRiskAssessmentReportQuery,
  RgRiskAssessmentReportQueryVariables,
} from './__generated__/useRgRiskAssessmentReport';
import { queryParams } from './queryParams';

const RG_RISK_ASSESSMENT_REPORT = gql`
  query RgRiskAssessmentReport(
    $playerGlobalId: ID
    $rgCraRiskLevels: [RgCraRiskLevel!]
    $rgCraReviewStatus: [RgCraReviewStatus!]
    $calculatedAt: RangeDate
    $lastReviewedAt: RangeDate
    $orderBy: RgCraRiskAggregationOrderBy
    $desc: Boolean
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    viewer {
      id
      rgCraRiskAssessmentsReport(
        playerGlobalId: $playerGlobalId
        rgCraRiskLevels: $rgCraRiskLevels
        rgCraReviewStatus: $rgCraReviewStatus
        calculatedAt: $calculatedAt
        lastReviewedAt: $lastReviewedAt
        orderBy: $orderBy
        desc: $desc
        before: $before
        after: $after
        first: $first
        last: $last
      ) {
        url
      }
    }
  }
`;

export default function useRgRiskAssessmentReport() {
  const [query] = useQueryParamsWithTime(queryParams, {
    fromAndToOptional: true,
  });

  const [fetching, setFetching] = useState(false);

  const calculatedAt = useMemo((): RangeDate | undefined => {
    if (query.from && query.to) {
      return {
        from: formatISO(query.from),
        to: formatISO(query.to),
      };
    }

    return undefined;
  }, [query.from, query.to]);

  const client = useClient();

  const generate = async () => {
    setFetching(true);

    return client
      .query<RgRiskAssessmentReportQuery, RgRiskAssessmentReportQueryVariables>(
        RG_RISK_ASSESSMENT_REPORT,
        { ...mapVariables(query), calculatedAt },
      )
      .toPromise()
      .then((res) => {
        const url = res.data?.viewer.rgCraRiskAssessmentsReport.url;

        if (url) {
          saveAs(url, 'rg-cra-risk-assessments-report.csv');
        }

        setFetching(false);
      });
  };

  return {
    generate,
    fetching,
  };
}
