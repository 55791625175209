import { graphql, useStaticQuery } from 'gatsby';
import React, { useRef } from 'react';

import { useTranslate } from '@/contexts';
import { getOperatorFromEnv } from '@/utils/operators';

const BLOCK_STATIC_QUERY = graphql`
  query SanityMarketingFinanceEmbedBlockStaticQuery {
    sanityMarketingFinanceEmbedBlock {
      title {
        ...SanityLocaleString
      }
      noEmbedUrlConfigured {
        ...SanityLocaleString
      }
    }
  }
`;

const MarketingFinanceEmbedBlock = () => {
  const { t } = useTranslate();
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const operator = getOperatorFromEnv();

  const block =
    useStaticQuery<Queries.SanityMarketingFinanceEmbedBlockStaticQueryQuery>(
      BLOCK_STATIC_QUERY,
    ).sanityMarketingFinanceEmbedBlock;

  const embedUrl = operator?.marketingAndFinanceEmbedUrl;

  if (!block) {
    return null;
  }

  const title = t(block.title);

  return (
    <div className="col-span-full">
      {embedUrl ? (
        <iframe
          src={embedUrl}
          title={title}
          width="100%"
          height={800}
          ref={iframeRef}
        />
      ) : (
        <div className="p-3 text-black dark:text-white">
          {t(block.noEmbedUrlConfigured)}
        </div>
      )}
    </div>
  );
};

export default MarketingFinanceEmbedBlock;
