import React, { FC, ReactNode } from 'react';

import { ContextBuilder } from './ContextBuilder';

const PageContext = new ContextBuilder<Queries.SanityPageQuery['sanityPage']>(
  'PageContext',
);

export const usePage = PageContext.use;

export const PageProvider: FC<{
  page: Queries.SanityPageQuery['sanityPage'];
  children: ReactNode;
}> = ({ children, page }) => {
  return (
    <PageContext.Context.Provider value={page}>
      {children}
    </PageContext.Context.Provider>
  );
};
