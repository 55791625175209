import { graphql } from 'gatsby';
import React, { ComponentType, FC, Fragment, ReactNode, lazy } from 'react';

import { logger } from '@/utils';
import { ErrorBoundary } from './../bits';
import { OUTLET_SANITY_TYPE } from './outlet-block/constants';

type BlockTypeMap = Record<
  string,
  ComponentType<{ block: any }> | ComponentType<{ block: any }>
>;

// prettier-ignore
const typeNameToComponent: BlockTypeMap = {
  SanityActivityMapBlock: lazy(() => import('./activity-map-block/component')),
  SanityAnalyticsBlock: lazy(() => import('./analytics-block/component')),
  SanityBulkAdjustmentsBlock: lazy(() => import('./bulk-adjustments-block/component')),
  SanityCashbackReportBlock: lazy(() => import('./cashback-report-block/component')),
  SanityChallengeCreateBlock: lazy(() => import('./challenge-create-block/component')),
  SanityChallengeDetailBlock: lazy(() => import('./challenge-detail-block/components')),
  SanityChallengesHistoryBlock: lazy(() => import('./challenge-history-block/component')),
  SanityClosingBalancesReportBlock: lazy(() => import('./closing-balances-report-block/component')),
  SanityCreateExcludedPlayerFormBlock: lazy(() => import('./create-excluded-player-form-block/component')),
  SanityGameInfoBlock: lazy(() => import('./game-info-block/component')),
  SanityGenericBreadcrumbsBlock: lazy(() => import('./generic-breadcrumbs-block/component')),
  SanityGlobalPlayerActivityInfoBlock: lazy(() => import('./global-player-activity-info-block/component')),
  SanityGlobalPlayerDocumentsBlock: lazy(() => import('./global-player-documents-block/component')),
  SanityGlobalPlayerInfoBlock: lazy(() => import('./global-player-info-block/component')),
  SanityGlobalPlayerKycOverviewBlock: lazy(() => import('./global-player-kyc-overview-block/component')),
  SanityGlobalPlayerNotesBlock: lazy(() => import('./global-player-notes-block/component')),
  SanityGlobalPlayerPaymentsBlock: lazy(() => import('./global-player-payments-block/component')),
  SanityGlobalPlayerQuickOverviewBlock: lazy(() => import('./global-player-quick-overview-block/component')),
  SanityGlobalPlayerSowBlock: lazy(() => import('./global-player-sow-block/component')),
  SanityGlobalPlayerSowHistoryBlock: lazy(() => import('./global-player-sow-history-block/component')),
  SanityGlobalPlayerWalletBlock: lazy(() => import('./global-player-wallet-block/component')),
  SanityKpiMetricsBlock: lazy(() => import('./kpi-metrics-block/component')),
  SanityKycPendingFlowsBlock: lazy(() => import('./kyc-pending-flows-block/component')),
  SanityKycValidationsBlock: lazy(() => import('./kyc-validations-block/component')),
  SanityLinkedAccountsBlock: lazy(() => import('./linked-accounts-block/component')),
  SanityMarketingFinanceEmbedBlock: lazy(() => import('./marketing-finance-embed-block/component')),
  SanityMarketingListReportBlock: lazy(() => import('./marketing-list-report-block/component')),
  SanityPaymentInspectionBlock: lazy(() => import('./payment-inspection-block/component')),
  SanityPaymentsBlock: lazy(() => import('./payments-block/component')),
  SanityPlayerActionsBlock: lazy(() => import('./player-actions-block/components')),
  SanityPlayerActiveBonusBlock: lazy(() => import('./player-active-bonus-block/component')),
  SanityPlayerActivityInfoBlock: lazy(() => import('./player-activity-info-block/component')),
  SanityPlayerAdjustmentsBlock: lazy(() => import('./player-adjustments-block/component')),
  SanityPlayerBonusesBlock: lazy(() => import('./player-bonuses-block/component')),
  SanityPlayerBreadcrumbsBlock: lazy(() => import('./player-breadcrumbs-block/component')),
  SanityPlayerCashbackReportBlock: lazy(() => import('./player-cashback-report-block/component')),
  SanityPlayerChallengeBlock: lazy(() => import('./player-challenge-block/component')),
  SanityPlayerDepositLimitsBlock: lazy(() => import('./player-deposit-limits-block/component')),
  SanityPlayerDocumentsBlock: lazy(() => import('./player-documents-block/component')),
  SanityPlayerGameRoundInspectionBlock: lazy(() => import('./player-game-round-inspection-block/component')),
  SanityPlayerGameRoundsBlock: lazy(() => import('./player-game-rounds-block/component')),
  SanityPlayerGamesBlock: lazy(() => import('./player-games-block/component')),
  SanityPlayerInfoBlock: lazy(() => import('./player-info-block/component')),
  SanityPlayerInfoHistoryBlock: lazy(() => import('./player-info-history-block/component')),
  SanityPlayerKycHistoryBlock: lazy(() => import('./player-kyc-history-block/component')),
  SanityPlayerKycOverviewBlock: lazy(() => import('./player-kyc-overview-block/component')),
  SanityPlayerLimitHistoryBlock: lazy(() => import('./player-limits-history-block/component')),
  SanityPlayerLossLimitsBlock: lazy(() => import('./player-loss-limits-block/component')),
  SanityPlayerGlobalLossLimitsBlock: lazy(() => import('./player-global-loss-limits-block/component')),
  SanityPlayerGlobalLimitHistoryBlock: lazy(() => import('./player-global-limits-history-block/component')),
  SanityPlayerMarketingBlock: lazy(() => import('./player-marketing-block/component')),
  SanityPlayerNotesBlock: lazy(() => import('./player-notes-block/component')),
  SanityPlayerPaymentsBlock: lazy(() => import('./player-payments-block/component')),
  SanityPlayerPepAndSanctionsReportBlock: lazy(() => import('./player-pep-and-sanctions-report-block/component')),
  SanityPlayerPhoneLoginsBlock: lazy(() => import('./player-phone-logins-block/component')),
  SanityPlayerQuickOverviewBlock: lazy(() => import('./player-quick-overview-block/component')),
  SanityPlayerRewardsActivityInfoBlock: lazy(() => import('./player-rewards-activity-info-block/component')),
  SanityPlayerRewardsBlock: lazy(() => import('./player-rewards-block/component')),
  SanityPlayerRewardsNgrBlock: lazy(() => import('./player-rewards-ngr-block/component')),
  SanityPlayerRgAutomationHistoryBlock: lazy(() => import('./player-rg-automation-history-block/component')),
  SanityPlayerRgBlocksOverviewBlock: lazy(() => import('./player-rg-blocks-overview-block/component')),
  SanityPlayerRgRiskAssessmentHistoryBlock: lazy(() => import('./player-rg-risk-assessment-history-block/component')),
  SanityPlayerRgRiskAssessmentOverviewBlock: lazy(() => import('./player-rg-risk-assessment-overview-block/component')),
  SanityPlayerRiskAssessmentHistoryBlock: lazy(() => import('./player-risk-assessment-history-block/component')),
  SanityPlayerRiskAssessmentOverviewBlock: lazy(() => import('./player-risk-assessment-overview-block/component')),
  SanityPlayerSearchResultBlock: lazy(() => import('./player-search-result-block/component')),
  SanityPlayerSelfExclusionBlock: lazy(() => import('./player-self-exclusion-block/component')),
  SanityPlayerSelfExclusionHistoryBlock: lazy(() => import('./player-self-exclusion-history-block/component')),
  SanityPlayerSelfExclusionReportBlock: lazy(() => import('./player-self-exclusion-report-block/component')),
  SanityPlayerSessionLimitsBlock: lazy(() => import('./player-session-limits-block/component')),
  SanityPlayerSessionsBlock: lazy(() => import('./player-sessions-block/component')),
  SanityPlayerSowBlock: lazy(() => import('./player-sow-block/component')),
  SanityPlayerSowHistoryBlock: lazy(() => import('./player-sow-history-block/component')),
  SanityPlayerTransactionsBlock: lazy(() => import('./player-transactions-block/component')),
  SanityPlayerWagerLimitsBlock: lazy(() => import('./player-wager-limits-block/component')),
  SanityPlayerWalletBlock: lazy(() => import('./player-wallet-block/component')),
  SanityPlayersCsvGeneratorBlock: lazy(() => import('./players-csv-generator-block/component')),
  SanityRewardDetailBlock: lazy(() => import('./reward-detail-block/component')),
  SanityRewardsHistoryBlock: lazy(() => import('./reward-history-block/component')),
  SanityRgRiskAssessmentBlock: lazy(() => import('./rg-risk-assessment-block/component')),
  SanityRiskAssessmentBlock: lazy(() => import('./risk-assessment-block/component')),
  SanitySourceOfWealthBlock: lazy(() => import('./source-of-wealth-block/component')),
  SanityTimeChartBlock: lazy(() => import('./time-chart-block/component')),
  SanityTransactionInspectionBlock: lazy(() => import('./transaction-inspection-block/component')),
  SanityTransactionsBlock: lazy(() => import('./transactions-block/component')),
  SanityWithdrawalApprovalsBlock: lazy(() => import('./withdrawal-approvals-block/component')),
};

export const ContentFragmentDeclarations = graphql`
  fragment SanityBlockContainer on SanityBlockContainer {
    blocksArray {
      __typename
      ...SanityChallengeCreateBlock
      ...SanityChallengeDetailBlock
      ...SanityChallengesHistoryBlock
      ...SanityGenericBreadcrumbsBlock
      ...SanityGlobalPlayerActivityInfoBlock
      ...SanityGlobalPlayerInfoBlock
      ...SanityGlobalPlayerKycOverviewBlock
      ...SanityGlobalPlayerPaymentsBlock
      ...SanityGlobalPlayerQuickOverviewBlock
      ...SanityGlobalPlayerSowBlock
      ...SanityGlobalPlayerSowHistoryBlock
      ...SanityGlobalPlayerWalletBlock
      ...SanityKpiMetricsBlock
      ...SanityKycPendingFlowsBlock
      ...SanityKycValidationsBlock
      ...SanityPaymentInspectionBlock
      ...SanityPaymentsBlock
      ...SanityPlayerActionsBlock
      ...SanityPlayerActiveBonusBlock
      ...SanityPlayerActivityInfoBlock
      ...SanityPlayerAdjustmentsBlock
      ...SanityPlayerBonusesBlock
      ...SanityPlayerBreadcrumbsBlock
      ...SanityPlayerCashbackReportBlock
      ...SanityPlayerChallengeBlock
      ...SanityPlayerDepositLimitsBlock
      ...SanityPlayerGameRoundInspectionBlock
      ...SanityPlayerGameRoundsBlock
      ...SanityPlayerGamesBlock
      ...SanityPlayerInfoBlock
      ...SanityPlayerInfoHistoryBlock
      ...SanityPlayerKycHistoryBlock
      ...SanityPlayerKycOverviewBlock
      ...SanityPlayerLimitsHistoryBlock
      ...SanityPlayerLossLimitsBlock
      ...SanityPlayerGlobalLossLimitsBlock
      ...SanityPlayerGlobalLimitHistoryBlock
      ...SanityPlayerPaymentsBlock
      ...SanityPlayerPhoneLoginsBlock
      ...SanityPlayerQuickOverviewBlock
      ...SanityPlayerRewardsActivityInfoBlock
      ...SanityPlayerRewardsBlock
      ...SanityPlayerRewardsNgrBlock
      ...SanityPlayerRgAutomationHistoryBlock
      ...SanityPlayerRgBlocksOverviewBlock
      ...SanityPlayerRgRiskAssessmentHistoryBlock
      ...SanityPlayerRgRiskAssessmentOverviewBlock
      ...SanityPlayerRiskAssessmentHistoryBlock
      ...SanityPlayerRiskAssessmentOverviewBlock
      ...SanityPlayerSearchResultBlock
      ...SanityPlayerSelfExclusionHistoryBlock
      ...SanityPlayerSessionsBlock
      ...SanityPlayerSowHistoryBlock
      ...SanityPlayerTransactionsBlock
      ...SanityPlayerWagerLimitsBlock
      ...SanityPlayerWalletBlock
      ...SanityPlayersCsvGeneratorBlock
      ...SanityRewardDetailBlock
      ...SanityRewardsHistoryBlock
      ...SanityTimeChartBlock
      ...SanityTransactionInspectionBlock
      ...SanityTransactionsBlock
      ...SanityWithdrawalApprovalsBlock
    }
  }
`;

export const BlockRenderer: FC<{
  blockContainer: Queries.SanityBlockContainerFragment;
  outletContent?: ReactNode;
}> = ({ blockContainer, outletContent }) => {
  const blocks = blockContainer?.blocksArray?.concat();
  return (
    <>
      {blocks &&
        blocks.reduce<ReactNode[]>((acc, block, i) => {
          if (block?.__typename === OUTLET_SANITY_TYPE) {
            if (outletContent) {
              acc.push(
                <Fragment key={`${i}_outlet`}>{outletContent}</Fragment>,
              );
            }
          } else {
            const Component = block && typeNameToComponent[block.__typename];
            if (Component) {
              acc.push(
                <ErrorBoundary key={`${i}_block`}>
                  <Component block={block} />
                </ErrorBoundary>,
              );
            } else {
              logger.error(`No block component for ${block?.__typename}`);
            }
          }
          return acc;
        }, [])}
    </>
  );
};
