import classNames from 'classnames';
import React from 'react';
import { Controller, useController, useFormContext } from 'react-hook-form';

import { Nullable } from '@/types';
import { BaseInputFieldProps } from '../BaseInputField';
import { BaseWrapper } from '../BaseWrapper';
import { ErrorMessage } from '../ErrorMessage';
import './SwitchField.css';

type Props = BaseInputFieldProps & {
  value?: string;
  labelPlacement?: 'above' | 'right';
  allowUndefinedState?: boolean;
};

const allSteps = [undefined, true, false];
const booleanOnlySteps = [true, false];

const getNextValue = (
  steps: Nullable<boolean>[],
  currentValue: boolean | undefined,
) => {
  const numberOfValues = steps.length;
  const currentIndex = steps.indexOf(currentValue) ?? 0;
  const nextIndex = (currentIndex + 1) % numberOfValues;
  return steps[nextIndex];
};

const getDataState = (value: boolean | undefined) => {
  if (typeof value === 'boolean') {
    return value ? 'ON' : 'OFF';
  }
  return 'UNSET';
};

export const SwitchField = ({
  title,
  name,
  required,
  className,
  labelPlacement = 'right',
  allowUndefinedState = true,
}: Props) => {
  const { control } = useFormContext();

  const {
    fieldState: { error },
  } = useController({ name });

  const steps = allowUndefinedState ? allSteps : booleanOnlySteps;

  const field = (
    <Controller
      name={name}
      control={control}
      defaultValue={undefined}
      render={({ field }) => (
        <button
          className="switch-field-container relative flex items-center cursor-pointer"
          data-state={getDataState(field.value)}
          onClick={() => field.onChange(getNextValue(steps, field.value))}
          onBlur={field.onBlur}
          type="button"
        >
          <div
            className={classNames(
              'switch-field-path w-12 h-6 rounded-full shadow-inner',
              {
                'bg-gray-200': !field.value,
                'bg-blue-500': field.value,
              },
            )}
          />
          <div className="switch-field-circle absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0" />
        </button>
      )}
    />
  );

  if (labelPlacement === 'above') {
    return (
      <BaseWrapper name={name} title={title}>
        <div className="mt-1">{field}</div>
      </BaseWrapper>
    );
  }

  return (
    <div className={classNames('w-full', className)}>
      <label className="flex items-center space-x-2">
        {field}
        <span className="focus:outline-none focus:ring w-full sm:text-sm">
          {title}
          {required && '*'}
        </span>
      </label>
      {error && <ErrorMessage message={error} variant="inline" />}
    </div>
  );
};
