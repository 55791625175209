import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import React, { FC } from 'react';

import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  ControlledModal,
} from '@/components';
import { useDataGrid } from '@/components/DataGrid';
import {
  NextIcon,
  PlusIcon,
  PreviousIcon,
  RefreshIcon,
} from '@/components/icons';
import { useTranslate } from '@/contexts';
import { PlayerSelfExclusionForm } from '@/forms';
import { assert } from '@/utils/error';
import usePlayerSelfExclusionHistory from './usePlayerSelfExclusionHistory';

export const Fragment = graphql`
  fragment SanityPlayerSelfExclusionHistoryBlock on SanityPlayerSelfExclusionHistoryBlock {
    title {
      ...SanityLocaleString
    }
  }
`;

const isEstonia = process.env.GATSBY_LICENSE === 'EST';

const PlayerSelfExclusionHistoryBlock: FC<{
  block: Queries.SanityPlayerSelfExclusionHistoryBlockFragment;
}> = ({ block }) => {
  const params = useParams();

  assert(params.playerId, 'missing playerId');

  const {
    history,
    fetching,
    nextPage,
    previousPage,
    refresh,
    orderBy,
    desc,
    setOrderBy,
    defaultFilter,
    updateFilter,
  } = usePlayerSelfExclusionHistory(params.playerId);

  const { DataGrid, GlobalCardOptions } = useDataGrid({
    name: 'playerSelfExclusion',
    data: history,
    defaultFilter,
    desc,
    fetching,
    setOrderBy,
    orderBy,
    updateFilter,
    schema: (s) => [
      s.dateTimeValue({
        field: 'requestedAt',
        title: 'Requested At',
      }),
      s.initiatorValue({
        field: 'requestedBy',
        title: 'Requested By',
      }),
      s.dateTimeValue({
        field: 'activeFrom',
        title: 'Active From',
      }),
      s.dateTimeValue({
        field: 'endsAt',
        title: 'Ends At',
      }),
      s.dateTimeValue({
        field: 'cancelledAt',
        title: 'Cancelled At',
      }),
      s.stringValue({
        field: 'eventType',
        title: 'Event Type',
      }),
      s.stringValue({
        field: 'comment',
        title: 'Comment',
      }),
    ],
  });
  const { t } = useTranslate();

  return (
    <Card
      size="lg"
      title={t(block.title)}
      showOptionsAtBottom
      options={
        <CardOptions>
          <CardOptionsButton
            disabled={!previousPage}
            onClick={() => previousPage && previousPage()}
          >
            <PreviousIcon />
          </CardOptionsButton>
          <CardOptionsButton
            disabled={!nextPage}
            onClick={() => nextPage && nextPage()}
          >
            <NextIcon />
          </CardOptionsButton>
          <GlobalCardOptions />
          <CardOptionsButton
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
          <ControlledModal
            content={<PlayerSelfExclusionForm playerId={params.playerId} />}
          >
            <CardOptionsButton disabled={isEstonia}>
              <PlusIcon />
            </CardOptionsButton>
          </ControlledModal>
        </CardOptions>
      }
    >
      <CardBody>
        <DataGrid />
      </CardBody>
    </Card>
  );
};

export default PlayerSelfExclusionHistoryBlock;
