import { RouteComponentProps } from '@reach/router';
import React from 'react';

import { BlockRenderer } from '@/blocks/BlockRenderer';
import { CardsWrapper, PageTemplate } from '@/components';
import { PageProvider, useViewer } from '@/contexts';

export const Page: React.FC<
  {
    page: Queries.SanityPageQuery['sanityPage'];
  } & RouteComponentProps
> = ({ page }) => {
  const { viewer, stale } = useViewer();

  if (stale) {
    return (
      <div className="bg-red-200 text-red-900 p-4" role="alert">
        <p className="font-bold">Are you connected to the VPN?</p>
        <p>
          The connection is taking a long time. It could be that you are not
          connected to the office network or VPN.
        </p>
      </div>
    );
  }

  if (!viewer) {
    return null;
  }

  const content = page?.content ? (
    <BlockRenderer blockContainer={page.content} />
  ) : null;

  return (
    <PageProvider page={page}>
      <div className="flex flex-col md:flex-row h-full">
        {page?.template ? (
          <PageTemplate template={page.template} pageContent={content} />
        ) : (
          <CardsWrapper>{content}</CardsWrapper>
        )}
      </div>
    </PageProvider>
  );
};
