import { Agent, AgentInitiator, Maybe, PlayerInitiator } from '@/globalTypes';

type PlayerInitiatorType = Pick<PlayerInitiator, '__typename'>;

type AgentInitiatorType = {
  __typename: AgentInitiator['__typename'];
  agent: Maybe<Pick<Agent, 'firstName' | 'lastName'>>;
};

type InitiatorValueType = PlayerInitiatorType | AgentInitiatorType;

export const getInitiator = (value: Maybe<InitiatorValueType>) => {
  if (value && value.__typename === 'PlayerInitiator') {
    return 'Player';
  }

  if (value && 'agent' in value && value.agent) {
    return `${value.agent.firstName} ${value.agent.lastName}`;
  }

  return '-';
};
