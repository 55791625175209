import { saveAs } from 'file-saver';
import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { useMemo, useState } from 'react';
import { useClient } from 'urql';

import { Card, CardBody, DateField, Form, SubmitButton } from '@/components';
import { useTranslate } from '@/contexts';
import {
  PepAndSanctionsPlayerListReportQuery,
  PepAndSanctionsPlayerListReportQueryVariables,
} from './__generated__/component';

const BLOCK_STATIC_QUERY = graphql`
  query SanityPlayerPepAndSanctionsReportBlockStaticQuery {
    sanityPlayerPepAndSanctionsReportBlock {
      title {
        ...SanityLocaleString
      }
      registeredFrom {
        ...SanityLocaleString
      }
      exportPepAndSanctions {
        ...SanityLocaleString
      }
    }
  }
`;

const REPORT_QUERY = gql`
  query PepAndSanctionsPlayerListReport($registeredFrom: OffsetDateTime) {
    viewer {
      id
      pepAndSanctionsPlayerListReport(registeredFrom: $registeredFrom) {
        url
      }
    }
  }
`;

type FormValues = {
  registeredFrom: string;
};

const PlayerPepAndSanctionsReportBlock = () => {
  const [isGenerating, setIsGenerating] = useState(false);
  const { t } = useTranslate();
  const client = useClient();

  const today = useMemo(() => new Date(), []);

  const block =
    useStaticQuery<Queries.SanityPlayerPepAndSanctionsReportBlockStaticQueryQuery>(
      BLOCK_STATIC_QUERY,
    ).sanityPlayerPepAndSanctionsReportBlock;

  if (!block) {
    return null;
  }

  const defaultValues: FormValues = {
    registeredFrom: '',
  };

  const onSubmit = ({ registeredFrom }: FormValues) => {
    setIsGenerating(true);

    client
      .query<
        PepAndSanctionsPlayerListReportQuery,
        PepAndSanctionsPlayerListReportQueryVariables
      >(
        REPORT_QUERY,
        { registeredFrom },
        {
          requestPolicy: 'network-only',
        },
      )
      .toPromise()
      .then((res) => {
        const url = res.data?.viewer.pepAndSanctionsPlayerListReport.url;

        if (url) {
          saveAs(url, 'player-pep-and-sanctions-report.csv');
        }
      })
      .finally(() => {
        setIsGenerating(false);
      });
  };

  return (
    <Card size="lg" title={t(block.title)}>
      <CardBody>
        <div className="p-3">
          <Form
            defaultValues={defaultValues}
            onSubmit={onSubmit}
            className="grid gap-3"
          >
            <DateField
              name="registeredFrom"
              id="pep-and-sanctions-report-block__from"
              title={t(block.registeredFrom)}
              maxDate={today}
              required
            />
            <SubmitButton
              value={t(block.exportPepAndSanctions)}
              disabled={isGenerating}
            />
          </Form>
        </div>
      </CardBody>
    </Card>
  );
};

export default PlayerPepAndSanctionsReportBlock;
