import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import queryString from 'query-string';
import React, { FC } from 'react';

import { useGetPlayerGameRoundPageLink } from '@/bits/links/useLink';
import { Card, CardBody, CardOptions, CardOptionsButton } from '@/components';
import { useDataGrid } from '@/components/DataGrid';
import {
  DocumentDownloadIcon,
  NextIcon,
  PreviousIcon,
  RefreshIcon,
} from '@/components/icons';
import { useTranslate } from '@/contexts';
import {
  GameProvider,
  GameRoundSearchOrder,
  GameRoundStatus,
} from '@/globalTypes';
import { assert } from '@/utils/error';
import usePlayerGameRounds from './usePlayerGameRounds';
import usePlayerGameRoundsReport from './usePlayerGameRoundsReport';

export const Fragment = graphql`
  fragment SanityPlayerGameRoundsBlock on SanityPlayerGameRoundsBlock {
    title {
      ...SanityLocaleString
    }
  }
`;

const PlayerGameRoundsBlock: FC<{
  block: Queries.SanityPlayerGameRoundsBlockFragment;
}> = ({ block }) => {
  const params = useParams();
  const { t } = useTranslate();

  assert(params.playerId, 'missing playerId');

  const gameRoundPageLink = useGetPlayerGameRoundPageLink()(params.playerId);

  const {
    gameRounds,
    fetching,
    nextPage,
    previousPage,
    refresh,
    orderBy,
    desc,
    setOrderBy,
    defaultFilter,
    updateFilter,
  } = usePlayerGameRounds(params.playerId);
  const report = usePlayerGameRoundsReport(params.playerId);

  const { DataGrid, GlobalCardOptions } = useDataGrid({
    name: 'playerGameRounds',
    data: gameRounds,
    fetching,
    orderBy,
    setOrderBy,
    desc,
    defaultFilter,
    updateFilter,
    getRowLink: ({ row }) =>
      `${gameRoundPageLink}?${queryString.stringify({
        gameRoundId: row.gameRoundId,
      })}`,
    schema: (s) => [
      s.dateTimeValue({
        field: 'startedAt',
        title: 'Started At',
      }),
      s.dateTimeValue({
        field: 'completedAt',
        title: 'Completed At',
        fromFilterField: 'from',
        toFilterField: 'to',
        orderBy: GameRoundSearchOrder.completedAt,
        includeTimeInFilter: true,
      }),
      s.enumValue({
        field: 'gameProvider',
        title: 'Game Provider',
        e: GameProvider,
        filterField: 'gameProviderId',
      }),
      s.stringValue({
        field: 'gameRoundId',
        title: 'Game Round ID',
        filterField: 'gameRoundId',
        orderBy: GameRoundSearchOrder.gameRoundId,
      }),
      s.enumValue({
        field: 'status',
        title: 'Status',
        e: GameRoundStatus,
        filterField: 'status',
      }),
      s.gameValue({
        field: 'gameDescriptor',
        title: 'Game',
        orderBy: GameRoundSearchOrder.gameId,
      }),
      s.monetaryValue({
        field: 'totalBets',
        title: 'Bets',
      }),
      s.monetaryValue({
        field: 'totalWins',
        title: 'Wins',
      }),
      s.monetaryValue({
        field: 'openingBalance',
        title: 'Opening Balance',
      }),
      s.monetaryValue({
        field: 'closingBalance',
        title: 'Closing Balance',
      }),
      s.monetaryValue({
        field: 'bonusBetAmount',
        title: 'Bonus Bet Amount',
      }),
      s.monetaryValue({
        field: 'bonusWinAmount',
        title: 'Bonus Win Amount',
      }),
      s.monetaryValue({
        field: 'openingBonusBalance',
        title: 'Bonus Opening Amount',
      }),
      s.monetaryValue({
        field: 'closingBonusBalance',
        title: 'Bonus Closing Amount',
      }),
      s.monetaryValue({
        field: 'totalJackpotContribution',
        title: 'JPC',
      }),
      s.monetaryValue({
        field: 'totalJackpotPayout',
        title: 'Jackpot Payout',
      }),
    ],
  });

  return (
    <Card
      size="lg"
      title={t(block.title)}
      showOptionsAtBottom
      options={
        <CardOptions>
          <CardOptionsButton
            disabled={!previousPage}
            onClick={() => previousPage && previousPage()}
          >
            <PreviousIcon />
          </CardOptionsButton>
          <CardOptionsButton
            disabled={!nextPage}
            onClick={() => nextPage && nextPage()}
          >
            <NextIcon />
          </CardOptionsButton>
          <GlobalCardOptions />

          <CardOptionsButton
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
          <CardOptionsButton onClick={() => report.generate()}>
            <DocumentDownloadIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <DataGrid />
      </CardBody>
    </Card>
  );
};

export default PlayerGameRoundsBlock;
