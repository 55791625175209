import { saveAs } from 'file-saver';
import { gql, useClient } from 'urql';
import { useQueryParams, withDefault } from 'use-query-params';

import { PaymentSearchOrder } from '@/globalTypes';
import { mapVariables } from '@/utils';
import { PaymentSearchOrderParam } from '@/utils/query/params';
import {
  PaymentsReportQuery,
  PaymentsReportQueryVariables,
} from './__generated__/usePaymentsReport';
import { queryParams } from './queryParams';

const PAYMENTS_REPORT_QUERY = gql`
  query PaymentsReport(
    $id: String
    $rawId: String
    $playerId: ID
    $sessionId: String
    $brand: BrandEnum
    $org: OrgEnum
    $paymentType: PaymentType
    $paymentStatus: PaymentStatus
    $currency: String
    $gameProviderId: GameProvider
    $from: OffsetDateTime
    $to: OffsetDateTime
    $orderBy: PaymentSearchOrder
    $desc: Boolean
    $indexPrefix: String
  ) {
    viewer {
      id
      exportedPayments(
        id: $id
        rawId: $rawId
        playerId: $playerId
        sessionId: $sessionId
        brand: $brand
        org: $org
        paymentType: $paymentType
        paymentStatus: $paymentStatus
        currency: $currency
        gameProviderId: $gameProviderId
        from: $from
        to: $to
        orderBy: $orderBy
        desc: $desc
        indexPrefix: $indexPrefix
      ) {
        url
      }
    }
  }
`;

export default function usePaymentsReport() {
  const [query] = useQueryParams({
    ...queryParams,
    orderBy: withDefault(
      PaymentSearchOrderParam,
      PaymentSearchOrder.processedAt,
    ),
  });
  const client = useClient();

  const mappedVariables = mapVariables(query);

  const generate = async () => {
    const variables: PaymentsReportQueryVariables = {
      ...mappedVariables,
      paymentStatus: mappedVariables.paymentStatus?.[0],
    };

    return client
      .query<PaymentsReportQuery, PaymentsReportQueryVariables>(
        PAYMENTS_REPORT_QUERY,
        variables,
      )
      .toPromise()
      .then((res) => {
        const url = res.data?.viewer.exportedPayments.url;

        if (url) {
          saveAs(url, 'payments-report.csv');
        }
      });
  };

  return {
    generate,
  };
}
