import {
  BooleanParam,
  NumberParam,
  StringParam,
  withDefault,
} from 'use-query-params';

import { ChallengeStatusParam } from '@/utils/query/params';

export const queryParams = {
  before: StringParam,
  after: StringParam,
  first: withDefault(NumberParam, 20),
  challengeStatus: ChallengeStatusParam,
  desc: withDefault(BooleanParam, true),
};
