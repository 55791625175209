import gql from 'graphql-tag';
import { useQuery } from 'urql';
import { useQueryParams } from 'use-query-params';

import { reviewDocumentForm_document } from '@/forms/review-document-form/ReviewDocumentForm';
import { mapVariables } from '@/utils';
import { makeOrderBy, makePagination } from '@/utils/pagination';
import {
  PlayerDocumentsQuery,
  PlayerDocumentsQueryVariables,
} from './__generated__/usePlayerDocuments';
import { queryParams } from './queryParams';

export const playerDocuments_document = gql`
  fragment PlayerDocuments_document on Document {
    documentType
    expirationDate
    id
    status
    uuid
    verificationTypes
    uploadedAt
    uploadedBy {
      ... on PlayerInitiator {
        player {
          id
          firstName
          lastName
          email
        }
      }
      ... on AgentInitiator {
        agent {
          firstName
          lastName
          email
        }
      }
    }
    verifiedAt
    verifiedBy {
      ... on PlayerInitiator {
        player {
          firstName
          lastName
          email
        }
      }
      ... on AgentInitiator {
        agent {
          firstName
          lastName
          email
        }
      }
    }
    deletedAt
    deletedBy {
      ... on PlayerInitiator {
        player {
          firstName
          lastName
          email
        }
      }
      ... on AgentInitiator {
        agent {
          firstName
          lastName
          email
        }
      }
    }
    ...ReviewDocumentForm_document
  }
  ${reviewDocumentForm_document}
`;

export const PLAYER_DOCUMENTS_QUERY = gql`
  query PlayerDocuments(
    $playerId: ID!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $orderBy: DocumentSearchOrder
    $desc: Boolean
  ) {
    player(playerId: $playerId) {
      id
      documents(
        after: $after
        before: $before
        first: $first
        last: $last
        orderBy: $orderBy
        desc: $desc
      ) {
        edges {
          node {
            ...PlayerDocuments_document
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
      }
    }
  }
  ${playerDocuments_document}
`;

export default function usePlayerDocuments(playerId: string) {
  const [query, setQuery] = useQueryParams(queryParams);
  const [{ data, fetching }, refresh] = useQuery<
    PlayerDocumentsQuery,
    PlayerDocumentsQueryVariables
  >({
    query: PLAYER_DOCUMENTS_QUERY,
    variables: {
      playerId,
      ...mapVariables(query),
    },
  });

  const documents = data?.player.documents;

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter: (values: {}) => setQuery(values, 'replaceIn'),
    ...makeOrderBy(query, setQuery),
    ...makePagination(query, setQuery, documents?.pageInfo),
    playerDocuments: documents?.edges?.map((edge) => edge?.node),
  };
}
