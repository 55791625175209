import React, { ComponentProps, FC } from 'react';
import {
  MdAddCircle,
  MdInfo,
  MdModeEdit,
  MdRemoveCircle,
  MdReport,
} from 'react-icons/md';

import { Tippy } from '../Tippy';

type ActionType = keyof typeof Icons;

const Icons = {
  add: <MdAddCircle />,
  remove: <MdRemoveCircle />,
  edit: <MdModeEdit />,
  report: <MdReport />,
  info: <MdInfo />,
  default: null,
};

const getIconByType = (actionType: ActionType): React.ReactNode =>
  Icons[actionType] ?? null;

export const ActionButton: FC<
  ComponentProps<'button'> & {
    label: string;
    description?: string;
    actionType: ActionType;
  }
> = ({ description, label, actionType = 'default', onClick }) => {
  return (
    <Tippy content={description ?? label}>
      <button
        title={label}
        className={`
          flex flex-row gap-1 items-center whitespace-nowrap px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white bg-gray-900 hover:bg-gray-700
          focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-800 focus-visible:ring-white
        `}
        onClick={onClick}
      >
        {getIconByType(actionType)} {label}
      </button>
    </Tippy>
  );
};
