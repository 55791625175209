import { useMemo } from 'react';

import { SourceOfFundType } from '@/globalTypes';

export const sourceOfFundsOptionLabels: Record<SourceOfFundType, string> = {
  CLIENT_DECLARATION_OF_LINE_OF_BUSINESS:
    'Client declaration of line of business',
  CRYPTO_MINING: 'Crypto-Mining',
  DIVIDENDS_FROM_CAP: 'Dividends - derived from CAP lines of business',
  DIVIDENDS_FROM_NON_CAP: 'Dividends - derived from non-CAP lines of business',
  DONATION_OR_GIFT: 'Donation / Gift',
  FUNDS_GENERATED_FROM_GAMBLING_WINNINGS:
    'Funds generated from gambling winnings',
  INCOME_AND_SALARY_BUSINESS_ACTIVITY:
    'Income & Salary Business Activity list of CAP',
  INCOME_FROM_INVESTMENTS: 'Income from investments',
  INHERITANCE: 'Inheritance',
  LOAN: 'Loan',
  SALE_OF_COMPANY_FROM_CAP:
    'Sale of company - derived from CAP lines of business',
  SALE_OF_COMPANY_FROM_NON_CAP:
    'Sale of company - derived from non-CAP lines of business',
  SALE_PROPERTY: 'Sale of property',
  SAVINGS_FROM_CAP: 'Savings - Derived from CAP lines of business',
  SAVINGS_FROM_NON_CAP: 'Savings - Derived from non-CAP lines of business',
};

export const useSourceOfFundsOptions = () => {
  return useMemo<{ label: string; value: SourceOfFundType }[]>(() => {
    return Object.values(SourceOfFundType).map((value) => ({
      label: sourceOfFundsOptionLabels[value],
      value,
    }));
  }, []);
};
