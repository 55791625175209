import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'urql';

import {
  Button,
  Card,
  CardCloseButton,
  CardOptions,
  ConfirmButton,
  ErrorMessage,
  NakedForm,
  SelectField,
  SubmitButton,
  TextareaField,
  useModalContext,
} from '@/components';
import FileViewer from '@/components/FileViewer';
import { useTranslate } from '@/contexts';
import { FundingOption } from '@/globalTypes';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import {
  GenerateFundingDocumentUrlMutation,
  GenerateFundingDocumentUrlMutationVariables,
  ReviewApproveFundingDocumentMutation,
  ReviewApproveFundingDocumentMutationVariables,
  ReviewDeleteFundingDocumentMutation,
  ReviewDeleteFundingDocumentMutationVariables,
  ReviewFundingDocumentForm_documentFragment,
  ReviewRejectFundingDocumentMutation,
  ReviewRejectFundingDocumentMutationVariables,
} from './__generated__/ReviewFundingDocumentForm';

const query = graphql`
  query SanityReviewFundingDocumentForm {
    sanityReviewFundingDocumentForm {
      title {
        ...SanityLocaleString
      }
      fundingOptionLabel {
        ...SanityLocaleString
      }
      commentLabel {
        ...SanityLocaleString
      }
      approve {
        ...SanityLocaleString
      }
      reject {
        ...SanityLocaleString
      }
      delete {
        ...SanityLocaleString
      }
    }
  }
`;

export const reviewFundingDocumentForm_document = gql`
  fragment ReviewFundingDocumentForm_document on Document {
    id
    verifiedAt
    expirationDate
    status
    verifiedBy {
      ... on PlayerInitiator {
        player {
          firstName
          lastName
          email
        }
      }
      ... on AgentInitiator {
        agent {
          firstName
          lastName
          email
        }
      }
    }
    deletedAt
    deletedBy {
      ... on PlayerInitiator {
        player {
          firstName
          lastName
          email
        }
      }
      ... on AgentInitiator {
        agent {
          firstName
          lastName
          email
        }
      }
    }
  }
`;

const approveFundingDocumentMutation = gql`
  mutation ReviewApproveFundingDocument(
    $playerId: ID!
    $documentId: ID!
    $fundingOption: FundingOption!
    $comment: String
  ) {
    approveFundingDocument(
      documentId: $documentId
      playerId: $playerId
      fundingOption: $fundingOption
      comment: $comment
    ) {
      ...ReviewFundingDocumentForm_document
    }
  }
  ${reviewFundingDocumentForm_document}
`;

const rejectFundingDocumentMutation = gql`
  mutation ReviewRejectFundingDocument(
    $playerId: ID!
    $documentId: ID!
    $fundingOption: FundingOption!
    $comment: String
  ) {
    rejectFundingDocument(
      documentId: $documentId
      playerId: $playerId
      fundingOption: $fundingOption
      comment: $comment
    ) {
      ...ReviewFundingDocumentForm_document
    }
  }
  ${reviewFundingDocumentForm_document}
`;

export const deleteFundingDocumentMutation = gql`
  mutation ReviewDeleteFundingDocument($documentId: ID!) {
    deleteFundingDocument(documentId: $documentId) {
      ...ReviewFundingDocumentForm_document
    }
  }
  ${reviewFundingDocumentForm_document}
`;

const generateFundingDocumentUrlMutation = gql`
  mutation GenerateFundingDocumentUrl($documentId: ID!) {
    generateFundingDocumentDownloadUrl(documentId: $documentId) {
      url
    }
  }
`;

const ReviewFundingDocumentForm: FC<{
  document: ReviewFundingDocumentForm_documentFragment;
  playerId: string;
}> = ({ document, playerId }) => {
  const staticData =
    useStaticQuery<Queries.SanityReviewFundingDocumentFormQuery>(query);

  const { close } = useModalContext();
  const { t } = useTranslate();
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();

  const defaultValues = {
    fundingOption: '' as FundingOption,
    comment: '',
  };

  const methods = useForm({ defaultValues });

  const form = staticData.sanityReviewFundingDocumentForm;

  const [approvedDocumentState, approveDocument] = useMutation<
    ReviewApproveFundingDocumentMutation,
    ReviewApproveFundingDocumentMutationVariables
  >(approveFundingDocumentMutation);

  const [rejectDocumentState, rejectDocument] = useMutation<
    ReviewRejectFundingDocumentMutation,
    ReviewRejectFundingDocumentMutationVariables
  >(rejectFundingDocumentMutation);

  const [deleteDocumentState, deleteDocument] = useMutation<
    ReviewDeleteFundingDocumentMutation,
    ReviewDeleteFundingDocumentMutationVariables
  >(deleteFundingDocumentMutation);

  const [documentUrlState, generateDocumentUrl] = useMutation<
    GenerateFundingDocumentUrlMutation,
    GenerateFundingDocumentUrlMutationVariables
  >(generateFundingDocumentUrlMutation);

  useEffect(() => {
    if (document.id) {
      generateDocumentUrl({ documentId: document.id });
    }
  }, [document.id, generateDocumentUrl]);

  const onSubmit = (values: typeof defaultValues) => {
    if (document.id) {
      approveDocument({
        documentId: document.id,
        fundingOption: values.fundingOption,
        playerId,
        comment: values.comment,
      }).then((res) => {
        if (!isMounted) {
          return;
        }

        if (res.error?.message) {
          setErrorMessage(res.error.message);
        } else {
          close?.();
        }
      });
    }
  };

  const onReject = () => {
    const comment = methods.getValues('comment');
    const fundingOption = methods.getValues('fundingOption');

    rejectDocument({
      documentId: document.id,
      fundingOption,
      playerId,
      comment,
    }).then((res) => {
      if (!isMounted) {
        return;
      }

      if (res.error?.message) {
        setErrorMessage(res.error.message);
      } else {
        close?.();
      }
    });
  };

  const onDelete = () => {
    deleteDocument({
      documentId: document.id,
    }).then((res) => {
      if (!isMounted) {
        return;
      }

      if (res.error?.message) {
        setErrorMessage(res.error.message);
      } else {
        close?.();
      }
    });
  };

  if (!form || !defaultValues) {
    return null;
  }

  const disableSubmitButtons =
    approvedDocumentState.fetching ||
    rejectDocumentState.fetching ||
    deleteDocumentState.fetching;

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3 space-y-6">
        {documentUrlState.data?.generateFundingDocumentDownloadUrl.url && (
          <FileViewer
            filePath={
              documentUrlState.data.generateFundingDocumentDownloadUrl.url
            }
          />
        )}

        <NakedForm methods={methods} onSubmit={onSubmit} className="grid gap-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            <SelectField
              name="fundingOption"
              id="ReviewFundingDocumentForm__fundingOption"
              title={t(form.fundingOptionLabel)}
              required
              options={Object.values(FundingOption).map((fundingOption) => ({
                label: fundingOption,
                value: fundingOption,
              }))}
            />
            <TextareaField
              title={t(form.commentLabel)}
              name="comment"
              id="ReviewFundingDocumentForm__comment"
              rows={3}
            />
          </div>

          <ErrorMessage message={errorMessage} />

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            <div>
              <SubmitButton
                value={t(form.approve)}
                disabled={disableSubmitButtons}
                className="w-full"
              />
            </div>
            <Button
              type="button"
              onClick={onReject}
              variant="secondary"
              style={{ minWidth: 200 }}
              disabled={disableSubmitButtons}
            >
              {t(form.reject)}
            </Button>
            <ConfirmButton
              type="button"
              onClick={onDelete}
              variant="secondary"
              style={{ minWidth: 200 }}
              disabled={disableSubmitButtons}
            >
              {t(form.delete)}
            </ConfirmButton>
          </div>
        </NakedForm>
      </div>
    </Card>
  );
};

export default ReviewFundingDocumentForm;
