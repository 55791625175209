import {
  BooleanParam,
  DateTimeParam,
  StringParam,
  withDefault,
} from 'use-query-params';

export const queryParams = {
  id: StringParam,
  updatedAtFrom: DateTimeParam,
  updatedAtTo: DateTimeParam,
  desc: withDefault(BooleanParam, true),
  before: StringParam,
  after: StringParam,
};
