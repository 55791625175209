import Tippy from '@tippyjs/react';
import { Maybe } from 'graphiql/dist/types';
import React, { FC } from 'react';

import { ControlledModal, InlineIconButton, Value } from '@/components';
import { InformationIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import formatMoney from '@/utils/formatter/formatMoney';
import { Copy } from '../../components/Copy';
import { InlineSpinner } from '../../components/InlineSpinner';
import { KpiMetricsStatsFragment } from './__generated__/component';
import KpiMetricDetails from './KpiMetricDetails';

const renderLastHour = (
  value: Maybe<number>,
  currency: Maybe<KpiMetricsStatsFragment['rows'][number]['currency']>,
) => {
  if (!value) {
    return '-';
  }

  if (value <= 0) {
    return <span className="text-red-500">{formatMoney(value, currency)}</span>;
  }

  return (
    <span className="text-green-500">+{formatMoney(value, currency)}</span>
  );
};

export const KpiMetricBox: FC<{
  title: string;
  fetching: boolean;
  block: Queries.SanityKpiMetricsBlockFragment;
  currency: Maybe<KpiMetricsStatsFragment['rows'][number]['currency']>;
  allTimeValue: Maybe<number>;
  lastHourValue: Maybe<number>;
  preselectedValue: Maybe<number>;
  isAllTimeSelected: boolean;
}> = ({
  title,
  fetching,
  block,
  currency,
  allTimeValue,
  lastHourValue,
  preselectedValue,
}) => {
  const { t } = useTranslate();

  const allTimeFormattedValue = formatMoney(allTimeValue ?? 0, currency);
  const preselectedFormattedValue = formatMoney(
    preselectedValue ?? 0,
    currency,
  );

  return (
    <div className={'grid gap-1 text-black dark:text-gray-200'}>
      {fetching ? (
        <InlineSpinner />
      ) : (
        <Value
          title={
            <div className="flex">
              <span>{title}</span>
              <ControlledModal
                content={
                  <KpiMetricDetails
                    title={title}
                    allTimeLabel={`${t(block.title)} - ${t(block.allTime)}`}
                    preselectedLabel={`${t(block.title)} - ${t(
                      block.preselectedPeriod,
                    )}`}
                    allTimeValue={allTimeValue}
                    preselectedValue={preselectedValue}
                    currency={currency}
                  />
                }
              >
                <InlineIconButton>
                  <InformationIcon />
                </InlineIconButton>
              </ControlledModal>
            </div>
          }
          fetching={fetching}
        >
          <Tippy content={t(block.allTime)}>
            <div className="flex items-center">
              <Copy value={allTimeFormattedValue} />
            </div>
          </Tippy>
          <Tippy content={t(block.preselectedPeriod)}>
            <div className="flex items-center">
              <Copy value={preselectedFormattedValue} />
            </div>
          </Tippy>
          <Tippy content={t(block.lastHour)}>
            <div className="flex items-center">
              {renderLastHour(lastHourValue, currency)}
            </div>
          </Tippy>
        </Value>
      )}
    </div>
  );
};
