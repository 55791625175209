import { useParams } from '@reach/router';
import classNames from 'classnames';
import { Link, graphql } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { MdOutlineErrorOutline } from 'react-icons/md';
import { useQuery } from 'urql';

import { useGetPlayerOverviewPageLink } from '@/bits/links/useLink';
import {
  Card,
  CardBody,
  CardCloseButton,
  CardOptions,
  CardOptionsButton,
  ControlledModal,
  InlineIconButton,
  Table,
  Tippy,
  Value,
} from '@/components';
import { EditIcon, EyeIcon, RefreshIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import SubmitRgReviewStatusForm from '@/forms/submit-rg-review-status-form/SubmitRgReviewStatusForm';
import { getRgRiskLevelColor } from '@/utils';
import formatDate from '@/utils/formatter/formatDate';
import { splitStringIntoTwoWords } from '@/utils/split-string-in-two-words';
import {
  RgRiskAssessmentQuery,
  RgRiskAssessmentQueryVariables,
} from './__generated__/component';

export const Fragment = graphql`
  fragment SanityPlayerRgRiskAssessmentOverviewBlock on SanityPlayerRgRiskAssessmentOverviewBlock {
    totalRiskAssessment {
      ...SanityLocaleString
    }
    riskLevel {
      ...SanityLocaleString
    }
    calculatedAt {
      ...SanityLocaleString
    }
    lastReviewed {
      ...SanityLocaleString
    }
    overriddenRiskLevel {
      ...SanityLocaleString
    }
    regions {
      ...SanityLocaleString
    }
    brands {
      ...SanityLocaleString
    }
    currentReviewStatus {
      ...SanityLocaleString
    }
    isReviewRequired {
      ...SanityLocaleString
    }
    reviewRequired {
      ...SanityLocaleString
    }
    updateReviewStatus {
      ...SanityLocaleString
    }
    playerGlobalUuid {
      ...SanityLocaleString
    }
  }
`;

const QUERY = gql`
  query RgRiskAssessment($playerGlobalId: ID!) {
    viewer {
      id
      rgCraRiskAssessment(playerGlobalId: $playerGlobalId) {
        playerGlobalId
        players {
          id
          rawPlayerId
          brand {
            name
          }
        }
        riskLevel
        overriddenRiskLevel
        reviewStatusInfo {
          status
          lastReviewed
        }
        reviewTrigger {
          byRisks {
            regionId
            riskId
          }
          byOverallRiskLevel
        }
        calculatedAt
        regions {
          regionName
          risks {
            riskName
            riskLevel
            calculatedAt
            reviewRequired
          }
        }
      }
    }
  }
`;

const PlayerRgRiskAssessmentOverviewBlock: FC<{
  block: Queries.SanityPlayerRgRiskAssessmentOverviewBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();
  const params = useParams();
  const getPlayerOverviewLink = useGetPlayerOverviewPageLink();

  const [{ data, fetching }, refresh] = useQuery<
    RgRiskAssessmentQuery,
    RgRiskAssessmentQueryVariables
  >({
    query: QUERY,
    variables: {
      playerGlobalId: params.playerGlobalId,
    },
  });

  const riskAssessment = data?.viewer.rgCraRiskAssessment;
  const players = riskAssessment?.players || [];

  return (
    <>
      <Card
        size="lg"
        title={t(block.totalRiskAssessment)}
        options={
          <CardOptions>
            <CardOptionsButton
              className="flex"
              onClick={() => refresh({ requestPolicy: 'network-only' })}
            >
              <RefreshIcon />
            </CardOptionsButton>
          </CardOptions>
        }
      >
        <CardBody>
          <div className="p-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
            <Value fetching={fetching} title={t(block.riskLevel)}>
              <div className="flex gap-1 items-center">
                <MdOutlineErrorOutline
                  className={classNames(
                    getRgRiskLevelColor(riskAssessment?.riskLevel),
                  )}
                />
                <span>
                  {splitStringIntoTwoWords(riskAssessment?.riskLevel)}
                </span>
                {riskAssessment?.overriddenRiskLevel && (
                  <span className={'text-red-600'}>*</span>
                )}
                {/* Temporary disabled for Phase 1 */}
                {/* <ControlledModal
                  content={
                    riskAssessment?.riskLevel ? (
                      <PlayerRgRiskLevelForm
                        riskLevel={riskAssessment.riskLevel}
                        playerGlobalId={params?.playerGlobalId}
                      />
                    ) : null
                  }
                >
                  <InlineIconButton>
                    <EditIcon />
                  </InlineIconButton>
                </ControlledModal> */}
              </div>
            </Value>
            <Value
              fetching={fetching}
              title={t(block.calculatedAt)}
              value={formatDate(riskAssessment?.calculatedAt)}
            />
            <Value fetching={fetching} title={t(block.brands)}>
              <div className="flex items-center">
                {players.length < 3 ? (
                  <span>
                    {players.map((player, i) => {
                      return (
                        <div key={`playerId_${i}`}>
                          <Link
                            key={`playerIdCraLink_${i}`}
                            to={getPlayerOverviewLink(player.id) ?? ''}
                          >
                            {player.brand.name}
                          </Link>
                          {players.length !== i + 1 && <span>, </span>}
                        </div>
                      );
                    })}
                  </span>
                ) : (
                  <>
                    <span className="text-blue-600">{players.length}</span>
                    <ControlledModal
                      content={
                        <Card
                          title="Brand(s)"
                          size="lg"
                          options={
                            <CardOptions>
                              <CardCloseButton />
                            </CardOptions>
                          }
                        >
                          <div className="p-3">
                            <Table
                              headings={['Player ID', 'Brand']}
                              rows={players.map((player, key) => ({
                                key: player.id,
                                data: [
                                  <Link
                                    key={`playerIdLink_${key}`}
                                    className="w-full h-full p-3 block"
                                    to={getPlayerOverviewLink(player.id) ?? ''}
                                  >
                                    {player.rawPlayerId}
                                  </Link>,
                                  player.brand.name,
                                ],
                              }))}
                            />
                          </div>
                        </Card>
                      }
                    >
                      <InlineIconButton>
                        <EyeIcon className="text-gray-400 text-lg" />
                      </InlineIconButton>
                    </ControlledModal>
                  </>
                )}
              </div>
            </Value>
            <Value fetching={fetching} title={t(block.currentReviewStatus)}>
              {riskAssessment?.reviewStatusInfo?.status
                ? riskAssessment.reviewStatusInfo.status
                : ''}

              <ControlledModal
                content={
                  riskAssessment?.reviewStatusInfo.status ? (
                    <SubmitRgReviewStatusForm
                      currentReviewStatus={
                        riskAssessment.reviewStatusInfo.status
                      }
                      playerGlobalId={params?.playerGlobalId}
                      reviewTrigger={riskAssessment.reviewTrigger}
                    />
                  ) : null
                }
              >
                {riskAssessment?.reviewStatusInfo.status ? (
                  <InlineIconButton title={t(block.updateReviewStatus)}>
                    <Tippy content={t(block.updateReviewStatus)}>
                      <div>
                        <EditIcon />
                      </div>
                    </Tippy>
                  </InlineIconButton>
                ) : (
                  <></>
                )}
              </ControlledModal>
            </Value>
            <Value fetching={fetching} title={t(block.lastReviewed)}>
              {formatDate(riskAssessment?.reviewStatusInfo?.lastReviewed)}
            </Value>
            <Value
              fetching={fetching}
              title={t(block.playerGlobalUuid)}
              value={riskAssessment?.playerGlobalId}
            />
          </div>
        </CardBody>
      </Card>
      <Card size="lg" title={t(block.regions)}>
        {riskAssessment?.regions.map((region, r) => {
          return (
            <div key={`regionContainer_${r}`} className="p-3">
              <div className="p-3">
                <Table
                  key={`regionRiskTable_${r}`}
                  columnWidths={['45%', '10%', '20%', '25%']}
                  headings={[
                    `${region.regionName}`,
                    t(block.riskLevel),
                    t(block.calculatedAt),
                    t(block.isReviewRequired),
                  ]}
                  rows={region.risks.map((risk) => ({
                    key: risk.riskName,
                    data: [
                      risk.riskName,
                      risk.riskLevel,
                      formatDate(risk.calculatedAt),
                      risk.reviewRequired && (
                        <span>{t(block.reviewRequired)}</span>
                      ),
                    ],
                  }))}
                />
              </div>
            </div>
          );
        })}
      </Card>
    </>
  );
};

export default PlayerRgRiskAssessmentOverviewBlock;
