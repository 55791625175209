import React, { ComponentProps } from 'react';

import { DateField, Pill } from '@/components';
import formatDate from '@/utils/formatter/formatDate';
import { DataDef, DataDefProps } from '../types';

type DateFieldProps = ComponentProps<typeof DateField>;

export function dateTimeValue<O, T extends Record<string, any>>({
  fromFilterField,
  toFilterField,
  title,
  field,
  getValue,
  maxDate,
  showMonthDropdown,
  showYearDropdown,
  includeTime = true,
  includeTimeInFilter = false,
  ...rest
}: DataDefProps<O, T> & {
  fromFilterField?: string;
  toFilterField?: string;
  showMonthDropdown?: DateFieldProps['showMonthDropdown'];
  maxDate?: DateFieldProps['maxDate'];
  showYearDropdown?: DateFieldProps['showYearDropdown'];
  includeTime?: boolean;
  includeTimeInFilter?: boolean;
}): DataDef<O, T> {
  return {
    ...rest,
    field,
    title,
    component: ({ row }) => {
      const value = getValue ? getValue({ row }) : row[field];
      return (
        <span className="tabular-nums">{formatDate(value, !includeTime)}</span>
      );
    },
    filter:
      fromFilterField && toFilterField
        ? {
            field,
            transformValues: (values) => {
              const [from, to] = [
                values[fromFilterField],
                values[toFilterField],
              ];
              if (from || to) {
                return {
                  [fromFilterField]: from || undefined,
                  [toFilterField]: to || undefined,
                };
              }
              return {
                [fromFilterField]: undefined,
                [toFilterField]: undefined,
              };
            },
            component: () => {
              if (fromFilterField === toFilterField) {
                const filterField = fromFilterField;
                return (
                  <DateField
                    name={filterField}
                    id={filterField}
                    title={title}
                    maxDate={maxDate}
                    showMonthDropdown={showMonthDropdown}
                    showYearDropdown={showYearDropdown}
                    showTimeInput={includeTimeInFilter}
                  />
                );
              }

              return (
                <>
                  <DateField
                    name={fromFilterField}
                    id={fromFilterField}
                    title={`${title} From`}
                    maxDate={maxDate}
                    showMonthDropdown={showMonthDropdown}
                    showYearDropdown={showYearDropdown}
                    showTimeInput={includeTimeInFilter}
                  />
                  <DateField
                    name={toFilterField}
                    id={toFilterField}
                    title={`${title} To`}
                    maxDate={maxDate}
                    showMonthDropdown={showMonthDropdown}
                    showYearDropdown={showYearDropdown}
                    showTimeInput={includeTimeInFilter}
                  />
                </>
              );
            },
            pillView: (values, updateFilter) => {
              const [from, to] = [
                values[fromFilterField],
                values[toFilterField],
              ];
              if (from || to) {
                return (
                  <Pill
                    onClick={() => {
                      updateFilter({
                        [fromFilterField]: undefined,
                        [toFilterField]: undefined,
                      });
                    }}
                  >{`${formatDate(from, !includeTimeInFilter)} - ${formatDate(
                    to,
                    !includeTimeInFilter,
                  )}`}</Pill>
                );
              }

              return null;
            },
          }
        : undefined,
  };
}
