import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useState } from 'react';
import { useMutation, useQuery } from 'urql';

import {
  Card,
  CardCloseButton,
  CardOptions,
  ErrorMessage,
  Form,
  SubmitButton,
  TextField,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import {
  ChangeAssignedSsnMutation,
  ChangeAssignedSsnMutationVariables,
  PlayerSsnFormQuery,
} from './__generated__/PlayerSsnForm';

const query = graphql`
  query SanityPlayerSsnForm {
    sanityPlayerSsnForm {
      title {
        ...SanityLocaleString
      }
      ssn {
        ...SanityLocaleString
      }
      submit {
        ...SanityLocaleString
      }
    }
  }
`;

const playerQuery = gql`
  query PlayerSsnForm($playerId: ID!) {
    player(playerId: $playerId) {
      id
      ssn
    }
  }
`;

const playerMutation = gql`
  mutation ChangeAssignedSsn($playerId: ID!, $ssn: String!) {
    changeAssignedSsn(playerId: $playerId, ssn: $ssn) {
      id
      ssn
    }
  }
`;

const PlayerSsnForm: FC<{ playerId: string }> = ({ playerId }) => {
  const staticData = useStaticQuery<Queries.SanityPlayerSsnFormQuery>(query);

  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();

  const form = staticData.sanityPlayerSsnForm;

  const [{ data }] = useQuery<PlayerSsnFormQuery>({
    query: playerQuery,
    variables: { playerId },
  });

  const [updatePlayerState, updatePlayer] = useMutation<
    ChangeAssignedSsnMutation,
    ChangeAssignedSsnMutationVariables
  >(playerMutation);

  const defaultValues = {
    ssn: data?.player?.ssn,
  };

  const { close } = useModalContext();

  const { t } = useTranslate();

  const onSubmit = (values: typeof defaultValues) => {
    if (!data?.player?.id) {
      return;
    }

    updatePlayer({
      playerId: data?.player.id,
      ssn: values.ssn || '',
    }).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      } else if (close) {
        close();
      }
    });
  };

  if (!form || !data) {
    return null;
  }

  if (!data) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">
        <Form
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          className="grid gap-6"
        >
          <div className="grid grid-cols-2 gap-6">
            <div>
              <TextField
                title={t(form.ssn)}
                name="ssn"
                id="PlayerSsnForm__ssn"
                className="col-span-full"
              />
            </div>
          </div>
          <ErrorMessage message={errorMessage} />
          <SubmitButton
            value={t(form.submit)}
            disabled={updatePlayerState.fetching}
          />
        </Form>
      </div>
    </Card>
  );
};

export default PlayerSsnForm;
