import {
  BooleanParam,
  DateTimeParam,
  NumberParam,
  StringParam,
  withDefault,
} from 'use-query-params';

import { PaymentSearchOrder } from '@/globalTypes';
import {
  AdjustmentCategoryParam,
  AdjustmentTypeParam,
  BrandParam,
  PaymentSearchOrderParam,
  PaymentStatusesParam,
  PaymentTypesParam,
  TransactionTypesParam,
} from '@/utils/query/params';

export const queryParams = {
  adjustmentCategory: AdjustmentCategoryParam,
  adjustmentType: AdjustmentTypeParam,
  after: StringParam,
  before: StringParam,
  brand: BrandParam,
  currency: StringParam,
  desc: withDefault(BooleanParam, true),
  first: withDefault(NumberParam, 20),
  from: DateTimeParam,
  id: StringParam,
  last: NumberParam,
  maxAmount: NumberParam,
  minAmount: NumberParam,
  orderBy: withDefault(
    PaymentSearchOrderParam,
    PaymentSearchOrder.initializedAt,
  ),
  paymentMethod: StringParam,
  paymentStatus: PaymentStatusesParam,
  playerId: StringParam,
  rawId: StringParam,
  to: DateTimeParam,
  transactionTypes: TransactionTypesParam,
  paymentTypes: PaymentTypesParam,
};
