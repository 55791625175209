import gql from 'graphql-tag';
import { useQuery } from 'urql';
import { useQueryParams } from 'use-query-params';

import { useElasticIndexPrefix } from '@/contexts';
import { mapVariables } from '@/utils';
import formatDate from '@/utils/formatter/formatDate';
import { makeOrderBy, makePagination } from '@/utils/pagination';
import {
  PlayerSearchQuery,
  PlayerSearchQueryVariables,
} from './__generated__/usePlayerSearchResults';
import { queryParams } from './queryParams';

const PLAYER_SEARCH_QUERY = gql`
  query PlayerSearch(
    $accountStatus: AccountStatusEnum
    $after: String
    $before: String
    $birthDate: LocalDate
    $brand: BrandEnum
    $city: String
    $countryCode: String
    $desc: Boolean
    $email: EmailAddress
    $first: Int
    $firstName: String
    $id: String
    $indexPrefix: String
    $lastLoginFrom: OffsetDateTime
    $lastLoginIp: String
    $lastLoginIpCountryCode: String
    $lastLoginTo: OffsetDateTime
    $lastName: String
    $maxBalance: PositiveBigDecimal
    $minBalance: PositiveBigDecimal
    $orderBy: PlayerSearchOrder
    $phoneNumber: String
    $registeredIp: String
    $registeredIpCountryCode: String
    $registrationStatus: RegistrationStatusFilter
    $ssn: String
    $street: String
    $zipCode: String
    $playerGlobalUUID: String
  ) {
    viewer {
      id
      brands {
        code
        name
      }
      playersSearchV2(
        accountStatus: $accountStatus
        after: $after
        before: $before
        birthDate: $birthDate
        brand: $brand
        city: $city
        countryCode: $countryCode
        desc: $desc
        email: $email
        first: $first
        firstName: $firstName
        id: $id
        indexPrefix: $indexPrefix
        lastLoginFrom: $lastLoginFrom
        lastLoginIp: $lastLoginIp
        lastLoginIpCountryCode: $lastLoginIpCountryCode
        lastLoginTo: $lastLoginTo
        lastName: $lastName
        maxBalance: $maxBalance
        minBalance: $minBalance
        orderBy: $orderBy
        phoneNumber: $phoneNumber
        registeredIp: $registeredIp
        registeredIpCountryCode: $registeredIpCountryCode
        registrationStatus: $registrationStatus
        ssn: $ssn
        street: $street
        zipCode: $zipCode
        playerGlobalUUID: $playerGlobalUUID
      ) {
        edges {
          node {
            playerId
            playerGlobalUUID
            rawPlayerId
            firstName
            lastName
            birthDate
            countryCode
            phoneNumber
            ssn
            player {
              id
              selfExclusionDetails {
                id
                exclusionEndsAt
                willBeCancelledAt
                pending {
                  exclusionEndsAt
                  configuredAt
                  activeFrom
                }
              }
            }
            addressInfo {
              street
              city
              zipCode
            }
            emailInfo {
              email
            }
            registrationInfoV2 {
              registeredAt
              registrationIp
              registrationIpCountryCode
            }
            loginInfo {
              lastLoginAt
              lastLoginIp
              lastLoginIpCountryCode
            }
            balance
            accountStatus
            brand {
              id
              name
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;

export default function usePlayerSearchResults() {
  const [query, setQuery] = useQueryParams(queryParams);
  const { indexPrefix } = useElasticIndexPrefix();
  const [{ data, fetching }, refresh] = useQuery<
    PlayerSearchQuery,
    PlayerSearchQueryVariables
  >({
    query: PLAYER_SEARCH_QUERY,
    variables: {
      first: 20,
      indexPrefix,
      ...mapVariables({
        ...query,
        birthDate: query.birthDate
          ? formatDate(query.birthDate, true)
          : undefined,
      }),
    },
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    brands: data?.viewer.brands,
    updateFilter: (values: {}) => setQuery(values, 'replaceIn'),
    ...makeOrderBy(query, setQuery),
    ...makePagination(query, setQuery, data?.viewer.playersSearchV2.pageInfo),
    playerSearchItems: data?.viewer.playersSearchV2?.edges?.map(
      (edge) => edge?.node,
    ),
  };
}
