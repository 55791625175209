import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'urql';

import {
  Button,
  Card,
  CardCloseButton,
  CardOptions,
  CheckboxField,
  ConfirmButton,
  DateField,
  ErrorMessage,
  NakedForm,
  SelectField,
  SubmitButton,
  TextareaField,
  useModalContext,
} from '@/components';
import FileViewer from '@/components/FileViewer';
import { useTranslate } from '@/contexts';
import { DocumentType, DocumentVerificationType } from '@/globalTypes';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import { getDocumentTypeName } from '@/utils/document-types';
import {
  ApproveDocumentMutation,
  ApproveDocumentMutationVariables,
  DeleteDocumentMutation,
  DeleteDocumentMutationVariables,
  GetDocumentUrlMutation,
  GetDocumentUrlMutationVariables,
  RejectDocumentMutation,
  RejectDocumentMutationVariables,
  ReviewDocumentForm_documentFragment,
} from './__generated__/ReviewDocumentForm';

const query = graphql`
  query SanityReviewDocumentForm {
    sanityReviewDocumentForm {
      title {
        ...SanityLocaleString
      }
      documentVerificationTypesLabel {
        ...SanityLocaleString
      }
      documentTypeLabel {
        ...SanityLocaleString
      }
      expiryDateLabel {
        ...SanityLocaleString
      }
      commentLabel {
        ...SanityLocaleString
      }
      approve {
        ...SanityLocaleString
      }
      reject {
        ...SanityLocaleString
      }
      delete {
        ...SanityLocaleString
      }
    }
  }
`;

export const reviewDocumentForm_document = gql`
  fragment ReviewDocumentForm_document on Document {
    id
    documentType
    verificationTypes
    verifiedAt
    expirationDate
    status
    verifiedBy {
      ... on PlayerInitiator {
        player {
          firstName
          lastName
          email
        }
      }
      ... on AgentInitiator {
        agent {
          firstName
          lastName
          email
        }
      }
    }
    deletedAt
    deletedBy {
      ... on PlayerInitiator {
        player {
          firstName
          lastName
          email
        }
      }
      ... on AgentInitiator {
        agent {
          firstName
          lastName
          email
        }
      }
    }
  }
`;

const approveDocumentMutation = gql`
  mutation ApproveDocument(
    $documentId: ID!
    $documentType: DocumentType!
    $documentVerificationTypes: [DocumentVerificationType!]!
    $expirationDate: LocalDate
    $comment: String!
  ) {
    approveDocumentV2(
      documentId: $documentId
      documentType: $documentType
      documentVerificationTypes: $documentVerificationTypes
      expirationDate: $expirationDate
      comment: $comment
    ) {
      ...ReviewDocumentForm_document
    }
  }
  ${reviewDocumentForm_document}
`;

const rejectDocumentMutation = gql`
  mutation RejectDocument($documentId: ID!, $comment: String!) {
    rejectDocumentV2(documentId: $documentId, comment: $comment) {
      ...ReviewDocumentForm_document
    }
  }
  ${reviewDocumentForm_document}
`;

export const deleteDocumentMutation = gql`
  mutation DeleteDocument($documentId: ID!) {
    deleteDocument(documentId: $documentId) {
      ...ReviewDocumentForm_document
    }
  }
  ${reviewDocumentForm_document}
`;

const getDocumentUrlMutation = gql`
  mutation GetDocumentUrl($documentId: ID!) {
    generateGetDocumentUrl(documentId: $documentId) {
      url
    }
  }
`;

const getDefaultValues = (document: ReviewDocumentForm_documentFragment) => ({
  documentType: document.documentType ?? DocumentType.ID,
  documentVerificationTypes: (document.verificationTypes.reduce(
    (acc, entry) => ({ ...acc, [entry]: true }),
    {},
  ) ?? {}) as Record<string, boolean>,
  expirationDate: document.expirationDate ?? '',
  comment: '',
});

const ReviewDocumentForm: FC<{
  document: ReviewDocumentForm_documentFragment;
}> = ({ document }) => {
  const staticData =
    useStaticQuery<Queries.SanityReviewDocumentFormQuery>(query);

  const { close } = useModalContext();
  const { t } = useTranslate();
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();

  const defaultValues = getDefaultValues(document);

  const methods = useForm({ defaultValues });

  const form = staticData.sanityReviewDocumentForm;

  const [approvedDocumentState, approveDocument] = useMutation<
    ApproveDocumentMutation,
    ApproveDocumentMutationVariables
  >(approveDocumentMutation);

  const [rejectDocumentState, rejectDocument] = useMutation<
    RejectDocumentMutation,
    RejectDocumentMutationVariables
  >(rejectDocumentMutation);

  const [deleteDocumentState, deleteDocument] = useMutation<
    DeleteDocumentMutation,
    DeleteDocumentMutationVariables
  >(deleteDocumentMutation);

  const [documentUrlState, getDocumentUrl] = useMutation<
    GetDocumentUrlMutation,
    GetDocumentUrlMutationVariables
  >(getDocumentUrlMutation);

  useEffect(() => {
    if (document.id) {
      getDocumentUrl({ documentId: document.id });
    }
  }, [document.id, getDocumentUrl]);

  const documentTypeOptions = useMemo(() => {
    const types: DocumentType[] = [
      DocumentType.ID,
      DocumentType.UtilityBill,
      DocumentType.BankStatement,
      DocumentType.Other,
    ];

    return types.map((t) => ({
      label: getDocumentTypeName(t),
      value: t,
    }));
  }, []);

  const onSubmit = (values: typeof defaultValues) => {
    if (document.id) {
      const documentVerificationTypes = Object.keys(
        values.documentVerificationTypes,
      ).filter(
        (key) => values.documentVerificationTypes[key],
      ) as DocumentVerificationType[];

      approveDocument({
        ...values,
        documentVerificationTypes,
        documentId: document.id,
        expirationDate: values.expirationDate || null,
      }).then((res) => {
        if (!isMounted) {
          return;
        }

        if (res.error?.message) {
          setErrorMessage(res.error.message);
        } else {
          close?.();
        }
      });
    }
  };

  const onReject = () => {
    const comment = methods.getValues('comment');

    rejectDocument({
      documentId: document.id,
      comment,
    }).then((res) => {
      if (!isMounted) {
        return;
      }

      if (res.error?.message) {
        setErrorMessage(res.error.message);
      } else {
        close?.();
      }
    });
  };

  const onDelete = () => {
    deleteDocument({
      documentId: document.id,
    }).then((res) => {
      if (!isMounted) {
        return;
      }

      if (res.error?.message) {
        setErrorMessage(res.error.message);
      } else {
        close?.();
      }
    });
  };

  if (!form || !defaultValues) {
    return null;
  }

  const disableSubmitButtons =
    approvedDocumentState.fetching ||
    rejectDocumentState.fetching ||
    deleteDocumentState.fetching;

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3 space-y-6">
        {documentUrlState.data?.generateGetDocumentUrl.url && (
          <FileViewer
            filePath={documentUrlState.data.generateGetDocumentUrl.url}
          />
        )}

        <NakedForm methods={methods} onSubmit={onSubmit} className="grid gap-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            <div className="space-y-1">
              <div className="block tracking-wide text-gray-700 text-sm font-semibold truncate dark:text-white">
                {t(form.documentVerificationTypesLabel)}
              </div>
              <CheckboxField
                title={DocumentVerificationType.Address}
                name={`documentVerificationTypes.${DocumentVerificationType.Address}`}
              />
              <CheckboxField
                title={DocumentVerificationType.Age}
                name={`documentVerificationTypes.${DocumentVerificationType.Age}`}
              />
              <CheckboxField
                title={DocumentVerificationType.Identity}
                name={`documentVerificationTypes.${DocumentVerificationType.Identity}`}
              />
            </div>
            <SelectField
              name="documentType"
              id="ReviewDocumentForm__documentType"
              options={documentTypeOptions}
              title={t(form.documentTypeLabel)}
            />
            <DateField
              title={t(form.expiryDateLabel)}
              name="expiryDate"
              id="ReviewDocumentForm__expiryDate"
              showYearDropdown
              showMonthDropdown
            />
            <TextareaField
              title={t(form.commentLabel)}
              name="comment"
              id="ReviewDocumentForm__comment"
              rows={3}
            />
          </div>

          <ErrorMessage message={errorMessage} />

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            <div>
              <SubmitButton
                value={t(form.approve)}
                disabled={disableSubmitButtons}
                className="w-full"
              />
            </div>
            <Button
              type="button"
              onClick={onReject}
              variant="secondary"
              style={{ minWidth: 200 }}
              disabled={disableSubmitButtons}
            >
              {t(form.reject)}
            </Button>
            <ConfirmButton
              type="button"
              onClick={onDelete}
              variant="secondary"
              style={{ minWidth: 200 }}
              disabled={disableSubmitButtons}
            >
              {t(form.delete)}
            </ConfirmButton>
          </div>
        </NakedForm>
      </div>
    </Card>
  );
};

export default ReviewDocumentForm;
