import classNames from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import React, { Dispatch, useCallback, useReducer } from 'react';
import { Accept, FileRejection, useDropzone } from 'react-dropzone';

import { useTranslate } from '@/contexts';
import { Nullable } from '@/types';
import { FileAction, fileUploadReducer } from './reducer';

export const useFileDropFile = () => {
  const [state, dispatch] = useReducer(fileUploadReducer, { file: undefined });

  return {
    file: state.file,
    dispatch,
  };
};

const query = graphql`
  query SanityFileDropBit {
    sanityFileDropBit {
      instructions {
        ...SanityLocaleString
      }
    }
  }
`;

type Props = {
  accept: Accept;
  setErrorMessage: (message: Nullable<string>) => void;
  file: Nullable<File>;
  dispatch: Dispatch<FileAction>;
};

const getAcceptedExtensions = (accept: Accept) => {
  // Input: {image/jpeg: ['.jpeg', '.jpg'], image/png: ['.png']}
  // Output: '.jpeg, .jpg, .png'
  return Object.values(accept).flat().join(', ');
};

export const FileDrop = ({
  accept,
  setErrorMessage,
  file,
  dispatch,
}: Props) => {
  const bit =
    useStaticQuery<Queries.SanityFileDropBitQuery>(query).sanityFileDropBit;
  const { t } = useTranslate();

  const onDrop = useCallback(
    async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      setErrorMessage(null);

      const [rejectedFile] = rejectedFiles;

      if (rejectedFile) {
        dispatch({ type: 'RESET' });
        return setErrorMessage(rejectedFile.errors[0].message);
      }

      dispatch({
        type: 'SET_FILE',
        file: acceptedFiles[0],
      });
    },
    [dispatch, setErrorMessage],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    onDrop,
    maxFiles: 1,
  });

  return (
    <div
      {...getRootProps()}
      className={classNames(
        'flex flex-col items-center justify-center h-32 border-2 border-dashed border-gray-300 dark:border-gray-700 text-center p-2',
        {
          'border-primary bg-gray-100 dark:bg-gray-600': isDragActive,
        },
      )}
    >
      <input {...getInputProps()} />
      <div className="text-gray-500 text-sm">
        {file ? file.name : t(bit?.instructions)}
      </div>
      {!file && (
        <em className="text-gray-400 text-xs">
          Only "{getAcceptedExtensions(accept)}" extensions will be accepted
        </em>
      )}
    </div>
  );
};
