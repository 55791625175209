import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useState } from 'react';
import { useMutation } from 'urql';

import {
  Card,
  CardCloseButton,
  CardOptions,
  ErrorMessage,
  Form,
  SubmitButton,
  useModalContext,
} from '@/components';
import { SelectField, TextareaField } from '@/components/form';
import { useTranslate } from '@/contexts';
import {
  ReviewStatus,
  ReviewTriggerArg,
  SubmitReviewStatus,
} from '@/globalTypes';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import {
  SubmitReviewStatusFormMutation,
  SubmitReviewStatusFormMutationVariables,
} from './__generated__/SubmitReviewStatusForm';

const query = graphql`
  query SanitySubmitReviewStatusForm {
    sanitySubmitReviewStatusForm {
      title {
        ...SanityLocaleString
      }
      adjustment {
        ...SanityLocaleString
      }
      note {
        ...SanityLocaleString
      }
      confirm {
        ...SanityLocaleString
      }
    }
  }
`;

const submitReviewStatusMutation = gql`
  mutation SubmitReviewStatusForm(
    $playerGlobalId: ID!
    $submitReviewStatus: SubmitReviewStatus!
    $comment: String!
    $reviewTrigger: ReviewTriggerArg!
  ) {
    submitReview(
      playerGlobalId: $playerGlobalId
      reviewStatus: $submitReviewStatus
      comment: $comment
      reviewTrigger: $reviewTrigger
    ) {
      playerGlobalId
      reviewStatusInfo {
        status
      }
      reviewTrigger {
        byRisks {
          regionId
          riskId
        }
        byOverallRiskLevel
      }
    }
  }
`;

type FormValues = {
  submitReviewStatus: SubmitReviewStatus | '';
  comment: string;
};

const stripTypenamesFromObject = (obj: any, propToDelete: string) => {
  for (const property in obj) {
    if (typeof obj[property] === 'object') {
      const newData = stripTypenamesFromObject(obj[property], propToDelete);
      obj[property] = newData;
    } else if (property === propToDelete) {
      delete obj[property];
    }
  }

  return obj;
};

const SubmitReviewStatusForm: FC<{
  playerGlobalId: string;
  currentReviewStatus: ReviewStatus;
  reviewTrigger: ReviewTriggerArg;
}> = ({ playerGlobalId, currentReviewStatus, reviewTrigger }) => {
  const staticData =
    useStaticQuery<Queries.SanitySubmitReviewStatusFormQuery>(query);

  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();
  const form = staticData.sanitySubmitReviewStatusForm;

  const [changeRiskReviewStatusState, changeRiskReviewStatus] = useMutation<
    SubmitReviewStatusFormMutation,
    SubmitReviewStatusFormMutationVariables
  >(submitReviewStatusMutation);

  const defaultValues: FormValues = {
    submitReviewStatus: '',
    comment: '',
  };

  const { close } = useModalContext();
  const { t } = useTranslate();

  const onSubmit = (values: FormValues) => {
    if (!values.submitReviewStatus) {
      return;
    }
    return changeRiskReviewStatus({
      playerGlobalId,
      submitReviewStatus: values.submitReviewStatus,
      comment: values.comment,
      reviewTrigger: stripTypenamesFromObject(reviewTrigger, '__typename'),
    }).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      } else if (close) {
        close();
      }
    });
  };

  return form ? (
    <Card
      size="lg"
      title={`${t(form.title)}: ${currentReviewStatus}`}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">
        <Form
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          className="grid grid-cols-1 sm:grid-cols-1s gap-6"
        >
          <SelectField
            name="submitReviewStatus"
            id="submitReviewStatusForm__submitReviewStatus"
            title={t(form.adjustment)}
            options={[
              {
                label: SubmitReviewStatus.ReviewPending,
                value: SubmitReviewStatus.ReviewPending,
              },
              {
                label: SubmitReviewStatus.ReviewRejected,
                value: SubmitReviewStatus.ReviewRejected,
              },
              {
                label: SubmitReviewStatus.ReviewApproved,
                value: SubmitReviewStatus.ReviewApproved,
              },
            ]}
            required
          />
          <TextareaField
            title={t(form.note)}
            name="comment"
            id="submitReviewStatusForm__comment"
            className="grid-cols-1"
            required
          />

          <ErrorMessage message={errorMessage} />
          <SubmitButton
            value={t(form.confirm)}
            disabled={changeRiskReviewStatusState.fetching}
          />
        </Form>
      </div>
    </Card>
  ) : null;
};

export default SubmitReviewStatusForm;
