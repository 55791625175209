import React from 'react';

import { Pill, TextField } from '@/components';
import { Nullable } from '@/types';
import formatPhoneNumber from '@/utils/formatter/formatPhoneNumber';
import { DataDef, DataDefProps, FilterType } from '../types';

const transformFilterValue = (value: Nullable<string>) => {
  if (value) {
    return value.replace(/ /g, '');
  }
  return value;
};

export const phoneNumberFilter = ({
  field,
  filterField,
  title,
}: {
  field: string;
  filterField: string;
  title: string;
}): FilterType => ({
  field,
  transformDefaultValues: (values) => {
    const value = values[filterField];
    return {
      ...values,
      [filterField]: transformFilterValue(value) || '',
    };
  },
  transformValues: (values) => {
    const value = values[filterField];
    return {
      ...values,
      [filterField]: transformFilterValue(value) || undefined,
    };
  },
  component: () => (
    <TextField name={filterField} id={filterField} title={title} />
  ),

  pillView: (values, updateFilter) => {
    const value = values[filterField];
    if (value) {
      return (
        <Pill onClick={() => updateFilter({ [filterField]: undefined })}>
          {value}
        </Pill>
      );
    }
    return null;
  },
});

export function phoneNumberValue<O, T extends Record<string, any>>({
  field,
  filterField,
  getValue,
  title,
  ...rest
}: DataDefProps<O, T> & { filterField?: string }): DataDef<O, T> {
  return {
    title,
    field,
    ...rest,
    filter: filterField
      ? phoneNumberFilter({ field, title, filterField })
      : undefined,
    component: ({ row }) => {
      const value = getValue ? getValue({ row }) : row[field];
      return <>{formatPhoneNumber(value)}</>;
    },
  };
}
