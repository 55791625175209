import { graphql, useStaticQuery } from 'gatsby';
import queryString from 'query-string';
import { useCallback } from 'react';
import { generatePath } from 'react-router-dom';

const BIT_STATIC_QUERY = graphql`
  query SanityLinksBit {
    sanityLinksBit {
      gamePage {
        path {
          current
        }
      }
      playerGamePage {
        path {
          current
        }
      }
      playerGameRoundPage {
        path {
          current
        }
      }
      playerOverviewPage {
        path {
          current
        }
      }
      globalPlayerOverviewPage {
        path {
          current
        }
      }
      playerTransactionPage {
        path {
          current
        }
      }
      playerRewardsPage {
        path {
          current
        }
      }
      rewardDetailPage {
        path {
          current
        }
      }
      playerRiskAssessmentPage {
        path {
          current
        }
      }
      playerRgRiskAssessmentPage {
        path {
          current
        }
      }
      transactionPage {
        path {
          current
        }
      }
      challengeDetailPage {
        path {
          current
        }
      }
      paymentInspectionPage {
        path {
          current
        }
      }
    }
  }
`;

const useBuildLink = (
  linkKey: keyof NonNullable<Queries.SanityLinksBitQuery['sanityLinksBit']>,
) => {
  const bit =
    useStaticQuery<Queries.SanityLinksBitQuery>(
      BIT_STATIC_QUERY,
    ).sanityLinksBit;

  const linkPath = linkKey && bit?.[linkKey]?.path?.current;

  const getLink = useCallback(
    (params = {}) => {
      if (!linkPath) {
        return null;
      }
      return generatePath(linkPath, params);
    },
    [linkPath],
  );

  return getLink;
};

export const useGetGamePageLink = () => {
  const buildLink = useBuildLink('gamePage');

  return useCallback((gameId: string) => buildLink({ gameId }), [buildLink]);
};

export const useGetPlayerGamePageLink = () => {
  const buildLink = useBuildLink('playerGamePage');

  return useCallback(
    (playerId: string) => buildLink({ playerId }),
    [buildLink],
  );
};

export const useGetPlayerGameRoundPageLink = () => {
  const buildLink = useBuildLink('playerGameRoundPage');

  return useCallback(
    (playerId: string) => buildLink({ playerId }),
    [buildLink],
  );
};

export const useGetPlayerOverviewPageLink = () => {
  const buildLink = useBuildLink('playerOverviewPage');

  return useCallback(
    (playerId: string) => buildLink({ playerId }),
    [buildLink],
  );
};

export const useGetPlayerRiskAssessmentOverviewPageLink = () => {
  const buildLink = useBuildLink('playerRiskAssessmentPage');

  return useCallback(
    (playerGlobalId: string) => buildLink({ playerGlobalId }),
    [buildLink],
  );
};

export const useGetPlayerRgRiskAssessmentOverviewPageLink = () => {
  const buildLink = useBuildLink('playerRgRiskAssessmentPage');

  return useCallback(
    (playerGlobalId: string) => buildLink({ playerGlobalId }),
    [buildLink],
  );
};

export const useGetGlobalPlayerOverviewPageLink = () => {
  const buildLink = useBuildLink('globalPlayerOverviewPage');

  return useCallback(
    (playerGlobalId: string) => buildLink({ playerGlobalId }),
    [buildLink],
  );
};

export const useGetPlayerTransactionPageLink = () => {
  const buildLink = useBuildLink('playerTransactionPage');

  return useCallback(
    (playerId: string) => buildLink({ playerId }),
    [buildLink],
  );
};

export const useGetPlayerRewardsPageLink = () => {
  const buildLink = useBuildLink('playerRewardsPage');

  return useCallback(
    (playerId: string) => buildLink({ playerId }),
    [buildLink],
  );
};

export const useGetRewardDetailPageLink = () => {
  const buildLink = useBuildLink('rewardDetailPage');

  return useCallback(
    (rewardId: string) => buildLink({ rewardId }),
    [buildLink],
  );
};

export const useGetChallengeDetailPageLink = () => {
  const buildLink = useBuildLink('challengeDetailPage');

  return useCallback(
    (challengeId: string) => buildLink({ challengeId }),
    [buildLink],
  );
};

export const useGetTransactionPageLink = () => {
  const buildLink = useBuildLink('transactionPage');

  return useCallback(
    (rawTransactionId: string) => {
      return `${buildLink()}?${queryString.stringify({ rawTransactionId })}`;
    },
    [buildLink],
  );
};

export const useGetPaymentPageLink = () => {
  const buildLink = useBuildLink('paymentInspectionPage');

  return useCallback(
    (rawPaymentId: string) => {
      return `${buildLink()}?${queryString.stringify({ rawPaymentId })}`;
    },
    [buildLink],
  );
};
