import gql from 'graphql-tag';
import { useQuery } from 'urql';

import { useElasticIndexPrefix } from '@/contexts';
import { useQueryParamsWithTime } from '@/hooks';
import { mapVariables } from '@/utils';
import { makeOrderBy, makePagination } from '@/utils/pagination';
import {
  GlobalPlayerPaymentsQuery,
  GlobalPlayerPaymentsQueryVariables,
} from './__generated__/useGlobalPlayerPayments';
import { queryParams } from './queryParams';

const GLOBAL_PLAYER_PAYMENTS_QUERY = gql`
  query GlobalPlayerPayments(
    $after: String
    $before: String
    $brand: BrandEnum
    $desc: Boolean
    $first: Int
    $from: OffsetDateTime
    $id: String
    $indexPrefix: String
    $last: Int
    $orderBy: PaymentSearchOrder
    $paymentStatus: PaymentStatus
    $paymentType: PaymentType
    $playerGlobalId: ID!
    $rawId: String
    $to: OffsetDateTime
  ) {
    viewer {
      id
      brands {
        name
        code
      }
    }
    playerGlobal(playerGlobalId: $playerGlobalId) {
      id
      globalPayments(
        after: $after
        before: $before
        brand: $brand
        desc: $desc
        first: $first
        from: $from
        id: $id
        indexPrefix: $indexPrefix
        last: $last
        orderBy: $orderBy
        paymentStatus: $paymentStatus
        paymentType: $paymentType
        rawId: $rawId
        to: $to
      ) {
        edges {
          node {
            brand {
              id
              name
              code
            }
            rawPaymentId
            initialAmount
            amount
            processedAt
            completedAt
            cancelledAt
            rollbackAt
            initializedAt
            paymentType
            paymentStatus
            openingBalance
            closingBalance
            instrument
            paymentMethod
            statusMessage
            extTransactionId
            currency
            paymentFee {
              money {
                amount
                currency
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;

export default function useGlobalPlayerPayments(playerGlobalId: string) {
  const [query, setQuery] = useQueryParamsWithTime(queryParams, {
    fromAndToOptional: true,
  });
  const { indexPrefix } = useElasticIndexPrefix();
  const [{ data, fetching }, refresh] = useQuery<
    GlobalPlayerPaymentsQuery,
    GlobalPlayerPaymentsQueryVariables
  >({
    query: GLOBAL_PLAYER_PAYMENTS_QUERY,
    variables: {
      playerGlobalId,
      indexPrefix,
      ...mapVariables(query),
    },
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    brands: data?.viewer.brands,
    updateFilter: (values: {}) => setQuery(values, 'replaceIn'),
    ...makeOrderBy(query, setQuery),
    ...makePagination(
      query,
      setQuery,
      data?.playerGlobal.globalPayments.pageInfo,
    ),
    payments: data?.playerGlobal.globalPayments?.edges?.map(
      (edge) => edge?.node,
    ),
  };
}
