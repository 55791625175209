import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useState } from 'react';
import { useMutation, useQuery } from 'urql';

import {
  Card,
  CardCloseButton,
  CardOptions,
  ErrorMessage,
  Form,
  PhoneNumberField,
  SubmitButton,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import {
  ChangePlayerPhoneNumberMutation,
  PlayerPhoneNumberFormQuery,
} from './__generated__/PlayerPhoneNumberForm';

const query = graphql`
  query SanityPlayerPhoneNumberForm {
    sanityPlayerPhoneNumberForm {
      title {
        ...SanityLocaleString
      }
      phoneNumber {
        ...SanityLocaleString
      }
      submit {
        ...SanityLocaleString
      }
    }
  }
`;

const playerQuery = gql`
  query PlayerPhoneNumberForm($playerId: ID!) {
    player(playerId: $playerId) {
      id
      phoneNumber
      brand {
        code
      }
    }
  }
`;

const playerMutation = gql`
  mutation ChangePlayerPhoneNumber(
    $playerId: ID!
    $phoneNumber: String!
    $brand: BrandEnum!
  ) {
    changePhoneNumber(
      playerId: $playerId
      phoneNumber: $phoneNumber
      brand: $brand
    ) {
      id
      phoneNumber
    }
  }
`;

const PlayerPhoneNumberForm: FC<{ playerId: string }> = ({ playerId }) => {
  const staticData =
    useStaticQuery<Queries.SanityPlayerPhoneNumberFormQuery>(query);

  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();

  const form = staticData.sanityPlayerPhoneNumberForm;

  const [{ data }] = useQuery<PlayerPhoneNumberFormQuery>({
    query: playerQuery,
    variables: { playerId },
  });

  const [changePlayerPhoneNumberState, changePlayerPhoneNumber] =
    useMutation<ChangePlayerPhoneNumberMutation>(playerMutation);

  const defaultValues = {
    phoneNumber: data?.player?.phoneNumber,
  };

  const { close } = useModalContext();

  const { t } = useTranslate();

  const onSubmit = (values: typeof defaultValues) => {
    changePlayerPhoneNumber({
      playerId: data?.player.id,
      brand: data?.player.brand.code,
      phoneNumber: values.phoneNumber,
    }).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      } else if (close) {
        close();
      }
    });
  };

  if (!form || !defaultValues) {
    return null;
  }
  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">
        <Form
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          className="grid grid-cols-2 sm:grid-cols-3 gap-6"
        >
          <PhoneNumberField
            title={t(form.phoneNumber)}
            name="phoneNumber"
            id="PlayerPhoneNumberForm__phoneNumber"
            className="col-span-full"
          />

          <ErrorMessage message={errorMessage} />
          <SubmitButton
            value={t(form.submit)}
            disabled={changePlayerPhoneNumberState.fetching}
          />
        </Form>
      </div>
    </Card>
  );
};

export default PlayerPhoneNumberForm;
