import { useParams } from '@reach/router';
import { formatISO, subDays } from 'date-fns';
import { graphql } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useCallback, useMemo } from 'react';
import { useQuery } from 'urql';

import {
  LimitPeriodDetailList,
  limitPeriodDetailList_periodLimit,
} from '@/bits';
import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  ControlledModal,
  InlineIconButton,
  Value,
} from '@/components';
import { Bar } from '@/components/Bar';
import { InformationIcon, RefreshIcon } from '@/components/icons';
import { TranslateFn, useTranslate } from '@/contexts';
import { LimitType, LimitTypeEnum } from '@/globalTypes';
import { Nullable } from '@/types';
import formatDate from '@/utils/formatter/formatDate';
import formatMoney from '@/utils/formatter/formatMoney';
import {
  adjustmentSummary_stats,
  depositSummary_stats,
  formatCurrencyAmountRecord,
  getAdjustmentsSummaryRecord,
  getDepositsSummaryRecord,
} from '@/utils/stats-with-currency';
import {
  PlayerWalletBoxQuery,
  PlayerWalletBoxQueryVariables,
} from './__generated__/component';

export const Fragment = graphql`
  fragment SanityPlayerWalletBlock on SanityPlayerWalletBlock {
    title {
      ...SanityLocaleString
    }
    balanceLabel {
      ...SanityLocaleString
    }
    reservedBalanceLabel {
      ...SanityLocaleString
    }
    upcomingCashbackLabel {
      ...SanityLocaleString
    }
    depositLimitsLabel {
      ...SanityLocaleString
    }
    lossLimitsLabel {
      ...SanityLocaleString
    }
    withdrawalLimitsLabel {
      ...SanityLocaleString
    }
    last24hDepositsLabel {
      ...SanityLocaleString
    }
    last24hAdjustmentsLabel {
      ...SanityLocaleString
    }
    sowExpendingUpperBoundLabel {
      ...SanityLocaleString
    }
    dailyLabel {
      ...SanityLocaleString
    }
    weeklyLabel {
      ...SanityLocaleString
    }
    monthlyLabel {
      ...SanityLocaleString
    }
    resetAtLabel {
      ...SanityLocaleString
    }
  }
`;

const QUERY = gql`
  query PlayerWalletBox(
    $playerId: ID!
    $last24hFrom: OffsetDateTime!
    $now: OffsetDateTime!
  ) {
    player(playerId: $playerId) {
      id
      upcomingCashbackV2 {
        amount
        currency
      }
      sourceOfWealth {
        expendingUpperBound
      }
      wallet {
        id
        currency
        balance
        reservedBalance
        withdrawalLimit {
          dayLimit {
            value
            available
            currentPeriodEndTime
            ...LimitPeriodDetailList_periodLimit
          }
          monthLimit {
            value
            available
            currentPeriodEndTime
            ...LimitPeriodDetailList_periodLimit
          }
        }
        depositLimit {
          dayLimit {
            value
            available
            currentPeriodEndTime
            ...LimitPeriodDetailList_periodLimit
          }
          weekLimit {
            value
            available
            currentPeriodEndTime
            ...LimitPeriodDetailList_periodLimit
          }
          monthLimit {
            value
            available
            currentPeriodEndTime
            ...LimitPeriodDetailList_periodLimit
          }
        }
        lossLimit {
          dayLimit {
            value
            available
            currentPeriodEndTime
            ...LimitPeriodDetailList_periodLimit
          }
          weekLimit {
            value
            available
            currentPeriodEndTime
            ...LimitPeriodDetailList_periodLimit
          }
          monthLimit {
            value
            available
            currentPeriodEndTime
            ...LimitPeriodDetailList_periodLimit
          }
        }
      }
      last24hStats: stats(from: $last24hFrom, to: $now, granularity: All) {
        rows {
          ...AdjustmentSummary_stats
          ...DepositSummary_stats
        }
      }
    }
  }
  ${limitPeriodDetailList_periodLimit}
  ${adjustmentSummary_stats}
  ${depositSummary_stats}
`;

type LimitPeriod =
  | NonNullable<
      NonNullable<
        PlayerWalletBoxQuery['player']['wallet']
      >['depositLimit']['dayLimit']
    >
  | NonNullable<
      NonNullable<
        PlayerWalletBoxQuery['player']['wallet']
      >['depositLimit']['weekLimit']
    >
  | NonNullable<
      NonNullable<
        PlayerWalletBoxQuery['player']['wallet']
      >['depositLimit']['monthLimit']
    >
  | NonNullable<
      NonNullable<
        PlayerWalletBoxQuery['player']['wallet']
      >['withdrawalLimit']['dayLimit']
    >
  | NonNullable<
      NonNullable<
        PlayerWalletBoxQuery['player']['wallet']
      >['withdrawalLimit']['monthLimit']
    >;

const LimitInfo = ({
  limit,
  fetching,
  labelTranslation,
  currency,
  limitPeriodType,
  limitTypeTitle,
  resetAt,
  resetAtTranslation,
}: {
  limit: Nullable<LimitPeriod>;
  fetching: boolean;
  labelTranslation: Parameters<TranslateFn>[0];
  currency: Nullable<string>;
  limitTypeTitle: string;
  limitPeriodType: LimitTypeEnum;
  resetAt?: boolean;
  resetAtTranslation?: string;
}) => {
  const { t } = useTranslate();

  const currencyValueFormatter = useCallback(
    (value: Nullable<number>) => formatMoney(value, currency),
    [currency],
  );

  return (
    <div className="flex items-center justify-between">
      <Bar
        loading={fetching}
        value={
          ((limit?.value ?? 0) - (limit?.available ?? 0)) / (limit?.value ?? 0)
        }
      >
        {t(labelTranslation, {
          value: formatMoney((limit?.value ?? 0) - (limit?.available ?? 0)),
          of: formatMoney(limit?.value, currency),
        })}
        {resetAt &&
          ` - ${resetAtTranslation} ${formatDate(limit?.currentPeriodEndTime)}`}
      </Bar>
      <ControlledModal
        content={
          limit && (
            <LimitPeriodDetailList
              limit={limit}
              limitPeriodType={limitPeriodType}
              limitTypeTitle={limitTypeTitle}
              formatValue={currencyValueFormatter}
            />
          )
        }
      >
        <InlineIconButton disabled={!limit}>
          <InformationIcon />
        </InlineIconButton>
      </ControlledModal>
    </div>
  );
};

const PlayerWalletBlock: FC<{
  block: Queries.SanityPlayerWalletBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();
  const params = useParams();
  const isEstonia = process.env.GATSBY_LICENSE === 'EST';

  const dateVariables = useMemo<
    Pick<PlayerWalletBoxQueryVariables, 'last24hFrom' | 'now'>
  >(() => {
    const now = new Date();
    return { now: formatISO(now), last24hFrom: formatISO(subDays(now, 1)) };
  }, []);

  const [{ data, fetching }, refresh] = useQuery<
    PlayerWalletBoxQuery,
    PlayerWalletBoxQueryVariables
  >({
    query: QUERY,
    variables: {
      playerId: params.playerId,
      ...dateVariables,
    },
  });

  const last24hStats = data?.player.last24hStats?.rows;
  const sow = data?.player.sourceOfWealth;

  const last24hAdjustmentSummary = getAdjustmentsSummaryRecord(last24hStats);
  const last24hDepositsSummary = getDepositsSummaryRecord(last24hStats);

  return (
    <Card
      size="md"
      title={t(block.title)}
      options={
        <CardOptions>
          <CardOptionsButton
            className="flex"
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <div className="p-3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
          <Value
            fetching={fetching}
            title={t(block.balanceLabel)}
            value={formatMoney(
              data?.player?.wallet?.balance,
              data?.player?.wallet?.currency,
            )}
          />
          <Value
            fetching={fetching}
            title={t(block.reservedBalanceLabel)}
            value={formatMoney(
              data?.player?.wallet?.reservedBalance,
              data?.player?.wallet?.currency,
            )}
          />
          <Value
            fetching={fetching}
            title={t(block.upcomingCashbackLabel)}
            value={formatMoney(
              data?.player.upcomingCashbackV2?.amount,
              data?.player.upcomingCashbackV2?.currency,
            )}
          />
          <Value
            fetching={fetching}
            title={t(block.last24hDepositsLabel)}
            value={formatCurrencyAmountRecord(last24hDepositsSummary)}
          />
          <Value
            fetching={fetching}
            title={t(block.last24hAdjustmentsLabel)}
            value={formatCurrencyAmountRecord(last24hAdjustmentSummary)}
          />
          <Value
            fetching={fetching}
            title={t(block.sowExpendingUpperBoundLabel)}
            value={formatMoney(
              sow?.expendingUpperBound,
              data?.player.wallet?.currency,
            )}
          />

          {!isEstonia && (
            <div className="col-span-full">
              <label className="text-sm text-gray-500 font-semibold">
                {t(block.depositLimitsLabel)}
              </label>
              <div className="grid gap-2 pt-1">
                <LimitInfo
                  fetching={fetching}
                  limit={data?.player.wallet?.depositLimit?.dayLimit}
                  labelTranslation={block.dailyLabel}
                  currency={data?.player.wallet?.currency}
                  limitPeriodType={LimitTypeEnum.Day}
                  limitTypeTitle={LimitType.Deposit}
                />
                <LimitInfo
                  fetching={fetching}
                  limit={data?.player.wallet?.depositLimit?.weekLimit}
                  labelTranslation={block.weeklyLabel}
                  currency={data?.player.wallet?.currency}
                  limitPeriodType={LimitTypeEnum.Week}
                  limitTypeTitle={LimitType.Deposit}
                />
                <LimitInfo
                  fetching={fetching}
                  limit={data?.player.wallet?.depositLimit?.monthLimit}
                  labelTranslation={block.monthlyLabel}
                  currency={data?.player.wallet?.currency}
                  limitPeriodType={LimitTypeEnum.Month}
                  limitTypeTitle={LimitType.Deposit}
                />
              </div>
            </div>
          )}

          {isEstonia && (
            <div className="col-span-full">
              <label className="text-sm text-gray-500 font-semibold">
                {t(block.lossLimitsLabel)}
              </label>
              <div className="grid gap-2 pt-1">
                <LimitInfo
                  fetching={fetching}
                  limit={data?.player.wallet?.lossLimit?.dayLimit}
                  labelTranslation={block.dailyLabel}
                  currency={data?.player.wallet?.currency}
                  limitPeriodType={LimitTypeEnum.Day}
                  limitTypeTitle={LimitType.Deposit}
                />
                <LimitInfo
                  fetching={fetching}
                  limit={data?.player.wallet?.lossLimit?.weekLimit}
                  labelTranslation={block.weeklyLabel}
                  currency={data?.player.wallet?.currency}
                  limitPeriodType={LimitTypeEnum.Week}
                  limitTypeTitle={LimitType.Deposit}
                />
                <LimitInfo
                  fetching={fetching}
                  limit={data?.player.wallet?.lossLimit?.monthLimit}
                  labelTranslation={block.monthlyLabel}
                  currency={data?.player.wallet?.currency}
                  limitPeriodType={LimitTypeEnum.Month}
                  limitTypeTitle={LimitType.Deposit}
                />
              </div>
            </div>
          )}

          <div className="col-span-full">
            <label className="text-sm text-gray-500 font-semibold">
              {t(block.withdrawalLimitsLabel)}
            </label>
            <div className="grid gap-2 pt-1">
              <LimitInfo
                fetching={fetching}
                limit={data?.player.wallet?.withdrawalLimit?.dayLimit}
                labelTranslation={block.dailyLabel}
                currency={data?.player.wallet?.currency}
                limitPeriodType={LimitTypeEnum.Day}
                limitTypeTitle="Withdrawal"
              />
              <LimitInfo
                fetching={fetching}
                limit={data?.player.wallet?.withdrawalLimit?.monthLimit}
                labelTranslation={block.monthlyLabel}
                currency={data?.player.wallet?.currency}
                limitPeriodType={LimitTypeEnum.Month}
                limitTypeTitle="Withdrawal"
                resetAt={true}
                resetAtTranslation={t(block.resetAtLabel)}
              />
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default PlayerWalletBlock;
