import { formatISO } from 'date-fns';
import { saveAs } from 'file-saver';
import { useMemo, useState } from 'react';
import { gql, useClient } from 'urql';

import { RangeDate } from '@/globalTypes';
import { useQueryParamsWithTime } from '@/hooks';
import { mapVariables } from '@/utils';
import {
  SowReportQuery,
  SowReportQueryVariables,
} from './__generated__/useSourceOfWealthReport';
import { queryParams } from './queryParams';

const SOW_REPORT_QUERY = gql`
  query SowReport(
    $amlLevel: AmlRiskLevel
    $complianceStatus: PlayerComplianceStatus
    $statusChangedAt: RangeDate
    $limitReachedAt: RangeDate
    $submittedAt: RangeDate
    $orderBy: SoWSearchOrder
    $desc: Boolean
  ) {
    viewer {
      id
      exportSowReportV2(
        amlLevel: $amlLevel
        complianceStatus: $complianceStatus
        statusChangedAt: $statusChangedAt
        limitReachedAt: $limitReachedAt
        submittedAt: $submittedAt
        orderBy: $orderBy
        desc: $desc
      ) {
        url
      }
    }
  }
`;

export default function useSowReport() {
  const [query] = useQueryParamsWithTime(queryParams, {
    fromAndToOptional: true,
  });

  const [fetching, setFetching] = useState(false);

  const submittedAt = useMemo((): RangeDate | undefined => {
    if (query.from && query.to) {
      return {
        from: formatISO(query.from),
        to: formatISO(query.to),
      };
    }

    return undefined;
  }, [query.from, query.to]);
  const client = useClient();

  const generate = async () => {
    setFetching(true);

    return client
      .query<SowReportQuery, SowReportQueryVariables>(SOW_REPORT_QUERY, {
        ...mapVariables(query),
        submittedAt,
      })
      .toPromise()
      .then((res) => {
        const url = res.data?.viewer.exportSowReportV2.url;

        if (url) {
          saveAs(url, 'sow-report.csv');
        }

        setFetching(false);
      });
  };

  return {
    generate,
    fetching,
  };
}
