import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { useQuery } from 'urql';

import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  GlobalPlayerBrandValues,
  Value,
} from '@/components';
import { RefreshIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import { getMoneyBrandsValues } from '@/utils';
import formatMoney from '@/utils/formatter/formatMoney';
import {
  GlobalPlayerWalletBoxQuery,
  GlobalPlayerWalletBoxQueryVariables,
} from './__generated__/component';

export const Fragment = graphql`
  fragment SanityGlobalPlayerWalletBlock on SanityGlobalPlayerWalletBlock {
    title {
      ...SanityLocaleString
    }
    balance {
      ...SanityLocaleString
    }
    reservedBalance {
      ...SanityLocaleString
    }
    balances {
      ...SanityLocaleString
    }
    reservedBalances {
      ...SanityLocaleString
    }
  }
`;

const QUERY = gql`
  query GlobalPlayerWalletBox($playerGlobalId: ID!) {
    playerGlobal(playerGlobalId: $playerGlobalId) {
      id
      players {
        id
        rawPlayerId
        accountStatus {
          status
        }
        brand {
          id
          name
        }
        wallet {
          id
          currency
          balance
          reservedBalance
        }
        loginInfo {
          lastLogin {
            loggedAt
          }
          registration {
            loggedAt
          }
        }
      }
      globalWallet {
        balance
        reservedBalance
      }
    }
  }
`;

const GlobalPlayerWalletBlock: FC<{
  block: Queries.SanityGlobalPlayerWalletBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();
  const params = useParams();

  const [{ data, fetching }, refresh] = useQuery<
    GlobalPlayerWalletBoxQuery,
    GlobalPlayerWalletBoxQueryVariables
  >({
    query: QUERY,
    variables: {
      playerGlobalId: params.playerGlobalId,
    },
  });

  const firstPlayer = data?.playerGlobal.players[0];

  return (
    <Card
      size="md"
      title={t(block.title)}
      options={
        <CardOptions>
          <CardOptionsButton
            className="flex"
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <div className="p-3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
          <Value
            fetching={fetching}
            title={t(block.balance)}
            value={formatMoney(
              data?.playerGlobal.globalWallet?.balance,
              firstPlayer?.wallet?.currency,
            )}
            suffix={
              <GlobalPlayerBrandValues
                title="Balances"
                values={
                  data &&
                  getMoneyBrandsValues(
                    data,
                    firstPlayer?.wallet?.currency
                      ? firstPlayer?.wallet?.currency
                      : '',
                    (entry) => entry.wallet?.balance,
                  )
                }
              />
            }
          />
          <Value
            fetching={fetching}
            title={t(block.reservedBalance)}
            value={formatMoney(
              data?.playerGlobal.globalWallet?.reservedBalance,
              firstPlayer?.wallet?.currency,
            )}
            suffix={
              <GlobalPlayerBrandValues
                title="Reserved Balances"
                values={
                  data &&
                  getMoneyBrandsValues(
                    data,
                    firstPlayer?.wallet?.currency
                      ? firstPlayer?.wallet?.currency
                      : '',
                    (entry) => entry.wallet?.reservedBalance,
                  )
                }
              />
            }
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default GlobalPlayerWalletBlock;
