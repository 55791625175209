import { useParams } from '@reach/router';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { feedback } from 'react-feedbacker';
import { useForm } from 'react-hook-form';
import { gql, useMutation } from 'urql';

import {
  CheckboxField,
  NakedForm,
  SelectField,
  SubmitButton,
  TextareaField,
} from '@/components';
import { useDrawer, useTranslate } from '@/contexts';
import { useDispatchNewNodes } from '@/contexts/NewNodesContext';
import { PriorityEnum } from '@/globalTypes';
import { usePlayer } from '@/hooks';
import { playerNotesNoteFragment } from '@/hooks/usePlayerNotes';
import { Nullable } from '@/types';
import { logger } from '@/utils';
import { useCan } from '@/utils/access';
import {
  AddGlobalPlayerNoteMutation,
  AddGlobalPlayerNoteMutationVariables,
  AddPlayerNoteMutation,
  AddPlayerNoteMutationVariables,
} from './__generated__/AddNote';

const ADD_GLOBAL_NOTE_MUTATION = gql`
  mutation AddGlobalPlayerNote(
    $playerGlobalId: ID!
    $priority: PriorityEnum!
    $content: String!
  ) {
    addGlobalNoteV2(
      playerGlobalId: $playerGlobalId
      priority: $priority
      content: $content
    ) {
      note {
        ...PlayerNotes_note
      }
    }
  }
  ${playerNotesNoteFragment}
`;

const ADD_NOTE_MUTATION = gql`
  mutation AddPlayerNote(
    $playerId: ID!
    $priority: PriorityEnum!
    $content: String!
  ) {
    addNote(playerId: $playerId, priority: $priority, content: $content) {
      ...PlayerNotes_note
    }
  }
  ${playerNotesNoteFragment}
`;

type FormValues = {
  content: '';
  priority: PriorityEnum;
  createGlobalNote: boolean;
};

type NotesBlock = NonNullable<
  | Queries.StaticGlobalPlayerNotesBlockQuery['sanityGlobalPlayerNotesBlock']
  | Queries.StaticPlayerNotesBlockQuery['sanityPlayerNotesBlock']
>;

const getEmptyDefaultValues = (
  canDefaultHighPriority: boolean,
): FormValues => ({
  content: '',
  priority: canDefaultHighPriority ? PriorityEnum.HIGH : PriorityEnum.LOW,
  createGlobalNote: false,
});

const getDefaultValues = (
  emptyDefaultValue: FormValues,
  storageKey: Nullable<string>,
): FormValues => {
  if (storageKey) {
    const stored = sessionStorage.getItem(storageKey);
    try {
      return (stored && JSON.parse(stored)) ?? emptyDefaultValue;
    } catch (error) {
      logger.error(error);
    }
  }
  return emptyDefaultValue;
};

const AddNote = ({
  block,
  isGlobal,
}: {
  block: NotesBlock;
  isGlobal: boolean;
}) => {
  const { t } = useTranslate();
  const { playerGlobalId } = usePlayer();
  const drawer = useDrawer();
  const dispatchNewNode = useDispatchNewNodes();
  const canDefaultHighPriority = useCan('DEFAULT_NOTE_HIGH_PRIORITY');
  const emptyDefaultValues = getEmptyDefaultValues(canDefaultHighPriority);
  const { playerId, playerGlobalId: globalPlayerId } = useParams();
  const currentPlayerId = playerId || globalPlayerId;
  const storageKey = drawer && `PlayerNote:drawer:${currentPlayerId}`;

  const [addGLobalNoteState, addGlobalNote] = useMutation<
    AddGlobalPlayerNoteMutation,
    AddGlobalPlayerNoteMutationVariables
  >(ADD_GLOBAL_NOTE_MUTATION);

  const [addNoteState, addNote] = useMutation<
    AddPlayerNoteMutation,
    AddPlayerNoteMutationVariables
  >(ADD_NOTE_MUTATION);

  const methods = useForm({
    defaultValues: getDefaultValues(emptyDefaultValues, storageKey),
  });

  const stringifiedValues = JSON.stringify(methods.watch());

  useEffect(() => {
    if (stringifiedValues && storageKey) {
      sessionStorage.setItem(storageKey, stringifiedValues);
    }
  }, [storageKey, stringifiedValues]);

  const setFormToDefault = () => {
    Object.keys(emptyDefaultValues).forEach((k) => {
      const key = k as keyof FormValues;
      const value = emptyDefaultValues[key];
      methods.setValue(key, value);
    });
  };

  const onSubmit = (values: FormValues) => {
    if (isGlobal || values.createGlobalNote) {
      return addGlobalNote({
        playerGlobalId: globalPlayerId || playerGlobalId,
        ...values,
      }).then(({ data }) => {
        if (data?.addGlobalNoteV2.note.id) {
          dispatchNewNode({
            type: 'ADD_NODE',
            nodeType: 'Note',
            node: data.addGlobalNoteV2.note,
          });
          if (storageKey) {
            sessionStorage.removeItem(storageKey);
          }
          feedback.success('Global Note Created');
          setFormToDefault();
        }
      });
    }

    return addNote({ playerId, ...values }).then(({ data }) => {
      if (data?.addNote.id) {
        dispatchNewNode({
          type: 'ADD_NODE',
          nodeType: 'Note',
          node: data.addNote,
        });
        if (storageKey) {
          sessionStorage.removeItem(storageKey);
        }
        feedback.success('Note Created');
        setFormToDefault();
      }
    });
  };

  return (
    <div className="p-3">
      <NakedForm
        className="space-y-3"
        methods={methods}
        onSubmit={(values) => {
          onSubmit(values);
        }}
      >
        <TextareaField
          name="content"
          id="player-notes-block__content"
          title={t(block.content)}
          required
        />
        <SelectField
          name="priority"
          id="player-notes-block__priority"
          title={t(block.priority)}
          options={[
            {
              label: _.startCase(PriorityEnum.HIGH.toLowerCase()),
              value: PriorityEnum.HIGH,
            },
            {
              label: _.startCase(PriorityEnum.MEDIUM.toLowerCase()),
              value: PriorityEnum.MEDIUM,
            },
            {
              label: _.startCase(PriorityEnum.LOW.toLowerCase()),
              value: PriorityEnum.LOW,
            },
          ]}
        />
        {!isGlobal && (
          <CheckboxField
            name="createGlobalNote"
            title={t(block.createGlobalNote)}
          />
        )}
        <SubmitButton
          value={t(block.submit)}
          disabled={
            Object.values(methods.formState.errors).length > 0 ||
            addGLobalNoteState.fetching ||
            addNoteState.fetching
          }
        />
      </NakedForm>
    </div>
  );
};

export default AddNote;
