import { graphql, useStaticQuery } from 'gatsby';
import React, { useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { useCurrencies } from '@/bits';
import {
  Card,
  CardCloseButton,
  CardOptions,
  NakedForm,
  NumberField,
  SelectField,
  SelectOption,
  TextField,
  TextareaField,
  useModalContext,
} from '@/components';
import { SubmitButton } from '@/components/form/SubmitButton';
import { GameSearch } from '@/components/GameSearch';
import { useTranslate } from '@/contexts';
import {
  ChallengeRewardInputV2,
  ChallengeRewardTypeEnum,
  ClaimType,
  GameProvider,
  Maybe,
  Scalars,
} from '@/globalTypes';
import { rewardGameProviders } from '@/utils';
import { assert } from '@/utils/error';

const query = graphql`
  query SanityChallengeRewardCreateForm {
    sanityChallengeRewardCreateForm {
      title {
        ...SanityLocaleString
      }
      rewardType {
        ...SanityLocaleString
      }
      value {
        ...SanityLocaleString
      }
      percentMatch {
        ...SanityLocaleString
      }
      maxAmount {
        ...SanityLocaleString
      }
      maxFreeSpinsAmount {
        ...SanityLocaleString
      }
      currency {
        ...SanityLocaleString
      }
      numberOfSpins {
        ...SanityLocaleString
      }
      paylines {
        ...SanityLocaleString
      }
      game {
        ...SanityLocaleString
      }
      gameProviders {
        ...SanityLocaleString
      }
      animation {
        ...SanityLocaleString
      }
      maxPayout {
        ...SanityLocaleString
      }
      nameOfReward {
        ...SanityLocaleString
      }
      timeToActive {
        ...SanityLocaleString
      }
      validFor {
        ...SanityLocaleString
      }
      claimableInstant {
        ...SanityLocaleString
      }
      notes {
        ...SanityLocaleString
      }
      wageringFactor {
        ...SanityLocaleString
      }
    }
  }
`;

type ActiveAfter = '1.second' | '1.day' | '7.days' | '30.days';
type ValidFor = '1.day' | '7.days' | '30.days';
type RewardOption = SelectOption & { value: ChallengeRewardTypeEnum };
type ActiveAfterOption = SelectOption & { value: ActiveAfter };
type ValidForOption = SelectOption & { value: ValidFor };
type FormValues = {
  rewardType: ChallengeRewardTypeEnum;
  gameProvider: GameProvider;
  activateAfter: string;
  expiresIn: string;
  claimType: ClaimType;
  note: string;
  spinsNumber: number | null;
  amount: number | null;
  multiplier: number | null;
  maxAmount: number | null;
  betLevel: number;
  currencyCode: Scalars['ISOCurrencyCode']['input'];
  gameDetails: {
    mobileId: string;
    desktopId: string;
  };
  wageringRequirementFactor: number | null;
  maxPayout: number | null;
  maxFreeSpinsAmount: number | null;
  freeSpinValue: number | null;
};

const useOptions = (showDynamicRewards: boolean) => {
  return useMemo(() => {
    const fixedRewardTypesOptions: RewardOption[] = [
      {
        label: 'FreeSpins',
        value: ChallengeRewardTypeEnum.FreeSpinsType,
      },
      {
        label: 'MoneyDrop',
        value: ChallengeRewardTypeEnum.MoneyDropType,
      },
      {
        label: 'BonusMoneyDrop',
        value: ChallengeRewardTypeEnum.BonusMoneyDropType,
      },
    ];

    const dynamicRewardTypesOptions: RewardOption[] = [
      {
        label: 'DynamicFreeSpins',
        value: ChallengeRewardTypeEnum.DynamicFreeSpinsType,
      },
      {
        label: 'DynamicMoneyDrop',
        value: ChallengeRewardTypeEnum.DynamicMoneyDropType,
      },
      {
        label: 'DynamicBonusMoneyDrop',
        value: ChallengeRewardTypeEnum.DynamicBonusMoneyDropType,
      },
    ];

    const rewardTypesOptions = showDynamicRewards
      ? fixedRewardTypesOptions.concat(dynamicRewardTypesOptions)
      : fixedRewardTypesOptions;

    const claimOptions: SelectOption[] = [
      {
        label: 'Claimable',
        value: 'Manual',
      },
      {
        label: 'Instant',
        value: 'Instant',
      },
    ];

    const activeAfterOptions: ActiveAfterOption[] = [
      {
        label: 'Instant',
        value: '1.second',
      },
      {
        label: 'After 1 Hour',
        value: '1.day',
      },
      {
        label: 'After 1 Week',
        value: '7.days',
      },
      {
        label: 'After 1 Month',
        value: '30.days',
      },
    ];

    const validForOptions: ValidForOption[] = [
      {
        label: '1 Day',
        value: '1.day',
      },
      {
        label: '1 Week',
        value: '7.days',
      },
      {
        label: '1 Month',
        value: '30.days',
      },
    ];

    return {
      rewardTypesOptions,
      claimOptions,
      activeAfterOptions,
      validForOptions,
    };
  }, []);
};

type Props = {
  onCompleted: (reward: ChallengeRewardInputV2) => void;
  showDynamicRewards: boolean;
  challengeCurrency: Maybe<Scalars['ISOCurrencyCode']['input']>;
};

const ChallengeRewardCreateForm = ({
  onCompleted,
  showDynamicRewards = false,
  challengeCurrency,
}: Props) => {
  const { t } = useTranslate();
  const staticData =
    useStaticQuery<Queries.SanityChallengeRewardCreateFormQuery>(query);
  const { close } = useModalContext();

  const form = staticData.sanityChallengeRewardCreateForm;
  assert(form, 'missing form data');

  const {
    rewardTypesOptions,
    claimOptions,
    activeAfterOptions,
    validForOptions,
  } = useOptions(showDynamicRewards);
  const { currencyOptions } = useCurrencies();

  const defaultValues: FormValues = {
    rewardType: ChallengeRewardTypeEnum.MoneyDropType,
    gameProvider: GameProvider.Relax,
    activateAfter: '1.second',
    expiresIn: '7.days',
    claimType: ClaimType.Manual,
    note: '',
    amount: null,
    spinsNumber: null,
    betLevel: 1,
    currencyCode: challengeCurrency || 'EUR',
    gameDetails: {
      desktopId: '',
      mobileId: '',
    },
    wageringRequirementFactor: null,
    maxPayout: null,
    multiplier: null,
    maxAmount: null,
    maxFreeSpinsAmount: null,
    freeSpinValue: null,
  };

  const methods = useForm<FormValues>({
    defaultValues,
  });

  const rewardType = methods.watch('rewardType');
  const selectedGameProvider = methods.watch('gameProvider');

  const getRewardTypeInput = (values: FormValues) => {
    switch (values.rewardType) {
      case ChallengeRewardTypeEnum.MoneyDropType:
        return {
          moneyDrop: {
            amount: Number(values.amount),
            currency: values.currencyCode,
          },
          challengeRewardType: values.rewardType,
        };
      case ChallengeRewardTypeEnum.BonusMoneyDropType:
        return {
          bonusMoneyDrop: {
            amount: Number(values.amount),
            currency: values.currencyCode,
            wageringRequirementFactor: Number(values.wageringRequirementFactor),
            maxPayout: Number(values.maxPayout),
          },
          challengeRewardType: values.rewardType,
        };
      case ChallengeRewardTypeEnum.DynamicFreeSpinsType:
        return {
          dynamicFreeSpins: {
            multiplier: values.multiplier ? Number(values.multiplier / 100) : 0,
            maxFreeSpinsAmount: Number(values.maxFreeSpinsAmount),
            freeSpinValue: Number(values.freeSpinValue),
            payLines: Number(values.betLevel),
            provider: values.gameProvider,
            currency: values.currencyCode,
            desktopId: values.gameDetails.desktopId,
            mobileId: values.gameDetails.mobileId,
          },
          challengeRewardType: values.rewardType,
        };
      case ChallengeRewardTypeEnum.DynamicMoneyDropType:
        return {
          dynamicMoneyDrop: {
            multiplier: values.multiplier ? Number(values.multiplier / 100) : 0,
            maxAmount: Number(values.maxAmount),
            currency: values.currencyCode,
          },
          challengeRewardType: values.rewardType,
        };
      case ChallengeRewardTypeEnum.DynamicBonusMoneyDropType:
        return {
          dynamicBonusMoneyDrop: {
            multiplier: values.multiplier ? Number(values.multiplier / 100) : 0, // Multiplier for BE is in decimal value from 0 to 1. For user input, it's in percentage from 0 to 100
            maxAmount: Number(values.maxAmount),
            currency: values.currencyCode,
            wageringRequirementFactor: Number(values.wageringRequirementFactor),
            maxPayout: Number(values.maxPayout),
          },
          challengeRewardType: values.rewardType,
        };
      default:
        return {
          freeSpins: {
            freeSpinValue: Number(values.amount),
            amountFreeSpins: Number(values.spinsNumber),
            payLines: Number(values.betLevel),
            provider: values.gameProvider,
            currency: values.currencyCode,
            desktopId: values.gameDetails.desktopId,
            mobileId: values.gameDetails.mobileId,
          },
          challengeRewardType: values.rewardType,
        };
    }
  };

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    const challengeRewardTypeInput = getRewardTypeInput(values);

    onCompleted({
      challengeRewardTypeInput,
      activateAfter: values.activateAfter,
      expiresIn: values.expiresIn,
      claimType: values.claimType,
    });

    if (close) {
      close();
    }
  };

  if (!form) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="flex p-6">
        <NakedForm className="w-full" onSubmit={onSubmit} methods={methods}>
          <div className="flex sm:flex-row flex-col space-x-6 pb-6">
            <div className="flex-1 space-y-4">
              <SelectField
                name="rewardType"
                id="ChallengeRewardCreateForm__rewardType"
                title={t(form.rewardType)}
                required
                options={rewardTypesOptions}
              />
              <div className="grid sm:grid-cols-2 gap-2">
                {[
                  ChallengeRewardTypeEnum.DynamicBonusMoneyDropType,
                  ChallengeRewardTypeEnum.DynamicMoneyDropType,
                ].includes(rewardType) && (
                  <>
                    <TextField
                      name="multiplier"
                      required
                      id="ChallengeRewardCreateForm__multiplier"
                      title={t(form.percentMatch)}
                    />
                    <TextField
                      name="maxAmount"
                      required
                      id="ChallengeRewardCreateForm__amount"
                      title={t(form.maxAmount)}
                    />
                    <SelectField
                      name="currencyCode"
                      required
                      id="ChallengeRewardCreateForm__currency"
                      title={t(form.currency)}
                      options={currencyOptions}
                      disabled={!!challengeCurrency}
                    />
                  </>
                )}
                {ChallengeRewardTypeEnum.DynamicFreeSpinsType ===
                  rewardType && (
                  <>
                    <TextField
                      name="multiplier"
                      required
                      id="ChallengeRewardCreateForm__multiplier"
                      title={t(form.percentMatch)}
                    />
                    <TextField
                      name="maxFreeSpinsAmount"
                      required
                      id="ChallengeRewardCreateForm__amount"
                      title={t(form.maxFreeSpinsAmount)}
                    />
                    <TextField
                      name="freeSpinValue"
                      required
                      id="ChallengeRewardCreateForm__freeSpinValue"
                      title={t(form.value)}
                    />
                  </>
                )}
                {rewardType === ChallengeRewardTypeEnum.FreeSpinsType && (
                  <>
                    <TextField
                      name="amount"
                      required
                      id="ChallengeRewardCreateForm__amount"
                      title={t(form.value)}
                    />
                    <NumberField
                      name="spinsNumber"
                      required
                      id="ChallengeRewardCreateForm__spinsNumber"
                      title={t(form.numberOfSpins)}
                      step={1}
                    />
                  </>
                )}
                {[
                  ChallengeRewardTypeEnum.DynamicFreeSpinsType,
                  ChallengeRewardTypeEnum.FreeSpinsType,
                ].includes(rewardType) && (
                  <>
                    <SelectField
                      name="currencyCode"
                      required
                      id="ChallengeRewardCreateForm__currency"
                      title={t(form.currency)}
                      options={currencyOptions}
                      disabled={!!challengeCurrency}
                    />
                    <NumberField
                      name="betLevel"
                      required
                      id="ChallengeRewardCreateForm__betLevel"
                      title={t(form.paylines)}
                      step={1}
                    />
                    <SelectField
                      name="gameProvider"
                      id="ChallengeRewardCreateForm__gameProviders"
                      title={t(form.gameProviders)}
                      options={rewardGameProviders}
                      required
                    />
                    <div className="col-span-2">
                      <GameSearch
                        name="gameDetails"
                        id="ChallengeRewardCreateForm__gameDetails"
                        title={t(form.game)}
                        providerName={selectedGameProvider}
                      />
                    </div>
                  </>
                )}

                {[
                  ChallengeRewardTypeEnum.MoneyDropType,
                  ChallengeRewardTypeEnum.BonusMoneyDropType,
                ].includes(rewardType) && (
                  <>
                    <TextField
                      name="amount"
                      required
                      id="ChallengeRewardCreateForm__amount"
                      title={t(form.value)}
                    />
                    <SelectField
                      name="currencyCode"
                      required
                      id="ChallengeRewardCreateForm__currency"
                      title={t(form.currency)}
                      options={currencyOptions}
                      disabled={!!challengeCurrency}
                    />
                  </>
                )}
                {[
                  ChallengeRewardTypeEnum.BonusMoneyDropType,
                  ChallengeRewardTypeEnum.DynamicBonusMoneyDropType,
                ].includes(rewardType) && (
                  <>
                    <NumberField
                      name="wageringRequirementFactor"
                      required
                      id="ChallengeRewardCreateForm__wageringRequirementFactor"
                      title={t(form.wageringFactor)}
                    />
                    <TextField
                      name="maxPayout"
                      required
                      id="ChallengeRewardCreateForm__maxPayout"
                      title={t(form.maxPayout)}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="flex-1 space-y-4">
              <SelectField
                name="activateAfter"
                id="ChallengeRewardCreateForm__activeAfter"
                title={t(form.timeToActive)}
                options={activeAfterOptions}
                required
              />
              <SelectField
                name="expiresIn"
                id="ChallengeRewardCreateForm__expiresIn"
                title={t(form.validFor)}
                options={validForOptions}
                required
              />
              <SelectField
                name="claimType"
                id="ChallengeRewardCreateForm__claimType"
                title={t(form.claimableInstant)}
                options={claimOptions}
                required
              />
            </div>
            <div className="flex-1">
              <TextareaField
                name="note"
                title={t(form.notes)}
                id="ChallengeRewardCreateForm__note"
              />
            </div>
          </div>
          <div className="flex justify-end">
            <SubmitButton value="Add Reward" />
          </div>
        </NakedForm>
      </div>
    </Card>
  );
};

export default ChallengeRewardCreateForm;
