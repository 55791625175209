import { QueryParamConfig } from 'use-query-params';

import {
  AccountStatusEnum,
  AdjustmentCategory,
  AdjustmentType,
  AmlRiskLevel,
  BrandEnum,
  ChallengeStatus,
  DecisionSearchType,
  DecisionType,
  DocumentSearchOrder,
  EventType,
  GameProvider,
  GameRoundSearchOrder,
  GameRoundStatus,
  GranularityEnum,
  InitiatorTypeEnum,
  KycProviderType,
  LimitSearchOrder,
  LimitStatusEnum,
  LimitType,
  LimitTypeEnum,
  PaymentApprovalSearchOrder,
  PaymentIntegration,
  PaymentSearchOrder,
  PaymentStatus,
  PaymentType,
  PlayerComplianceHistorySearchOrder,
  PlayerComplianceStatus,
  PlayerKycHistorySearchOrder,
  PlayerRewardStatus,
  PlayerSearchOrder,
  RegistrationStatusFilter,
  ReviewStatus,
  RewardStatus,
  RewardTypeEnum,
  RgCraReviewStatus,
  RgCraRiskAggregationOrderBy,
  RgCraRiskLevel,
  RiskAggregationOrderBy,
  RiskHistoryOrderBy,
  RiskLevel,
  SearchRewardsOrderBy,
  SessionSearchOrder,
  SessionStatus,
  TerminationCause,
  TransactionSearchOrder,
  TransactionType,
} from '@/globalTypes';
import { Nullable } from '@/types';

type StandardEnum<T> = {
  [id: string]: T | string;
  [nu: number]: string;
};

export function enumParam<E extends StandardEnum<unknown>>(
  e: E,
): QueryParamConfig<E[keyof E], E[keyof E] | undefined | null> {
  type V = E[keyof E];
  return {
    encode(value: V): string {
      return value as string;
    },
    decode(
      value: string | (string | null)[] | null | undefined,
    ): V | null | undefined {
      return Object.values(e).includes(value as V) ? (value as V) : undefined;
    },
  };
}

function notEmpty<TValue>(value: Nullable<TValue>): value is TValue {
  return value != null;
}

export function enumArrayParam<E extends StandardEnum<unknown>>(
  e: E,
): QueryParamConfig<E[keyof E][], E[keyof E][] | undefined | null> {
  type V = E[keyof E];
  return {
    encode(value: V[]): (string | null)[] | null | undefined {
      return value as string[];
    },
    decode(
      value: string | (string | null)[] | null | undefined,
    ): V[] | null | undefined {
      let result;
      if (value instanceof Array) {
        result = value
          .map((v) => (Object.values(e).includes(v as V) ? (v as V) : null))
          .filter(notEmpty);
      } else {
        result = [
          Object.values(e).includes(value as V) ? (value as V) : null,
        ].filter(notEmpty);
      }
      if (result.length) {
        return result;
      }
      return undefined;
    },
  };
}

export const BrandParam = enumParam(BrandEnum);
export const RegistrationStatusFilterParam = enumParam(
  RegistrationStatusFilter,
);
export const AccountStatusParam = enumParam(AccountStatusEnum);
export const DecisionTypeParam = enumParam(DecisionType);
export const DecisionSearchTypeParam = enumParam(DecisionSearchType);
export const PlayerSearchOrderParam = enumParam(PlayerSearchOrder);

export const PaymentStatusesParam = enumArrayParam(PaymentStatus);
export const PaymentTypesParam = enumArrayParam(PaymentType);
export const TransactionTypesParam = enumArrayParam(TransactionType);

export const PaymentTypeParam = enumParam(PaymentType);
export const PaymentStatusParam = enumParam(PaymentStatus);
export const PaymentApprovalSearchOrderParam = enumArrayParam(
  PaymentApprovalSearchOrder,
);
export const PaymentIntegrationParam = enumParam(PaymentIntegration);

export const AdjustmentTypeParam = enumParam(AdjustmentType);
export const AdjustmentCategoryParam = enumParam(AdjustmentCategory);
export const TransactionSearchOrderParam = enumParam(TransactionSearchOrder);

export const GameRoundStatusParam = enumParam(GameRoundStatus);
export const GameRoundSearchOrderParam = enumParam(GameRoundSearchOrder);
export const PaymentSearchOrderParam = enumParam(PaymentSearchOrder);
export const GameProviderParam = enumParam(GameProvider);

export const InitiatorTypeParam = enumParam(InitiatorTypeEnum);
export const SessionStatusParam = enumParam(SessionStatus);
export const TerminationCauseParam = enumParam(TerminationCause);
export const SessionSearchOrderParam = enumParam(SessionSearchOrder);

export const LimitTypeParam = enumParam(LimitType);
export const LimitPeriodTypeParam = enumParam(LimitTypeEnum);
export const LimitStatusParam = enumParam(LimitStatusEnum);
export const LimitSearchOrderParam = enumParam(LimitSearchOrder);

export const KycProviderTypeParam = enumParam(KycProviderType);
export const PlayerKycHistorySearchOrderParam = enumParam(
  PlayerKycHistorySearchOrder,
);
export const DocumentsSearchOrderParam = enumParam(DocumentSearchOrder);
export const PlayerComplianceHistorySearchOrderParam = enumParam(
  PlayerComplianceHistorySearchOrder,
);

export const PlayerComplianceStatusParam = enumParam(PlayerComplianceStatus);
export const AmlRiskLevelParam = enumParam(AmlRiskLevel);

export const GranularityParam = enumParam(GranularityEnum);

export const RewardStatusParam = enumParam(RewardStatus);
export const PlayerRewardStatusParam = enumParam(PlayerRewardStatus);
export const RewardTypeEnumParam = enumArrayParam(RewardTypeEnum);
export const SearchRewardsOrderByParam = enumParam(SearchRewardsOrderBy);
export const ReviewStatusParam = enumArrayParam(ReviewStatus);
export const RiskLevelsParam = enumArrayParam(RiskLevel);
export const RgReviewStatusParam = enumArrayParam(RgCraReviewStatus);
export const RgRiskLevelsParam = enumArrayParam(RgCraRiskLevel);
export const RgRiskAggregationOrderBy = enumParam(RgCraRiskAggregationOrderBy);
export const RiskAggregationOrderByParam = enumParam(RiskAggregationOrderBy);
export const RiskHistoryOrderByParam = enumParam(RiskHistoryOrderBy);
export const EventTypeParam = enumParam(EventType);
export const ChallengeStatusParam = enumParam(ChallengeStatus);
