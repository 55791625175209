import gql from 'graphql-tag';
import { useQuery } from 'urql';

import {
  TransactionsInspectionQuery,
  TransactionsInspectionQueryVariables,
} from './__generated__/useTransactions';

const TRANSACTIONS_QUERY = gql`
  query TransactionsInspection($rawId: String!) {
    viewer {
      id
      transactionsV2(rawId: $rawId, orderBy: processedAt, desc: false) {
        edges {
          node {
            rawTransactionId
            uuid
            transactionId
            playerId
            playerSessionId
            playerSessionUUID
            isRoundFinished
            extTransactionId
            payload
            provider
            brand {
              id
              name
            }
            processedAt
            paymentMethod
            paymentStatus
            transactionType
            openingBalance
            closingBalance
            currency
            comment
            paymentFee {
              money {
                amount
                currency
              }
            }
            amount
            brand {
              code
            }
            initiator {
              ... on PlayerInitiator {
                player {
                  id
                  firstName
                  lastName
                  email
                }
              }
              ... on AgentInitiator {
                agent {
                  email
                  firstName
                  lastName
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default function useTransactions(rawId: string) {
  const [{ data, fetching }, refresh] = useQuery<
    TransactionsInspectionQuery,
    TransactionsInspectionQueryVariables
  >({
    query: TRANSACTIONS_QUERY,
    variables: {
      rawId,
    },
  });

  return {
    refresh,
    fetching,
    transactions: data?.viewer.transactionsV2?.edges,
  };
}
