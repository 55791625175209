import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { feedback } from 'react-feedbacker';
import { useQuery } from 'urql';

import { useFeedbackMessages } from '@/bits';
import { limitPeriodDetailList_periodLimit } from '@/bits/limit-period-detail-list/component';
import { Card, CardBody, CardOptions, CardOptionsButton } from '@/components';
import { LockClosedIcon, LockOpenIcon, RefreshIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import { limitBlockingFragment, limitFragment } from '@/fragments/Limits';
import { LimitTypeEnum } from '@/globalTypes';
import { Unwrap } from '@/types';
import { useCan } from '@/utils/access';
import {
  PlayerDepositLimitsBoxQuery,
  PlayerDepositLimitsBoxQueryVariables,
} from './__generated__/component';
import { useBlockDepositLimit, useUnblockDepositLimit } from './blocking';
import { DepositLimitBar } from './DepositLimitBar';

export const Fragment = graphql`
  fragment SanityPlayerDepositLimitsBlock on SanityPlayerDepositLimitsBlock {
    title {
      ...SanityLocaleString
    }
    depositLimitsLabel {
      ...SanityLocaleString
    }
    dailyLabel {
      ...SanityLocaleString
    }
    weeklyLabel {
      ...SanityLocaleString
    }
    monthlyLabel {
      ...SanityLocaleString
    }
  }
`;

const QUERY = gql`
  query PlayerDepositLimitsBox($playerId: ID!) {
    player(playerId: $playerId) {
      id
      wallet {
        id
        currency
        depositLimit {
          ...LimitBlockingStatus
          dayLimit {
            ...PeriodLimit
            ...LimitPeriodDetailList_periodLimit
          }
          weekLimit {
            ...PeriodLimit
            ...LimitPeriodDetailList_periodLimit
          }
          monthLimit {
            ...PeriodLimit
            ...LimitPeriodDetailList_periodLimit
          }
        }
      }
    }
  }
  ${limitFragment}
  ${limitBlockingFragment}
  ${limitPeriodDetailList_periodLimit}
`;

const PlayerDepositLimitsBlock: FC<{
  block: Queries.SanityPlayerDepositLimitsBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();
  const params = useParams();
  const feedbackMessages = useFeedbackMessages();

  const [{ data, fetching }, refresh] = useQuery<
    PlayerDepositLimitsBoxQuery,
    PlayerDepositLimitsBoxQueryVariables
  >({
    query: QUERY,
    variables: {
      playerId: params.playerId,
    },
  });

  const [blockLimitState, blockLimit] = useBlockDepositLimit();
  const [unblockLimitState, unblockLimit] = useUnblockDepositLimit();

  const canBlock = useCan('BLOCK_PLAYER_LIMITS');
  const canUnblock = useCan('BLOCK_PLAYER_LIMITS');

  const onBlockMutationCompleted = (
    res: Unwrap<ReturnType<typeof blockLimit | typeof unblockLimit>>,
  ) => {
    if (res.error?.message) {
      feedback.error(res.error.message);
    } else {
      feedback.success(t(feedbackMessages.success));
    }
  };

  const loadingBlockMutation =
    blockLimitState.fetching || unblockLimitState.fetching;

  const depositLimit = data?.player.wallet?.depositLimit;
  const isBlocked = depositLimit?.blockingStatus?.blocked;

  const canSwitchBlocking = isBlocked ? canUnblock : canBlock;

  const onBlockToggle = () => {
    if (!canSwitchBlocking) {
      return;
    }
    if (isBlocked) {
      unblockLimit({ playerId: params.playerId }).then(
        onBlockMutationCompleted,
      );
      return;
    }
    blockLimit({ playerId: params.playerId }).then(onBlockMutationCompleted);
  };

  return (
    <Card
      size="lg"
      title={t(block.title)}
      options={
        <CardOptions>
          <CardOptionsButton
            type="button"
            disabled={loadingBlockMutation || !canSwitchBlocking}
            onClick={onBlockToggle}
          >
            {isBlocked ? <LockClosedIcon /> : <LockOpenIcon />}
          </CardOptionsButton>
          <CardOptionsButton
            className="flex"
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <div className="p-3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
          <div className="col-span-full">
            <label className="text-sm text-gray-500 font-semibold">
              {t(block.depositLimitsLabel)}
            </label>
            <div className="grid gap-2 pt-1">
              <DepositLimitBar
                currency={data?.player.wallet?.currency}
                fetching={fetching}
                limit={depositLimit?.dayLimit}
                label={block.dailyLabel}
                playerId={data?.player.id}
                limitPeriodType={LimitTypeEnum.Day}
              />
              <DepositLimitBar
                currency={data?.player.wallet?.currency}
                fetching={fetching}
                limit={depositLimit?.weekLimit}
                label={block.weeklyLabel}
                playerId={data?.player.id}
                limitPeriodType={LimitTypeEnum.Week}
              />
              <DepositLimitBar
                currency={data?.player.wallet?.currency}
                fetching={fetching}
                limit={depositLimit?.monthLimit}
                label={block.monthlyLabel}
                playerId={data?.player.id}
                limitPeriodType={LimitTypeEnum.Month}
              />
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default PlayerDepositLimitsBlock;
