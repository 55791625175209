import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useState } from 'react';
import { useMutation } from 'urql';

import {
  Card,
  CardBody,
  CardCloseButton,
  CardOptions,
  ErrorMessage,
  Form,
  SubmitButton,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { useIsMounted } from '@/hooks';
import { Nullable, PlayerId } from '@/types';
import {
  PlayerReopenCustomerMutation,
  PlayerReopenCustomerMutationVariables,
} from './__generated__/PlayerReopenCustomerForm';

const query = graphql`
  query SanityPlayerReopenCustomerStatusForm {
    sanityPlayerReopenCustomerStatusForm {
      title {
        ...SanityLocaleString
      }
      description {
        ...SanityLocaleString
      }
      submit {
        ...SanityLocaleString
      }
    }
  }
`;

const playerReopenCustomerMutation = gql`
  mutation PlayerReopenCustomer($playerGlobalId: ID!) {
    reopenCustomer(playerGlobalId: $playerGlobalId) {
      ... on Customer {
        id
        playerGlobalUUID
        isCancelWithdrawalBlocked
        isIncreaseDepositLimitBlocked
        isRewardsBlocked
        isDepositsBlocked
        isMarketingBlocked
        isRgNetDepositLimitEnabled
        customerClosure {
          closeAccountCause
        }
      }
    }
  }
`;

const ReopenCustomerForm: FC<{
  playerGlobalId: PlayerId;
}> = ({ playerGlobalId }) => {
  const staticData =
    useStaticQuery<Queries.SanityPlayerReopenCustomerStatusFormQuery>(query);
  const { t } = useTranslate();
  const isMounted = useIsMounted();
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const form = staticData.sanityPlayerReopenCustomerStatusForm;

  const [reopenPlayerCustomerState, reopenPlayerCustomer] = useMutation<
    PlayerReopenCustomerMutation,
    PlayerReopenCustomerMutationVariables
  >(playerReopenCustomerMutation);

  const { close } = useModalContext();

  const onSubmit = () => {
    if (playerGlobalId) {
      reopenPlayerCustomer({
        playerGlobalId,
      }).then((res) => {
        if (res.error?.message && isMounted) {
          setErrorMessage(res.error?.message);
        } else if (close) {
          close();
        }
      });
    }
  };

  if (!playerGlobalId || !form) {
    return null;
  }

  return (
    <Card
      size="md"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <CardBody>
        <div className="p-3">
          <Form defaultValues={{}} onSubmit={onSubmit} className="grid gap-6">
            <div className="space-y-1">
              <div className="block text-gray-600 text-sm dark:text-gray-200">
                {t(form.description)}
              </div>
            </div>
            <ErrorMessage message={errorMessage} />
            <SubmitButton
              value={t(form.submit)}
              disabled={reopenPlayerCustomerState.fetching}
            />
          </Form>
        </div>
      </CardBody>
    </Card>
  );
};

export default ReopenCustomerForm;
