import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useState } from 'react';
import { useMutation } from 'urql';

import {
  Card,
  CardCloseButton,
  CardOptions,
  ErrorMessage,
  Form,
  SubmitButton,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { useIsMounted } from '@/hooks';
import { Nullable, PlayerId } from '@/types';
import { RgCraRewardRequestMutation } from './__generated__/PlayerReportBonusRequestForm';

const query = graphql`
  query SanityReportBonusRequestForm {
    sanityPlayerReportBonusRequestForm {
      title {
        ...SanityLocaleString
      }
      description {
        ...SanityLocaleString
      }
      submit {
        ...SanityLocaleString
      }
    }
  }
`;

const rgCraRewardRequestMutation = gql`
  mutation RgCraRewardRequest($playerId: ID!) {
    rgCraRewardRequest(playerId: $playerId)
  }
`;

const PlayerReportBonusRequestForm: FC<{
  playerId: PlayerId;
}> = ({ playerId }) => {
  const staticData =
    useStaticQuery<Queries.SanityReportBonusRequestFormQuery>(query);

  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();
  const form = staticData.sanityPlayerReportBonusRequestForm;

  const [reportBonusRequestState, reportBonusRequest] =
    useMutation<RgCraRewardRequestMutation>(rgCraRewardRequestMutation);

  const { close } = useModalContext();
  const { t } = useTranslate();

  const onSubmit = () => {
    reportBonusRequest({
      playerId,
    }).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error?.message);
        return;
      }
      if (close) {
        close();
      }
    });
  };

  if (!form) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">
        <Form defaultValues={{}} onSubmit={onSubmit} className="grid gap-6">
          <div className="text-gray-400 dark:text-white">
            {t(form.description)}
          </div>
          <ErrorMessage message={errorMessage} />
          <SubmitButton
            value={t(form.submit)}
            disabled={reportBonusRequestState.fetching}
          />
        </Form>
      </div>
    </Card>
  );
};

export default PlayerReportBonusRequestForm;
