import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { gql, useQuery } from 'urql';

import { BooleanValue } from '@/bits';
import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  ControlledModal,
  InlineIconButton,
  Value,
} from '@/components';
import { EditIcon, RefreshIcon, ResetHardIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import {
  PlayerAmlRiskLevelForm,
  PlayerRequestDocumentsForm,
  PlayerRequestSowForm,
  PlayerVerifySourceOfWealthForm,
} from '@/forms';
import PlayerSourceOfFundsForm from '@/forms/player-source-of-funds-form/PlayerSourceOfFundsForm';
import { sourceOfFundsOptionLabels } from '@/forms/player-source-of-funds-form/useSourceOfFundsOptions';
import { PlayerComplianceStatus } from '@/globalTypes';
import { usePlayerGlobal } from '@/hooks';
import { Nullable } from '@/types';
import formatMoney from '@/utils/formatter/formatMoney';
import { fundingOptionLabels } from '@/utils/funding-options';
import {
  GlobalPlayerSowBoxQuery,
  GlobalPlayerSowBoxQueryVariables,
} from './__generated__/component';

export const Fragment = graphql`
  fragment SanityGlobalPlayerSowBlock on SanityGlobalPlayerSowBlock {
    title {
      ...SanityLocaleString
    }
    risk {
      ...SanityLocaleString
    }
    status {
      ...SanityLocaleString
    }
    needsManualApproval {
      ...SanityLocaleString
    }
    nationality {
      ...SanityLocaleString
    }
    placeOfBirth {
      ...SanityLocaleString
    }
    fundingOptions {
      ...SanityLocaleString
    }
    grossIncomeUpper {
      ...SanityLocaleString
    }
    grossIncomeLower {
      ...SanityLocaleString
    }
    expendingLowerBound {
      ...SanityLocaleString
    }
    expendingUpperBound {
      ...SanityLocaleString
    }
    otherFundingDescription {
      ...SanityLocaleString
    }
    requiredDocumentTypes {
      ...SanityLocaleString
    }
    sourceOfFunds {
      ...SanityLocaleString
    }
    requestSow {
      ...SanityLocaleString
    }
  }
`;

const QUERY = gql`
  query GlobalPlayerSowBox($playerGlobalId: ID!) {
    viewer {
      id
      sowPlayerIdFromGlobalId(playerGlobalId: $playerGlobalId) {
        playerId
      }
    }
    playerGlobal(playerGlobalId: $playerGlobalId) {
      id
      players {
        id
        wallet {
          currency
        }
      }
      playerComplianceDetails {
        id
        needsManualReview
        requiredDocumentTypes
        risk
        status
      }
      providedSourceOfFunds {
        sourceOfFunds {
          sourceOfFundType
          wasProvided
        }
      }
      sourceOfWealth {
        expendingLowerBound
        expendingUpperBound
        fundingOptions
        nationality
        placeOfBirth
        otherFundingDescription
        grossIncomeLowerBound
        grossIncomeUpperBound
      }
    }
  }
`;

const getStatusLabel = (status: Nullable<PlayerComplianceStatus>) => {
  if (status === PlayerComplianceStatus.Verified) {
    return 'Approved';
  }

  return status;
};

const getActiveSourcesOfFunds = (
  providedSourceOfFunds: GlobalPlayerSowBoxQuery['playerGlobal']['providedSourceOfFunds'],
) => {
  const providedSoF = providedSourceOfFunds?.sourceOfFunds.filter(
    (sof) => sof.wasProvided,
  );

  const renderSof =
    providedSoF &&
    providedSoF
      .slice(0, 2)
      .map((sof) => sourceOfFundsOptionLabels[sof.sourceOfFundType])
      .join(', ');
  return providedSoF && providedSoF.length > 2
    ? `${renderSof} + ${providedSoF.length - 2}`
    : renderSof;
};

const GlobalPlayerSowBlock: FC<{
  block: Queries.SanityGlobalPlayerSowBlockFragment;
}> = ({ block }) => {
  const { playerGlobalId, uuid } = usePlayerGlobal();
  const { t } = useTranslate();

  const [{ data, fetching }, refresh] = useQuery<
    GlobalPlayerSowBoxQuery,
    GlobalPlayerSowBoxQueryVariables
  >({
    query: QUERY,
    variables: {
      playerGlobalId,
    },
  });

  if (!block) {
    return null;
  }

  const sowPlayerId = data?.viewer.sowPlayerIdFromGlobalId?.playerId;
  const firstPlayer = data?.playerGlobal.players[0];
  const details = data?.playerGlobal.playerComplianceDetails;
  const sow = data?.playerGlobal.sourceOfWealth;
  const providedSourceOfFunds = data?.playerGlobal.providedSourceOfFunds;

  return (
    <Card
      size="lg"
      title={t(block.title)}
      options={
        <CardOptions>
          <CardOptionsButton
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <div className="p-3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
          <Value
            title={t(block.risk)}
            fetching={fetching}
            value={details?.risk}
            suffix={
              details && (
                <ControlledModal
                  content={
                    firstPlayer && (
                      <PlayerAmlRiskLevelForm playerId={firstPlayer?.id} />
                    )
                  }
                >
                  <InlineIconButton>
                    <EditIcon />
                  </InlineIconButton>
                </ControlledModal>
              )
            }
          />
          <Value
            title={t(block.status)}
            fetching={fetching}
            value={getStatusLabel(details?.status)}
            suffix={
              <>
                {details && (
                  <ControlledModal
                    content={
                      sowPlayerId && (
                        <PlayerVerifySourceOfWealthForm
                          playerId={sowPlayerId}
                        />
                      )
                    }
                  >
                    <InlineIconButton>
                      <EditIcon />
                    </InlineIconButton>
                  </ControlledModal>
                )}
                <ControlledModal
                  content={
                    uuid && (
                      <PlayerRequestSowForm
                        playerUuid={uuid}
                        callback={() =>
                          refresh({ requestPolicy: 'network-only' })
                        }
                      />
                    )
                  }
                >
                  <InlineIconButton tippyContent={t(block.requestSow)}>
                    <ResetHardIcon />
                  </InlineIconButton>
                </ControlledModal>
              </>
            }
          />
          <BooleanValue
            title={t(block.needsManualApproval)}
            fetching={fetching}
            value={details?.needsManualReview}
          />
          <Value
            title={t(block.nationality)}
            fetching={fetching}
            value={sow?.nationality}
          />
          <Value
            title={t(block.placeOfBirth)}
            fetching={fetching}
            value={sow?.placeOfBirth}
          />
          <Value
            title={t(block.fundingOptions)}
            fetching={fetching}
            value={sow?.fundingOptions.join(', ')}
          />
          <Value
            title={t(block.grossIncomeLower)}
            fetching={fetching}
            value={formatMoney(
              data?.playerGlobal.sourceOfWealth?.grossIncomeLowerBound,
              firstPlayer?.wallet?.currency,
            )}
          />
          <Value
            title={t(block.grossIncomeUpper)}
            fetching={fetching}
            value={formatMoney(
              data?.playerGlobal.sourceOfWealth?.grossIncomeUpperBound,
              firstPlayer?.wallet?.currency,
            )}
          />
          <Value
            title={t(block.expendingLowerBound)}
            fetching={fetching}
            value={formatMoney(
              data?.playerGlobal.sourceOfWealth?.expendingLowerBound,
              firstPlayer?.wallet?.currency,
            )}
          />
          <Value
            title={t(block.expendingUpperBound)}
            fetching={fetching}
            value={formatMoney(
              data?.playerGlobal.sourceOfWealth?.expendingUpperBound,
              firstPlayer?.wallet?.currency,
            )}
          />
          <Value
            title={t(block.otherFundingDescription)}
            fetching={fetching}
            value={sow?.otherFundingDescription}
          />
          <Value
            title={t(block.requiredDocumentTypes)}
            fetching={fetching}
            value={details?.requiredDocumentTypes
              .map((value) => fundingOptionLabels[value])
              .join(', ')}
            suffix={
              <ControlledModal
                content={
                  firstPlayer?.id ? (
                    <PlayerRequestDocumentsForm playerId={firstPlayer.id} />
                  ) : null
                }
              >
                <InlineIconButton>
                  <EditIcon />
                </InlineIconButton>
              </ControlledModal>
            }
          />
          <Value
            title={t(block.sourceOfFunds)}
            fetching={fetching}
            value={
              providedSourceOfFunds &&
              getActiveSourcesOfFunds(providedSourceOfFunds)
            }
            suffix={
              <ControlledModal
                content={
                  firstPlayer && (
                    <PlayerSourceOfFundsForm playerId={firstPlayer?.id} />
                  )
                }
              >
                <InlineIconButton>
                  <EditIcon />
                </InlineIconButton>
              </ControlledModal>
            }
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default GlobalPlayerSowBlock;
