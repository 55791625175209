import { graphql } from 'gatsby';
import { keyBy } from 'lodash';
import { parse } from 'papaparse';
import React, { FC, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useForm } from 'react-hook-form';
import { gql, useClient } from 'urql';

import { FileDrop, useFileDropFile } from '@/bits/file-drop';
import { useGetPlayerOverviewPageLink } from '@/bits/links/useLink';
import {
  Card,
  CardBody,
  ErrorMessage,
  NakedForm,
  SelectField,
  SubmitButton,
} from '@/components';
import { DocumentDownloadIcon } from '@/components/icons';
import { useElasticIndexPrefix, useTranslate } from '@/contexts';
import { Nullable } from '@/types';
import { readFile } from '@/utils/file';
import {
  PlayersCsvGeneratorBlockQuery,
  PlayersCsvGeneratorBlockQueryVariables,
} from './__generated__/component';

export const Fragment = graphql`
  fragment SanityPlayersCsvGeneratorBlock on SanityPlayersCsvGeneratorBlock {
    title {
      ...SanityLocaleString
    }
    description {
      ...SanityLocaleString
    }
    selectFieldsLabel {
      ...SanityLocaleString
    }
    downloadButton {
      ...SanityLocaleString
    }
    submitButton {
      ...SanityLocaleString
    }
  }
`;

type Player = NonNullable<
  NonNullable<
    PlayersCsvGeneratorBlockQuery['viewer']['playersSearchV2']['edges']
  >[number]
>['node']['player'];

type UuidV4 = `${string}-${string}-${string}-${string}-${string}`;
type CsvValue = string | number | UuidV4 | Player['email'];
type CsvRow = CsvValue[];

type FormValues = {
  fields: string[];
};

const uuidRe =
  /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/;

const isUuid = (value: unknown): value is UuidV4 => {
  return !!(typeof value === 'string' && value && uuidRe.test(value));
};

const PLAYER_IDS_QUERY = gql`
  query PlayersCsvGeneratorBlock(
    $indexPrefix: String
    $playerUUIDs: [String!]!
  ) {
    viewer {
      id
      playersSearchV2(
        first: 1000
        indexPrefix: $indexPrefix
        playerIds: $playerUUIDs
      ) {
        edges {
          node {
            playerId
            uuid
            player {
              email
            }
          }
        }
      }
    }
  }
`;

const fieldsOptions = [
  { label: 'Link', value: 'link' },
  { label: 'Email', value: 'email' },
];

const PlayersCsvGeneratorBlock: FC<{
  block: Queries.SanityPlayersCsvGeneratorBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();
  const { indexPrefix } = useElasticIndexPrefix();
  const { dispatch, file } = useFileDropFile();
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [csvRows, setCsvRows] = useState<CsvRow[] | null>(null);
  const client = useClient();
  const getPlayerOverviewPageLink = useGetPlayerOverviewPageLink();

  const defaultValues: FormValues = {
    fields: [],
  };

  const methods = useForm({
    defaultValues,
  });

  if (!block) {
    return null;
  }

  const handleSubmit = async (values: FormValues) => {
    if (!file) {
      return;
    }

    try {
      setIsGenerating(true);
      setCsvRows(null);

      const fileContent = await readFile(file);

      if (typeof fileContent !== 'string') {
        throw new Error('invalid file content');
      }

      const csvRows = parse<CsvRow>(fileContent).data;

      const playerUUIDs = csvRows.reduce<string[]>((acc, row) => {
        const [head] = row;
        if (isUuid(head)) {
          acc = [...acc, head];
        }
        return acc;
      }, []);

      const res = await client
        .query<
          PlayersCsvGeneratorBlockQuery,
          PlayersCsvGeneratorBlockQueryVariables
        >(PLAYER_IDS_QUERY, {
          playerUUIDs,
          indexPrefix,
        })
        .toPromise();

      if (res.error) {
        setIsGenerating(false);
        return setErrorMessage(res.error.message);
      }

      const playerNodes = res.data?.viewer.playersSearchV2.edges?.reduce<
        NonNullable<
          NonNullable<
            PlayersCsvGeneratorBlockQuery['viewer']['playersSearchV2']['edges']
          >[number]
        >['node'][]
      >((acc, e) => {
        if (e) {
          acc = [...acc, e.node];
        }
        return acc;
      }, []);

      const playersByUUID = keyBy(playerNodes, (v) => v?.uuid);

      const newCsvRows = csvRows.map((row) => {
        const [uuid, ...tail] = row;
        const playerDetails = (isUuid(uuid) && playersByUUID[uuid]) || null;

        const pageLinkPath =
          playerDetails && getPlayerOverviewPageLink(playerDetails.playerId);

        const baseUrl = `${window.location.protocol}//${window.location.hostname}`;
        const link = pageLinkPath ? `${baseUrl}${pageLinkPath}` : '';
        const email = playerDetails?.player.email || '';

        const newValues = values.fields.reduce<CsvRow>((acc, field) => {
          if (field === 'link') {
            acc = [...acc, link];
          }

          if (field === 'email') {
            acc = [...acc, email];
          }
          return acc;
        }, []);

        return [uuid, ...newValues, ...tail];
      });

      setCsvRows(newCsvRows);
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <Card size="lg" title={t(block.title)}>
      <CardBody>
        <NakedForm methods={methods} onSubmit={handleSubmit}>
          <div className="grid gap-3 p-3">
            <div className="text-sm text-black dark:text-gray-200">
              {t(block.description)}
            </div>
            <FileDrop
              dispatch={dispatch}
              file={file}
              accept={{ 'text/csv': ['.csv'] }}
              setErrorMessage={setErrorMessage}
            />
            <SelectField
              title={t(block.selectFieldsLabel)}
              isMulti
              name="fields"
              id="players-csv-generator-block__result"
              options={fieldsOptions}
            ></SelectField>
            <SubmitButton
              value={t(block.submitButton)}
              disabled={!!errorMessage || !file || isGenerating}
            />
            <ErrorMessage message={errorMessage} />
          </div>
        </NakedForm>
        {csvRows && (
          <div className="p-3">
            <CSVLink
              filename={file?.name ?? 'file'}
              className="align-top disabled:opacity-50 disabled:pointer-events-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-800 focus-visible:ring-white inline-flex justify-center items-center rounded-md p-2 text-gray-600 hover:text-gray-700 hover:bg-gray-300 active:bg-gray-400 hover:bg-opacity-30 focus:outline-none dark:text-gray-200 dark:hover:bg-gray-700 dark:active:bg-gray-600 dark:hover:bg-opacity-30"
              data={csvRows}
            >
              <span className="mr-1">{t(block.downloadButton)}</span>
              <DocumentDownloadIcon />
            </CSVLink>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default PlayersCsvGeneratorBlock;
