import gql from 'graphql-tag';
import { useQuery } from 'urql';
import { useQueryParams } from 'use-query-params';

import { mapVariables } from '@/utils';
import { makeOrderBy, makePagination } from '@/utils/pagination';
import {
  PlayerChallengeSearchQuery,
  PlayerChallengeSearchQueryVariables,
} from './__generated__/usePlayerChallenge';
import { queryParams } from './queryParams';

const PLAYER_CHALLENGE_QUERY = gql`
  query PlayerChallengeSearch(
    $playerId: String!
    $after: String
    $before: String
    $desc: Boolean
    $first: Int
    $challengeStatus: ChallengeStatus
  ) {
    viewer {
      id
      playersChallenge: playersChallengeV3(
        globalPlayerIdList: [$playerId]
        after: $after
        before: $before
        desc: $desc
        first: $first
        challengeStatus: $challengeStatus
      ) {
        edges {
          node {
            challengeUUID
            playerId
            challengeName
            startDate
            endDate
            challengeCriteriaTotal
            challengeCriteriaRemaining
            challengeCriteriaCompleted
            challenge {
              edges {
                node {
                  id
                  name
                  status
                  challengeRewards {
                    challengeRewardType {
                      name
                      ... on FreeSpinChallengeReward {
                        freeSpinsAmount: amount
                      }
                      ... on MoneyDropChallengeReward {
                        moneyDropAmount: amount
                        currency
                      }
                      ... on BonusMoneyDropChallengeReward {
                        bonusMoneyDropAmount: amount
                        currency
                      }
                      ... on DynamicFreeSpinChallengeReward {
                        dynamicFreeSpinMultiplier: multiplier
                        currency
                      }
                      ... on DynamicMoneyDropChallengeReward {
                        dynamicMoneyDropMultiplier: multiplier
                        currency
                      }
                      ... on DynamicBonusMoneyDropChallengeReward {
                        dynamicBonusMoneyDropMultiplier: multiplier
                        currency
                      }
                    }
                  }
                }
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;

export default function usePlayerChallenge(playerId: string) {
  const [query, setQuery] = useQueryParams(queryParams);
  const [{ data, fetching }, refresh] = useQuery<
    PlayerChallengeSearchQuery,
    PlayerChallengeSearchQueryVariables
  >({
    query: PLAYER_CHALLENGE_QUERY,
    variables: {
      playerId,
      ...mapVariables(query),
    },
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter: (values: {}) => setQuery(values, 'replaceIn'),
    ...makeOrderBy(query, setQuery),
    ...makePagination(query, setQuery, data?.viewer.playersChallenge.pageInfo),
    playerChallenge: data?.viewer.playersChallenge.edges?.map(
      (edge) => edge?.node,
    ),
  };
}
