import { format, parseISO } from 'date-fns';
import { sortBy } from 'lodash';
import React, { ComponentType, FC } from 'react';

import { GranularityEnum } from '@/globalTypes';
import { Nullable } from '@/types';
import formatMoney from '@/utils/formatter/formatMoney';

type BaseValueProps = {
  value: Nullable<any>;
};

type Formatter<ValueType> = (value: ValueType) => string | number;
type NumberFormatter = Formatter<Nullable<number>>;
type ToStringFormatter<ValueType> = (value: ValueType) => string;
type NumberToStringFormatter = ToStringFormatter<Nullable<number>>;

export type ValueConfig<T extends BaseValueProps = BaseValueProps> = {
  name: string;
  fieldName?: string;
  label: string;
  Component: ComponentType<T>;
  toFormattedValue: Formatter<any>;
  staticProps: Omit<T, 'value'>;
};

const isValidNumber = (value: unknown): value is number => {
  return typeof value === 'number' && value != null && !isNaN(value);
};

const percentageValueToStringValue: NumberToStringFormatter = (value) => {
  if (isValidNumber(value)) {
    const pct = (value * 100).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return `${pct}%`;
  }
  return 'N/A';
};

const PercentageValue: FC<{ value: Nullable<number> }> = ({ value }) => (
  <>{percentageValueToStringValue(value)}</>
);

const monetaryValueToStringValue: NumberToStringFormatter = (value) => {
  if (isValidNumber(value)) {
    return formatMoney(value);
  }
  return 'N/A';
};

const formatMonetaryValue: NumberFormatter = (value) =>
  isValidNumber(value) ? value : 'N/A';

const MonetaryValue: FC<{ value: Nullable<number> }> = ({ value }) => (
  <>{monetaryValueToStringValue(value)}</>
);

const numberValueToStringValue: NumberToStringFormatter = (value) => {
  if (isValidNumber(value)) {
    return value.toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  }
  return 'N/A';
};

const formatNumberValue: NumberFormatter = (value) =>
  isValidNumber(value) ? value : 'N/A';

const NumberValue: FC<{ value: Nullable<number> }> = ({ value }) => (
  <>{numberValueToStringValue(value)}</>
);

const stringValueToStringValue: ToStringFormatter<Nullable<string>> = (value) =>
  value ?? '';

const StringValue: FC<{ value: Nullable<string> }> = ({ value }) => (
  <>{stringValueToStringValue(value)}</>
);

const CountryValue: FC<{ value: Nullable<string> }> = ({ value }) => (
  <StringValue value={value} />
);

const granularityFormat: Record<GranularityEnum, string | undefined> = {
  [GranularityEnum.All]: undefined,
  [GranularityEnum.Year]: 'yyyy',
  [GranularityEnum.Quarter]: 'yyyy qqq',
  [GranularityEnum.Week]: 'yyyy I',
  [GranularityEnum.Month]: 'yyyy-MM',
  [GranularityEnum.Day]: 'yyyy-MM-dd',
  [GranularityEnum.Hour]: 'yyyy-MM-dd HH:00',
  [GranularityEnum.Minute]: 'yyyy-MM-dd HH:mm',
  [GranularityEnum.Second]: 'yyyy-MM-dd HH:mm:ss',
};

const parseISODate = (dateString: Nullable<string>) => {
  if (!dateString) {
    return undefined;
  }

  const d = parseISO(dateString);
  return isNaN(d.getTime()) ? undefined : d;
};

const timePeriodValueToStringValue =
  (granularity: GranularityEnum): ToStringFormatter<Nullable<string>> =>
  (value) => {
    const d = parseISODate(value);
    const dateFormat = granularityFormat[granularity];

    if (d && dateFormat) {
      return format(d, dateFormat, {
        weekStartsOn: 1,
      });
    }

    if (!dateFormat) {
      return 'N/A';
    }

    return '';
  };

const TimePeriodValue: FC<{ value: string; granularity: GranularityEnum }> = ({
  value,
  granularity,
}) => <>{timePeriodValueToStringValue(granularity)(value)}</>;

type BaseOptions = {
  name: string;
  label: string;
  fieldName?: string;
};

function valueFactory<
  Options extends BaseOptions = BaseOptions,
  Props extends BaseValueProps = BaseValueProps,
>(
  Component: ComponentType<Props>,
  toFormattedValue: ValueConfig['toFormattedValue'],
  staticProps: Omit<Props, 'value'>,
) {
  return function (options: Options): ValueConfig<Props> {
    return {
      Component,
      toFormattedValue,
      staticProps,
      ...options,
    };
  };
}

const percentageValue = valueFactory(
  PercentageValue,
  percentageValueToStringValue,
  {},
);
const monetaryValue = valueFactory(MonetaryValue, formatMonetaryValue, {});
const numberValue = valueFactory(NumberValue, formatNumberValue, {});
const stringValue = valueFactory(StringValue, stringValueToStringValue, {});
const countryValue = valueFactory(CountryValue, stringValueToStringValue, {});

const createTimePeriodValue = (granularity: GranularityEnum) =>
  valueFactory(TimePeriodValue, timePeriodValueToStringValue(granularity), {
    granularity,
  });

export const timePeriodDimensionsMap: Record<
  string,
  ValueConfig<{ granularity: GranularityEnum; value: string }>
> = {
  all: createTimePeriodValue(GranularityEnum.All)({
    name: 'all',
    label: 'All',
    fieldName: 'timePeriod',
  }),
  day: createTimePeriodValue(GranularityEnum.Day)({
    name: 'day',
    label: 'Day',
    fieldName: 'timePeriod',
  }),
  hour: createTimePeriodValue(GranularityEnum.Hour)({
    name: 'hour',
    label: 'Hour',
    fieldName: 'timePeriod',
  }),
  month: createTimePeriodValue(GranularityEnum.Month)({
    name: 'month',
    label: 'Month',
    fieldName: 'timePeriod',
  }),
  quarter: createTimePeriodValue(GranularityEnum.Quarter)({
    name: 'quarter',
    label: 'Quarter',
    fieldName: 'timePeriod',
  }),
  week: createTimePeriodValue(GranularityEnum.Week)({
    name: 'week',
    label: 'Week',
    fieldName: 'timePeriod',
  }),
  year: createTimePeriodValue(GranularityEnum.Year)({
    name: 'year',
    label: 'Year',
    fieldName: 'timePeriod',
  }),
};

export const checkHasTimeRangeDimension = (selectedDimensions: string[]) => {
  return selectedDimensions.some((val) => val in timePeriodDimensionsMap);
};

const nonTimePeriodDimensionsMap: Record<string, ValueConfig> = {
  affiliateId: stringValue({ name: 'affiliateId', label: 'Affiliate ID' }),
  brand: stringValue({ name: 'brand', label: 'Brand' }),
  currency: stringValue({ name: 'currency', label: 'Currency' }),
  device: stringValue({ name: 'device', label: 'Device' }),
  campaignId: stringValue({ name: 'campaignId', label: 'Campaign Id' }),
  cashBackEnableLookup: stringValue({
    name: 'cashBackEnableLookup',
    label: 'Cashback Enabled',
  }),
  gameProvider: stringValue({ name: 'gameProvider', label: 'Game Provider' }),
  gameCategoryLookup: stringValue({
    name: 'gameCategoryLookup',
    label: 'Game Category',
  }),
  gameNameLookup: stringValue({
    name: 'gameNameLookup',
    label: 'Game Name',
  }),
  moneyDropType: stringValue({
    name: 'moneyDropType',
    label: 'Money Drop Type',
  }),
  isIncognito: stringValue({ name: 'isIncognito', label: 'Is Incognito' }),
  isLiveCasinoGameLookup: stringValue({
    name: 'isLiveCasinoGameLookup',
    label: 'Is Live Casino Game',
  }),
  os: stringValue({ name: 'os', label: 'OS' }),
  paymentProvider: stringValue({
    name: 'paymentProvider',
    label: 'Payment Provider',
  }),
  playerAccountStatusLookup: stringValue({
    name: 'playerAccountStatusLookup',
    label: 'Player Account',
  }),
  playerCurrentSegment: stringValue({
    name: 'playerCurrentSegment',
    label: 'Player Current Segment',
  }),
  residenceCountryCode: countryValue({
    name: 'residenceCountryCode',
    label: 'Residence Country Code',
  }),
  volatilityLookup: countryValue({
    name: 'volatilityLookup',
    label: 'Volatility',
  }),
};

const sortByLabel = (collection: ValueConfig<any>[]) => {
  return sortBy(collection, (a) => a.label.toLowerCase().trim());
};

export const dimensionsSchema: ValueConfig[] = sortByLabel([
  ...Object.values(timePeriodDimensionsMap),
  ...Object.values(nonTimePeriodDimensionsMap),
]);

const dimensionFromName = (name: string): ValueConfig<any> | undefined => {
  return timePeriodDimensionsMap[name] || nonTimePeriodDimensionsMap[name];
};

const valuesMap: Record<string, ValueConfig> = {
  amountAdjustments: monetaryValue({
    name: 'amountAdjustments',
    label: 'Adjustment Amount',
  }),
  amountAllBonus: monetaryValue({
    name: 'amountAllBonus',
    label: 'All Bonus Amount',
  }),
  amountBonusAdjustments: monetaryValue({
    name: 'amountBonusAdjustments',
    label: 'Bonus Adjustment Amount',
  }),
  amountCashbacks: monetaryValue({
    name: 'amountCashbacks',
    label: 'Cashback Amount',
  }),
  amountDeposits: monetaryValue({
    name: 'amountDeposits',
    label: 'Deposit Amount',
  }),
  amountFreeSpinWins: monetaryValue({
    name: 'amountFreeSpinWins',
    label: 'Free Spins Wins Amount',
  }),
  amountMoneyDrops: monetaryValue({
    name: 'amountMoneyDrops',
    label: 'Money Drops Amount',
  }),
  amountReversals: monetaryValue({
    name: 'amountReversals',
    label: 'Deposit Reversals Amount',
  }),
  amountFtd: monetaryValue({ name: 'amountFtd', label: 'FTD Amount' }),
  amountFailedDeposits: monetaryValue({
    name: 'amountFailedDeposits',
    label: 'Failed Deposit Amount',
  }),
  amountFailedWithdrawals: monetaryValue({
    name: 'amountFailedWithdrawals',
    label: 'Failed Withdrawal Amount',
  }),
  AvgBetSize: monetaryValue({
    name: 'AvgBetSize',
    label: 'Average Bet Size',
  }),
  AvgDepositAmount: monetaryValue({
    name: 'AvgDepositAmount',
    label: 'Average Deposit Amount',
  }),
  AvgDepositPerUser: monetaryValue({
    name: 'AvgDepositPerUser',
    label: 'Average Deposit Per User',
  }),
  AvgRevenuePerUser: monetaryValue({
    name: 'AvgRevenuePerUser',
    label: 'Average Revenue Per User',
  }),
  calculatedBalance: monetaryValue({
    name: 'calculatedBalance',
    label: 'Calculated Balance',
  }),
  ggr: monetaryValue({ name: 'ggr', label: 'GGR' }),
  allWins: monetaryValue({ name: 'allWins', label: 'Game Win' }),
  jpc: monetaryValue({ name: 'jpc', label: 'JPC' }),
  ngr: monetaryValue({ name: 'ngr', label: 'NGR' }),
  turnover: monetaryValue({ name: 'turnover', label: 'Turnover' }),
  amountWithdrawableWinnings: monetaryValue({
    name: 'amountWithdrawableWinnings',
    label: 'Withdrawable Winnings Amount',
  }),
  amountWithdrawals: monetaryValue({
    name: 'amountWithdrawals',
    label: 'Withdrawal Amount',
  }),
  ftd: numberValue({ name: 'ftd', label: 'FTD' }),
  NetDeposits: monetaryValue({
    name: 'NetDeposits',
    label: 'Net Deposits',
  }),
  nrc: numberValue({ name: 'nrc', label: 'NRC' }),
  nrcByNsc: numberValue({
    name: 'nrcByNsc',
    label: 'New Registered customers in terms of new signed up customers',
  }),
  nsc: numberValue({ name: 'nsc', label: 'New Signed Up Customers' }),
  numUniqueActivePlayers: numberValue({
    name: 'numUniqueActivePlayers',
    label: 'Num. Active Players',
  }),
  numAdjustments: numberValue({
    name: 'numAdjustments',
    label: 'Num. Adjustments',
  }),
  wagers: numberValue({ name: 'wagers', label: 'Num. Bets' }),
  numCashbacks: numberValue({ name: 'numCashbacks', label: 'Num. Cashbacks' }),
  numReversals: numberValue({
    name: 'numReversals',
    label: 'Num. Deposit Reversals',
  }),
  numUniqueDepositors: numberValue({
    name: 'numUniqueDepositors',
    label: 'Num. Depositors',
  }),
  numUniqueActiveDepositors: numberValue({
    name: 'numUniqueActiveDepositors',
    label: 'Num. of Unique Active Depositors',
  }),
  numDeposits: numberValue({ name: 'numDeposits', label: 'Num. Deposits' }),
  numFailedDeposits: numberValue({
    name: 'numFailedDeposits',
    label: 'Num. Failed Deposits',
  }),
  numFailedWithdrawals: numberValue({
    name: 'numFailedWithdrawals',
    label: 'Num. Failed Withdrawals',
  }),
  numUniqueSessions: numberValue({
    name: 'numUniqueSessions',
    label: 'Num. of Player Sessions',
  }),
  numUniqueActiveGlobalPlayers: numberValue({
    name: 'numUniqueActiveGlobalPlayers',
    label: 'Num. of Active Global Players',
  }),
  numUniquePlayers: numberValue({
    name: 'numUniquePlayers',
    label: 'Num. of Players',
  }),
  numWithdrawals: numberValue({
    name: 'numWithdrawals',
    label: 'Num. Withdrawals',
  }),
  ftdOfNrc: percentageValue({ name: 'ftdOfNrc', label: 'FTD of NRC' }),
  margin: percentageValue({ name: 'margin', label: 'Margin' }),
  pendingWithdrawals: numberValue({
    name: 'pendingWithdrawals',
    label: 'Pending Withdrawals',
  }),
};

export const valuesSchema: ValueConfig[] = sortByLabel(
  Object.values(valuesMap),
);

const valueFromName = (name: string): ValueConfig | undefined =>
  valuesMap[name];

export const getValueConfig = (name: string) => {
  return dimensionFromName(name) || valueFromName(name);
};
