import React, { useEffect, useMemo } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';

import { NakedForm, SelectField } from '@/components';
import { useTranslate } from '@/contexts';
import { PlayerNoteSearchOrder } from '@/globalTypes';
import { createStorage } from '@/utils/storage';

const storage = createStorage<PlayerNoteSearchOrder>(
  'sessionStorage',
  'PlayerNotes:orderBy',
);

type FormValues = {
  orderBy: PlayerNoteSearchOrder;
};

type NotesBlock = Queries.StaticPlayerNotesBlockQuery['sanityPlayerNotesBlock'];

export const useNotesOrderByForm = (): {
  methods: UseFormReturn<FormValues>;
  orderBy: PlayerNoteSearchOrder;
} => {
  const methods = useForm<FormValues>({
    defaultValues: {
      orderBy: storage.get() || PlayerNoteSearchOrder.createdAt,
    },
  });

  const orderBy = methods.watch('orderBy');

  useEffect(() => {
    storage.set(orderBy);
  }, [orderBy]);

  return {
    methods,
    orderBy,
  };
};

export const NotesOrderForm = ({
  methods,
  block,
}: {
  methods: UseFormReturn<FormValues>;
  block: NotesBlock;
}) => {
  const { t } = useTranslate();

  const orderOptions = useMemo<
    { label: string; value: PlayerNoteSearchOrder }[]
  >(
    () => [
      {
        label: t(block?.date),
        value: PlayerNoteSearchOrder.createdAt,
      },
      {
        label: t(block?.priority),
        value: PlayerNoteSearchOrder.priorityValue,
      },
    ],
    [block?.date, block?.priority, t],
  );

  return (
    <NakedForm onSubmit={() => {}} methods={methods}>
      <SelectField
        name="orderBy"
        options={orderOptions}
        title={t(block?.orderBy)}
      />
    </NakedForm>
  );
};
