import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import React, { FC } from 'react';

import { Card, CardBody, CardOptions, CardOptionsButton } from '@/components';
import { useDataGrid } from '@/components/DataGrid';
import { NextIcon, PreviousIcon, RefreshIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import { LimitSearchOrder, LimitType } from '@/globalTypes';
import { assert } from '@/utils/error';
import usePlayerGlobalLimitHistory from './usePlayerGlobalLimitHistory';

export const Fragment = graphql`
  fragment SanityPlayerGlobalLimitHistoryBlock on SanityPlayerGlobalLimitHistoryBlock {
    title {
      ...SanityLocaleString
    }
    type
  }
`;

const PlayerGlobalLimitsHistoryBlock: FC<{
  block: Queries.SanityPlayerGlobalLimitHistoryBlockFragment;
}> = ({ block }) => {
  const params = useParams();
  const { t } = useTranslate();

  assert(block.type, 'missing block type');
  assert(params.playerId, 'missing playerId');

  const limitType: LimitType = LimitType[block.type as keyof typeof LimitType];

  const {
    history,
    fetching,
    nextPage,
    previousPage,
    refresh,
    orderBy,
    desc,
    setOrderBy,
    defaultFilter,
    updateFilter,
  } = usePlayerGlobalLimitHistory(params.playerId, limitType);

  const { DataGrid, GlobalCardOptions } = useDataGrid({
    name: 'playerLimitsHistory',
    data: history,
    fetching,
    orderBy,
    setOrderBy,
    desc,
    defaultFilter,
    updateFilter,
    schema: (s) => {
      return [
        s.dateTimeValue({
          field: 'requestedAt',
          title: 'Requested At',
          orderBy: LimitSearchOrder.requestedAt,
        }),
        s.dateTimeValue({
          field: 'confirmedAt',
          title: 'Confirmed At',
        }),
        s.dateTimeValue({
          field: 'availableAt',
          title: 'Available At',
        }),
        s.stringValue({
          field: 'period',
          title: 'Period',
        }),
        s.monetaryValue({
          field: 'prevValue',
          title: 'Previous Value',
        }),
        s.monetaryValue({
          field: 'newValue',
          title: 'New Value',
        }),
        s.initiatorValue({
          field: 'requestedBy',
          title: 'Requested By',
        }),
        s.stringValue({
          field: 'limitStatus',
          title: 'Limit Status',
          filterField: 'limitStatus',
        }),
      ];
    },
  });

  return (
    <Card
      size="lg"
      title={t(block.title)}
      showOptionsAtBottom
      options={
        <CardOptions>
          <CardOptionsButton
            disabled={!previousPage}
            onClick={() => previousPage && previousPage()}
          >
            <PreviousIcon />
          </CardOptionsButton>
          <CardOptionsButton
            disabled={!nextPage}
            onClick={() => nextPage && nextPage()}
          >
            <NextIcon />
          </CardOptionsButton>
          <GlobalCardOptions />
          <CardOptionsButton
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <DataGrid />
      </CardBody>
    </Card>
  );
};

export default PlayerGlobalLimitsHistoryBlock;
