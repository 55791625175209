// I suck at regex, but this checks if a string is a number while also allowing decimals
const numericRe = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;

export function isNumeric(value: unknown) {
  if (typeof value === 'number') {
    return true;
  }

  if (typeof value === 'string') {
    return numericRe.test(value);
  }

  return false;
}
