import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useState } from 'react';
import { useMutation } from 'urql';

import {
  Card,
  CardBody,
  CardCloseButton,
  CardOptions,
  CheckboxField,
  ErrorMessage,
  Form,
  SubmitButton,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { useIsMounted } from '@/hooks';
import { Nullable, PlayerId } from '@/types';
import {
  PlayerUpdateCancelWithdrawalBlockMutation,
  PlayerUpdateCancelWithdrawalBlockMutationVariables,
} from './__generated__/PlayerUpdateCancelWithdrawalBlockForm';

const query = graphql`
  query SanityPlayerUpdateCancelWithdrawalBlockForm {
    sanityPlayerUpdateCancelWithdrawalBlockForm {
      title {
        ...SanityLocaleString
      }
      description {
        ...SanityLocaleString
      }
      isCancelWithdrawalBlocked {
        ...SanityLocaleString
      }
      submit {
        ...SanityLocaleString
      }
    }
  }
`;

const updateCustomerCancelWithdrawalBlockMutation = gql`
  mutation PlayerUpdateCancelWithdrawalBlock(
    $playerGlobalId: ID!
    $enable: Boolean!
  ) {
    updateCustomerCancelWithdrawalBlock(
      playerGlobalId: $playerGlobalId
      enable: $enable
    ) {
      ... on Customer {
        isCancelWithdrawalBlocked
      }
    }
  }
`;

const PlayerUpdateCancelWithdrawalBlockForm: FC<{
  playerGlobalId: PlayerId;
  isBlocked: boolean;
}> = ({ playerGlobalId, isBlocked }) => {
  const staticData =
    useStaticQuery<Queries.SanityPlayerUpdateCancelWithdrawalBlockFormQuery>(
      query,
    );

  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();
  const form = staticData.sanityPlayerUpdateCancelWithdrawalBlockForm;
  const { close } = useModalContext();
  const { t } = useTranslate();

  const [updateCancelWithdrawalBlockState, updateCancelWithdrawalBlock] =
    useMutation<
      PlayerUpdateCancelWithdrawalBlockMutation,
      PlayerUpdateCancelWithdrawalBlockMutationVariables
    >(updateCustomerCancelWithdrawalBlockMutation);

  const defaultValues = {
    isBlocked,
  };

  const onSubmit = ({ isBlocked }: typeof defaultValues) => {
    updateCancelWithdrawalBlock({
      playerGlobalId,
      enable: !isBlocked,
    }).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      } else if (close) {
        close();
      }
    });
  };

  if (!form) {
    return null;
  }

  return (
    <Card
      size="md"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <CardBody>
        <div className="p-3">
          <Form
            defaultValues={defaultValues}
            onSubmit={onSubmit}
            className="grid gap-6"
          >
            <div className="space-y-1">
              <div className="block text-gray-600 text-sm dark:text-gray-200">
                {t(form.description)}
              </div>
            </div>
            <CheckboxField
              title={t(form.isCancelWithdrawalBlocked)}
              name="isCancelWithdrawalBlocked"
              id="PlayerUpdateCancelWithdrawalBlockForm_isCancelWithdrawalBlocked"
              className="col-span-full"
              defaultChecked={isBlocked}
            />
            <ErrorMessage message={errorMessage} />
            <SubmitButton
              value={t(form.submit)}
              disabled={updateCancelWithdrawalBlockState.fetching}
            />
          </Form>
        </div>
      </CardBody>
    </Card>
  );
};

export default PlayerUpdateCancelWithdrawalBlockForm;
