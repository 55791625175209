import { useParams } from '@reach/router';
import { Link, graphql } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { useQuery } from 'urql';

import { useGetGlobalPlayerOverviewPageLink } from '@/bits/links/useLink';
import { Breadcrumb, Breadcrumbs, InlineSpinner } from '@/components';
import { usePage } from '@/contexts';
import { Nullable } from '@/types';
import { makeBrandThumbnail } from '@/utils/makeBrandThumbnail';
import {
  PlayerBreadcrumbsQuery,
  PlayerBreadcrumbsQueryVariables,
} from './__generated__/component';

export const Fragment = graphql`
  fragment SanityPlayerBreadcrumbsBlock on SanityPlayerBreadcrumbsBlock {
    pages {
      _id
      title
      path {
        current
      }
    }
  }
`;

const QUERY = gql`
  query PlayerBreadcrumbs($playerId: ID!) {
    player(playerId: $playerId) {
      id
      playerGlobalId
      firstName
      lastName
      gender
      brand {
        id
        code
        name
      }
    }
  }
`;

const MaleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 117.6 122.88"
    fill="currentColor"
    className="text-current w-3 h-3 ml-1"
  >
    <path d="M46.27 30.34v.02c10.09 0 19.43 3.22 27.02 8.69l9.48-9.69-14.24-13.25L117.6 0l-12.66 50.07-14.02-13.33-9.24 10.08A46.12 46.12 0 0192.52 76.6h.02v.01h-.02c0 12.79-5.17 24.36-13.53 32.71-8.36 8.36-19.92 13.53-32.71 13.53v.02h-.01v-.02c-12.79 0-24.36-5.17-32.71-13.53A46.082 46.082 0 01.02 76.62H0v-.01h.02c0-12.79 5.17-24.36 13.53-32.71 8.36-8.36 19.92-13.53 32.71-13.53l.01-.03zm0 11.91v.02h-.01l.01-.02c-9.47 0-18.06 3.86-24.28 10.08-6.23 6.23-10.08 14.82-10.08 24.28h.02v.01h-.02c0 9.46 3.85 18.05 10.08 24.27 6.23 6.23 14.82 10.08 24.28 10.08v-.02h.01v.02c9.46 0 18.05-3.85 24.27-10.08 6.23-6.23 10.08-14.82 10.08-24.28h-.02v-.01h.02c0-9.46-3.85-18.05-10.08-24.27-6.23-6.23-14.81-10.08-24.28-10.08z" />
  </svg>
);

const FemaleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 84.89 122.88"
    fill="currentColor"
    className="text-current w-3 h-3 ml-1 mt-1"
  >
    <path d="M42.45 0v.02c23.11 0 42.42 19.23 42.42 42.42h.02v.01h-.02c0 11.73-4.75 22.34-12.42 30.01-6.45 6.46-15 10.84-24.54 12.06v13.26h18.8c3.02 0 5.47 2.45 5.47 5.47 0 3.02-2.45 5.47-5.47 5.47h-18.8v9.71c0 2.45-2.45 4.43-5.47 4.43s-5.47-1.98-5.47-4.43v-9.71h-18.8c-3.02 0-5.47-2.45-5.47-5.47 0-3.02 2.45-5.47 5.47-5.47h18.8V84.53a42.336 42.336 0 01-24.54-12.06C4.76 64.79.02 54.19.02 42.46H0v-.01h.02c0-11.73 4.75-22.34 12.41-30.01C20.1 4.77 30.71.02 42.44.02l.01-.02zm0 10.92v.02h-.01l.01-.02c-8.69 0-16.56 3.54-22.28 9.25-5.71 5.71-9.25 13.59-9.25 22.27h.02v.01h-.02c0 8.68 3.54 16.55 9.25 22.27 5.71 5.71 13.59 9.25 22.27 9.25v-.02h.01v.02c8.68 0 16.56-3.54 22.27-9.25 5.71-5.71 9.25-13.59 9.25-22.27h-.02v-.01h.02c0-8.68-3.54-16.55-9.25-22.27-5.71-5.71-13.59-9.24-22.27-9.25z" />
  </svg>
);

const getFluid = (data: PlayerBreadcrumbsQuery | undefined) => {
  return data?.player.brand && makeBrandThumbnail(data.player.brand);
};

const renderGenderIcon = (gender: Nullable<string>) => {
  if (gender === 'Male') {
    return <MaleIcon />;
  }
  if (gender === 'Female') {
    return <FemaleIcon />;
  }
  return null;
};

const PlayerBreadcrumbsBlock: FC<{
  block: Queries.SanityPlayerBreadcrumbsBlockFragment;
}> = ({ block }) => {
  const params = useParams();
  const currentPage = usePage();
  const getGlobalPlayerOverviewLink = useGetGlobalPlayerOverviewPageLink();

  const [{ data, fetching }] = useQuery<
    PlayerBreadcrumbsQuery,
    PlayerBreadcrumbsQueryVariables
  >({
    query: QUERY,
    variables: {
      playerId: params.playerId,
    },
  });

  const fluid = getFluid(data);

  const getGlobalPlayerBreadcrumb = (
    player: PlayerBreadcrumbsQuery['player'],
  ) => {
    return player.playerGlobalId ? (
      <Link to={getGlobalPlayerOverviewLink(player.playerGlobalId) ?? ''}>
        {player.firstName || ''} {player.lastName || ''}
      </Link>
    ) : (
      ''
    );
  };

  return (
    <Breadcrumbs className="col-span-full">
      {block.pages?.map((page) => (
        <Breadcrumb key={page?._id}>{page?.title}</Breadcrumb>
      ))}
      {!fetching && data?.player.playerGlobalId && (
        <Breadcrumb key="global">
          <div className="flex-shrink-0 pr-2 inline-flex">
            <picture>
              <img
                src="/Globe.png"
                alt=""
                loading="lazy"
                className="w-6 h-6 rounded-sm"
              />
            </picture>
          </div>
          {getGlobalPlayerBreadcrumb(data?.player)}
        </Breadcrumb>
      )}
      <Breadcrumb>
        <div className="flex-shrink-0 pr-2 inline-flex">
          {fluid && (
            <picture>
              <source sizes={fluid.sizes} srcSet={fluid.srcSet} />
              <img
                src={fluid.src}
                alt=""
                loading="lazy"
                className="w-6 h-6 rounded-sm"
              />
            </picture>
          )}
        </div>
        {fetching ? (
          <InlineSpinner />
        ) : data?.player.firstName || data?.player.lastName ? (
          `${data?.player.firstName || ''} ${data?.player.lastName || ''}`
        ) : (
          'No Name'
        )}
        {renderGenderIcon(data?.player.gender)}
      </Breadcrumb>
      <Breadcrumb last>{currentPage?.title}</Breadcrumb>
    </Breadcrumbs>
  );
};

export default PlayerBreadcrumbsBlock;
