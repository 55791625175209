import { Maybe } from '@/globalTypes';

type PlayerSegment =
  | 'Tier1'
  | 'Tier2'
  | 'Tier3'
  | 'Tier4'
  | 'PVIP'
  | 'VIP1'
  | 'VIP2'
  | 'VIP3';

type BonusCondition = {
  segment: PlayerSegment;
  minLoss: number;
  maxLoss: number;
  rewardAmount: number | null;
  wagering: number | null;
  maxWin: number | null;
};

type FreeSpinsCondition = {
  segment: PlayerSegment;
  minLoss: number;
  maxLoss: number;
  nrOfSpins: number | null;
  value: number | null;
};

type BonusResult = {
  rewardAmount: Maybe<number>;
  wagering: Maybe<number>;
  maxWin: Maybe<number>;
};

type FreeSpinsResult = {
  nrOfSpins: Maybe<number>;
  value: Maybe<number>;
};

export const freeSpinsConditions: FreeSpinsCondition[] = [
  { segment: 'Tier1', minLoss: 0, maxLoss: 99, nrOfSpins: null, value: null },
  { segment: 'Tier2', minLoss: 100, maxLoss: 349, nrOfSpins: 10, value: 20 },
  { segment: 'Tier3', minLoss: 100, maxLoss: 349, nrOfSpins: 10, value: 20 },
  { segment: 'Tier4', minLoss: 100, maxLoss: 349, nrOfSpins: 20, value: 20 },
  {
    segment: 'PVIP',
    minLoss: 100,
    maxLoss: Infinity,
    nrOfSpins: 25,
    value: 20,
  },
];

export const bonusConditions: BonusCondition[] = [
  {
    segment: 'Tier1',
    minLoss: 0,
    maxLoss: 99,
    rewardAmount: null,
    wagering: null,
    maxWin: null,
  },
  {
    segment: 'Tier3',
    minLoss: 350,
    maxLoss: 749,
    rewardAmount: 5,
    wagering: 25,
    maxWin: 50,
  },
  {
    segment: 'Tier4',
    minLoss: 350,
    maxLoss: 749,
    rewardAmount: 10,
    wagering: 25,
    maxWin: 100,
  },
  {
    segment: 'Tier4',
    minLoss: 750,
    maxLoss: 999,
    rewardAmount: 25,
    wagering: 35,
    maxWin: 250,
  },
  {
    segment: 'PVIP',
    minLoss: 350,
    maxLoss: 749,
    rewardAmount: 10,
    wagering: 25,
    maxWin: 100,
  },
  {
    segment: 'PVIP',
    minLoss: 750,
    maxLoss: 999,
    rewardAmount: 25,
    wagering: 35,
    maxWin: 250,
  },
  {
    segment: 'PVIP',
    minLoss: 1000,
    maxLoss: 1999,
    rewardAmount: 50,
    wagering: 40,
    maxWin: 250,
  },
  {
    segment: 'VIP1',
    minLoss: 100,
    maxLoss: 349,
    rewardAmount: 10,
    wagering: 10,
    maxWin: 100,
  },
  {
    segment: 'VIP1',
    minLoss: 350,
    maxLoss: 749,
    rewardAmount: 40,
    wagering: 10,
    maxWin: 400,
  },
  {
    segment: 'VIP1',
    minLoss: 750,
    maxLoss: 999,
    rewardAmount: 100,
    wagering: 20,
    maxWin: 1000,
  },
  {
    segment: 'VIP1',
    minLoss: 1000,
    maxLoss: 1999,
    rewardAmount: 150,
    wagering: 20,
    maxWin: 1500,
  },
  {
    segment: 'VIP1',
    minLoss: 2000,
    maxLoss: 3999,
    rewardAmount: 300,
    wagering: 20,
    maxWin: 3000,
  },
  {
    segment: 'VIP2',
    minLoss: 100,
    maxLoss: 349,
    rewardAmount: 10,
    wagering: 10,
    maxWin: 100,
  },
  {
    segment: 'VIP2',
    minLoss: 350,
    maxLoss: 749,
    rewardAmount: 50,
    wagering: 10,
    maxWin: 500,
  },
  {
    segment: 'VIP2',
    minLoss: 750,
    maxLoss: 999,
    rewardAmount: 100,
    wagering: 15,
    maxWin: 1000,
  },
  {
    segment: 'VIP2',
    minLoss: 1000,
    maxLoss: 1999,
    rewardAmount: 150,
    wagering: 15,
    maxWin: 1500,
  },
  {
    segment: 'VIP2',
    minLoss: 2000,
    maxLoss: 3999,
    rewardAmount: 300,
    wagering: 15,
    maxWin: 3000,
  },
  {
    segment: 'VIP2',
    minLoss: 4000,
    maxLoss: 6999,
    rewardAmount: 600,
    wagering: 15,
    maxWin: 6000,
  },
  {
    segment: 'VIP3',
    minLoss: 100,
    maxLoss: 349,
    rewardAmount: 20,
    wagering: 10,
    maxWin: 200,
  },
  {
    segment: 'VIP3',
    minLoss: 350,
    maxLoss: 749,
    rewardAmount: 70,
    wagering: 10,
    maxWin: 700,
  },
  {
    segment: 'VIP3',
    minLoss: 750,
    maxLoss: 999,
    rewardAmount: 150,
    wagering: 10,
    maxWin: 1500,
  },
  {
    segment: 'VIP3',
    minLoss: 1000,
    maxLoss: 1999,
    rewardAmount: 250,
    wagering: 10,
    maxWin: 2500,
  },
  {
    segment: 'VIP3',
    minLoss: 2000,
    maxLoss: 3999,
    rewardAmount: 500,
    wagering: 10,
    maxWin: 5000,
  },
  {
    segment: 'VIP3',
    minLoss: 4000,
    maxLoss: Infinity,
    rewardAmount: 1000,
    wagering: 10,
    maxWin: 10000,
  },
];

export const calculateBonus = (
  playerCurrentSegment: string,
  ngr: number,
): BonusResult => {
  const result = bonusConditions.find(
    (condition) =>
      condition.segment === playerCurrentSegment &&
      ngr >= condition.minLoss &&
      ngr <= condition.maxLoss,
  ) || { rewardAmount: null, wagering: null, maxWin: null };

  return {
    ...result,
    wagering: result.wagering !== null ? result.wagering : null,
  };
};

export const calculateFreeSpins = (
  playerCurrentSegment: string,
  ngr: number,
): FreeSpinsResult => {
  const result = freeSpinsConditions.find(
    (condition) =>
      condition.segment === playerCurrentSegment &&
      ngr >= condition.minLoss &&
      ngr <= condition.maxLoss,
  ) || { nrOfSpins: null, value: null };

  return {
    ...result,
  };
};
