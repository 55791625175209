import { useParams } from '@reach/router';
import Tippy from '@tippyjs/react';
import { graphql } from 'gatsby';
import gql from 'graphql-tag';
import { startCase } from 'lodash/fp';
import React, { FC } from 'react';
import { useQuery } from 'urql';

import { BooleanValue } from '@/bits';
import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  ControlledModal,
  InlineIconButton,
  Value,
} from '@/components';
import { EditIcon, RefreshIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import {
  PlayerUpdateCancelWithdrawalBlockForm,
  PlayerUpdateCustomerWhiteListStatusForm,
  PlayerUpdateDepositsBlockForm,
  PlayerUpdateIncreaseDepositLimitBlockForm,
  PlayerUpdateMarketingBlockForm,
  PlayerUpdateRewardsBlockForm,
  PlayerUpdateRgNetDepositLimitBlockForm,
} from '@/forms';
import { PlayerReopenCustomerForm } from '@/forms/player-reopen-customer-form';
import {
  PlayerRgBlocksOverviewBoxQuery,
  PlayerRgBlocksOverviewBoxQueryVariables,
} from './__generated__/component';

export const Fragment = graphql`
  fragment SanityPlayerRgBlocksOverviewBlock on SanityPlayerRgBlocksOverviewBlock {
    title {
      ...SanityLocaleString
    }
    playerGlobalUUID {
      ...SanityLocaleString
    }
    rewardsBlocked {
      ...SanityLocaleString
    }
    depositsBlocked {
      ...SanityLocaleString
    }
    rgNetDepositLimitBlocked {
      ...SanityLocaleString
    }
    increaseDepositLimitBlocked {
      ...SanityLocaleString
    }
    cancelWithdrawalBlocked {
      ...SanityLocaleString
    }
    customerStatus {
      ...SanityLocaleString
    }
    marketingBlocked {
      ...SanityLocaleString
    }
    closed {
      ...SanityLocaleString
    }
    isWhitelisted {
      ...SanityLocaleString
    }
  }
`;

const QUERY = gql`
  query PlayerRgBlocksOverviewBox($playerId: ID!) {
    player(playerId: $playerId) {
      id
      playerGlobalId
      customer {
        id
        playerGlobalUUID
        isCancelWithdrawalBlocked
        isIncreaseDepositLimitBlocked
        isRewardsBlocked
        isDepositsBlocked
        isMarketingBlocked
        isRgNetDepositLimitEnabled
        isCustomerWhitelisted
        customerClosure {
          closeAccountCause
        }
      }
    }
  }
`;

const PlayerRgBlocksOverviewBlock: FC<{
  block: Queries.SanityPlayerRgBlocksOverviewBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();
  const { playerId } = useParams();
  const shouldBeDisplayed = process.env.GATSBY_LICENSE === 'MGA';

  const [{ data, fetching }, refresh] = useQuery<
    PlayerRgBlocksOverviewBoxQuery,
    PlayerRgBlocksOverviewBoxQueryVariables
  >({
    query: QUERY,
    variables: {
      playerId,
    },
    // TODO: Temporary fix for until we have a better solution for this DEV-4241
    pause: !shouldBeDisplayed,
  });

  if (!shouldBeDisplayed) {
    return null;
  }

  if (!data || !data.player || !data.player.customer) {
    return null;
  }

  const customer = data.player.customer;
  const playerGlobalId = data.player.playerGlobalId;
  const customerStatus = customer.customerClosure?.closeAccountCause;

  return (
    <Card
      size="lg"
      title={t(block.title)}
      options={
        <CardOptions>
          <CardOptionsButton
            className="flex"
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <div className="p-3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
          <Value
            fetching={fetching}
            title={t(block.playerGlobalUUID)}
            value={customer.playerGlobalUUID}
          />
          <BooleanValue
            fetching={fetching}
            title={t(block.rewardsBlocked)}
            value={customer.isRewardsBlocked}
            suffix={
              playerGlobalId && (
                <ControlledModal
                  content={
                    <PlayerUpdateRewardsBlockForm
                      playerGlobalId={playerGlobalId}
                      isBlocked={customer.isRewardsBlocked}
                    />
                  }
                >
                  <InlineIconButton>
                    <EditIcon />
                  </InlineIconButton>
                </ControlledModal>
              )
            }
          />
          <BooleanValue
            fetching={fetching}
            title={t(block.cancelWithdrawalBlocked)}
            value={customer.isCancelWithdrawalBlocked}
            suffix={
              playerGlobalId && (
                <ControlledModal
                  content={
                    <PlayerUpdateCancelWithdrawalBlockForm
                      playerGlobalId={playerGlobalId}
                      isBlocked={customer.isCancelWithdrawalBlocked}
                    />
                  }
                >
                  <InlineIconButton>
                    <EditIcon />
                  </InlineIconButton>
                </ControlledModal>
              )
            }
          />
          <Value
            fetching={fetching}
            title={t(block.customerStatus)}
            suffix={
              customerStatus &&
              playerGlobalId && (
                <ControlledModal
                  content={
                    <PlayerReopenCustomerForm playerGlobalId={playerGlobalId} />
                  }
                >
                  <InlineIconButton>
                    <EditIcon />
                  </InlineIconButton>
                </ControlledModal>
              )
            }
          >
            {customerStatus && (
              <Tippy content={startCase(customerStatus)}>
                <span>{t(block.closed)}</span>
              </Tippy>
            )}
          </Value>
          <BooleanValue
            fetching={fetching}
            title={t(block.depositsBlocked)}
            value={customer.isDepositsBlocked}
            suffix={
              playerGlobalId && (
                <ControlledModal
                  content={
                    <PlayerUpdateDepositsBlockForm
                      playerGlobalId={playerGlobalId}
                      isBlocked={customer.isDepositsBlocked}
                    />
                  }
                >
                  <InlineIconButton>
                    <EditIcon />
                  </InlineIconButton>
                </ControlledModal>
              )
            }
          />
          <BooleanValue
            fetching={fetching}
            title={t(block.increaseDepositLimitBlocked)}
            value={customer.isIncreaseDepositLimitBlocked}
            suffix={
              playerGlobalId && (
                <ControlledModal
                  content={
                    <PlayerUpdateIncreaseDepositLimitBlockForm
                      playerGlobalId={playerGlobalId}
                      isBlocked={customer.isIncreaseDepositLimitBlocked}
                    />
                  }
                >
                  <InlineIconButton>
                    <EditIcon />
                  </InlineIconButton>
                </ControlledModal>
              )
            }
          />
          <BooleanValue
            fetching={fetching}
            title={t(block.marketingBlocked)}
            value={customer.isMarketingBlocked}
            suffix={
              playerGlobalId && (
                <ControlledModal
                  content={
                    <PlayerUpdateMarketingBlockForm
                      playerGlobalId={playerGlobalId}
                      isBlocked={customer.isMarketingBlocked}
                    />
                  }
                >
                  <InlineIconButton>
                    <EditIcon />
                  </InlineIconButton>
                </ControlledModal>
              )
            }
          />
          <BooleanValue
            fetching={fetching}
            title={t(block.rgNetDepositLimitBlocked)}
            value={customer.isRgNetDepositLimitEnabled}
            suffix={
              playerGlobalId && (
                <ControlledModal
                  content={
                    <PlayerUpdateRgNetDepositLimitBlockForm
                      playerGlobalId={playerGlobalId}
                      isBlocked={customer.isRgNetDepositLimitEnabled}
                    />
                  }
                >
                  <InlineIconButton>
                    <EditIcon />
                  </InlineIconButton>
                </ControlledModal>
              )
            }
          />
          <BooleanValue
            fetching={fetching}
            title={t(block.isWhitelisted)}
            value={customer.isCustomerWhitelisted}
            suffix={
              playerGlobalId && (
                <ControlledModal
                  content={
                    <PlayerUpdateCustomerWhiteListStatusForm
                      playerGlobalId={playerGlobalId}
                      isWhitelisted={customer.isCustomerWhitelisted}
                    />
                  }
                >
                  <InlineIconButton>
                    <EditIcon />
                  </InlineIconButton>
                </ControlledModal>
              )
            }
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default PlayerRgBlocksOverviewBlock;
