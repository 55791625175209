import { graphql } from 'gatsby';
import React from 'react';
import { useQueryParam } from 'use-query-params';

import PaymentsView from './PaymentView';
import TransactionsView from './TransactionsView';

export const Fragment = graphql`
  fragment SanityPaymentInspectionBlock on SanityPaymentInspectionBlock {
    title {
      ...SanityLocaleString
    }
  }
`;

const PaymentInspectionBlock = () => {
  const rawPaymentId = useQueryParam('rawPaymentId');
  const rawId = (rawPaymentId[0] as string) || '';

  return (
    <>
      {rawId && (
        <>
          <PaymentsView rawId={rawId} />
          <TransactionsView rawId={rawId} />
        </>
      )}
    </>
  );
};

export default PaymentInspectionBlock;
