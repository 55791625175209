import Tippy from '@tippyjs/react';
import React from 'react';
import { MdInfoOutline } from 'react-icons/md';

const iconWrapperStyle = 'inline-block mr-1';

export const InlineMoreInfoIcon = ({
  tippyContent,
}: {
  tippyContent?: string;
}) => {
  if (tippyContent) {
    return (
      <div className={iconWrapperStyle}>
        <Tippy content={tippyContent}>
          <div>
            <MdInfoOutline color="orange" />
          </div>
        </Tippy>
      </div>
    );
  }

  return (
    <div className={iconWrapperStyle}>
      <MdInfoOutline color="orange" />
    </div>
  );
};
