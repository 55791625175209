import classNames from 'classnames';
import React, { ClipboardEvent, useCallback, useEffect, useState } from 'react';

import { InlineIconButton } from '../../InlineIconButton';
import { InlineSpinner } from '../../InlineSpinner';
import UploadAndParseField from './UploadAndParseField';

type Props = {
  list: (items: object[] | undefined) => void;
  listItem: (item: ListItemProps<unknown>) => ItemProps;
  onImportBulk: (items: string[] | undefined) => {
    results: any[] | undefined;
    loading: boolean;
  };
  defaultAttrName: string;
};

export type ItemProps = {
  itemId: string;
  name: string;
};

export type ListItemProps<T> = T & object;

const BulkImportField = ({
  list,
  onImportBulk,
  defaultAttrName,
  listItem,
}: Props) => {
  const [options, setOptions] = useState<string[]>([]);
  const [pasted, setPasted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { results } = onImportBulk(options);

  const noOptions = options.length === 0 || options[0] === '';
  const disableTextArea = disabled || loading;
  const notEverythingImported = options.length > 0 && disabled;

  useEffect(() => {
    setDisabled(false);
  }, [options, setDisabled]);

  const onSuccessImport = useCallback(() => {
    const itemsToRemove = results?.map((result) => result[defaultAttrName]);
    if (itemsToRemove) {
      setOptions(options.filter((item) => !itemsToRemove.includes(item)));
    }
  }, [options, results, setOptions, defaultAttrName]);

  const onChangeTextarea = useCallback(
    (value: string) => {
      setPasted(false);
      const newValue = value.split(/,| /).join('\n');
      setOptions(newValue.split('\n'));
    },
    [setOptions],
  );

  const onPaste = useCallback(
    (e: ClipboardEvent) => {
      setPasted(true);
      const data = e.clipboardData.getData('Text');
      setOptions(options.concat(data.split('\n')));
    },
    [options, setOptions],
  );

  const getUploadedList = (items: string[]) => {
    if (items.length > 0) {
      setOptions(options.concat(items));
    }
  };

  const getBulkList = (items: object[] | undefined) => items?.map(listItem);

  const onClickImportList = () => {
    setLoading(true);
    setDisabled(true);
    list(getBulkList(results));
    onSuccessImport();
  };

  const onClickClearList = () => {
    setDisabled(false);
    setOptions([]);
  };

  const renderImportButton = () => {
    if (noOptions) {
      return <UploadAndParseField list={getUploadedList} />;
    }
    return (
      <>
        {notEverythingImported ? (
          <InlineIconButton type="button" onClick={onClickClearList}>
            Clear list
          </InlineIconButton>
        ) : (
          <InlineIconButton type="button" onClick={onClickImportList}>
            Add to the list
          </InlineIconButton>
        )}
        <div className="ml-auto">
          {options.length} {options.length === 1 ? 'item' : 'items'}{' '}
          {notEverythingImported && 'left'}
        </div>
      </>
    );
  };

  return (
    <div className="w-full mt-1">
      <textarea
        disabled={disableTextArea}
        className={classNames(
          `w-full h-24 border-gray-300 text-xs rounded-t-md align-top`,
          {
            'bg-gray-200 text-red-600': disableTextArea,
          },
        )}
        placeholder="Copy and paste your list of items here, separated by comma, space or new line or click upload a CSV file"
        value={pasted ? '' : options.join('\n')}
        onChange={(e) => onChangeTextarea(e.target.value)}
        onPaste={(e) => onPaste(e)}
      >
        <InlineSpinner />
      </textarea>
      <div className="flex p-1 pl-0 items-center rounded-b border-gray-300 h-9 border text-sm text-gray-500 border-t-0">
        {renderImportButton()}
      </div>
    </div>
  );
};

export default BulkImportField;
