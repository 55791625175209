import React from 'react';

import { makeFluidThumbnail } from '@/utils/makeFluidThumbnail';
import { DataDef, DataDefProps } from '../types';

export function gameValue<O, T extends Record<string, any>>({
  field,
  getValue,
  ...rest
}: DataDefProps<O, T>): DataDef<O, T> {
  return {
    field,
    ...rest,
    component: ({ row }) => {
      const value = getValue ? getValue({ row }) : row[field];
      const game = value?.json;
      const fluid = makeFluidThumbnail(
        game?.thumbnail,
        game?.thumbnailUpdatedAt,
      );
      return game?.name ? (
        <>
          <picture>
            <source sizes={fluid.sizes} srcSet={fluid.srcSet} />
            <img
              src={fluid.src}
              alt={game?.name}
              loading="lazy"
              className="w-6 h-6 inline-block rounded-sm mr-1"
            />
          </picture>
          {game?.name}
        </>
      ) : (
        <>-</>
      );
    },
  };
}
