import { useParams } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { useMutation, useQuery } from 'urql';

import {
  Card,
  CardCloseButton,
  CardOptions,
  Form,
  SubmitButton,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { assert } from '@/utils/error';
import {
  PlayerWithdrawalsStatusFormQuery,
  blockPlayerWithdrawalsMutation,
  blockPlayerWithdrawalsMutationVariables,
  unblockPlayerWithdrawalsStatusMutation,
  unblockPlayerWithdrawalsStatusMutationVariables,
} from './__generated__/PlayerWithdrawalsStatusForm';

const query = graphql`
  query SanityPlayerWithdrawalsStatusForm {
    sanityPlayerWithdrawalsStatusForm {
      title {
        ...SanityLocaleString
      }
      blockWithdrawalsSubmit {
        ...SanityLocaleString
      }
      unblockWithdrawalsSubmit {
        ...SanityLocaleString
      }
    }
  }
`;

const playerQuery = gql`
  query PlayerWithdrawalsStatusForm($playerId: ID!) {
    player(playerId: $playerId) {
      id
      areWithdrawalsBlocked
    }
  }
`;

const blockMutation = gql`
  mutation blockPlayerWithdrawals($playerId: ID!) {
    blockWithdrawals(playerId: $playerId) {
      id
      areWithdrawalsBlocked
    }
  }
`;

const unblockMutation = gql`
  mutation unblockPlayerWithdrawalsStatus($playerId: ID!) {
    unblockWithdrawals(playerId: $playerId) {
      id
      areWithdrawalsBlocked
    }
  }
`;

const BlockWithdrawalsForm: FC<{
  playerId: PlayerWithdrawalsStatusFormQuery['player']['id'];
  form: Queries.SanityPlayerWithdrawalsStatusFormQuery['sanityPlayerWithdrawalsStatusForm'];
}> = ({ form }) => {
  const { t } = useTranslate();
  const { playerId } = useParams();

  const [blockPlayerWithdrawalsState, blockPlayerWithdrawals] = useMutation<
    blockPlayerWithdrawalsMutation,
    blockPlayerWithdrawalsMutationVariables
  >(blockMutation);

  const fetching = playerId && blockPlayerWithdrawalsState.fetching;

  const { close } = useModalContext();

  const onSubmit = () => {
    if (playerId) {
      blockPlayerWithdrawals({
        playerId,
      }).then((res) => {
        if (close) {
          close();
        }
      });
    }
  };

  return (
    <Form
      defaultValues={{}}
      onSubmit={onSubmit}
      className="grid grid-cols-2 sm:grid-cols-3 gap-6"
    >
      <SubmitButton
        value={t(form?.blockWithdrawalsSubmit)}
        disabled={fetching}
      />
    </Form>
  );
};

const UnblockWithdrawalsForm: FC<{
  playerId: PlayerWithdrawalsStatusFormQuery['player']['id'];
  form: Queries.SanityPlayerWithdrawalsStatusFormQuery['sanityPlayerWithdrawalsStatusForm'];
}> = ({ playerId, form }) => {
  const { t } = useTranslate();

  const [unblockPlayerWithdrawalsState, unblockPlayerWithdrawals] = useMutation<
    unblockPlayerWithdrawalsStatusMutation,
    unblockPlayerWithdrawalsStatusMutationVariables
  >(unblockMutation);

  const { close } = useModalContext();

  const defaultValues = {
    overrideCooldown: false,
  };

  const onSubmit = () => {
    if (playerId) {
      unblockPlayerWithdrawals({
        playerId,
      }).then((res) => {
        if (close) {
          close();
        }
      });
    }
  };

  if (!playerId) {
    return null;
  }

  return (
    <Form
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      className="grid grid-cols-2 sm:grid-cols-3 gap-6"
    >
      <SubmitButton
        value={t(form?.unblockWithdrawalsSubmit)}
        disabled={unblockPlayerWithdrawalsState.fetching}
      />
    </Form>
  );
};

const PlayerWithdrawalsStatusForm: FC<{
  playerId: string;
}> = ({ playerId }) => {
  const { t } = useTranslate();
  const staticData =
    useStaticQuery<Queries.SanityPlayerWithdrawalsStatusFormQuery>(query);
  const form = staticData.sanityPlayerWithdrawalsStatusForm;

  assert(form, 'missing form data');

  const [{ data }] = useQuery<PlayerWithdrawalsStatusFormQuery>({
    query: playerQuery,
    variables: { playerId },
  });

  if (!data) {
    return null;
  }

  const player = data.player;

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">
        {player.areWithdrawalsBlocked ? (
          <UnblockWithdrawalsForm playerId={playerId} form={form} />
        ) : (
          <BlockWithdrawalsForm playerId={playerId} form={form} />
        )}
      </div>
    </Card>
  );
};

export default PlayerWithdrawalsStatusForm;
