import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useState } from 'react';
import { useMutation, useQuery } from 'urql';

import {
  Card,
  CardCloseButton,
  CardOptions,
  CheckboxField,
  ErrorMessage,
  Form,
  SubmitButton,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import {
  ChangePlayerMarketingPhoneMutation,
  ChangePlayerMarketingPhoneMutationVariables,
  PlayerMarketingPhoneFormQuery,
} from './__generated__/PlayerMarketingPhoneForm';

const query = graphql`
  query SanityPlayerMarketingPhoneForm {
    sanityPlayerMarketingPhoneForm {
      title {
        ...SanityLocaleString
      }
      isPhoneMarketingAccepted {
        ...SanityLocaleString
      }
      submit {
        ...SanityLocaleString
      }
    }
  }
`;

const playerQuery = gql`
  query PlayerMarketingPhoneForm($playerId: ID!) {
    player(playerId: $playerId) {
      id
      isPhoneMarketingAccepted
    }
  }
`;

const playerMutation = gql`
  mutation ChangePlayerMarketingPhone(
    $playerId: ID!
    $isPhoneMarketingAccepted: Boolean!
  ) {
    changePhoneMarketingSettings(
      playerId: $playerId
      isPhoneMarketingAccepted: $isPhoneMarketingAccepted
    ) {
      id
      isPhoneMarketingAccepted
    }
  }
`;

const PlayerMarketingPhoneForm: FC<{ playerId: string }> = ({ playerId }) => {
  const staticData =
    useStaticQuery<Queries.SanityPlayerMarketingPhoneFormQuery>(query);

  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();

  const form = staticData.sanityPlayerMarketingPhoneForm;

  const [{ data }] = useQuery<PlayerMarketingPhoneFormQuery>({
    query: playerQuery,
    variables: { playerId },
  });

  const [changePlayerMarketingPhoneState, changePlayerMarketingPhone] =
    useMutation<
      ChangePlayerMarketingPhoneMutation,
      ChangePlayerMarketingPhoneMutationVariables
    >(playerMutation);

  const defaultValues = {
    isPhoneMarketingAccepted: !!data?.player?.isPhoneMarketingAccepted,
  };

  const { close } = useModalContext();

  const { t } = useTranslate();

  const onSubmit = (values: typeof defaultValues) => {
    changePlayerMarketingPhone({
      playerId,
      isPhoneMarketingAccepted: values.isPhoneMarketingAccepted,
    }).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      } else if (close) {
        close();
      }
    });
  };

  if (!form || !data) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">
        <Form
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          className="grid grid-cols-2 sm:grid-cols-3 gap-6"
        >
          <CheckboxField
            title={t(form.isPhoneMarketingAccepted)}
            name="isPhoneMarketingAccepted"
            id="PlayerMarketingPhoneForm__isPhoneMarketingAccepted"
            className="col-span-full"
          />

          <ErrorMessage message={errorMessage} />
          <SubmitButton
            value={t(form.submit)}
            disabled={changePlayerMarketingPhoneState.fetching}
          />
        </Form>
      </div>
    </Card>
  );
};

export default PlayerMarketingPhoneForm;
