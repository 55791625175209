import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { gql, useQuery } from 'urql';

import {
  Card,
  CardBody,
  CardOptionsButton,
  ControlledModal,
  Country,
  InlineIconButton,
  Value,
} from '@/components';
import { EditIcon, RefreshIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import {
  PlayerBirthDateForm,
  PlayerCityForm,
  PlayerNameForm,
  PlayerStreetForm,
  PlayerZipCodeForm,
} from '@/forms';
import { assert } from '@/utils/error';
import formatPhoneNumber from '@/utils/formatter/formatPhoneNumber';
import {
  PlayerQuickOverviewBoxQuery,
  PlayerQuickOverviewBoxQueryVariables,
} from './__generated__/component';

export const Fragment = graphql`
  fragment SanityPlayerQuickOverviewBlock on SanityPlayerQuickOverviewBlock {
    title {
      ...SanityLocaleString
    }
    brand {
      ...SanityLocaleString
    }
    playerId {
      ...SanityLocaleString
    }
    name {
      ...SanityLocaleString
    }
    dateOfBirth {
      ...SanityLocaleString
    }
    address {
      ...SanityLocaleString
    }
    city {
      ...SanityLocaleString
    }
    country {
      ...SanityLocaleString
    }
    zipCode {
      ...SanityLocaleString
    }
    phone {
      ...SanityLocaleString
    }
    email {
      ...SanityLocaleString
    }
    playerGlobalId {
      ...SanityLocaleString
    }
  }
`;

const QUERY = gql`
  query PlayerQuickOverviewBox($playerId: ID!) {
    player(playerId: $playerId) {
      id
      uuid
      firstName
      lastName
      birthDate
      countryCode
      zipCode
      street
      city
      phoneNumber
      email
      brand {
        id
        name
      }
      riskAssessment {
        playerGlobalId
      }
    }
  }
`;

const PlayerPhoneLoginsBlock: FC<{
  block: Queries.SanityPlayerQuickOverviewBlockFragment;
}> = ({ block }) => {
  const params = useParams();
  const { t } = useTranslate();

  assert(params.playerId, 'missing playerId');

  const [{ data, fetching }, refresh] = useQuery<
    PlayerQuickOverviewBoxQuery,
    PlayerQuickOverviewBoxQueryVariables
  >({
    query: QUERY,
    variables: {
      playerId: params.playerId,
    },
  });

  return (
    <Card
      size="lg"
      title={t(block.title)}
      options={
        <CardOptionsButton
          className="flex"
          onClick={() => refresh({ requestPolicy: 'network-only' })}
        >
          <RefreshIcon />
        </CardOptionsButton>
      }
    >
      <CardBody>
        <div className="p-3">
          <Value
            title={t(block.brand)}
            fetching={fetching}
            value={data?.player.brand.name}
          />
          <Value
            title={t(block.playerId)}
            fetching={fetching}
            value={data?.player.uuid}
          />
          <Value
            title={t(block.name)}
            fetching={fetching}
            value={[data?.player.firstName, data?.player.lastName]
              .filter(Boolean)
              .join(' ')}
            suffix={
              <ControlledModal
                content={
                  data?.player.id ? (
                    <PlayerNameForm playerId={data.player.id} />
                  ) : null
                }
              >
                <InlineIconButton>
                  <EditIcon />
                </InlineIconButton>
              </ControlledModal>
            }
          />
          <Value
            title={t(block.dateOfBirth)}
            fetching={fetching}
            value={data?.player.birthDate}
            suffix={
              <ControlledModal
                content={
                  data?.player.id ? (
                    <PlayerBirthDateForm playerId={data.player.id} />
                  ) : null
                }
              >
                <InlineIconButton>
                  <EditIcon />
                </InlineIconButton>
              </ControlledModal>
            }
          />
          <Value
            title={t(block.address)}
            fetching={fetching}
            value={data?.player.street}
            suffix={
              <ControlledModal
                content={
                  data?.player.id ? (
                    <PlayerStreetForm playerId={data.player.id} />
                  ) : null
                }
              >
                <InlineIconButton>
                  <EditIcon />
                </InlineIconButton>
              </ControlledModal>
            }
          />
          <Value
            title={t(block.city)}
            fetching={fetching}
            value={data?.player.city}
            suffix={
              <ControlledModal
                content={
                  data?.player.id ? (
                    <PlayerCityForm playerId={data.player.id} />
                  ) : null
                }
              >
                <InlineIconButton>
                  <EditIcon />
                </InlineIconButton>
              </ControlledModal>
            }
          />
          <Value
            title={t(block.zipCode)}
            fetching={fetching}
            value={data?.player.zipCode}
            suffix={
              <ControlledModal
                content={
                  data?.player.id ? (
                    <PlayerZipCodeForm playerId={data.player.id} />
                  ) : null
                }
              >
                <InlineIconButton>
                  <EditIcon />
                </InlineIconButton>
              </ControlledModal>
            }
          />
          <Value title={t(block.country)} fetching={fetching}>
            <Country countryCode={data?.player?.countryCode} />
          </Value>
          <Value
            title={t(block.phone)}
            fetching={fetching}
            value={formatPhoneNumber(data?.player?.phoneNumber)}
          />
          <Value
            title={t(block.email)}
            fetching={fetching}
            value={data?.player.email}
          />
          <Value
            fetching={fetching}
            title={t(block.playerGlobalId)}
            value={data?.player.riskAssessment?.playerGlobalId}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default PlayerPhoneLoginsBlock;
