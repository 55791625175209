import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useState } from 'react';
import { useMutation } from 'urql';

import {
  Card,
  CardCloseButton,
  CardOptions,
  ErrorMessage,
  Form,
  SubmitButton,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { limitFragment } from '@/fragments/Limits';
import { LimitTypeEnum } from '@/globalTypes';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import {
  RemoveWagerLimitMutation,
  RemoveWagerLimitMutationVariables,
} from './__generated__/PlayerRemoveWagerLimitForm';

const query = graphql`
  query SanityPlayerRemoveWagerLimitForm {
    sanityPlayerRemoveWagerLimitsForm {
      title {
        ...SanityLocaleString
      }
      submit {
        ...SanityLocaleString
      }
    }
  }
`;

const removeWagerLimitMutation = gql`
  mutation RemoveWagerLimit($playerId: ID!, $limitPeriodType: LimitTypeEnum!) {
    removeWagerLimit(playerId: $playerId, limitPeriodType: $limitPeriodType) {
      id
      wagerLimit {
        dayLimit {
          ...PeriodLimit
        }
        weekLimit {
          ...PeriodLimit
        }
        monthLimit {
          ...PeriodLimit
        }
      }
    }
  }
  ${limitFragment}
`;

const PlayerRemoveWagerLimitForm: FC<{
  playerId: string;
  limitPeriodType: LimitTypeEnum;
}> = ({ playerId, limitPeriodType }) => {
  const staticData =
    useStaticQuery<Queries.SanityPlayerRemoveWagerLimitFormQuery>(query);

  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();

  const form = staticData.sanityPlayerRemoveWagerLimitsForm;

  const [removePlayerWagerLimitState, removePlayerWagerLimit] = useMutation<
    RemoveWagerLimitMutation,
    RemoveWagerLimitMutationVariables
  >(removeWagerLimitMutation);

  const { close } = useModalContext();

  const { t } = useTranslate();

  const onSubmit = (_values: {}) => {
    if (playerId) {
      removePlayerWagerLimit({
        playerId,
        limitPeriodType,
      }).then((res) => {
        if (res.error?.message && isMounted) {
          setErrorMessage(res.error?.message);
          return;
        }
        if (close) {
          close();
        }
      });
    }
  };

  if (!form) {
    return null;
  }
  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">
        <Form
          defaultValues={{}}
          onSubmit={onSubmit}
          className="grid grid-cols-2 sm:grid-cols-3 gap-6"
        >
          <ErrorMessage message={errorMessage} />
          <SubmitButton
            value={t(form.submit)}
            disabled={removePlayerWagerLimitState.fetching}
          />
        </Form>
      </div>
    </Card>
  );
};

export default PlayerRemoveWagerLimitForm;
