import { useOktaAuth } from '@okta/okta-react';
import { useLocation } from '@reach/router';
import React, {
  FC,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';

const TIMEOUT_MS = 3600 * 1000;

const STORAGE_KEY = 'userActivity';

const useStaleSignOut = () => {
  const { oktaAuth } = useOktaAuth();
  const location = useLocation();
  const [lastActivityAt, setLastActivityAt] = useState<string | null>();

  useEffect(() => {
    setLastActivityAt(Date.now().toString());
  }, [location.pathname, setLastActivityAt]);

  useEffect(() => {
    const storageListener = (event: StorageEvent) => {
      if (event.key === STORAGE_KEY) {
        setLastActivityAt(event.newValue);
      }
    };

    window.addEventListener('storage', storageListener, true);
    return () => window.removeEventListener('storage', storageListener);
  }, [setLastActivityAt]);

  useEffect(() => {
    if (lastActivityAt) {
      localStorage.setItem(STORAGE_KEY, lastActivityAt);
    }
    const timeoutId = setTimeout(() => {
      oktaAuth.signOut();
    }, TIMEOUT_MS);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [lastActivityAt, oktaAuth]);
};

const SecureRoute: FC<{ children: ReactNode }> = ({ children }) => {
  const { oktaAuth, authState } = useOktaAuth();
  const pendingLogin = useRef(false);
  const uri = useLocation();

  useStaleSignOut();

  useEffect(() => {
    const login = async () => oktaAuth.signInWithRedirect();
    if (!authState) {
      pendingLogin.current = false;
      return;
    }

    if (!authState.isAuthenticated) {
      oktaAuth.setOriginalUri(uri.pathname);
      login();
      pendingLogin.current = true;
    }
  }, [authState, pendingLogin, oktaAuth, uri.pathname]);

  return children as ReactElement;
};

export const Secure: FC<{ children: ReactNode }> = ({ children }) => {
  if (typeof window === 'undefined') {
    return null;
  }
  return <SecureRoute>{children}</SecureRoute>;
};
