import Tippy from '@tippyjs/react';
import { graphql, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';

import { LockClosedIcon, LockOpenIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';

const query = graphql`
  query SanityPadlockBit {
    sanityPadlockBit {
      lock {
        ...SanityLocaleString
      }
      unlock {
        ...SanityLocaleString
      }
    }
  }
`;

export const Locker: FC<{
  isLocked: boolean;
}> = ({ isLocked }) => {
  const { t } = useTranslate();
  const bit =
    useStaticQuery<Queries.SanityPadlockBitQuery>(query).sanityPadlockBit;

  if (!bit) {
    return null;
  }

  const padlockContent = isLocked ? t(bit.unlock) : t(bit.lock);

  return (
    <Tippy content={padlockContent}>
      <div>{isLocked ? <LockClosedIcon /> : <LockOpenIcon />}</div>
    </Tippy>
  );
};
