import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';

import { SelectOption } from '@/components';
import { useTranslate } from '@/contexts';

const query = graphql`
  query SanityCurrenciesListBit {
    sanityCurrenciesListBit {
      usDollar {
        ...SanityLocaleString
      }
      euro {
        ...SanityLocaleString
      }
      canadianDollar {
        ...SanityLocaleString
      }
      norwegianKrone {
        ...SanityLocaleString
      }
      newZealandDollar {
        ...SanityLocaleString
      }
      southAfricanRand {
        ...SanityLocaleString
      }
      indianRupee {
        ...SanityLocaleString
      }
      swissFranc {
        ...SanityLocaleString
      }
    }
  }
`;

export const useCurrencies = () => {
  const bit =
    useStaticQuery<Queries.SanityCurrenciesListBitQuery>(
      query,
    ).sanityCurrenciesListBit;
  const { t } = useTranslate();

  return useMemo(() => {
    const currencyOptions: SelectOption[] = [
      {
        label: t(bit?.usDollar),
        value: 'USD',
      },
      {
        label: t(bit?.euro),
        value: 'EUR',
      },
      {
        label: t(bit?.canadianDollar),
        value: 'CAD',
      },
      {
        label: t(bit?.norwegianKrone),
        value: 'NOK',
      },
      {
        label: t(bit?.newZealandDollar),
        value: 'NZD',
      },
      {
        label: t(bit?.southAfricanRand),
        value: 'ZAR',
      },
      {
        label: t(bit?.indianRupee),
        value: 'INR',
      },
      {
        label: t(bit?.swissFranc),
        value: 'CHF',
      },
    ];

    return {
      currencyOptions,
    };
  }, [
    bit?.usDollar,
    bit?.euro,
    bit?.canadianDollar,
    bit?.indianRupee,
    bit?.newZealandDollar,
    bit?.norwegianKrone,
    bit?.southAfricanRand,
    bit?.swissFranc,
    t,
  ]);
};
