import { formatISO, isAfter, parseISO, subWeeks } from 'date-fns';
import { gql, useQuery } from 'urql';

import { calculateBonus, calculateFreeSpins } from '@/utils';
import {
  PlayerLastBonusAtQuery,
  PlayerLastBonusAtQueryVariables,
  PlayerNgrSinceLastManualRewardQuery,
  PlayerNgrSinceLastManualRewardQueryVariables,
  PlayerRewardCalculatorQuery,
  PlayerRewardCalculatorQueryVariables,
} from './__generated__/usePlayerRewardCalculator';

const QUERY = gql`
  query PlayerRewardCalculator(
    $playerId: ID!
    $from: OffsetDateTime!
    $to: OffsetDateTime!
  ) {
    player(playerId: $playerId) {
      id
      stats(granularity: All, from: $from, to: $to) {
        error
        rows {
          ngr
          playerCurrentSegment
          currency
        }
      }
    }
  }
`;

const LAST_BONUS_AT_QUERY = gql`
  query PlayerLastBonusAt(
    $playerId: ID!
    $from: OffsetDateTime!
    $to: OffsetDateTime!
  ) {
    player(playerId: $playerId) {
      id
      stats(granularity: All, from: $from, to: $to) {
        error
        rows {
          lastBonusAt: lastBonusedAt
        }
      }
    }
  }
`;

const NGR_SINCE_LAST_MANUAL_REWARD_QUERY = gql`
  query PlayerNgrSinceLastManualReward(
    $playerId: ID!
    $from: OffsetDateTime!
    $to: OffsetDateTime!
  ) {
    player(playerId: $playerId) {
      id
      stats(granularity: All, from: $from, to: $to) {
        error
        rows {
          ngrSinceLastManualReward
        }
      }
    }
  }
`;

export const usePlayerRewardCalculator = (playerId: string) => {
  const dateNow = new Date();
  const dateSixWeeksAgo = subWeeks(dateNow, 6);

  const [{ data, fetching: fetchingReward }] = useQuery<
    PlayerRewardCalculatorQuery,
    PlayerRewardCalculatorQueryVariables
  >({
    query: QUERY,
    variables: {
      playerId,
      from: formatISO(dateSixWeeksAgo),
      to: formatISO(dateNow),
    },
    requestPolicy: 'network-only',
  });

  const [{ data: dataLastBonusAt, fetching: fetchingLastBonusAt }] = useQuery<
    PlayerLastBonusAtQuery,
    PlayerLastBonusAtQueryVariables
  >({
    query: LAST_BONUS_AT_QUERY,
    variables: {
      playerId,
      from: formatISO(dateSixWeeksAgo),
      to: formatISO(dateNow),
    },
    requestPolicy: 'network-only',
  });

  const [
    {
      data: dataNgrSinceLastManualReward,
      fetching: fetchingNgrSinceLastManualReward,
    },
  ] = useQuery<
    PlayerNgrSinceLastManualRewardQuery,
    PlayerNgrSinceLastManualRewardQueryVariables
  >({
    query: NGR_SINCE_LAST_MANUAL_REWARD_QUERY,
    variables: {
      playerId,
      from: formatISO(dateSixWeeksAgo),
      to: formatISO(dateNow),
    },
    requestPolicy: 'network-only',
  });

  const fetching =
    fetchingReward || fetchingLastBonusAt || fetchingNgrSinceLastManualReward;

  const rewardsData = data?.player.stats?.rows[0];
  const playerCurrentSegment = rewardsData?.playerCurrentSegment || '1';
  const ngr = rewardsData?.ngr || 0;
  const lastBonusAt = dataLastBonusAt?.player.stats?.rows[0]?.lastBonusAt;

  // Parse the date strings using parseISO
  const parsedLastBonusAt = lastBonusAt ? parseISO(lastBonusAt.toString()) : 0;
  const parsedDateSixWeeksAgo = parseISO(formatISO(dateSixWeeksAgo));

  const shouldUseLastBonusDate = isAfter(
    parsedLastBonusAt,
    parsedDateSixWeeksAgo,
  );

  const ngrSinceLastManualReward =
    dataNgrSinceLastManualReward?.player.stats?.rows[0]
      ?.ngrSinceLastManualReward || 0;

  const netLoss = shouldUseLastBonusDate ? ngrSinceLastManualReward : ngr;
  const getFreeSpinsValues = calculateFreeSpins(playerCurrentSegment, netLoss);
  const getBonusMoneyDropValues = calculateBonus(playerCurrentSegment, netLoss);
  const ngrCurrency = data?.player.stats?.rows[0]?.currency;

  return {
    data: { ...getFreeSpinsValues, ...getBonusMoneyDropValues, ngrCurrency },
    fetching,
  };
};
