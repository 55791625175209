import { useParams } from '@reach/router';
import _ from 'lodash';
import { useMemo } from 'react';
import { gql } from 'urql';

import { useNewNodesOf } from '@/contexts/NewNodesContext';
import { usePagination, usePlayerGlobal } from '@/hooks';
import { cleanArray } from '@/utils';
import {
  PlayerNotesBlockQuery,
  PlayerNotesBlockQueryVariables,
} from './__generated__/usePlayerNotes';

export const playerNotesNoteFragment = gql`
  fragment PlayerNotes_note on Note {
    id
    playerId
    createdAt
    priority
    content
    addedBy {
      __typename
      ... on AgentInitiator {
        agent {
          email
          firstName
          lastName
        }
      }
      ... on PlayerInitiator {
        player {
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const playerNotesOriginFragment = gql`
  fragment PlayerNotes_brand on Brand {
    __typename
    code
  }
`;

const query = gql`
  query PlayerNotesBlock(
    $playerId: ID
    $playerGlobalId: ID
    $orderBy: [PlayerNoteSearchOrder!]
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    viewer {
      id
      globalNotes(
        playerId: $playerId
        playerGlobalId: $playerGlobalId
        orderBy: $orderBy
        after: $after
        before: $before
        first: $first
        last: $last
      ) {
        edges {
          node {
            note {
              ...PlayerNotes_note
            }
            origin {
              ...PlayerNotes_brand
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
      }
    }
  }
  ${playerNotesNoteFragment}
  ${playerNotesOriginFragment}
`;

export const usePlayerNotes = ({
  orderBy,
}: {
  orderBy: PlayerNotesBlockQueryVariables['orderBy'];
}) => {
  const { playerId, playerGlobalId } = useParams();
  const { brand, playerGlobalId: globalId } = usePlayerGlobal();
  const newNotes = useNewNodesOf('Note');
  const currentPlayerId = playerId || playerGlobalId;

  const { items, fetch, nextPage, firstPage, previousPage, loading } =
    usePagination<
      PlayerNotesBlockQuery,
      PlayerNotesBlockQueryVariables,
      NonNullable<
        NonNullable<
          PlayerNotesBlockQuery['viewer']['globalNotes']['edges']
        >[number]
      >['node']
    >({
      query,
      objectMapper: (a) => a?.viewer.globalNotes,
      variables: {
        playerId,
        playerGlobalId,
        orderBy,
      },
    });

  const newPlayerNotes = useMemo(() => {
    const newNotesByPlayer = newNotes.filter((note) => {
      if (note.playerId === currentPlayerId) {
        return note.playerId === currentPlayerId;
      }
      return note.playerId === globalId;
    });

    // include current (brand or global) to the new added notes
    return newNotesByPlayer.map((note) => ({
      note,
      origin:
        note.playerId === currentPlayerId
          ? { __typename: brand.__typename, code: brand.code }
          : { __typename: brand.__typename, code: '' },
    }));
  }, [newNotes, globalId, currentPlayerId, brand]);

  const notes = useMemo(
    () =>
      _.uniqBy(
        [...newPlayerNotes, ...cleanArray(items).map((node) => node)],
        (entry) => entry.note.id,
      ),
    [items, newPlayerNotes],
  );

  return {
    firstPage,
    nextPage,
    previousPage,
    loading,
    notes,
    fetch,
  };
};
