import { useParams } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';

import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  ControlledModal,
  InlineIconButton,
} from '@/components';
import { useDataGrid } from '@/components/DataGrid';
import {
  EditIcon,
  NextIcon,
  PlusIcon,
  PreviousIcon,
  RefreshIcon,
} from '@/components/icons';
import { useTranslate } from '@/contexts';
import {
  PlayerDocumentUploadForm,
  ReviewDocumentForm,
  ReviewFundingDocumentForm,
} from '@/forms';
import {
  DocumentSearchOrder,
  DocumentStatus,
  DocumentType,
} from '@/globalTypes';
import {
  useGlobalPlayers,
  useGlobalPlayersValueOptions,
} from '@/hooks/useGlobalPlayers';
import { getDocumentTypeName } from '@/utils/document-types';
import { assert } from '@/utils/error';
import formatDate from '@/utils/formatter/formatDate';
import { GlobalPlayerDocuments_documentFragment } from './__generated__/useGlobalPlayerDocuments';
import useGlobalPlayerDocuments from './useGlobalPlayerDocuments';

const BLOCK_STATIC_QUERY = graphql`
  query SanityGlobalPlayerDocumentsBlockStaticQuery {
    sanityGlobalPlayerDocumentsBlock {
      title {
        ...SanityLocaleString
      }
      documentId {
        ...SanityLocaleString
      }
      playerId {
        ...SanityLocaleString
      }
      brand {
        ...SanityLocaleString
      }
      uploadedAt {
        ...SanityLocaleString
      }
      actions {
        ...SanityLocaleString
      }
      status {
        ...SanityLocaleString
      }
      uploadedBy {
        ...SanityLocaleString
      }
      verifiedBy {
        ...SanityLocaleString
      }
      documentType {
        ...SanityLocaleString
      }
      verificationTypes {
        ...SanityLocaleString
      }
      expirationDate {
        ...SanityLocaleString
      }
      deletedAt {
        ...SanityLocaleString
      }
      deletedBy {
        ...SanityLocaleString
      }
    }
  }
`;

const fundingDocumentTypesMap: Record<string, true> = {
  DividendsSOW: true,
  InheritanceSOW: true,
  SOW: true,
  SalarySOW: true,
  SaleOfCompanySOW: true,
  SaleOfPropertySOW: true,
  SourceOfWealth: true,
};

const isFundingDocument = (
  document: GlobalPlayerDocuments_documentFragment,
) => {
  return !!(
    document.documentType && fundingDocumentTypesMap[document.documentType]
  );
};

const GlobalPlayerDocumentsBlock: FC = () => {
  const params = useParams();
  const { t } = useTranslate();

  assert(params.playerGlobalId, 'missing globalPlayerId');

  const { players } = useGlobalPlayers(params.playerGlobalId);
  const playersValueOptions = useGlobalPlayersValueOptions(players);

  const block =
    useStaticQuery<Queries.SanityGlobalPlayerDocumentsBlockStaticQueryQuery>(
      BLOCK_STATIC_QUERY,
    ).sanityGlobalPlayerDocumentsBlock;

  const {
    globalPlayerDocuments,
    fetching,
    nextPage,
    previousPage,
    refresh,
    orderBy,
    desc,
    setOrderBy,
    defaultFilter,
    updateFilter,
  } = useGlobalPlayerDocuments(params.playerGlobalId);

  const { DataGrid, GlobalCardOptions } = useDataGrid({
    name: 'globalPlayerDocuments',
    data: globalPlayerDocuments,
    fetching,
    orderBy,
    setOrderBy,
    desc,
    defaultFilter,
    updateFilter,
    schema: (s) => [
      s.stringValue({
        field: 'id',
        title: t(block?.documentId),
        getValue: ({ row }) => row.document.id,
      }),
      s.stringValue({
        field: 'uuid',
        title: t(block?.playerId),
        getValue: ({ row }) => row.document.player?.uuid,
      }),
      s.stringValue({
        field: 'name',
        title: t(block?.brand),
        getValue: ({ row }) => row.document.player?.brand.name,
      }),
      s.dateTimeValue({
        field: 'uploadedAt',
        title: t(block?.uploadedAt),
        getValue: ({ row }) => formatDate(row.document.uploadedAt),
        orderBy: DocumentSearchOrder.uploadedAt,
      }),
      s.actionValue({
        field: 'actions',
        title: t(block?.actions),
        getValue: ({ row }) =>
          row.document.status !== DocumentStatus.Deleted && (
            <ControlledModal
              content={
                isFundingDocument(row.document) && row.document.player?.id ? (
                  <ReviewFundingDocumentForm
                    document={row.document}
                    playerId={row.document.player.id}
                  />
                ) : (
                  <ReviewDocumentForm document={row.document} />
                )
              }
            >
              <InlineIconButton>
                <EditIcon />
              </InlineIconButton>
            </ControlledModal>
          ),
      }),
      s.enumValue({
        field: 'status',
        title: t(block?.status),
        e: DocumentStatus,
        getValue: ({ row }) => (
          <>
            {row.document.status === DocumentStatus.Approved && '✅ '}
            {row.document.status === DocumentStatus.Rejected && '❌ '}
            {row.document.status === DocumentStatus.Pending && '⏳ '}
            {row.document.status === DocumentStatus.Deleted && '🗑 '}
            {row.document.status}
          </>
        ),
      }),
      s.initiatorValue({
        field: 'uploadedBy',
        title: t(block?.uploadedBy),
        getValue: ({ row }) => row.document.uploadedBy,
      }),
      s.initiatorValue({
        field: 'verifiedBy',
        title: t(block?.verifiedBy),
        getValue: ({ row }) => row.document.verifiedBy,
      }),
      s.enumValue({
        field: 'documentType',
        title: t(block?.documentType),
        e: DocumentType,
        getValue: ({ row }) =>
          row.document.documentType &&
          getDocumentTypeName(row.document.documentType),
      }),
      s.stringValue({
        field: 'verificationTypes',
        title: t(block?.verificationTypes),
        getValue: ({ row }) => row.document.verificationTypes.join(', ') || '-',
      }),
      s.stringValue({
        field: 'expirationDate',
        title: t(block?.expirationDate),
        getValue: ({ row }) => formatDate(row.document.expirationDate, true),
        orderBy: DocumentSearchOrder.expirationDate,
      }),
      s.dateTimeValue({
        field: 'deletedAt',
        title: t(block?.deletedAt),
        getValue: ({ row }) => formatDate(row.document.deletedAt),
        orderBy: DocumentSearchOrder.deletedAt,
      }),
      s.initiatorValue({
        field: 'deletedBy',
        title: t(block?.deletedBy),
        getValue: ({ row }) => row.document.deletedBy,
      }),
    ],
  });

  if (!block) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(block.title)}
      showOptionsAtBottom
      options={
        <CardOptions>
          <CardOptionsButton
            disabled={!previousPage}
            onClick={() => previousPage && previousPage()}
          >
            <PreviousIcon />
          </CardOptionsButton>
          <CardOptionsButton
            disabled={!nextPage}
            onClick={() => nextPage && nextPage()}
          >
            <NextIcon />
          </CardOptionsButton>
          <GlobalCardOptions />

          <CardOptionsButton
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
          <ControlledModal
            content={
              <PlayerDocumentUploadForm
                isGlobalPlayer
                playerOptions={playersValueOptions.options}
              />
            }
          >
            <CardOptionsButton>
              <PlusIcon />
            </CardOptionsButton>
          </ControlledModal>
        </CardOptions>
      }
    >
      <CardBody>
        <DataGrid />
      </CardBody>
    </Card>
  );
};

export default GlobalPlayerDocumentsBlock;
