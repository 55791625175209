import { graphql } from 'gatsby';
import React, { FC, lazy } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco, nord } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  Value,
} from '@/components';
import { RefreshIcon } from '@/components/icons';
import { useTheme } from '@/contexts';
import formatDate from '@/utils/formatter/formatDate';
import formatMoney from '@/utils/formatter/formatMoney';
import useTransactions from './useTransactions';

const ZimplerWithdrawalManualApproveButton = lazy(
  () => import('./ZimplerWithdrawalManualApproveButton'),
);

export const Fragment = graphql`
  fragment SanityTransactionInspectionBlock on SanityTransactionInspectionBlock {
    title {
      ...SanityLocaleString
    }
  }
`;

const TransactionsView: FC<{
  rawId: string;
}> = ({ rawId }) => {
  const { fetching, refresh, transactions } = useTransactions(rawId);
  const theme = useTheme();

  if (!transactions || !transactions.length) return null;

  return (
    <>
      {transactions.map((t, index) => {
        const transaction = t?.node;

        let code = transaction?.payload || '';
        let lang = '';
        try {
          if (transaction?.payload) {
            lang = 'json';
            code = JSON.stringify(JSON.parse(transaction?.payload), null, 2);
          }
        } catch (e) {}

        const syntaxStyle = theme.name === 'dark' ? nord : docco;

        return (
          <Card
            size="lg"
            title={`Transaction ${index + 1}`}
            options={
              <CardOptions>
                <CardOptionsButton
                  className="flex"
                  onClick={() => refresh({ requestPolicy: 'network-only' })}
                >
                  <RefreshIcon />
                </CardOptionsButton>
              </CardOptions>
            }
          >
            <CardBody>
              <div className="p-3">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
                  <Value
                    fetching={fetching}
                    title="Processed At"
                    value={formatDate(transaction?.processedAt ?? undefined)}
                  />
                  <Value
                    fetching={fetching}
                    title="Transaction Type"
                    value={transaction?.transactionType}
                  />
                  <Value
                    fetching={fetching}
                    title="Transaction ID"
                    value={transaction?.rawTransactionId}
                  />
                  <Value
                    fetching={fetching}
                    title="Ex. Transaction ID"
                    value={transaction?.extTransactionId}
                  />
                  <Value
                    fetching={fetching}
                    title="Payment Method"
                    value={transaction?.paymentMethod}
                  />
                  <Value
                    fetching={fetching}
                    title="Payment Status"
                    value={transaction?.paymentStatus}
                    suffix={
                      transaction && (
                        <div className="mx-1">
                          <ZimplerWithdrawalManualApproveButton
                            transaction={transaction}
                          />
                        </div>
                      )
                    }
                  />
                  <Value
                    fetching={fetching}
                    title="Amount"
                    value={formatMoney(
                      transaction?.amount,
                      transaction?.currency,
                    )}
                  />
                  <Value
                    fetching={fetching}
                    title="Opening Balance"
                    value={formatMoney(
                      transaction?.openingBalance,
                      transaction?.currency,
                    )}
                  />
                  <Value
                    fetching={fetching}
                    title="Closing Balance"
                    value={formatMoney(
                      transaction?.closingBalance,
                      transaction?.currency,
                    )}
                  />
                  <Value
                    fetching={fetching}
                    title="Payment Fee"
                    value={formatMoney(
                      transaction?.paymentFee?.money.amount,
                      transaction?.paymentFee?.money.currency,
                    )}
                  />
                  <Value
                    fetching={fetching}
                    title="Comment"
                    value={transaction?.comment}
                  />
                </div>

                <SyntaxHighlighter
                  language={lang}
                  style={syntaxStyle}
                  className="col-span-full text-sm"
                >
                  {code}
                </SyntaxHighlighter>
              </div>
            </CardBody>
          </Card>
        );
      })}
    </>
  );
};

export default TransactionsView;
