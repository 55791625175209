import gql from 'graphql-tag';
import { useMemo } from 'react';
import { useQuery } from 'urql';

import { SelectOption } from '@/components';
import { Nullable } from '@/types';
import { GlobalPlayersQuery } from './__generated__/useGlobalPlayers';

const query = gql`
  query GlobalPlayers($playerGlobalId: ID!) {
    playerGlobal(playerGlobalId: $playerGlobalId) {
      players {
        __typename
        id
        uuid
        brand {
          name
        }
      }
    }
  }
`;

export const useGlobalPlayers = (playerGlobalId: string) => {
  const [{ fetching, data }] = useQuery<GlobalPlayersQuery>({
    query,
    variables: {
      playerGlobalId,
    },
  });

  return { fetching, players: data?.playerGlobal.players };
};

export const useGlobalPlayersValueOptions = (
  players: Nullable<GlobalPlayersQuery['playerGlobal']['players']>,
) => {
  const options = useMemo<SelectOption[]>(() => {
    if (!players) {
      return [];
    }

    return players.map((player) => ({
      value: player.id,
      label: `${player.uuid} - ${player.brand.name}`,
    }));
  }, [players]);

  return { options };
};
