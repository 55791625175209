import Tippy from '@tippyjs/react';
import classNames from 'classnames';
import React, { ComponentProps, forwardRef } from 'react';

const colorStyles =
  'text-gray-600 hover:text-gray-700 hover:bg-gray-300 active:bg-gray-400 hover:bg-opacity-30';
const darkColorStyles =
  'dark:text-gray-200 dark:hover:text-gray-300 dark:hover:bg-gray-700 dark:active:bg-gray-800';

const Button = forwardRef<HTMLButtonElement, ComponentProps<'button'>>(
  ({ className, ...rest }, ref) => {
    return (
      <button
        ref={ref}
        className={classNames(
          'ml-1 align-top disabled:opacity-50 disabled:pointer-events-none dark:disabled:opacity-20 focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-800 focus-visible:ring-white inline-flex justify-center items-center rounded-md p-1 focus:outline-none',
          colorStyles,
          darkColorStyles,
          className,
        )}
        {...rest}
      />
    );
  },
);

export const InlineIconButton = forwardRef<
  HTMLButtonElement,
  ComponentProps<'button'> & { tippyContent?: string }
>(({ tippyContent, ...rest }, ref) => {
  if (tippyContent) {
    return (
      <Tippy content={tippyContent}>
        <Button {...rest} ref={ref} />
      </Tippy>
    );
  }

  return <Button {...rest} ref={ref} />;
});
