import { RouteComponentProps } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import React, { ReactNode, useState } from 'react';

import { Button, GlobalSearch, Link, Theme } from '@/components';
import { CloseIcon, HamburgerIcon } from '@/components/icons';
import { Nullable } from '@/types';
import { Operator, getOperatorFromEnv } from '@/utils/operators';
import { GlobalOptions } from './GlobalOptions';
import { TimePicker } from './Timepicker';

const LAYOUT_QUERY = graphql`
  query Layout {
    siteSettings: sanitySiteSettings {
      title
      navMenu {
        items {
          _key
          ...SanityLink
        }
      }
      theme {
        ...SanityTheme
      }
    }
  }
`;

const OperatorGlobalSearch = ({
  currentOperator,
}: {
  currentOperator: Nullable<Operator>;
}) => {
  if (!currentOperator) {
    return null;
  }

  return (
    <div className="flex items-center flex-shrink-0 mr-3 flex-auto md:flex-initial">
      <GlobalSearch />
    </div>
  );
};

const NavItems = ({
  currentOperator,
  siteSettings,
}: {
  currentOperator: Nullable<Operator>;
  siteSettings: Queries.LayoutQuery['siteSettings'];
}) => {
  const navMenuItems = siteSettings?.navMenu?.items?.concat();

  if (!currentOperator || !navMenuItems) {
    return null;
  }

  return (
    <>
      {navMenuItems.map(
        (link) =>
          link && (
            <Button
              as={Link}
              key={link._key}
              link={link}
              activeClassName="bg-gray-900"
              className="block md:inline-block px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 dark:text-gray-300 dark:hover:text-white"
            />
          ),
      )}
    </>
  );
};

export const Layout: React.FC<
  RouteComponentProps & { children: ReactNode }
> = ({ children }) => {
  const { siteSettings } = useStaticQuery<Queries.LayoutQuery>(LAYOUT_QUERY);
  const [open, setOpen] = useState(false);

  const currentOperator = getOperatorFromEnv();

  if (!siteSettings) {
    return null;
  }

  return (
    <Theme
      theme={siteSettings.theme}
      style={{
        minHeight: '100vh',
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
      }}
    >
      <nav className="flex gap-3 items-center flex-wrap p-3 bg-primary">
        <div className="text-white text-lg">{currentOperator?.name}</div>
        <OperatorGlobalSearch currentOperator={currentOperator} />
        <div className="block md:hidden">
          <Button
            onClick={() => setOpen((a) => !a)}
            className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700"
          >
            <span className="sr-only">Open main menu</span>
            {open ? <CloseIcon /> : <HamburgerIcon />}
          </Button>
        </div>
        <div
          className={`w-full flex-grow md:flex md:items-center md:w-auto ${
            open ? 'block' : 'hidden'
          }`}
        >
          <div className="text-sm md:flex md:flex-grow md:justify-between">
            <div className="md:inline-flex">
              <NavItems
                currentOperator={currentOperator}
                siteSettings={siteSettings}
              />
            </div>
            <div className="flex items-stretch">
              <TimePicker buttonClassName="block md:inline-block px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 bg-gray-900" />
              <GlobalOptions buttonClassName="p-2 dark:text-gray-200 dark:hover:text-gray-300 dark:hover:bg-gray-700 dark:active:bg-gray-800" />
            </div>
          </div>
        </div>
      </nav>
      {children}
    </Theme>
  );
};
