import {
  BooleanParam,
  NumberParam,
  StringParam,
  withDefault,
} from 'use-query-params';

import { DocumentSearchOrder } from '@/globalTypes';
import { DocumentsSearchOrderParam } from '@/utils/query/params';

export const queryParams = {
  after: StringParam,
  before: StringParam,
  first: withDefault(NumberParam, 20),
  last: NumberParam,
  desc: withDefault(BooleanParam, true),
  orderBy: withDefault(
    DocumentsSearchOrderParam,
    DocumentSearchOrder.uploadedAt,
  ),
};
