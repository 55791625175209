import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import gql from 'graphql-tag';
import _ from 'lodash';
import React, { FC } from 'react';
import { useQuery } from 'urql';

import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  Copy,
  Date as DateComponent,
  GlobalPlayerBrandValues,
  Value,
} from '@/components';
import { RefreshIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import { getGenericBrandsValues } from '@/utils';
import formatCountryCode from '@/utils/formatter/formatCountryCode';
import {
  GlobalPlayerActivityInfoBoxQuery,
  GlobalPlayerActivityInfoBoxQueryVariables,
} from './__generated__/component';

export const Fragment = graphql`
  fragment SanityGlobalPlayerActivityInfoBlock on SanityGlobalPlayerActivityInfoBlock {
    title {
      ...SanityLocaleString
    }
    registered {
      ...SanityLocaleString
    }
    registrationIp {
      ...SanityLocaleString
    }
    lastLogin {
      ...SanityLocaleString
    }
    lastLoginIp {
      ...SanityLocaleString
    }
    firstDeposit {
      ...SanityLocaleString
    }
    lastDeposit {
      ...SanityLocaleString
    }
  }
`;

const QUERY = gql`
  query GlobalPlayerActivityInfoBox($playerGlobalId: ID!) {
    playerGlobal(playerGlobalId: $playerGlobalId) {
      id
      playerGlobalUUID
      players {
        id
        rawPlayerId
        accountStatus {
          status
        }
        brand {
          id
          name
        }
        depositInfoV2 {
          first
          last
        }
        loginInfo {
          lastLogin {
            ip
            loggedAt
            countryCode
          }
          registration {
            ip
            loggedAt
            countryCode
          }
        }
      }
    }
  }
`;

const getDepositListByProperty = (
  players: GlobalPlayerActivityInfoBoxQuery['playerGlobal']['players'],
  dateProperty: string,
) => {
  return players
    .filter((el) => el.depositInfoV2)
    .map((el) =>
      dateProperty === 'first'
        ? el.depositInfoV2?.first
        : el.depositInfoV2?.last,
    );
};

const GlobalPlayerActivityInfoBlock: FC<{
  block: Queries.SanityGlobalPlayerActivityInfoBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();
  const { playerGlobalId } = useParams();

  const [{ data, fetching }, refresh] = useQuery<
    GlobalPlayerActivityInfoBoxQuery,
    GlobalPlayerActivityInfoBoxQueryVariables
  >({
    query: QUERY,
    variables: {
      playerGlobalId,
    },
  });

  const firstDeposit = _.sortBy(
    getDepositListByProperty(data?.playerGlobal.players || [], 'first'),
  )[0];

  const lastDeposit = _.sortBy(
    getDepositListByProperty(data?.playerGlobal.players || [], 'last'),
  ).reverse()[0];

  const firstRegistrationPlayer = _.orderBy(
    data?.playerGlobal.players,
    (i) => i.loginInfo?.registration.loggedAt,
    'asc',
  )[0];

  const lastLoginPlayer = _.orderBy(
    data?.playerGlobal.players,
    (i) => i.loginInfo?.lastLogin.loggedAt,
    'desc',
  )[0];

  return (
    <Card
      size="md"
      title={
        <>
          {t(block.title)}{' '}
          <Copy
            fetching={fetching}
            value={data?.playerGlobal.playerGlobalUUID}
          />
        </>
      }
      options={
        <CardOptions>
          <CardOptionsButton
            className="flex"
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <div className="p-3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-2">
          <Value
            fetching={fetching}
            title={t(block.registered)}
            suffix={
              <GlobalPlayerBrandValues
                title={t(block.registered)}
                values={
                  data &&
                  _.orderBy(
                    getGenericBrandsValues(
                      data,
                      (entry) => entry.loginInfo?.registration.loggedAt,
                    ),
                    (i) => i.brandValue,
                    'asc',
                  )
                }
              />
            }
          >
            {formatCountryCode(
              firstRegistrationPlayer?.loginInfo?.registration.countryCode,
            )}{' '}
            <DateComponent
              copyable
              date={firstRegistrationPlayer?.loginInfo?.registration.loggedAt}
            />
          </Value>
          <Value
            fetching={fetching}
            title={t(block.registrationIp)}
            suffix={
              <GlobalPlayerBrandValues
                title={t(block.registrationIp)}
                values={
                  data &&
                  _.orderBy(
                    getGenericBrandsValues(
                      data,
                      (entry) => entry.loginInfo?.registration.ip,
                    ),
                    (i) => i.registeredLoggedAt,
                    'asc',
                  )
                }
              />
            }
          >
            {formatCountryCode(
              firstRegistrationPlayer?.loginInfo?.registration.countryCode,
            )}{' '}
            <Copy value={firstRegistrationPlayer?.loginInfo?.registration.ip} />
          </Value>
          <Value
            fetching={fetching}
            title={t(block.lastLogin)}
            suffix={
              <GlobalPlayerBrandValues
                title={t(block.lastLogin)}
                values={
                  data &&
                  _.orderBy(
                    getGenericBrandsValues(
                      data,
                      (entry) => entry.loginInfo?.lastLogin.loggedAt,
                    ),
                    (i) => i.brandValue,
                    'desc',
                  )
                }
              />
            }
          >
            {formatCountryCode(
              lastLoginPlayer?.loginInfo?.lastLogin.countryCode,
            )}{' '}
            <DateComponent
              copyable
              date={lastLoginPlayer?.loginInfo?.lastLogin.loggedAt}
            />
          </Value>{' '}
          <Value
            fetching={fetching}
            title={t(block.lastLoginIp)}
            suffix={
              <GlobalPlayerBrandValues
                title={t(block.lastLoginIp)}
                values={
                  data &&
                  _.orderBy(
                    getGenericBrandsValues(
                      data,
                      (entry) => entry.loginInfo?.lastLogin.ip,
                    ),
                    (i) => i.lastLoginLoggedAt,
                    'desc',
                  )
                }
              />
            }
          >
            {formatCountryCode(
              lastLoginPlayer?.loginInfo?.lastLogin.countryCode,
            )}{' '}
            <Copy value={lastLoginPlayer?.loginInfo?.lastLogin.ip} />
          </Value>
          <Value
            fetching={fetching}
            title={t(block.firstDeposit)}
            suffix={
              <GlobalPlayerBrandValues
                title={t(block.firstDeposit)}
                values={
                  data &&
                  _.orderBy(
                    getGenericBrandsValues(
                      data,
                      (entry) => entry.depositInfoV2?.first,
                    ),
                    (i) => i.brandValue,
                    'asc',
                  )
                }
              />
            }
          >
            <DateComponent copyable date={firstDeposit} />
          </Value>
          <Value
            fetching={fetching}
            title={t(block.lastDeposit)}
            suffix={
              <GlobalPlayerBrandValues
                title={t(block.lastDeposit)}
                values={
                  data &&
                  _.orderBy(
                    getGenericBrandsValues(
                      data,
                      (entry) => entry.depositInfoV2?.last,
                    ),
                    (i) => i.brandValue,
                    'desc',
                  )
                }
              />
            }
          >
            <DateComponent copyable date={lastDeposit} presentation="timeAgo" />
          </Value>
        </div>
      </CardBody>
    </Card>
  );
};

export default GlobalPlayerActivityInfoBlock;
