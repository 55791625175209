exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-example-breadcrumbs-ts": () => import("./../../../src/pages/example/breadcrumbs.ts" /* webpackChunkName: "component---src-pages-example-breadcrumbs-ts" */),
  "component---src-pages-example-card-tsx": () => import("./../../../src/pages/example/card.tsx" /* webpackChunkName: "component---src-pages-example-card-tsx" */),
  "component---src-pages-example-detail-list-tsx": () => import("./../../../src/pages/example/detail-list.tsx" /* webpackChunkName: "component---src-pages-example-detail-list-tsx" */),
  "component---src-pages-example-feedbacker-tsx": () => import("./../../../src/pages/example/feedbacker.tsx" /* webpackChunkName: "component---src-pages-example-feedbacker-tsx" */),
  "component---src-pages-example-game-search-tsx": () => import("./../../../src/pages/example/game-search.tsx" /* webpackChunkName: "component---src-pages-example-game-search-tsx" */),
  "component---src-pages-example-options-menu-tsx": () => import("./../../../src/pages/example/options-menu.tsx" /* webpackChunkName: "component---src-pages-example-options-menu-tsx" */),
  "component---src-pages-example-player-search-tsx": () => import("./../../../src/pages/example/player-search.tsx" /* webpackChunkName: "component---src-pages-example-player-search-tsx" */),
  "component---src-pages-example-switch-field-tsx": () => import("./../../../src/pages/example/switch-field.tsx" /* webpackChunkName: "component---src-pages-example-switch-field-tsx" */),
  "component---src-pages-example-table-visualization-tsx": () => import("./../../../src/pages/example/table-visualization.tsx" /* webpackChunkName: "component---src-pages-example-table-visualization-tsx" */),
  "component---src-pages-example-time-picker-tsx": () => import("./../../../src/pages/example/time-picker.tsx" /* webpackChunkName: "component---src-pages-example-time-picker-tsx" */),
  "component---src-pages-examples-tsx": () => import("./../../../src/pages/examples.tsx" /* webpackChunkName: "component---src-pages-examples-tsx" */),
  "component---src-pages-graphql-tsx": () => import("./../../../src/pages/graphql.tsx" /* webpackChunkName: "component---src-pages-graphql-tsx" */),
  "component---src-pages-implicit-callback-tsx": () => import("./../../../src/pages/implicit/callback.tsx" /* webpackChunkName: "component---src-pages-implicit-callback-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-page-template-tsx": () => import("./../../../src/templates/PageTemplate.tsx" /* webpackChunkName: "component---src-templates-page-template-tsx" */)
}

