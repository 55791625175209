import { graphql, useStaticQuery } from 'gatsby';

import { assert } from '@/utils/error';

const query = graphql`
  query SanityFeedbackMessagesBit {
    sanityFeedbackMessageBit {
      success {
        ...SanityLocaleString
      }
    }
  }
`;

export const useFeedbackMessages = () => {
  const staticData =
    useStaticQuery<Queries.SanityFeedbackMessagesBitQuery>(query);

  assert(staticData.sanityFeedbackMessageBit, 'missing feedback messages');

  return staticData.sanityFeedbackMessageBit;
};
