import { createClient } from '@sanity/client';

export const sanityClient = createClient({
  projectId: 'm422yxhv',
  dataset: 'production',
  apiVersion: '2021-08-31',
  token:
    'skeazrurYfA1eWkdXqZ1f3PynuQIbNmeCH7VGjaK6YccEmuk6wq9M8IGJjZTcRZNONCBEK4hvgK0nM1SeaP8T1fent829JCdxpobrdiBR9LmWR9VX2xkafpcrLcgqLGlUGuSTfj7Bbp4cYBwH6dQsHJPhelNuLdDA7y2WMPCBlo4UhGhgRqh',
  // Check this for more details https://www.sanity.io/help/js-client-browser-token
  ignoreBrowserTokenWarning: true,
  useCdn: false,
});
