import { useParams } from '@reach/router';
import gql from 'graphql-tag';
import { useMemo } from 'react';
import { useQuery } from 'urql';

import { PlayerQuery, PlayerQueryVariables } from './__generated__/usePlayer';

const query = gql`
  query Player($playerId: ID!) {
    player(playerId: $playerId) {
      __typename
      id
      playerGlobalId
      uuid
      brand {
        __typename
        code
      }
    }
  }
`;

export const usePlayer = () => {
  const { playerId } = useParams();

  const [{ data, fetching }] = useQuery<PlayerQuery, PlayerQueryVariables>({
    query,
    variables: {
      playerId,
    },
    pause: !playerId,
  });

  const results = useMemo(() => {
    return {
      fetching,
      playerId,
      playerGlobalId: data?.player?.playerGlobalId,
      uuid: data?.player.uuid || '',
      brand: data?.player.brand,
    };
  }, [playerId, fetching, data]);

  return results;
};
