import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import React, { FC } from 'react';

import { Card, CardBody, CardOptions, CardOptionsButton } from '@/components';
import { useDataGrid } from '@/components/DataGrid';
import { NextIcon, PreviousIcon, RefreshIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import { assert } from '@/utils/error';
import usePlayerPhoneLogins from './usePlayerPhoneLogins';

export const Fragment = graphql`
  fragment SanityPlayerPhoneLoginsBlock on SanityPlayerPhoneLoginsBlock {
    title {
      ...SanityLocaleString
    }
    initiator {
      ...SanityLocaleString
    }
    processedAt {
      ...SanityLocaleString
    }
    action {
      ...SanityLocaleString
    }
  }
`;

const formatEvent = (event: string) => {
  return event
    .split(/(?=[A-Z])/)
    .join(' ')
    .replace('Event', '')
    .toString();
};

const PlayerPhoneLoginsBlock: FC<{
  block: Queries.SanityPlayerPhoneLoginsBlockFragment;
}> = ({ block }) => {
  const params = useParams();
  const { t } = useTranslate();

  assert(params.playerId, 'missing playerId');

  const {
    phoneLogins,
    fetching,
    nextPage,
    previousPage,
    refresh,
    defaultFilter,
    updateFilter,
    desc,
  } = usePlayerPhoneLogins(params.playerId);

  const { DataGrid, GlobalCardOptions } = useDataGrid({
    name: 'playerPhoneLogins',
    data: phoneLogins,
    fetching,
    defaultFilter,
    updateFilter,
    orderBy: undefined,
    setOrderBy: () => {},
    desc,
    schema: (s) => [
      s.initiatorValue({
        field: 'initiator',
        title: t(block.initiator),
      }),
      s.dateTimeValue({
        field: 'processedAt',
        title: t(block.processedAt),
      }),
      s.stringValue({
        field: 'eventType',
        title: t(block.action),
        getValue: ({ row }) => formatEvent(row.eventType),
      }),
    ],
  });

  return (
    <Card
      size="lg"
      title={t(block.title)}
      showOptionsAtBottom
      options={
        <CardOptions>
          <CardOptionsButton
            disabled={!previousPage}
            onClick={() => previousPage && previousPage()}
          >
            <PreviousIcon />
          </CardOptionsButton>
          <CardOptionsButton
            disabled={!nextPage}
            onClick={() => nextPage && nextPage()}
          >
            <NextIcon />
          </CardOptionsButton>
          <GlobalCardOptions />
          <CardOptionsButton
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <DataGrid />
      </CardBody>
    </Card>
  );
};

export default PlayerPhoneLoginsBlock;
