import type { DocumentNode } from 'graphql';
import { useState } from 'react';
import { AnyVariables, UseQueryArgs, useQuery } from 'urql';

import { Nullable } from '@/types';

type Edges<Node = unknown> = {
  node: Node;
};

type Paginatable<Node> = {
  edges: (Edges<Node> | null)[] | null;
  pageInfo: {
    endCursor: string | null;
    startCursor: string | null;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
};

type PaginateStateType = {
  after?: Nullable<string>;
  before?: Nullable<string>;
  first?: Nullable<number>;
  last?: Nullable<number>;
};

const DEFAULT_LIMIT = 10;

export const usePagination = <Data, Variables, Item>({
  query,
  objectMapper,
  variables,
  pause,
}: {
  query: DocumentNode;
  objectMapper: (d: Data | undefined) => Paginatable<Item> | undefined;
  variables: Variables;
  pause?: UseQueryArgs['pause'];
}) => {
  const [pagination, setPagination] = useState<PaginateStateType>({
    first: DEFAULT_LIMIT,
  });

  const [{ data, fetching }, fetch] = useQuery<Data, AnyVariables>({
    query,
    requestPolicy: 'network-only',
    variables: {
      ...variables,
      ...pagination,
    },
    pause,
  });

  const object = objectMapper(data);

  return {
    fetch,
    items: object?.edges?.map((edge) => edge?.node) || [],
    loading: fetching && pagination.after === null,
    firstPage: object?.pageInfo.hasPreviousPage
      ? () =>
          setPagination({
            first: DEFAULT_LIMIT,
          })
      : null,
    nextPage: object?.pageInfo.hasNextPage
      ? () => {
          setPagination({
            after: object?.pageInfo?.endCursor,
            first: DEFAULT_LIMIT,
          });
        }
      : null,
    previousPage: object?.pageInfo.hasPreviousPage
      ? () => {
          setPagination({
            before: object?.pageInfo?.startCursor,
            last: DEFAULT_LIMIT,
          });
        }
      : null,
  };
};
