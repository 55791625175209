export const convertValuesToNumbers = (obj: {
  [key: string]: any;
}): {
  [key: string]: number;
} => {
  return Object.entries(obj).reduce((newObj, [key, value]) => {
    newObj[key] = Number(value);
    return newObj;
  }, {} as { [key: string]: number });
};
