import {
  formatDuration as dateFnsFormatDuration,
  intervalToDuration,
} from 'date-fns';

import { Nullable } from '@/types';

export default function formatDuration(value: Nullable<number>) {
  if (typeof value !== 'number' || !isFinite(value)) {
    return '-';
  }

  return dateFnsFormatDuration(
    intervalToDuration({ start: 0, end: value * 1000 }),
    {
      format: ['months', 'weeks', 'days', 'hours', 'minutes'],
    },
  );
}
