import { useParams } from '@reach/router';
import classNames from 'classnames';
import {
  format,
  isWeekend,
  isWithinInterval,
  parseISO,
  startOfDay,
  startOfHour,
  startOfMinute,
  startOfMonth,
  startOfQuarter,
  startOfSecond,
  startOfWeek,
  startOfYear,
  subDays,
  subHours,
  subMinutes,
  subMonths,
  subQuarters,
  subSeconds,
  subWeeks,
  subYears,
} from 'date-fns';
import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import { keyBy, sum } from 'lodash';
import React, { FC, useMemo } from 'react';
import { useToggle } from 'react-use';
import { useQuery } from 'urql';

import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  Tippy,
} from '@/components';
import { PauseIcon, PlayIcon, RefreshIcon } from '@/components/icons';
import {
  ThemeName,
  TimeValue,
  useTheme,
  useTime,
  useTranslate,
  useViewer,
} from '@/contexts';
import { GranularityEnum } from '@/globalTypes';
import { Nullable } from '@/types';
import {
  DARK_NEGATIVE_HEATMAP,
  DARK_POSITIVE_HEATMAP,
  LIGHT_NEGATIVE_HEATMAP,
  LIGHT_POSITIVE_HEATMAP,
  getColorForPercentageFunction,
} from '@/utils/colors';
import formatMoney from '@/utils/formatter/formatMoney';
import {
  ActivityMapBoxQuery,
  ActivityMapBoxQueryVariables,
  ActivityStatsFragment,
} from './__generated__/component';

const BLOCK_STATIC_QUERY = graphql`
  query SanityActivityMapBlockStaticQuery {
    sanityActivityMapBlock {
      title {
        ...SanityLocaleString
      }
    }
  }
`;

const STATS_FRAGMENT = gql`
  fragment ActivityStats on StatsType {
    rows {
      timePeriod
      amountDeposits
      amountCashbacks
      turnover
      amountAdjustments
      amountBonusAdjustments
      amountWithdrawals
      ggr
      currency @include(if: $requireCurrency)
    }
  }
`;

const ACTIVITY_MAP_QUERY = gql`
  query ActivityMapBox(
    $entityId: ID!
    $from: OffsetDateTime!
    $to: OffsetDateTime!
    $timeZone: String
    $granularity: GranularityEnum!
    $requireCurrency: Boolean!
  ) {
    node(id: $entityId) {
      id
      __typename
      ... on EntityWithStatistics {
        stats(
          granularity: $granularity
          from: $from
          to: $to
          timeZone: $timeZone
          exchangeRateBaseCurrency: "EUR"
        ) {
          ...ActivityStats
        }
      }
    }
  }
  ${STATS_FRAGMENT}
`;

type ColorFunction = ReturnType<typeof getColorForPercentageFunction>;

const createColors = (themeName: ThemeName): [ColorFunction, ColorFunction] => {
  const POSITIVE_HM =
    themeName === 'dark' ? DARK_POSITIVE_HEATMAP : LIGHT_POSITIVE_HEATMAP;
  const NEGATIVE_HM =
    themeName === 'dark' ? DARK_NEGATIVE_HEATMAP : LIGHT_NEGATIVE_HEATMAP;

  return [
    getColorForPercentageFunction(POSITIVE_HM),
    getColorForPercentageFunction(NEGATIVE_HM),
  ];
};

const ActivityItem: FC<{
  color: string;
  inTimeRange: boolean;
  title: string;
  isSpecial?: boolean;
}> = ({ color, inTimeRange, title, isSpecial }) => {
  return (
    <Tippy content={title}>
      <div
        style={{
          backgroundColor: color,
        }}
        className={classNames(
          'w-3 h-3 border flex-shrink-0 m-0.5 border-opacity-10 border-gray-700 dark:border-gray-700 rounded-sm transition-all',
          {
            'border-opacity-30': isSpecial,
            'opacity-50': !inTimeRange,
          },
        )}
      />
    </Tippy>
  );
};

type MetricKey =
  | 'amountDeposits'
  | 'amountCashbacks'
  | 'ggr'
  | 'amountAdjustments'
  | 'amountBonusAdjustments'
  | 'amountWithdrawals';

const availableMetrics: { title: string; metrics: MetricKey[] }[] = [
  {
    title: 'Dep',
    metrics: ['amountDeposits'],
  },
  {
    title: 'GGR',
    metrics: ['ggr'],
  },
  {
    title: 'Adj',
    metrics: ['amountAdjustments', 'amountBonusAdjustments'],
  },
  {
    title: 'CB',
    metrics: ['amountCashbacks'],
  },
  {
    title: 'With',
    metrics: ['amountWithdrawals'],
  },
];

const ActivityRow: FC<{
  rows: ActivityStatsFragment['rows'];
  numPoints: number;
  time: TimeValue;
  metrics: MetricKey[];
  groupedByGranularity: {
    [key: string]: ActivityStatsFragment['rows'][number];
  };
}> = ({ rows, numPoints, metrics, groupedByGranularity, time }) => {
  const theme = useTheme();

  const allValues = (rows || []).map(
    (row) => sum(metrics.map((k) => row[k as keyof typeof row])) || 0,
  );

  const high = Math.max(Math.max(...allValues, 1), -Math.min(...allValues, 0));
  const colors = createColors(theme.name);

  return (
    <div className="relative h-3 mb-1">
      <div className="absolute right-0 flex flex-row">
        {[...Array(numPoints)].map((_row, index) => {
          const date = calculateDateFromIndex(time, numPoints, index);
          const dateString = format(date, granularityFormat[time.granularity]);
          const entry = groupedByGranularity[dateString];
          const currency = (entry && entry.currency) || 'EUR';

          const x = entry
            ? sum(metrics.map((m) => entry[m as keyof typeof entry])) || 0
            : 0;
          const v = high > 0 ? x / high : 0;
          const inTimeRange = isWithinInterval(date, {
            start: time.from,
            end: time.to,
          });
          return (
            <ActivityItem
              inTimeRange={inTimeRange}
              title={`${dateString}: ${formatMoney(x, currency)}`}
              key={index}
              isSpecial={
                time.granularity === GranularityEnum.Day && isWeekend(date)
              }
              color={v >= 0 ? colors[0](v) : colors[1](-v)}
            />
          );
        })}
      </div>
    </div>
  );
};

const granularityFormat = {
  [GranularityEnum.All]: '',
  [GranularityEnum.Year]: 'yyyy',
  [GranularityEnum.Quarter]: 'yyyy qqq',
  [GranularityEnum.Week]: 'yyyy ww',
  [GranularityEnum.Month]: 'yyyy-MM',
  [GranularityEnum.Day]: 'yyyy-MM-dd',
  [GranularityEnum.Hour]: 'yyyy-MM-dd HH:00',
  [GranularityEnum.Minute]: 'yyyy-MM-dd HH:mm',
  [GranularityEnum.Second]: 'yyyy-MM-dd HH:mm:ss',
};

const ActivityTimeline: FC<{
  node: Nullable<ActivityMapBoxQuery['node']>;
  numPoints: number;
  time: TimeValue;
}> = ({ node, numPoints, time }) => {
  const rows = useMemo(() => {
    return node?.__typename === 'Viewer' || node?.__typename === 'Player'
      ? node?.stats?.rows || []
      : [];
  }, [node]);

  const groupedByGranularity = useMemo(
    () =>
      keyBy(
        rows.filter(
          (row) => row.timePeriod,
        ) as (ActivityStatsFragment['rows'][number] & {
          timePeriod: string;
        })[],
        (row) =>
          row.timePeriod &&
          format(parseISO(row.timePeriod), granularityFormat[time.granularity]),
      ),
    [rows, time.granularity],
  );

  return (
    <div
      className="overflow-hidden grid"
      style={{ gridTemplateColumns: 'auto 1fr' }}
    >
      <div className="bg-white dark:bg-gray-800 text-xs px-1 w-12 flex flex-col font-semibold text-gray-500 dark:text-gray-400">
        {availableMetrics.map((a) => (
          <div key={a.metrics.join(',')} className="h-3 m-0.5 leading-3">
            {a.title}
          </div>
        ))}
      </div>
      <div className="overflow-hidden">
        {availableMetrics.map((a) => (
          <ActivityRow
            key={a.metrics.join(',')}
            rows={rows}
            numPoints={numPoints}
            time={time}
            metrics={a.metrics}
            groupedByGranularity={groupedByGranularity}
          />
        ))}
      </div>
    </div>
  );
};

const getDateFromIndexMap = (
  time: TimeValue,
  numPoints: number,
  index: number,
): Record<TimeValue['granularity'], Date | undefined> => ({
  Day: startOfDay(subDays(time.to, numPoints - index - 1)),
  Hour: startOfHour(subHours(time.to, numPoints - index - 1)),
  Minute: startOfMinute(subMinutes(time.to, numPoints - index - 1)),
  Week: startOfWeek(subWeeks(time.to, numPoints - index - 1)),
  Month: startOfMonth(subMonths(time.to, numPoints - index - 1)),
  Quarter: startOfQuarter(subQuarters(time.to, numPoints - index - 1)),
  Year: startOfYear(subYears(time.to, numPoints - index - 1)),
  Second: startOfSecond(subSeconds(time.to, numPoints - index - 1)),
  All: undefined,
});

const calculateDateFromIndex = (
  time: TimeValue,
  numPoints: number,
  index: number,
) => {
  const dateFromIndexMap = getDateFromIndexMap(time, numPoints, index);
  const date = dateFromIndexMap[time.granularity];

  if (date) {
    return date;
  }

  throw new Error(`Can't calculate index for granularity ${time.granularity}`);
};

const ActivityMapBlock = () => {
  const { t } = useTranslate();
  const params = useParams();

  const block =
    useStaticQuery<Queries.SanityActivityMapBlockStaticQueryQuery>(
      BLOCK_STATIC_QUERY,
    ).sanityActivityMapBlock;

  const { viewer } = useViewer();
  const { time, graphQLVariables } = useTime();
  const [polling, togglePolling] = useToggle(true);

  const numPoints = Math.min(
    96,
    typeof window !== 'undefined' ? Math.floor(window.outerWidth / 14) : 96,
  );
  const [{ data }, refresh] = useQuery<
    ActivityMapBoxQuery,
    ActivityMapBoxQueryVariables
  >({
    query: ACTIVITY_MAP_QUERY,
    variables: {
      entityId: params.playerId || viewer?.id,
      ...graphQLVariables,
      requireCurrency: !!params.playerId,
    },
    // @ts-expect-error
    pollInterval: polling ? 5000 : undefined,
    requestPolicy: 'cache-and-network',
  });

  if (!block) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(block.title)}
      options={
        <CardOptions>
          <CardOptionsButton
            className="flex"
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
          <CardOptionsButton className="flex" onClick={() => togglePolling()}>
            {polling ? <PauseIcon /> : <PlayIcon />}
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <div className="p-3">
          <ActivityTimeline
            numPoints={numPoints}
            node={data?.node}
            time={time}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default ActivityMapBlock;
