import { useParams } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useMemo } from 'react';
import { feedback } from 'react-feedbacker';
import { useQuery } from 'urql';

import { useFeedbackMessages } from '@/bits';
import {
  LimitPeriodDetailList,
  limitPeriodDetailList_periodLimit,
} from '@/bits/limit-period-detail-list/component';
import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  ConditionalWrap,
  ControlledModal,
  InlineIconButton,
  Tippy,
} from '@/components';
import { Bar } from '@/components/Bar';
import {
  CloseIcon,
  EditIcon,
  InformationIcon,
  LockClosedIcon,
  LockOpenIcon,
  RefreshIcon,
} from '@/components/icons';
import { TrashIcon } from '@/components/icons/TrashIcon';
import { useTranslate } from '@/contexts';
import {
  PlayerCancelSessionLimitForm,
  PlayerRemoveSessionLimitForm,
  PlayerSessionLimitsForm,
} from '@/forms';
import { limitBlockingFragment, limitFragment } from '@/fragments/Limits';
import { LimitType, LimitTypeEnum } from '@/globalTypes';
import { Nullable, Unwrap } from '@/types';
import { useCan } from '@/utils/access';
import formatDate from '@/utils/formatter/formatDate';
import formatDuration from '@/utils/formatter/formatDuration';
import {
  PlayerSessionLimitsBoxQuery,
  PlayerSessionLimitsBoxQueryVariables,
} from './__generated__/component';
import { useBlockSessionLimit, useUnblockSessionLimit } from './blocking';

type LimitPeriod =
  | NonNullable<
      PlayerSessionLimitsBoxQuery['player']['wallet']
    >['sessionLimit']['dayLimit']
  | NonNullable<
      PlayerSessionLimitsBoxQuery['player']['wallet']
    >['sessionLimit']['weekLimit']
  | NonNullable<
      PlayerSessionLimitsBoxQuery['player']['wallet']
    >['sessionLimit']['monthLimit'];

const BLOCK_STATIC_QUERY = graphql`
  query SanityPlayerSessionLimitsBlockStaticQuery {
    sanityPlayerSessionLimitsBlock {
      title {
        ...SanityLocaleString
      }
      sessionLimitsLabel {
        ...SanityLocaleString
      }
      dailyLabel {
        ...SanityLocaleString
      }
      weeklyLabel {
        ...SanityLocaleString
      }
      monthlyLabel {
        ...SanityLocaleString
      }
    }
  }
`;

const QUERY = gql`
  query PlayerSessionLimitsBox($playerId: ID!) {
    player(playerId: $playerId) {
      id
      wallet {
        id
        currency
        sessionLimit {
          ...LimitBlockingStatus
          dayLimit {
            ...PeriodLimit
            ...LimitPeriodDetailList_periodLimit
          }
          weekLimit {
            ...PeriodLimit
            ...LimitPeriodDetailList_periodLimit
          }
          monthLimit {
            ...PeriodLimit
            ...LimitPeriodDetailList_periodLimit
          }
        }
      }
    }
  }
  ${limitFragment}
  ${limitBlockingFragment}
  ${limitPeriodDetailList_periodLimit}
`;

const SessionLimitBar: FC<{
  playerId?: string;
  fetching: boolean;
  limit: Nullable<LimitPeriod>;
  label: Nullable<Queries.SanityLocaleStringFragment>;
  limitPeriodType: LimitTypeEnum;
}> = ({ fetching, limit, label, playerId, limitPeriodType }) => {
  const { t } = useTranslate();

  const { limitBarValue } = useMemo(() => {
    const limitBarValue =
      ((limit?.value ?? 0) - (limit?.available ?? 0)) / (limit?.value ?? 0);

    return {
      limitBarValue,
    };
  }, [limit?.available, limit?.value]);

  const barLabel = useMemo(() => {
    return t(label, {
      value: formatDuration((limit?.value ?? 0) - (limit?.available ?? 0)),
      of: formatDuration(limit?.value),
    });
  }, [label, limit?.available, limit?.value, t]);

  return (
    <div>
      <div className="flex">
        <ConditionalWrap
          condition={!!limit}
          wrap={(children) => (
            <Tippy
              content={`Period ending: ${formatDate(
                limit?.currentPeriodEndTime,
              )}`}
            >
              <div className="w-full">{children}</div>
            </Tippy>
          )}
        >
          <Bar loading={fetching} value={limitBarValue}>
            {barLabel}
          </Bar>
        </ConditionalWrap>
        <ControlledModal
          content={
            playerId && limit ? (
              <LimitPeriodDetailList
                limit={limit}
                limitTypeTitle={LimitType.Session}
                limitPeriodType={limitPeriodType}
                formatValue={formatDuration}
              />
            ) : null
          }
        >
          <InlineIconButton disabled={!limit}>
            <InformationIcon />
          </InlineIconButton>
        </ControlledModal>
        <ControlledModal
          content={
            playerId ? (
              <PlayerSessionLimitsForm
                playerId={playerId}
                limitPeriodType={limitPeriodType}
                limit={limit}
              />
            ) : null
          }
        >
          <InlineIconButton>
            <EditIcon />
          </InlineIconButton>
        </ControlledModal>
        <ControlledModal
          content={
            playerId ? (
              <PlayerRemoveSessionLimitForm
                playerId={playerId}
                limitPeriodType={limitPeriodType}
              />
            ) : null
          }
        >
          <InlineIconButton>
            <TrashIcon />
          </InlineIconButton>
        </ControlledModal>
      </div>
      {limit?.pendingPeriodLimit && (
        <div>
          Limit will be updated to{' '}
          {formatDuration(limit.pendingPeriodLimit.value)} on{' '}
          {formatDate(limit.pendingPeriodLimit.validFrom)}
          <ControlledModal
            content={
              playerId ? (
                <PlayerCancelSessionLimitForm
                  playerId={playerId}
                  limitPeriodType={limitPeriodType}
                />
              ) : null
            }
          >
            <InlineIconButton>
              <CloseIcon />
            </InlineIconButton>
          </ControlledModal>
        </div>
      )}
      {limit?.pendingPeriodLimitRemoval && (
        <div>
          Limit will be removed on{' '}
          {formatDate(limit.pendingPeriodLimitRemoval.validFrom)}
        </div>
      )}
    </div>
  );
};

const PlayerSessionLimitsBlock: FC = () => {
  const { t } = useTranslate();
  const params = useParams();
  const feedbackMessages = useFeedbackMessages();

  const block =
    useStaticQuery<Queries.SanityPlayerSessionLimitsBlockStaticQueryQuery>(
      BLOCK_STATIC_QUERY,
    ).sanityPlayerSessionLimitsBlock;

  const [{ data, fetching }, refresh] = useQuery<
    PlayerSessionLimitsBoxQuery,
    PlayerSessionLimitsBoxQueryVariables
  >({
    query: QUERY,
    variables: {
      playerId: params.playerId,
    },
  });

  const [blockLimitState, blockLimit] = useBlockSessionLimit();
  const [unblockLimitState, unblockLimit] = useUnblockSessionLimit();

  const canBlock = useCan('BLOCK_PLAYER_LIMITS');
  const canUnblock = useCan('BLOCK_PLAYER_LIMITS');

  const onBlockMutationCompleted = (
    res: Unwrap<ReturnType<typeof blockLimit | typeof unblockLimit>>,
  ) => {
    if (res.error?.message) {
      feedback.error(res.error.message);
    } else {
      feedback.success(t(feedbackMessages.success));
    }
  };

  const loadingBlockMutation =
    blockLimitState.fetching || unblockLimitState.fetching;

  if (!block) {
    return null;
  }

  const sessionLimit = data?.player.wallet?.sessionLimit;
  const isBlocked = sessionLimit?.blockingStatus?.blocked;

  const canSwitchBlocking = isBlocked ? canUnblock : canBlock;

  const onBlockToggle = () => {
    if (!canSwitchBlocking) {
      return;
    }
    if (isBlocked) {
      unblockLimit({ playerId: params.playerId }).then(
        onBlockMutationCompleted,
      );
      return;
    }
    blockLimit({ playerId: params.playerId }).then(onBlockMutationCompleted);
  };

  return (
    <Card
      size="lg"
      title={t(block.title)}
      options={
        <CardOptions>
          <CardOptionsButton
            type="button"
            disabled={loadingBlockMutation || !canSwitchBlocking}
            onClick={onBlockToggle}
          >
            {isBlocked ? <LockClosedIcon /> : <LockOpenIcon />}
          </CardOptionsButton>
          <CardOptionsButton
            className="flex"
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <div className="p-3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
          <div className="col-span-full">
            <label className="text-sm text-gray-500 font-semibold">
              {t(block.sessionLimitsLabel)}
            </label>
            <div className="grid gap-2 pt-1">
              <SessionLimitBar
                fetching={fetching}
                limit={sessionLimit?.dayLimit}
                label={block.dailyLabel}
                playerId={data?.player.id}
                limitPeriodType={LimitTypeEnum.Day}
              />
              <SessionLimitBar
                fetching={fetching}
                limit={sessionLimit?.weekLimit}
                label={block.weeklyLabel}
                playerId={data?.player.id}
                limitPeriodType={LimitTypeEnum.Week}
              />
              <SessionLimitBar
                fetching={fetching}
                limit={sessionLimit?.monthLimit}
                label={block.monthlyLabel}
                playerId={data?.player.id}
                limitPeriodType={LimitTypeEnum.Month}
              />
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default PlayerSessionLimitsBlock;
