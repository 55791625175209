import { graphql } from 'gatsby';
import React, { FC } from 'react';

import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  ControlledModal,
} from '@/components';
import { useDataGrid } from '@/components/DataGrid';
import {
  NextIcon,
  PreviousIcon,
  RefreshIcon,
  SendIcon,
} from '@/components/icons';
import { useTranslate } from '@/contexts';
import { WithdrawalReviewsForm } from '@/forms';
import {
  DecisionSearchType,
  DecisionType,
  PaymentIntegration,
  PaymentType,
} from '@/globalTypes';
import formatMoney from '@/utils/formatter/formatMoney';
import { useWithdrawalApprovals } from './useWithdrawalApprovals';

export const Fragment = graphql`
  fragment SanityWithdrawalApprovalsBlock on SanityWithdrawalApprovalsBlock {
    title {
      ...SanityLocaleString
    }
    paymentId {
      ...SanityLocaleString
    }
    playerId {
      ...SanityLocaleString
    }
    paymentType {
      ...SanityLocaleString
    }
    amount {
      ...SanityLocaleString
    }
    autoDecision {
      ...SanityLocaleString
    }
    manualDecision {
      ...SanityLocaleString
    }
    address {
      ...SanityLocaleString
    }
    processedAt {
      ...SanityLocaleString
    }
    integration {
      ...SanityLocaleString
    }
  }
`;

const WithdrawalApprovalsBlock: FC<{
  block: Queries.SanityWithdrawalApprovalsBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();

  const {
    withdrawalApprovals,
    fetching,
    nextPage,
    previousPage,
    refresh,
    desc,
    orderBy,
    setOrderBy,
    defaultFilter,
    updateFilter,
  } = useWithdrawalApprovals();

  const { DataGrid, GlobalCardOptions, selectedRows } = useDataGrid({
    name: 'withdrawalManualReview',
    data: withdrawalApprovals,
    fetching,
    orderBy,
    setOrderBy,
    desc,
    defaultFilter,
    updateFilter,
    includeCheckboxColumn: true,
    schema: (s) => [
      s.dateTimeValue({
        field: 'processedAt',
        title: t(block.processedAt),
        fromFilterField: 'processedAtfrom',
        toFilterField: 'processedAtTo',
      }),
      s.stringValue({
        field: 'paymentId',
        title: t(block.paymentId),
        filterField: 'paymentId',
      }),
      s.stringValue({
        field: 'playerId',
        title: t(block.playerId),
        filterField: 'playerId',
      }),
      s.enumValue({
        field: 'paymentType',
        title: t(block.paymentType),
        e: PaymentType,
        filterField: 'paymentType',
      }),
      s.stringValue({
        field: 'amount',
        title: t(block.amount),
        getValue: ({ row }) =>
          formatMoney(row.amount.amount, row.amount.currency),
      }),
      s.enumValue({
        field: 'integration',
        title: t(block.integration),
        e: PaymentIntegration,
        filterField: 'PaymentIntegration',
      }),
      s.stringValue({
        field: 'address',
        title: t(block.address),
        filterField: 'address',
      }),
      s.enumValue({
        field: 'autoDecision',
        title: t(block.autoDecision),
        e: DecisionType,
        filterField: 'autoDecision',
      }),
      s.enumValue({
        field: 'manualDecision',
        title: t(block.manualDecision),
        e: DecisionSearchType,
        filterField: 'manualDecision',
      }),
    ],
  });

  return (
    <Card
      size="lg"
      title={t(block.title)}
      showOptionsAtBottom
      options={
        <CardOptions>
          <ControlledModal
            content={
              <WithdrawalReviewsForm selectedWithdrawals={selectedRows} />
            }
          >
            <CardOptionsButton disabled={selectedRows.length <= 0}>
              <SendIcon />
            </CardOptionsButton>
          </ControlledModal>
          <CardOptionsButton
            disabled={!previousPage}
            onClick={() => previousPage && previousPage()}
          >
            <PreviousIcon />
          </CardOptionsButton>
          <CardOptionsButton
            disabled={!nextPage}
            onClick={() => nextPage && nextPage()}
          >
            <NextIcon />
          </CardOptionsButton>
          <GlobalCardOptions />
          <CardOptionsButton
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <DataGrid />
      </CardBody>
    </Card>
  );
};

export default WithdrawalApprovalsBlock;
