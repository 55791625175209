import { graphql } from 'gatsby';
import React, { FC } from 'react';

import { Breadcrumb, Breadcrumbs as BreadcrumbsComponent } from '@/components';
import { usePage } from '@/contexts';

export const Fragment = graphql`
  fragment SanityGenericBreadcrumbsBlock on SanityGenericBreadcrumbsBlock {
    pages {
      _id
      title
      path {
        current
      }
    }
  }
`;

const GenericBreadcrumbsBlock: FC<{
  block: Queries.SanityGenericBreadcrumbsBlockFragment;
}> = ({ block }) => {
  const currentPage = usePage();

  return (
    <BreadcrumbsComponent>
      {block.pages?.map((page) => (
        <Breadcrumb key={page?._id}>{page?.title}</Breadcrumb>
      ))}
      <Breadcrumb last>{currentPage?.title}</Breadcrumb>
    </BreadcrumbsComponent>
  );
};

export default GenericBreadcrumbsBlock;
