import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useState } from 'react';
import { useMutation } from 'urql';

import {
  Card,
  CardBody,
  CardCloseButton,
  CardOptions,
  CheckboxField,
  ErrorMessage,
  Form,
  SubmitButton,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { useIsMounted } from '@/hooks';
import { Nullable, PlayerId } from '@/types';
import {
  PlayerUpdateCustomerWhiteListStatusMutation,
  PlayerUpdateCustomerWhiteListStatusMutationVariables,
} from './__generated__/PlayerUpdateCustomerWhiteListStatusForm';

const query = graphql`
  query SanityPlayerUpdateCustomerWhiteListStatusForm {
    sanityPlayerUpdateCustomerWhiteListStatusForm {
      title {
        ...SanityLocaleString
      }
      description {
        ...SanityLocaleString
      }
      isCustomerWhiteListed {
        ...SanityLocaleString
      }
      submit {
        ...SanityLocaleString
      }
    }
  }
`;

const updateCustomerWhiteListStatusMutation = gql`
  mutation PlayerUpdateCustomerWhiteListStatus(
    $playerGlobalId: ID!
    $whitelisted: Boolean!
  ) {
    updateCustomerWhitelistStatus(
      playerGlobalId: $playerGlobalId
      whitelisted: $whitelisted
    ) {
      ... on Customer {
        isCustomerWhitelisted
      }
    }
  }
`;

const PlayerUpdateCustomerWhiteListStatusForm: FC<{
  playerGlobalId: PlayerId;
  isWhitelisted: boolean;
}> = ({ playerGlobalId, isWhitelisted }) => {
  const staticData =
    useStaticQuery<Queries.SanityPlayerUpdateCustomerWhiteListStatusFormQuery>(
      query,
    );

  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();
  const form = staticData.sanityPlayerUpdateCustomerWhiteListStatusForm;
  const { close } = useModalContext();
  const { t } = useTranslate();

  const [updateCustomerWhiteListStatusState, updateCustomerWhiteListStatus] =
    useMutation<
      PlayerUpdateCustomerWhiteListStatusMutation,
      PlayerUpdateCustomerWhiteListStatusMutationVariables
    >(updateCustomerWhiteListStatusMutation);

  const defaultValues = {
    isWhitelisted,
  };

  const onSubmit = ({ isWhitelisted }: typeof defaultValues) => {
    updateCustomerWhiteListStatus({
      playerGlobalId,
      whitelisted: !isWhitelisted,
    }).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      } else if (close) {
        close();
      }
    });
  };

  if (!form) {
    return null;
  }

  return (
    <Card
      size="md"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <CardBody>
        <div className="p-3">
          <Form
            defaultValues={defaultValues}
            onSubmit={onSubmit}
            className="grid gap-6"
          >
            <div className="space-y-1">
              <div className="block text-gray-600 text-sm dark:text-gray-200">
                {t(form.description)}
              </div>
            </div>
            <CheckboxField
              title={t(form.isCustomerWhiteListed)}
              name="isCustomerWhiteListed"
              id="PlayerUpdateCustomerWhiteListStatusForm_isCustomerWhiteListed"
              className="col-span-full"
              defaultChecked={isWhitelisted}
            />
            <ErrorMessage message={errorMessage} />
            <SubmitButton
              value={t(form.submit)}
              disabled={updateCustomerWhiteListStatusState.fetching}
            />
          </Form>
        </div>
      </CardBody>
    </Card>
  );
};

export default PlayerUpdateCustomerWhiteListStatusForm;
