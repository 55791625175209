import React from 'react';

import { Pill } from '@/components';
import { CountryField } from '@/components/form';
import formatCountryCode from '@/utils/formatter/formatCountryCode';
import { DataDef, DataDefProps, FilterType } from '../types';

export const countryFilter = ({
  field,
  filterField,
  title,
}: {
  field: string;
  filterField: string;
  title: string;
}): FilterType => ({
  field,
  transformDefaultValues: (values) => {
    const value = values[filterField];
    return {
      ...values,
      [filterField]: value || '',
    };
  },
  transformValues: (values) => {
    const value = values[filterField];
    return { ...values, [filterField]: value || undefined };
  },
  component: () => (
    <CountryField name={filterField} id={filterField} title={title} />
  ),

  pillView: (values, updateFilter) => {
    const value = values[filterField];
    if (value) {
      return (
        <Pill onClick={() => updateFilter({ [filterField]: undefined })}>
          {value}
        </Pill>
      );
    }
    return null;
  },
});

export function countryValue<O, T extends Record<string, any>>({
  title,
  field,
  getValue,
  filterField,
  ...rest
}: DataDefProps<O, T> & { filterField?: string }): DataDef<O, T> {
  return {
    title,
    field,
    ...rest,
    filter: filterField
      ? countryFilter({ field, title, filterField })
      : undefined,
    component: ({ row }) => {
      const value = getValue ? getValue({ row }) : row[field];
      return <>{formatCountryCode(value)}</>;
    },
  };
}
