import React, { ComponentType, lazy } from 'react';

import { Visualization, useAnalyticsState } from '../Context';
import { StatsRow } from '../helpers';

const TableVisualization = lazy(() => import('./TableVisualization'));

export type VisualizationProps = {
  columns: string[];
  rows: string[];
  values: string[];
  stats: StatsRow[];
  exportedUrl?: string | null;
};

const useGetVisualizationComponent =
  (): ComponentType<VisualizationProps> | null => {
    const { visualization } = useAnalyticsState();

    switch (visualization) {
      case Visualization.Table:
        return TableVisualization;
      default:
        return null;
    }
  };

export const VisualizationView = (props: VisualizationProps) => {
  const Component = useGetVisualizationComponent();

  if (Component) {
    return <Component {...props} />;
  }

  return null;
};
