import { graphql } from 'gatsby';
import queryString from 'query-string';
import React, { FC } from 'react';

import { useGetChallengeDetailPageLink } from '@/bits/links/useLink';
import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  ControlledModal,
  InlineIconButton,
  Tippy,
} from '@/components';
import { useDataGrid } from '@/components/DataGrid';
import {
  NextIcon,
  PreviousIcon,
  RefreshIcon,
  TrashIcon,
} from '@/components/icons';
import { useTranslate } from '@/contexts';
import { ChallengeRemoveForm } from '@/forms';
import { BrandEnum, ChallengeStatus } from '@/globalTypes';
import useChallenges from './useChallenges';

export const Fragment = graphql`
  fragment SanityChallengesHistoryBlock on SanityChallengesHistoryBlock {
    title {
      ...SanityLocaleString
    }
    name {
      ...SanityLocaleString
    }
    challengeId {
      ...SanityLocaleString
    }
    challengeUuid {
      ...SanityLocaleString
    }
    numberOfPlayers {
      ...SanityLocaleString
    }
    brands {
      ...SanityLocaleString
    }
    countries {
      ...SanityLocaleString
    }
    startDate {
      ...SanityLocaleString
    }
    endDate {
      ...SanityLocaleString
    }
    status {
      ...SanityLocaleString
    }
    actions {
      ...SanityLocaleString
    }
  }
`;

const returnBrands = (brands: BrandEnum[]) => {
  return String(brands);
};

const returnCountries = (countries: string[]) => {
  return String(countries);
};

const shouldShowRemoveAction = (status: ChallengeStatus) => {
  return (
    status === ChallengeStatus.Activated || status === ChallengeStatus.Scheduled
  );
};

const ChallengeHistoryBlock: FC<{
  block: Queries.SanityChallengesHistoryBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();

  const getChallengeDetailPageLink = useGetChallengeDetailPageLink();

  const {
    challenges,
    fetching,
    nextPage,
    previousPage,
    refresh,
    orderBy,
    desc,
    setOrderBy,
    defaultFilter,
    updateFilter,
  } = useChallenges();

  const { DataGrid, GlobalCardOptions } = useDataGrid({
    name: 'challenge',
    data: challenges,
    fetching,
    orderBy,
    setOrderBy,
    desc,
    defaultFilter,
    updateFilter,
    schema: (s) => [
      s.stringValue({
        field: 'name',
        title: t(block.name),
        getValue: ({ row }) => row.name,
        linkTo: ({ row }) =>
          getChallengeDetailPageLink(
            `${row.challengeUUID}?${queryString.stringify({
              tabIndex: 0,
            })}`,
          ) || '',
      }),
      s.stringValue({
        field: 'challengeId',
        title: t(block.challengeId),
        hidden: true,
      }),
      s.stringValue({
        field: 'challengeUUID',
        title: t(block.challengeUuid),
        filterField: 'challengeId',
      }),
      s.numberValue({
        field: 'numberOfPlayers',
        title: t(block.numberOfPlayers),
        linkTo: ({ row }) =>
          getChallengeDetailPageLink(
            `${row.challengeUUID}?${queryString.stringify({
              tabIndex: 1,
            })}`,
          ) || '',
      }),
      s.stringValue({
        field: 'brands',
        title: t(block.brands),
        getValue: ({ row }) => returnBrands(row.brands),
      }),
      s.stringValue({
        field: 'countries',
        title: t(block.countries),
        getValue: ({ row }) => returnCountries(row.countries),
      }),
      s.dateTimeValue({
        field: 'startDate',
        title: t(block.startDate),
      }),
      s.dateTimeValue({
        field: 'endDate',
        title: t(block.endDate),
      }),
      s.stringValue({
        field: 'status',
        title: t(block.status),
      }),
      s.actionValue({
        field: 'actions',
        title: 'Actions',
        getValue: ({ row }) =>
          shouldShowRemoveAction(row.status) ? (
            <ControlledModal
              content={<ChallengeRemoveForm challengeId={row.challengeUUID} />}
            >
              <InlineIconButton>
                <Tippy content="Remove">
                  <div>
                    <TrashIcon />
                  </div>
                </Tippy>
              </InlineIconButton>
            </ControlledModal>
          ) : null,
      }),
    ],
  });

  return (
    <Card
      size="lg"
      title={t(block.title)}
      showOptionsAtBottom
      options={
        <CardOptions>
          <CardOptionsButton
            disabled={!previousPage}
            onClick={() => previousPage && previousPage()}
          >
            <PreviousIcon />
          </CardOptionsButton>
          <CardOptionsButton
            disabled={!nextPage}
            onClick={() => nextPage && nextPage()}
          >
            <NextIcon />
          </CardOptionsButton>
          <GlobalCardOptions />
          <CardOptionsButton
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <DataGrid />
      </CardBody>
    </Card>
  );
};

export default ChallengeHistoryBlock;
