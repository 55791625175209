import { useFormContext } from 'react-hook-form';

import { logger } from '@/utils';

type Props = {
  names: string[];
};

export const FormValueLogger = ({ names }: Props) => {
  const values = useFormContext().watch(names);
  logger.log('form values', values);

  return null;
};
