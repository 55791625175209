import gql from 'graphql-tag';
import { useQuery } from 'urql';
import { useQueryParams } from 'use-query-params';

import { mapVariables } from '@/utils';
import { makeOrderBy, makePagination } from '@/utils/pagination';
import {
  RewardsQuery,
  RewardsQueryVariables,
} from './__generated__/useRewards';
import { queryParams } from './queryParams';

const REWARDS_QUERY = gql`
  query Rewards(
    $createdAtFrom: OffsetDateTime
    $createdAtTo: OffsetDateTime
    $rewardId: String
    $createdBy: String
    $rewardName: String
    $first: Int
    $last: Int
    $after: String
    $before: String
    $rewardTypes: [RewardTypeEnum!]
    $players: [String!]
    $startsAtFrom: OffsetDateTime
    $startsAtTo: OffsetDateTime
    $endsAtFrom: OffsetDateTime
    $endsAtTo: OffsetDateTime
    $rewardStatus: RewardStatus
    $desc: Boolean
    $orderBy: SearchRewardsOrderBy
  ) {
    viewer {
      id
      rewards(
        createdAtFrom: $createdAtFrom
        createdAtTo: $createdAtTo
        rewardId: $rewardId
        createdBy: $createdBy
        first: $first
        last: $last
        after: $after
        before: $before
        rewardName: $rewardName
        rewardTypes: $rewardTypes
        playerIds: $players
        startsAtFrom: $startsAtFrom
        startsAtTo: $startsAtTo
        endsAtFrom: $endsAtFrom
        endsAtTo: $endsAtTo
        rewardStatus: $rewardStatus
        orderBy: $orderBy
        desc: $desc
      ) {
        edges {
          node {
            id
            rewardId
            totalPlayers
            name
            createdAt
            createdBy {
              firstName
              lastName
            }
            startsAt
            endsAt
            rewardType {
              name
            }
            status
            players {
              edges {
                node {
                  playerId
                }
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;

export default function useRewards() {
  const [query, setQuery] = useQueryParams(queryParams);
  const [{ data, fetching }, refresh] = useQuery<
    RewardsQuery,
    RewardsQueryVariables
  >({
    query: REWARDS_QUERY,
    variables: {
      first: 20,
      ...mapVariables(query),
    },
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter: (values: {}) => setQuery(values, 'replaceIn'),
    ...makeOrderBy(query, setQuery),
    ...makePagination(query, setQuery, data?.viewer.rewards.pageInfo),
    rewards: data?.viewer.rewards?.edges?.map((edge) => edge?.node),
  };
}
