import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import React, { FC } from 'react';

import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  Value,
} from '@/components';
import { RefreshIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import { assert } from '@/utils/error';
import formatDate from '@/utils/formatter/formatDate';
import formatMoney from '@/utils/formatter/formatMoney';
import usePlayerGameRound from './usePlayerGameRound';

export const Fragment = graphql`
  fragment SanityPlayerGameRoundInspectionBlock on SanityPlayerGameRoundInspectionBlock {
    title {
      ...SanityLocaleString
    }
  }
`;

const PlayerGameRoundInspectionBlock: FC<{
  block: Queries.SanityPlayerGameRoundInspectionBlockFragment;
}> = ({ block }) => {
  const params = useParams();

  assert(params.playerId, 'missing playerId');

  const { gameRound, fetching, refresh } = usePlayerGameRound(params.playerId);
  const { t } = useTranslate();
  const game =
    gameRound?.gameDescriptor &&
    'json' in gameRound?.gameDescriptor &&
    gameRound.gameDescriptor.json;

  return (
    <Card
      size="lg"
      title={t(block.title)}
      options={
        <CardOptions>
          <CardOptionsButton
            className="flex"
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <div className="p-3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
          <Value
            fetching={fetching}
            title={'Game Provider'}
            value={gameRound?.gameProvider}
          />
          <Value
            fetching={fetching}
            title={'Game Studio'}
            value={game?.gameProvider?.name}
          />
          <Value fetching={fetching} title={'Game Name'} value={game?.name} />
          <Value
            fetching={fetching}
            title={'Game ID'}
            value={gameRound?.gameId}
          />
          <Value
            fetching={fetching}
            title={'Game Round ID'}
            value={gameRound?.gameRoundId}
          />
          <Value
            fetching={fetching}
            title={'Started At'}
            value={formatDate(gameRound?.startedAt)}
          />
          <Value
            fetching={fetching}
            title={'Completed At'}
            value={formatDate(gameRound?.completedAt ?? undefined)}
          />
          <Value
            fetching={fetching}
            title={'Total Bets'}
            value={formatMoney(gameRound?.totalBets, gameRound?.currency)}
          />
          <Value
            fetching={fetching}
            title={'Total Wins'}
            value={formatMoney(gameRound?.totalWins, gameRound?.currency)}
          />
          <Value
            fetching={fetching}
            title={'JPC'}
            value={formatMoney(
              gameRound?.totalJackpotContribution,
              gameRound?.currency,
            )}
          />
          <Value
            fetching={fetching}
            title={'Jackpot Payout'}
            value={formatMoney(
              gameRound?.totalJackpotPayout,
              gameRound?.currency,
            )}
          />
          <Value
            fetching={fetching}
            title={'Opening Balance'}
            value={formatMoney(gameRound?.openingBalance, gameRound?.currency)}
          />
          <Value
            fetching={fetching}
            title={'Closing Balance'}
            value={formatMoney(gameRound?.closingBalance, gameRound?.currency)}
          />
          <Value
            fetching={fetching}
            title={'Status'}
            value={gameRound?.status}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default PlayerGameRoundInspectionBlock;
