import { useParams } from '@reach/router';
import Tippy from '@tippyjs/react';
import { graphql } from 'gatsby';
import React, { FC } from 'react';

import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  Copy,
} from '@/components';
import { useDataGrid } from '@/components/DataGrid';
import { NextIcon, PreviousIcon, RefreshIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import { EventType, RiskHistoryOrderBy } from '@/globalTypes';
import { assert } from '@/utils/error';
import usePlayerRgRiskAssessmentHistory from './usePlayerRgRiskAssessmentHistory';

export const Fragment = graphql`
  fragment SanityPlayerRgRiskAssessmentHistoryBlock on SanityPlayerRgRiskAssessmentHistoryBlock {
    title {
      ...SanityLocaleString
    }
    riskLevelOverrideRemoved {
      ...SanityLocaleString
    }
    riskLevelOverridden {
      ...SanityLocaleString
    }
    riskUpdated {
      ...SanityLocaleString
    }
  }
`;

const PlayerRgRiskAssessmentHistoryBlock: FC<{
  block: Queries.SanityPlayerRgRiskAssessmentHistoryBlockFragment;
}> = ({ block }) => {
  const params = useParams();
  const { t } = useTranslate();

  const riskAssessmentTypesTranslations: Record<string, string> = {
    RiskLevelOverrideRemoved: t(block.riskLevelOverrideRemoved),
    RiskLevelOverridden: t(block.riskLevelOverridden),
    RiskUpdated: t(block.riskUpdated),
  };

  assert(params.playerGlobalId, 'missing playerGlobalId');

  const {
    history,
    fetching,
    nextPage,
    previousPage,
    refresh,
    orderBy,
    desc,
    setOrderBy,
    defaultFilter,
    updateFilter,
  } = usePlayerRgRiskAssessmentHistory(params.playerGlobalId);

  const { DataGrid, GlobalCardOptions } = useDataGrid({
    name: 'playerRgRiskAssessmentHistory',
    data: history,
    fetching,
    orderBy,
    setOrderBy,
    desc,
    defaultFilter,
    updateFilter,
    schema: (s) => [
      s.dateTimeValue({
        field: 'processedAt',
        title: 'Time',
        fromFilterField: 'from',
        toFilterField: 'to',
        orderBy: RiskHistoryOrderBy.processedAt,
      }),
      s.initiatorValue({
        field: 'initiator',
        title: 'Initiator',
      }),
      s.enumValue({
        field: 'eventType',
        title: 'Event Type',
        filterField: 'eventType',
        e: EventType,
        getValue: ({ row }) => (
          <Tippy content={row.eventType}>
            <span>
              {riskAssessmentTypesTranslations[row.eventType] ?? row.eventType}
            </span>
          </Tippy>
        ),
      }),
      s.stringValue({
        field: 'playerId',
        title: 'Player UUID',
        getValue: ({ row }) => {
          return row.playerId ? (
            <Copy fetching={fetching} value={row.playerId} />
          ) : (
            '-'
          );
        },
      }),
      s.stringValue({
        field: 'comment',
        title: 'Comment',
        getValue: ({ row }) => row.comment,
      }),
      s.stringValue({
        field: 'eventId',
        title: 'Event ID',
        hidden: true,
        getValue: ({ row }) => row.eventId,
      }),
    ],
  });

  return (
    <Card
      size="lg"
      title={t(block.title)}
      showOptionsAtBottom
      options={
        <CardOptions>
          <CardOptionsButton
            disabled={!previousPage}
            onClick={() => previousPage && previousPage()}
          >
            <PreviousIcon />
          </CardOptionsButton>
          <CardOptionsButton
            disabled={!nextPage}
            onClick={() => nextPage && nextPage()}
          >
            <NextIcon />
          </CardOptionsButton>
          <GlobalCardOptions />
          <CardOptionsButton
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <DataGrid />
      </CardBody>
    </Card>
  );
};

export default PlayerRgRiskAssessmentHistoryBlock;
