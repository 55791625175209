import { formatISO } from 'date-fns';
import { saveAs } from 'file-saver';
import { useMemo, useState } from 'react';
import { gql, useClient } from 'urql';

import { RangeDate } from '@/globalTypes';
import { useQueryParamsWithTime } from '@/hooks';
import { mapVariables } from '@/utils';
import {
  AmlRiskAssessmentReportQuery,
  AmlRiskAssessmentReportQueryVariables,
} from './__generated__/useAmlRiskAssessmentReport';
import { queryParams } from './queryParams';

const AML_RISK_ASSESSMENT_QUERY = gql`
  query AmlRiskAssessmentReport(
    $playerGlobalId: ID
    $percentage: RangeInt
    $finalScore: RangeInt
    $score: RangeInt
    $riskLevels: [RiskLevel!]
    $reviewStatus: [ReviewStatus!]
    $calculatedAt: RangeDate
    $lastReviewedAt: RangeDate
    $orderBy: RiskAggregationOrderBy
    $desc: Boolean
  ) {
    viewer {
      id
      amlRiskAssessmentsReport(
        playerGlobalId: $playerGlobalId
        percentage: $percentage
        finalScore: $finalScore
        score: $score
        riskLevels: $riskLevels
        reviewStatus: $reviewStatus
        calculatedAt: $calculatedAt
        lastReviewedAt: $lastReviewedAt
        orderBy: $orderBy
        desc: $desc
      ) {
        url
      }
    }
  }
`;

export default function useAmlRiskAssessmentReport() {
  const [query] = useQueryParamsWithTime(queryParams, {
    fromAndToOptional: true,
  });

  const [fetching, setFetching] = useState(false);

  const calculatedAt = useMemo((): RangeDate | undefined => {
    if (query.from && query.to) {
      return {
        from: formatISO(query.from),
        to: formatISO(query.to),
      };
    }

    return undefined;
  }, [query.from, query.to]);

  const client = useClient();

  const generate = async () => {
    setFetching(true);

    return client
      .query<
        AmlRiskAssessmentReportQuery,
        AmlRiskAssessmentReportQueryVariables
      >(AML_RISK_ASSESSMENT_QUERY, { ...mapVariables(query), calculatedAt })
      .toPromise()
      .then((res) => {
        const url = res.data?.viewer.amlRiskAssessmentsReport.url;

        if (url) {
          saveAs(url, 'aml-risk-assessment-report.csv');
        }

        setFetching(false);
      });
  };

  return {
    generate,
    fetching,
  };
}
