import { saveAs } from 'file-saver';
import { gql, useClient } from 'urql';
import { useQueryParams } from 'use-query-params';

import { useElasticIndexPrefix } from '@/contexts';
import { mapVariables } from '@/utils';
import {
  GlobalPlayerPaymentsReportQuery,
  GlobalPlayerPaymentsReportQueryVariables,
} from './__generated__/useGlobalPlayerPaymentsReport';
import { queryParams } from './queryParams';

const GLOBAL_PLAYER_SEARCH_REPORT_QUERY = gql`
  query GlobalPlayerPaymentsReport(
    $brand: BrandEnum
    $currency: String
    $desc: Boolean
    $from: OffsetDateTime
    $gameProviderId: GameProvider
    $id: String
    $indexPrefix: String
    $orderBy: PaymentSearchOrder
    $org: OrgEnum
    $paymentStatus: PaymentStatus
    $paymentType: PaymentType
    $playerGlobalId: ID!
    $rawId: String
    $sessionId: String
    $to: OffsetDateTime
  ) {
    viewer {
      id
      exportedGlobalPayments(
        brand: $brand
        currency: $currency
        desc: $desc
        from: $from
        gameProviderId: $gameProviderId
        id: $id
        indexPrefix: $indexPrefix
        orderBy: $orderBy
        org: $org
        paymentStatus: $paymentStatus
        paymentType: $paymentType
        playerGlobalId: $playerGlobalId
        rawId: $rawId
        sessionId: $sessionId
        to: $to
      ) {
        url
      }
    }
  }
`;

export default function useGlobalPlayerSearchReport(playerGlobalId: string) {
  const [query] = useQueryParams(queryParams);
  const client = useClient();

  const { indexPrefix } = useElasticIndexPrefix();

  const generate = async () => {
    const variables: GlobalPlayerPaymentsReportQueryVariables = {
      ...mapVariables(query),
      indexPrefix,
      playerGlobalId,
    };

    return client
      .query<
        GlobalPlayerPaymentsReportQuery,
        GlobalPlayerPaymentsReportQueryVariables
      >(GLOBAL_PLAYER_SEARCH_REPORT_QUERY, variables)
      .toPromise()
      .then((res) => {
        const url = res.data?.viewer.exportedGlobalPayments.url;

        if (url) {
          saveAs(url, 'global-player-payments-report.csv');
        }
      });
  };

  return {
    generate,
  };
}
