import { Maybe } from 'graphiql/dist/types';
import React from 'react';

import { Card, CardCloseButton, CardOptions, DetailList } from '@/components';
import formatMoney from '@/utils/formatter/formatMoney';
import { KpiMetricsStatsFragment } from './__generated__/component';

type Props = {
  title: string;
  allTimeValue: Maybe<number>;
  allTimeLabel: Maybe<string>;
  preselectedLabel: Maybe<string>;
  preselectedValue: Maybe<number>;
  currency: Maybe<KpiMetricsStatsFragment['rows'][number]['currency']>;
};

const KpiMetricDetails = ({
  title,
  allTimeLabel,
  preselectedLabel,
  allTimeValue,
  preselectedValue,
  currency,
}: Props) => {
  return (
    <Card
      size="lg"
      title={title}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <DetailList
        items={[
          {
            label: allTimeLabel,
            value: formatMoney(allTimeValue ?? 0, currency),
          },
          {
            label: preselectedLabel,
            value: formatMoney(preselectedValue ?? 0, currency),
          },
        ]}
      />
    </Card>
  );
};

export default KpiMetricDetails;
