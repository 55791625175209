import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { feedback } from 'react-feedbacker';
import { gql, useMutation, useQuery } from 'urql';

import { useFeedbackMessages } from '@/bits';
import {
  Card,
  CardCloseButton,
  CardOptions,
  Form,
  SelectField,
  SubmitButton,
  TextField,
} from '@/components';
import { useTranslate } from '@/contexts';
import { AmlRiskLevel } from '@/globalTypes';
import {
  ChangeAmlRiskLevelMutation,
  ChangeAmlRiskLevelMutationVariables,
  PlayerAmlRiskLevelFormQuery,
  PlayerAmlRiskLevelFormQueryVariables,
} from './__generated__/PlayerAmlRiskLevelForm';

const query = graphql`
  query SanityPlayerAmlRiskLevelForm {
    sanityPlayerAmlRiskLevelForm {
      title {
        ...SanityLocaleString
      }
      amlLevelLabel {
        ...SanityLocaleString
      }
      riskLevelNotSet {
        ...SanityLocaleString
      }
      riskLevelLow {
        ...SanityLocaleString
      }
      riskLevelMedium {
        ...SanityLocaleString
      }
      riskLevelHigh {
        ...SanityLocaleString
      }
      reasonLabel {
        ...SanityLocaleString
      }
      submit {
        ...SanityLocaleString
      }
    }
  }
`;

const QUERY = gql`
  query PlayerAmlRiskLevelForm($playerId: ID!) {
    player(playerId: $playerId) {
      id
      rawPlayerId
      playerComplianceDetails {
        id
        risk
        status
      }
    }
  }
`;

const MUTATION = gql`
  mutation ChangeAmlRiskLevel(
    $playerId: ID!
    $amlLevel: AmlRiskLevel!
    $reason: String!
  ) {
    changeAmlRiskLevel(
      playerId: $playerId
      amlLevel: $amlLevel
      reason: $reason
    ) {
      id
      status
      risk
      needsManualReview
    }
  }
`;

const PlayerAmlRiskLevelForm = ({ playerId }: { playerId: string }) => {
  const { t } = useTranslate();
  const feedbackMessages = useFeedbackMessages();
  const staticData =
    useStaticQuery<Queries.SanityPlayerAmlRiskLevelFormQuery>(query);

  const form = staticData.sanityPlayerAmlRiskLevelForm;

  const [{ data }] = useQuery<
    PlayerAmlRiskLevelFormQuery,
    PlayerAmlRiskLevelFormQueryVariables
  >({
    query: QUERY,
    variables: { playerId },
  });

  const [changeAmlRiskState, changeAmlRisk] = useMutation<
    ChangeAmlRiskLevelMutation,
    ChangeAmlRiskLevelMutationVariables
  >(MUTATION);

  if (!form || !data || !data.player.playerComplianceDetails) {
    return null;
  }

  const defaultValues = {
    amlLevel: data.player.playerComplianceDetails.risk,
    reason: '',
  };

  const onSubmit = (values: typeof defaultValues) => {
    changeAmlRisk({
      playerId: data.player.rawPlayerId,
      amlLevel: values.amlLevel,
      reason: values.reason,
    }).then((res) => {
      if (res.error?.message) {
        feedback.error(res.error.message);
      } else {
        feedback.success(t(feedbackMessages.success));
      }
    });
  };

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">
        <Form
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          className="grid grid-cols-2 sm:grid-cols-3 gap-6"
        >
          <SelectField
            name="amlLevel"
            id="PlayerAmlRiskLevelForm__amlLevel"
            title={t(form.amlLevelLabel)}
            options={[
              {
                label: t(form.riskLevelNotSet),
                value: AmlRiskLevel.NotSet,
              },
              {
                label: t(form.riskLevelLow),
                value: AmlRiskLevel.Low,
              },
              {
                label: t(form.riskLevelMedium),
                value: AmlRiskLevel.Medium,
              },
              {
                label: t(form.riskLevelHigh),
                value: AmlRiskLevel.High,
              },
            ]}
          />

          <TextField
            title={t(form.reasonLabel)}
            name="reason"
            id="PlayerAmlRiskLevelForm__reason"
            className="col-span-full"
          />
          <SubmitButton
            value={t(form.submit)}
            disabled={changeAmlRiskState.fetching}
          />
        </Form>
      </div>
    </Card>
  );
};

export default PlayerAmlRiskLevelForm;
