import {
  BooleanParam,
  DateTimeParam,
  StringParam,
  withDefault,
} from 'use-query-params';

import { PaymentApprovalSearchOrder } from '@/globalTypes';
import {
  DecisionSearchTypeParam,
  DecisionTypeParam,
  PaymentApprovalSearchOrderParam,
  PaymentIntegrationParam,
  PaymentTypeParam,
} from '@/utils/query/params';

export const queryParams = {
  processedAtfrom: DateTimeParam,
  processedAtTo: DateTimeParam,
  paymentId: StringParam,
  playerId: StringParam,
  paymentType: PaymentTypeParam,
  PaymentIntegration: PaymentIntegrationParam,
  address: StringParam,
  autoDecision: DecisionTypeParam,
  manualDecision: DecisionSearchTypeParam,
  desc: withDefault(BooleanParam, true),
  orderBy: withDefault(PaymentApprovalSearchOrderParam, [
    PaymentApprovalSearchOrder.processedAt,
  ]),
  before: StringParam,
  after: StringParam,
};
